import { Button } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';

import Divider from '../../../components/Divider/Divider';

import {
  Package,
  PackagePriceData,
} from '../../../redux/reducers/stripeReducer';
import {
  formatCreditsPerDay,
  formatCreditsPerMonth,
} from '../../../lib/subscriptionPlanUtils';
import { DesktopOnly, MobileOnly } from '../../../lib/stylingUtils';
import { SubscriptionPlan } from '../../../types/subscription';

interface Props {
  selectedPlan?: Package;
  selectedTier?: PackagePriceData;
  handleCheckoutClick: () => void;
  handleBackClick: () => void;
  isCheckoutDisabled: boolean;
}

const OrderSummary = ({
  handleCheckoutClick,
  handleBackClick,
  selectedPlan,
  selectedTier,
  isCheckoutDisabled,
}: Props) => (
  <Wrapper>
    <Typography color="grey.A200" variant="h6">
      Order summary
    </Typography>
    <PlanInfoWrapper>
      <PlanInfoItem>
        {selectedPlan ? (
          <Row>
            <Typography color="primary.main" variant="h6">
              Price per month
            </Typography>
            <Typography color="primary.main" variant="h6">
              {selectedPlan.name === SubscriptionPlan.enterprise
                ? 'Custom'
                : `${selectedTier?.price_per_month || 0}`}
            </Typography>
          </Row>
        ) : (
          <Row>
            <Typography color="primary.main" variant="h6">
              Select a plan...
            </Typography>
          </Row>
        )}
        {selectedPlan && (
          <>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                API Package
              </Typography>
              <PlanName color="grey.A200" variant="paragraphSmall">
                {selectedPlan?.name}
              </PlanName>
            </Row>
            <Row>
              <Typography color="grey.A200" variant="paragraphSmall">
                API credits
              </Typography>
              <Typography color="grey.A200" variant="paragraphSmall">
                {selectedPlan.name === SubscriptionPlan.enterprise
                  ? 'Custom'
                  : selectedPlan?.name === SubscriptionPlan.artist
                  ? formatCreditsPerDay(selectedPlan?.credits_per_day)
                  : formatCreditsPerMonth(selectedPlan?.credits_per_month)}
              </Typography>
            </Row>
          </>
        )}
      </PlanInfoItem>
      <Divider />
    </PlanInfoWrapper>
    <ButtonWrapper>
      <DesktopOnly>
        <Button disabled={isCheckoutDisabled} onClick={handleCheckoutClick}>
          Checkout
        </Button>
      </DesktopOnly>
      <MobileOnly>
        <Actions>
          <BackButton onClick={handleBackClick}>Back</BackButton>
          <Button disabled={isCheckoutDisabled} onClick={handleCheckoutClick}>
            Checkout
          </Button>
        </Actions>
      </MobileOnly>
    </ButtonWrapper>
  </Wrapper>
);

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
});

const PlanInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
});

const PlanInfoItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PlanName = styled(Typography)({
  textTransform: 'capitalize',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  '& > button': {
    width: 'calc(50% - 16px)',
  },
});

const BackButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey['50'],
  color: theme.palette.grey.A200,

  '&:hover': {
    background: theme.palette.grey['50'],
    color: theme.palette.grey.A200,
  },
}));

export default OrderSummary;
