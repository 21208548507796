export enum SettingsPages {
  accountDetails = 'accountDetails',
  credentials = 'credentials',
  overview = 'overview',
  plans = 'plans',
  billing = 'billing',
  team = 'team',
}

export enum CheckoutSteps {
  orderSummary = 'orderSummary',
  checkout = 'checkout',
  waitingForPayment = 'waitingForPayment',
  failure = 'failure',
  success = 'success',
}
