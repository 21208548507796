import { styled, Typography } from '@mui/material';

interface Props {
  cx: number;
  cy: number;
  isAnimationActive: boolean;
  name: string;
}

const CustomPieChartTooltip = ({ cx, cy, isAnimationActive, name }: Props) => {
  return (
    <Wrapper cx={cx} cy={cy} hover={isAnimationActive}>
      <TooltipText variant="paragraphMedium" lineHeight="19px">
        {name}
      </TooltipText>
    </Wrapper>
  );
};

export const Wrapper = styled('div')<{
  cx: number;
  cy: number;
  hover: boolean;
}>(({ theme, cx, cy, hover }) => ({
  display: 'block',
  position: 'absolute',
  top: `${cy}px`,
  left: `${cx}px`,
  backgroundColor: '#fff',
  borderRadius: '15px',
  border: 'none',
  outline: 'none',
  padding: '4px 10px',
  height: 'auto',
  pointerEvents: 'none',
  transition: 'all 0.2s',

  ...(hover
    ? {
        visibility: 'visible',
        opacity: 0.85,
      }
    : {
        visibility: 'hidden',
        opacity: 0,
      }),
}));

export const TooltipText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.grey.A200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export default CustomPieChartTooltip;
