import {
  MetricsItem,
  ProjectStateModules,
  QueryDistributionItem,
  RequestVolume,
} from '../../types/project';
import {
  UPDATE_PROJECT_METRICS,
  UPDATE_PROJECT_METRICS_LOADING,
  UPDATE_QUERY_DISTRIBUTION,
  UPDATE_QUERY_DISTRIBUTION_LOADING,
  UPDATE_SINGLE_PROJECT_REQUEST_VOLUME,
  UPDATE_SINGLE_PROJECT_REQUEST_VOLUME_LOADING,
} from '../actions/singleProjectActions';
import { StoreType } from '../types/store.types';

export interface singleProjectStateType {
  [ProjectStateModules.metrics]: {
    metrics: MetricsItem | null;
    isLoading: boolean;
  };
  [ProjectStateModules.queryDistribution]: {
    queryDistribution: QueryDistributionItem[];
    isLoading: boolean;
  };
  [ProjectStateModules.requestVolume]: {
    requestVolume: RequestVolume | null;
    isLoading: boolean;
  };
}
export const singleProjectInitialState: singleProjectStateType = {
  [ProjectStateModules.metrics]: {
    metrics: null,
    isLoading: true,
  },
  [ProjectStateModules.queryDistribution]: {
    queryDistribution: [],
    isLoading: true,
  },
  [ProjectStateModules.requestVolume]: {
    isLoading: false,
    requestVolume: null,
  },
};

const singleProjectReducer = (
  state = singleProjectInitialState,
  action: any
) => {
  switch (action.type) {
    case UPDATE_PROJECT_METRICS: {
      return {
        ...state,
        [ProjectStateModules.metrics]: {
          ...state[ProjectStateModules.metrics],
          metrics: action.payload.metrics,
        },
      };
    }
    case UPDATE_PROJECT_METRICS_LOADING: {
      return {
        ...state,
        [ProjectStateModules.metrics]: {
          ...state[ProjectStateModules.metrics],
          isLoading: action.payload.status,
        },
      };
    }
    case UPDATE_QUERY_DISTRIBUTION: {
      return {
        ...state,
        [ProjectStateModules.queryDistribution]: {
          ...state[ProjectStateModules.queryDistribution],
          queryDistribution: action.payload.data,
        },
      };
    }
    case UPDATE_QUERY_DISTRIBUTION_LOADING: {
      return {
        ...state,
        [ProjectStateModules.queryDistribution]: {
          ...state[ProjectStateModules.queryDistribution],
          isLoading: action.payload.status,
        },
      };
    }
    case UPDATE_SINGLE_PROJECT_REQUEST_VOLUME: {
      return {
        ...state,
        [ProjectStateModules.requestVolume]: {
          ...state[ProjectStateModules.requestVolume],
          requestVolume: action.payload.data,
        },
      };
    }
    case UPDATE_SINGLE_PROJECT_REQUEST_VOLUME_LOADING: {
      return {
        ...state,
        [ProjectStateModules.requestVolume]: {
          ...state[ProjectStateModules.requestVolume],
          isLoading: action.payload.status,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getProjectMetrics = (state: StoreType) =>
  state.singleProject[ProjectStateModules.metrics].metrics;
export const getProjectMetricsLoading = (state: StoreType) =>
  state.singleProject[ProjectStateModules.metrics].isLoading;
export const getProjectQueryDistribution = (state: StoreType) =>
  state.singleProject[ProjectStateModules.queryDistribution].queryDistribution;
export const getQueryDistributionLoading = (state: StoreType) =>
  state.singleProject[ProjectStateModules.queryDistribution].isLoading;
export const getSingleRequestVolume = (state: StoreType) =>
  state.singleProject[ProjectStateModules.requestVolume].requestVolume;
export const getSingleRequestVolumeLoading = (state: StoreType) =>
  state.singleProject[ProjectStateModules.requestVolume].isLoading;

export default singleProjectReducer;
