import { styled, useTheme, Theme } from '@mui/material';
import {
  CreateWebhookFields,
  TriggerCondition,
} from '../../../../forms/createWebhook/types';
import { NewEntryField, NewEntryFieldTypes } from '../../../../types/newEntry';
import { Dropdown, TextField } from '@maestro-org/ui-kit';

import { Option } from '../../../../types/newEntry';

interface DynamicFieldsGroupProps {
  condition: TriggerCondition;
  onFieldChange: (fieldName: string, value: any) => void;
  fields: NewEntryField[];
}

const DynamicFieldsGroup = ({
  condition,
  onFieldChange,
  fields,
}: DynamicFieldsGroupProps) => {
  const theme = useTheme();

  const handleFieldChange = (fieldName: string, value: any) => {
    onFieldChange(fieldName, value);

    if (fieldName === CreateWebhookFields.field) {
      onFieldChange(CreateWebhookFields.operator, []);
      onFieldChange(CreateWebhookFields.value, '');
    }
  };

  const getFieldValue = (field: NewEntryField) => {
    const fieldName = field.name as keyof Omit<TriggerCondition, 'id'>;
    const value = condition[fieldName];

    if (field.type === NewEntryFieldTypes.select) {
      return Array.isArray(value) ? value : [];
    }

    return typeof value === 'string' ? value : '';
  };

  const isOperatorDisabled = (fieldName: string) => {
    if (fieldName === 'operator') {
      return !condition.field.length;
    }
    return false;
  };

  const getModifiedField = (field: NewEntryField) => {
    return field;
  };

  const getSelectedLabels = (selectedValues: string[], options: Option[]) => {
    return selectedValues
      .map((val) => {
        const option = options.find((opt) => opt.value === val);
        return option ? option.label : val;
      })
      .join(', ');
  };

  return (
    <>
      {fields.map((field: NewEntryField) => {
        const modifiedField = getModifiedField(field);
        return (
          <Wrapper key={`${condition.id}_${field.name}`}>
            {field.type === NewEntryFieldTypes.select ? (
              <StyledSelect
                label={modifiedField.label}
                name={`${modifiedField.name}_${condition.id}`}
                value={getFieldValue(modifiedField) as string[]}
                onChange={(value) =>
                  handleFieldChange(modifiedField.name, value)
                }
                renderValue={(selected) =>
                  getSelectedLabels(selected as string[], field.options || [])
                }
                options={modifiedField.options || []}
                fieldLabel={modifiedField.fieldLabel}
                disabled={isOperatorDisabled(field.name)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      ...getMenuStyle(theme),
                    },
                  },
                }}
              />
            ) : (
              <StyledTextfield
                variant="outlined"
                label={modifiedField.label}
                name={`${modifiedField.name}_${condition.id}`}
                value={getFieldValue(modifiedField)}
                onChange={(e) =>
                  handleFieldChange(modifiedField.name, e.target.value)
                }
                fieldLabel={modifiedField.fieldLabel}
                inputProps={{
                  maxLength: 64,
                }}
              />
            )}
          </Wrapper>
        );
      })}
    </>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
});

const StyledTextfield = styled(TextField)<{ isMaxReached?: boolean }>(
  ({ isMaxReached }) => ({
    '& fieldset': {
      borderRadius: '4px !important',
      borderWidth: '1px !important',
    },

    '& .MuiInputBase-root': {
      padding: '12.5px 16px',
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: '#DC6675 !important',
    },

    ...(isMaxReached && {
      '& fieldset': {
        borderColor: '#DC6675 !important',
      },

      '& .Mui-focused fieldset': {
        borderColor: '#DC6675 !important',
      },
    }),
  })
);

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  paddingLeft: '0',

  '& .MuiSelect-select': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px !important',
    borderWidth: '1px !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '& .MuiInputBase-inputAdornedStart': {
    '& span': {
      paddingLeft: '14px',
    },
  },

  '& img': {
    position: 'absolute',
    left: '7px',
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : 'auto',
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: 'none',
  border: `2px solid ${
    error
      ? theme.palette.dropdown.border.error
      : theme.palette.dropdown.border.main
  }`,
  transform: 'translateY(5px)',
});

export default DynamicFieldsGroup;
