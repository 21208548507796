export enum NewEntryFieldTypes {
  text = 'text',
  select = 'select',
}

export interface Option {
  value: string;
  label?: string;
  disabled?: boolean;
}

export interface NewEntryField {
  type: NewEntryFieldTypes;
  name: string;
  label?: string;
  placeholder: string;
  options?: Option[];
  fieldLabel?: string;
}
