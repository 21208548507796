import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteEvent } from './hooks/useDeleteEvent';

const DeleteEvent = () => {
  const { isOpen, handleClose, handleDelete } = useDeleteEvent();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Delete Event Manager"
      description="Are you sure you want to delete this event manager? The action cannot be undone."
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmLabel="Delete"
    />
  );
};

export default DeleteEvent;
