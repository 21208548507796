import { Chip } from '@maestro-org/ui-kit';
import { styled } from '@mui/material';

import { SubscriptionPlan } from '../../types/subscription';
import { getPlanIcon } from '../../lib/subscriptionPlanUtils';
import { formActiveSubscriptionName } from '../../lib/subscriptionPlanUtils';
import { Package } from '../../redux/reducers/stripeReducer';

interface Props {
  activeSubscriptionName: SubscriptionPlan;
  textColor?: string;
  subscriptionPlans: Package[];
  activeSubscriptionId: string;
}

const PlanChip = ({
  activeSubscriptionName,
  textColor,
  subscriptionPlans,
  activeSubscriptionId,
}: Props): JSX.Element => (
  <StyledChip
    textColor={textColor || '#0F0311'}
    color="custom"
    hexColor="#D166E0"
    label={
      <>
        {getPlanIcon[activeSubscriptionName]}
        {formActiveSubscriptionName(
          activeSubscriptionId,
          activeSubscriptionName,
          subscriptionPlans
        )}
      </>
    }
  />
);

const StyledChip = styled(Chip)<{ textColor: string }>(
  ({ theme, textColor }) => ({
    borderRadius: '43px',
    background: theme.palette.grey[50],
    padding: '8px 16px',
    height: 'auto',
    textTransform: 'uppercase',

    '&:hover': {
      background: theme.palette.grey[50],
    },

    '& .MuiChip-label': {
      color: textColor,
      fontWeight: `${theme.typography.h6.fontWeight} !important`,
      lineHeight: '20px',
      fontSize: '14px !important',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',

      '& svg': {
        width: '20px',
        height: '20px',
      },

      '& path': {
        fill: theme.palette.primary.main,
      },
    },

    '& svg': {
      display: 'inline-flex',
    },
  })
);

export default PlanChip;
