import { AuthField } from '../../types/auth';
import { ResetPasswordFields } from './types';

export const initialValuesResetPassword = {
  [ResetPasswordFields.email]: '',
};

export const resetPasswordFields: AuthField[] = [
  {
    type: 'email',
    fieldLabel: 'Email address',
    name: ResetPasswordFields.email,
    fullwidth: true,
  },
];
