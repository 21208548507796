export const BuildTogetherTipIcon = () => (
  <svg
    width="54"
    height="48"
    viewBox="0 0 54 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 19.1971V33.5985H14.5254V19.1971H0ZM12.1033 31.1971H2.42214V21.5985H12.1033V31.1971ZM26.6213 0H12.1033V14.4015H26.6287V0H26.6213ZM38.7246 21.5985H26.6213V33.5985H14.5254V48H53.25V9.59853H38.7246V21.5985ZM29.0434 24H38.7246V33.5985H29.0434V24ZM26.6213 45.5985H16.9401V36H26.6213V45.5985ZM38.7246 45.5985H29.0434V36H38.7246V45.5985ZM50.8279 33.5985H41.1467V24H50.8279V33.5985ZM41.1467 12H50.8279V21.5985H41.1467V12Z"
      fill="#C53DD8"
    />
  </svg>
);
