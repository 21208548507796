import { combineReducers } from 'redux';

import appReducer from './appReducer';
import authReducer from './authReducer';
import popupsReducer from './popupsReducer';
import projectsReducer from './projectsReducer';
import requestVolumeReducer from './requestVolumeReducer';
import singleProjectReducer from './singleProjectReducer';
import stripeReducer from './stripeReducer';
import transactionsReducer from './transactionsReducer';
import usersReducer from './usersReducer';
import webhooksReducer from './webhooksReducer';
import teamsReducer from './teamsReducer';
import eventsReducer from './eventsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  transactions: transactionsReducer,
  projects: projectsReducer,
  singleProject: singleProjectReducer,
  webhooks: webhooksReducer,
  requestVolume: requestVolumeReducer,
  stripe: stripeReducer,
  popups: popupsReducer,
  users: usersReducer,
  team: teamsReducer,
  events: eventsReducer,
});

export default rootReducer;
