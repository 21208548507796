import { TooltipProps, Tooltip as MuiTooltip, styled } from '@mui/material';
import React from 'react';

const Tooltip = ({ ...props }: TooltipProps) => <StyledTooltip {...props} />;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    background: theme.palette.tooltip.background,
  },
}));

export default Tooltip;
