import { Sector, Surface } from 'recharts';
import { Typography, styled } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { TokenomicsPoint } from '../../../types/tokenomicsTypes';

import CustomPieChartLabel from './CustomPieChartLabel';
import CustomPieChartLegend from './CustomPieChartLegend';
import CustomPieChartTooltip from './CustomPieChartTooltip';
import handleViewport, {
  InjectedViewportProps,
  useInViewport,
} from 'react-in-viewport';
import {
  APIRefIcon,
  CodeIcon,
  EmptyTransactionsIcon,
  UrlIcon,
} from '../../Icons';
import Tabs from '../../Tabs/Tabs';
import { TabsTypes } from '../../../modules/Project/components/PieChartCard';
import { Chip } from '@maestro-org/ui-kit';

const sectionsSpacing = -0.5;

interface PieChartProps {
  timeFrameKey?: number | string;
  data: TokenomicsPoint[];
  image: string;
  color: any;
  selectSection: string;
  handleSelectSection: (label: string) => void;
  tabsPanelOptions: {
    value: TabsTypes;
    label: string;
  }[];
  tabValue: TabsTypes;
  handleTabsChange: (event: React.SyntheticEvent, newValue: TabsTypes) => void;
}

interface ViewportProps {
  inViewport: boolean;
  forwardedRef: any;
  enterCount: number;
}

interface AnimationProps {
  isAnimationActive: boolean;
}

const Chart = ({
  timeFrameKey,
  data,
  image,
  color,
  selectSection,
  handleSelectSection,
  isAnimationActive,
  tabsPanelOptions,
  tabValue,
  handleTabsChange,
}: AnimationProps & PieChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [isAnimActive, setIsAnimActive] = useState<boolean>(false);

  const onMouseOver = useCallback((e: any, index: number, label: string) => {
    setActiveIndex(index);
    setIsAnimActive(true);
    setCoordinates({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
    handleSelectSection(label);
  }, []);

  const onMouseLeave = useCallback((e: any) => {
    setIsAnimActive(false);
    handleSelectSection('');
  }, []);

  const arraySum = data.reduce(
    (sum: number, elem: TokenomicsPoint) => sum + elem.count,
    0
  );

  const angle = data.map(
    (elem: TokenomicsPoint) => (-elem.count / arraySum) * 360
  );

  const total = data.reduce(
    (acc: number, item: TokenomicsPoint) => (acc += item.count),
    0
  );

  useEffect(() => {
    if (isAnimationActive) {
      const surfaceElement = document.getElementById('surface');
      if (!surfaceElement) return;

      surfaceElement.classList.add('animated');
    }
  }, [isAnimationActive, timeFrameKey]);

  return (
    <Wrapper>
      <Left>
        <TitleWrapper>
          <Typography variant="h5" color="grey.A200">
            Query Distribution
          </Typography>
          <a
            href="https://docs.gomaestro.org/platform-overview"
            target="_blank"
            rel="noreferrer"
          >
            <APIRefChip
              color="custom"
              hexColor="#FFFFFF"
              label={
                <ChipContent>
                  <CodeIcon />
                  <Typography color="grey.A200" variant="paragraphMedium">
                    API Reference
                  </Typography>
                </ChipContent>
              }
            />
          </a>
        </TitleWrapper>
        <CustomPieChartLegend
          data={data}
          selectSection={selectSection}
          handleSelectSection={handleSelectSection}
        />
      </Left>
      <Right>
        <Tabs
          options={tabsPanelOptions}
          value={tabValue}
          onChange={handleTabsChange}
          variant="fullWidth"
        />
        <ChartWrapper>
          {total ? (
            <Surface
              height={350}
              width={349}
              viewBox={{
                width: 349,
                height: 350,
                x: -15,
                y: -15,
              }}
              key={timeFrameKey}
              id="surface"
            >
              {data.map((radius: TokenomicsPoint, index: number) => {
                const startAngle =
                  (angle
                    .slice(0, index)
                    .reduce((prev: number, curr: number) => prev + curr, 0) ||
                    0) + 90;
                const endAngle = startAngle + angle[index];

                return (
                  <Sector
                    style={{
                      transform: `rotate(${startAngle}deg)`,
                      opacity: radius.label === selectSection ? 0.5 : 1,
                    }}
                    fill={color[index % color.length]}
                    innerRadius={50}
                    outerRadius={110 + index * 6}
                    cx={165}
                    cy={165}
                    startAngle={startAngle + sectionsSpacing}
                    endAngle={endAngle - sectionsSpacing}
                    key={radius.label}
                    onMouseEnter={(e) => onMouseOver(e, index, radius.label)}
                    onMouseLeave={(e) => onMouseLeave(e)}
                  />
                );
              })}
              <CustomPieChartLabel
                image={image}
                value={data[activeIndex]?.count || 0}
                activeIndex={activeIndex}
                isAnimationActive={isAnimActive}
                cx={165}
                cy={165}
                color={color}
              />
            </Surface>
          ) : (
            <div>Query data is empty</div>
          )}
        </ChartWrapper>
        <CustomPieChartTooltip
          cx={coordinates.x}
          cy={coordinates.y}
          name={data[activeIndex]?.label || ''}
          isAnimationActive={isAnimActive}
        />
      </Right>
    </Wrapper>
  );
};

const PieChartContent = ({
  color,
  data,
  handleSelectSection,
  handleTabsChange,
  image,
  selectSection,
  tabValue,
  tabsPanelOptions,
  timeFrameKey,
  isAnimationActive,
}: PieChartProps & AnimationProps) => {
  return (
    <>
      {data.length ? (
        <Chart
          timeFrameKey={timeFrameKey}
          data={data}
          image={image}
          color={color}
          selectSection={selectSection}
          handleSelectSection={handleSelectSection}
          isAnimationActive={isAnimationActive}
          tabsPanelOptions={tabsPanelOptions}
          tabValue={tabValue}
          handleTabsChange={handleTabsChange}
        />
      ) : (
        <EmptyMessageWrapper>
          <EmptyIconWrapper>
            <EmptyTransactionsIcon />
          </EmptyIconWrapper>
          <NotFoundTitle color="grey.A200" variant="h6">
            No results found
          </NotFoundTitle>
          <NotFoundSubtitle color="grey.300" variant="paragraphMedium">
            Try making your first transactions in order to find what you’re
            looking for.
          </NotFoundSubtitle>
        </EmptyMessageWrapper>
      )}
    </>
  );
};

const PieChart = ({
  timeFrameKey,
  data,
  image,
  color,
  selectSection,
  handleSelectSection,
  tabsPanelOptions,
  tabValue,
  handleTabsChange,
}: PieChartProps) => {
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const myRef = useRef(null);
  const { inViewport, enterCount, leaveCount } = useInViewport(myRef);

  useEffect(() => {
    if (inViewport && enterCount === 1) {
      setIsAnimationActive(true);
    }
  }, [inViewport, enterCount]);

  return (
    <div ref={myRef}>
      <PieChartContent
        color={color}
        data={data}
        handleSelectSection={handleSelectSection}
        handleTabsChange={handleTabsChange}
        image={image}
        selectSection={selectSection}
        tabValue={tabValue}
        tabsPanelOptions={tabsPanelOptions}
        timeFrameKey={timeFrameKey}
        isAnimationActive={isAnimationActive}
      />
    </div>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  // alignItems: "center",
  justifyContent: 'space-between',
  width: '100%',

  '.recharts-surface': {
    '.recharts-sector': {
      transition: 'opacity 0.2s, transform 1.2s',
      transformOrigin: 'inherit',

      '&:hover': {
        opacity: 0.75,
      },
    },

    '&.animated .recharts-sector': {
      transform: 'none !important',
    },
  },

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '51px',
  },
}));

const EmptyMessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '7px 0 89px',
  // justifyContent: "center",
  alignItems: 'center',
  rowGap: '12px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '40px',
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '30px',
  },
}));

const EmptyIconWrapper = styled('div')({
  paddingBottom: '20px',

  '& circle': {
    fill: '#FFFFFF',
  },
});

const NotFoundTitle = styled(Typography)({
  lineHeight: '20px',
});

const NotFoundSubtitle = styled(Typography)({
  fontWeight: 300,
  textAlign: 'center',
});

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '20px',
  },
}));

const APIRefChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  padding: '5px 20px',

  '& svg': {
    display: 'block',
  },
}));

const ChipContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const Left = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '48px',
  flex: 3,

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },

  [theme.breakpoints.down('sm')]: {
    rowGap: '32px',
  },
}));

const Right = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '48px',
  alignItems: 'flex-end',
  position: 'relative',
  flex: 2,

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    alignItems: 'center',
    rowGap: 0,
  },
}));

const ChartWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '310px',
});

export default PieChart;
