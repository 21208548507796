import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useUpdateTeamMemberRole } from './hooks/useUpdateTeamMemberRole';

const UpdateTeamMemberRole = () => {
  const { isOpen, email, handleClose, handleUpdate } =
    useUpdateTeamMemberRole();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Update ${email} role`}
      description="Are you sure you want to update this user's role?"
      onConfirm={handleUpdate}
      onCancel={handleClose}
      confirmLabel="Update"
    />
  );
};

export default UpdateTeamMemberRole;
