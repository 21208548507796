import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import SettingsLayout from '../../layouts/SettingsLayout';

import CurrentSubscriptionPlan from './components/CurrentSubscriptionPlan';
import AvailableCards from './components/AvailableCards';
import BillingInfo from './components/BillingInfo';

import {
  getAllPaymentMethods,
  getBillingDataLoading,
} from '../../redux/reducers/stripeReducer';

import { SettingsPages } from '../../types/settings';
import withPrivateRoute from '../../hooks/withPrivateRoute';
import EmptyPaymentMethods from './components/EmptyPaymentMethods';
import { getCustomerServer } from '../../redux/actions/stripeActions';

const SubscriptionPlanPage = () => {
  const paymentMethods = useSelector(getAllPaymentMethods);
  const isLoading = useSelector(getBillingDataLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerServer());
  }, []);

  return (
    <SettingsLayout
      path={[
        { title: 'Settings', href: '/' },
        { title: 'Subscription', href: '/' },
        { title: 'Overview', href: '/' },
      ]}
      active={SettingsPages.overview}
    >
      <Wrapper>
        <CurrentSubscriptionPlan />
        {paymentMethods.length || isLoading ? (
          <>
            <AvailableCards />
            <BillingInfo />
          </>
        ) : (
          <EmptyPaymentMethods />
        )}
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
}));

export default withPrivateRoute(SubscriptionPlanPage);
