import React from 'react';
import { styled, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Popups } from '../../../../types/popups';
import { updatePopup } from '../../../../redux/actions/popupsActions';

import { useDispatch } from 'react-redux';

import {
  ArrowDownTriangleIcon,
  ArrowDownTriangleGreyIcon,
} from '../../../../components/Icons';

interface Props {
  email: string;
  currentRole: string;
  isUserPending: boolean;
}

const options = ['Admin', 'Developer', 'Member'];

const MemberRoleMenu = ({ email, currentRole, isUserPending }: Props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleChange = (role: string) => {
    dispatch(
      updatePopup({
        popup: Popups.updateTeamMemberRole,
        status: true,
        prefilled: { email, role: role.toLowerCase() },
      })
    );
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label="items-menu"
        disabled={isUserPending}
      >
        {isUserPending ? (
          <ArrowDownTriangleGreyIcon />
        ) : (
          <ArrowDownTriangleIcon />
        )}
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <StyledMenuItem
            key={option}
            selected={option === currentRole}
            onClick={() => handleRoleChange(option)}
          >
            <StyledItemText>{option}</StyledItemText>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

//prettier-ignore
const StyledMenu = styled(Menu)(({ theme }) => ({
    "& .MuiList-root": {
        padding: "16px",
        gap: "16px",
        display: "flex",
        flexDirection: "column",

        "& .Mui-selected": {
            backgroundColor: "transparent !important",

            "& .MuiTypography-root": {
                color: theme.palette.contrastText.main,
            },
        },
    },
}));

//prettier-ignore
const StyledItemText = styled(Typography)({
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "#333333",
});

//prettier-ignore
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: "0",

    "&:hover": {
        backgroundColor: "transparent",

        "& .MuiTypography-root": {
            color: theme.palette.contrastText.main,
        },
    },
}));

export default MemberRoleMenu;
