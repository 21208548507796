import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TriggerCondition } from '../../../../forms/createWebhook/types';
import { useCurrentSubscription } from '../../../../hooks/useCurrentSubscription';
import { checkLimit, getLimitTooltip } from '../../../../lib/eventManagerUtils';
import { getEvents } from '../../../../redux/reducers/eventsReducer';
import { Option } from '../../../../types/newEntry';

export const useTriggerConditions = (isEditMode: boolean, prefillData: any) => {
  const events = useSelector(getEvents);
  const currentSubscription = useCurrentSubscription();

  const [triggerConditions, setTriggerConditions] = useState<
    TriggerCondition[]
  >([
    {
      id: '0',
      field: [],
      operator: [],
      value: '',
    },
  ]);

  const useTriggersCount = (excludeEventId?: string) => {
    return events.reduce((total, event) => {
      if (event.id === excludeEventId) return total;
      return total + (event.triggers?.length || 0);
    }, 0);
  };

  const totalTriggersCount = useMemo(
    () => useTriggersCount(isEditMode && prefillData.id),
    [events, isEditMode, prefillData.id]
  );

  const canAddTrigger = () => {
    const { isLimitReached } = checkLimit({
      currentCount: totalTriggersCount + triggerConditions.length,
      subscriptionPlan: currentSubscription.name,
      limitType: 'triggers',
    });

    return !isLimitReached;
  };

  const getAddTriggerTooltip = () => {
    return canAddTrigger()
      ? ''
      : getLimitTooltip(currentSubscription.name, 'triggers');
  };

  const handleAddTrigger = (options: Option[]) => {
    if (canAddTrigger()) {
      const selectedFields = triggerConditions
        .filter((condition) => condition.field.length > 0)
        .map((condition) => condition.field[0]);

      const availableOptions = options.filter(
        (option) => !selectedFields.includes(option.value)
      );

      if (availableOptions.length === 0) {
        return;
      }

      setTriggerConditions((prev) => [
        ...prev,
        { id: String(prev.length), field: [], operator: [], value: '' },
      ]);
    }
  };

  const areTriggerConditionsValid = (): boolean => {
    return triggerConditions.every(
      (condition) =>
        condition.field.length > 0 &&
        condition.operator.length > 0 &&
        condition.value.trim() !== ''
    );
  };

  const getFieldDisabledOptions = (
    id: string,
    conditionalsPicklist: Option[]
  ) => {
    const selectedFields = triggerConditions
      .filter((condition) => condition.id !== id && condition.field.length > 0)
      .map((condition) => condition.field[0]);

    return conditionalsPicklist.map((option) => ({
      ...option,
      disabled: selectedFields.includes(option.value),
    }));
  };

  const handleFieldChange = (
    conditionId: string,
    fieldName: string,
    value: any
  ) => {
    setTriggerConditions((prev) =>
      prev.map((item) =>
        item.id === conditionId ? { ...item, [fieldName]: value } : item
      )
    );
  };

  const handleRemoveCondition = (conditionId: string) => {
    setTriggerConditions((prev) =>
      prev.filter((item) => item.id !== conditionId)
    );
  };

  return {
    triggerConditions,
    setTriggerConditions,
    canAddTrigger,
    getAddTriggerTooltip,
    handleAddTrigger,
    areTriggerConditionsValid,
    getFieldDisabledOptions,
    handleFieldChange,
    handleRemoveCondition,
  };
};
