import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteTeamMember } from './hooks/useDeleteTeamMember';

const DeleteTeamMember = () => {
  const { isOpen, email, handleClose, handleDelete } = useDeleteTeamMember();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Delete ${email}`}
      description="Are you sure you want to delete this user?"
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmLabel="Delete"
    />
  );
};

export default DeleteTeamMember;
