import { styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMetricsOverviewServer } from '../../../redux/actions/userActions';
import {
  getUserMetrics,
  getUserMetricsLoading,
} from '../../../redux/reducers/usersReducer';
import { getIsAuthorized } from '../../../redux/reducers/authReducer';

import SubscriptionWidget from './SubscriptionWidget';
import Container from '../../../components/Container/Container';
import WidgetCard from './WidgetCard';

const Widgets = () => {
  const dispatch = useDispatch();

  const userMetrcisData = useSelector(getUserMetrics);
  const userMetrcisDataLoading = useSelector(getUserMetricsLoading);
  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getMetricsOverviewServer());
  }, [isAuthorized]);

  return (
    <OuterWrapper>
      <Container>
        <Wrapper>
          <SubscriptionWidget />

          <WidgetCard
            title="Total requests (24h)"
            value={userMetrcisData?.all_requests_last_24h?.count || 0}
            isLoading={userMetrcisDataLoading}
          />
          <WidgetCard
            title="Total webhooks events (24h)"
            value={userMetrcisData?.webhook_events_last_24h || 0}
            isLoading={userMetrcisDataLoading}
          />
          <WidgetCard
            title="Transactions submitted (24h)"
            value={userMetrcisData?.transactions_last_24h?.count || 0}
            isLoading={userMetrcisDataLoading}
          />
        </Wrapper>
      </Container>
    </OuterWrapper>
  );
};

const OuterWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.grey[50],
  padding: '72px 0 48px',

  [theme.breakpoints.down('lg')]: {
    padding: '56px 0',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '32px 0 56px',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down(1101)]: {
    flexWrap: 'wrap',
    rowGap: '16px',

    '& > div': {
      width: 'calc(50% - 10px)',
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& > div': {
      width: '100%',
    },
  },
}));

export default Widgets;
