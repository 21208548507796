export const EmptyTeamIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4688_3528)">
      <path
        d="M12.0026 11.9974C13.4754 11.9974 14.6693 10.8035 14.6693 9.33073C14.6693 7.85797 13.4754 6.66406 12.0026 6.66406C10.5298 6.66406 9.33594 7.85797 9.33594 9.33073C9.33594 10.8035 10.5298 11.9974 12.0026 11.9974Z"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73494 21.0347C7.08294 20.8374 6.64427 20.188 6.7896 19.5214C7.3136 17.1254 9.44694 15.332 12.0003 15.332C14.5536 15.332 16.6869 17.1254 17.2109 19.5214C17.3563 20.1867 16.9189 20.8374 16.2656 21.0347C15.1709 21.3654 13.7176 21.6667 12.0003 21.6667C10.2829 21.6667 8.8296 21.3667 7.73494 21.0347Z"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3359 6.33203C19.4405 6.33203 20.3359 5.4366 20.3359 4.33203C20.3359 3.22746 19.4405 2.33203 18.3359 2.33203C17.2314 2.33203 16.3359 3.22746 16.3359 4.33203C16.3359 5.4366 17.2314 6.33203 18.3359 6.33203Z"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1133 9.66158C18.1866 9.65892 18.2599 9.65625 18.3346 9.65625C20.5653 9.65625 22.4399 11.1856 22.9653 13.2522C23.1386 13.9336 22.6839 14.6122 22.0079 14.8082C21.1506 15.0562 20.0653 15.2776 18.8039 15.3216"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66797 6.33203C6.77254 6.33203 7.66797 5.4366 7.66797 4.33203C7.66797 3.22746 6.77254 2.33203 5.66797 2.33203C4.5634 2.33203 3.66797 3.22746 3.66797 4.33203C3.66797 5.4366 4.5634 6.33203 5.66797 6.33203Z"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.88903 9.66158C5.8157 9.65892 5.74236 9.65625 5.6677 9.65625C3.43703 9.65625 1.56236 11.1856 1.03703 13.2522C0.863695 13.9336 1.31836 14.6122 1.99436 14.8082C2.8517 15.0562 3.93703 15.2776 5.19836 15.3216"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4688_3528">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
