export const MasterCardIcon = () => (
  <svg
    width="35"
    height="15"
    viewBox="0 0 23 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.10156 14C10.9676 14 14.1016 10.866 14.1016 7C14.1016 3.13401 10.9676 0 7.10156 0C3.23557 0 0.101562 3.13401 0.101562 7C0.101562 10.866 3.23557 14 7.10156 14Z"
      fill="#E61C24"
    />
    <path
      d="M15.793 14C19.659 14 22.793 10.866 22.793 7C22.793 3.13401 19.659 0 15.793 0C11.927 0 8.79297 3.13401 8.79297 7C8.79297 10.866 11.927 14 15.793 14Z"
      fill="#F99F1B"
    />
    <path
      d="M11.4468 1.51953C10.6195 2.17359 9.95094 3.00645 9.49133 3.95569C9.03172 4.90492 8.79297 5.94588 8.79297 7.00053C8.79297 8.05518 9.03172 9.09614 9.49133 10.0454C9.95094 10.9946 10.6195 11.8275 11.4468 12.4815C12.2742 11.8275 12.9427 10.9946 13.4023 10.0454C13.8619 9.09614 14.1007 8.05518 14.1007 7.00053C14.1007 5.94588 13.8619 4.90492 13.4023 3.95569C12.9427 3.00645 12.2742 2.17359 11.4468 1.51953Z"
      fill="#F26622"
    />
  </svg>
);
