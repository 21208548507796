import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createTeamServer } from '../../../../redux/actions/teamsActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import { Popups } from '../../../../types/popups';
import { initialValuesCreateTeam } from '../../../../forms/createTeam/form';
import { CreateTeamFormValues } from '../../../../forms/createTeam/types';
import { createTeamSchema } from '../../../../forms/createTeam/validation';
import { Theme } from '@mui/material';

export const useCreateTeamForm = () => {
  const dispatch = useDispatch();

  const onSubmit = (values: CreateTeamFormValues) => {
    const team_name = values.teamName;
    dispatch(createTeamServer(team_name));
    dispatch(updatePopup({ popup: Popups.createTeam, status: false }));
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialValuesCreateTeam,
    validationSchema: createTeamSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    resetForm,
  } = formik;

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.createTeam, status: false }));
    resetForm();
  };

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string): boolean => {
    return !!(
      errors[name as keyof typeof errors] &&
      touched[name as keyof typeof touched]
    );
  };

  const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
    width: width ? `${width}px` : 'auto',
    background: theme.palette.common.white,
    boxShadow: 'none',
    border: `1px solid ${
      error
        ? theme.palette.dropdown.border.error
        : theme.palette.dropdown.border.main
    }`,
    transform: 'translateY(5px)',
  });

  return {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleDropdownChange,
    handleDropdownBlur,
    checkError,
    isValid,
    dirty,
    handleClose,
    getMenuStyle,
  };
};
