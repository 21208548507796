import { LinearProgress, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@maestro-org/ui-kit';
import { format } from 'date-fns';

import {
  getCreditUsage,
  getUser,
  getUserLoading,
  getTeamOwner,
} from '../../../redux/reducers/usersReducer';
import { getUserCreditUsageServer } from '../../../redux/actions/userActions';

import { InfoTooltipIcon, UpgradeIcon } from '../../../components/Icons';

import { SubscriptionPlan } from '../../../types/subscription';
import { pages } from '../../../lib/routeUtils';
import { numberDecorator } from '../../../lib/numberDecorator';
import { LineChart } from '../../../components/Charts';
import {
  getDaysBetweenBilling,
  getDaysUntilNextBilling,
  getEmptyCreditLeftDays,
  getEmptyCreditUsageData,
} from '../../../lib/creditUsage';
import Tooltip from '../../../components/Tooltip/Tooltip';

import { UserRoles } from '../../../types/roles';
import { getPlanIcon } from '../../../lib/subscriptionPlanUtils';
import { useCurrentSubscription } from '../../../hooks/useCurrentSubscription';

const compareTwoDates = (date1: string, date2: string) => {
  try {
    return (
      format(new Date(date1), 'yy MM dd') ===
      format(new Date(date2), 'yy MM dd')
    );
  } catch (error) {
    return false;
  }
};

const SubscriptionWidget = () => {
  const loadingUser = useSelector(getUserLoading);
  const creditUsage = useSelector(getCreditUsage);
  const user = useSelector(getUser);
  const owner = useSelector(getTeamOwner);

  const creditsFetched = useRef<boolean>(false);

  const currentPlan = useCurrentSubscription();

  const showOwnerData =
    user.team_id && !(user.team_role_name === UserRoles.OWNER);

  const target = showOwnerData ? owner : user;

  const dispatch = useDispatch();

  const lastBillingDate = target?.last_billing_date
    ? new Date(target.last_billing_date * 1000)
    : new Date();
  const nextBillingDate = target?.next_billing_date
    ? new Date(target.next_billing_date * 1000)
    : new Date();

  const showCreditsDiagram =
    currentPlan.name === SubscriptionPlan.conductor ||
    currentPlan.name === SubscriptionPlan.virtuoso ||
    currentPlan.name === SubscriptionPlan.enterprise;

  const allCredits = currentPlan.credits_per_day;
  const monthlyCredits = currentPlan.credits_per_month;

  const initialChartData = getEmptyCreditUsageData(lastBillingDate);

  useEffect(() => {
    if (!lastBillingDate || !nextBillingDate || creditsFetched.current) return;

    dispatch(getUserCreditUsageServer({ days: initialChartData.length || 1 }));

    creditsFetched.current = true;
  }, [lastBillingDate, nextBillingDate]);

  const creditsUsageData = initialChartData.map((emptyItem) => {
    const usageItem = creditUsage.find((usageItem) =>
      compareTwoDates(
        emptyItem.date,
        new Date(usageItem.date).toLocaleDateString('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      )
    );
    return usageItem
      ? {
          date: format(new Date(usageItem.date), 'MM/dd/yyyy h:mm:ss a'),
          value: +usageItem.credits_used || 0,
          emptyValue: false,
        }
      : emptyItem;
  });

  const spentCredits = showCreditsDiagram
    ? creditsUsageData.reduce((acc, item) => acc + item.value, 0)
    : +(target?.credits_used || 0);

  const allDaysBetweenBilling = getDaysBetweenBilling(
    lastBillingDate,
    nextBillingDate
  );

  const passedDaysPercent =
    ((allDaysBetweenBilling - getDaysUntilNextBilling(nextBillingDate)) /
      allDaysBetweenBilling) *
    100;

  return (
    <Wrapper>
      <Row>
        <CurrentPlan>
          {getPlanIcon[currentPlan.name]}
          <PlanLabel color="grey.A200" variant="paragraphMedium">
            {currentPlan.name}
          </PlanLabel>
        </CurrentPlan>
        <Link to={pages.settings()}>
          <UpgradeWrapper>
            <UpgradeIcon />
            <Typography color="primary.main" variant="paragraphMedium">
              Upgrade
            </Typography>
          </UpgradeWrapper>
        </Link>
      </Row>
      <CreditsDataWrapper>
        {loadingUser ? (
          <Loader />
        ) : showCreditsDiagram ? (
          <CreditsDiagram
            data={creditsUsageData}
            passedDaysPercent={passedDaysPercent}
            spentCredits={spentCredits}
            lastBillingDate={lastBillingDate}
            nextBillingDate={nextBillingDate}
          />
        ) : (
          <CreditsSlider
            creditsUsed={spentCredits}
            allCredits={allCredits}
            monthlyCredits={monthlyCredits}
          />
        )}
      </CreditsDataWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: '24px 27px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
  background: theme.palette.common.white,
  borderRadius: '2px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: '0px 8px 30px -2px rgba(0, 0, 0, 0.04)',
  width: '460px',

  [theme.breakpoints.down('lg')]: {
    width: '370px',
  },
}));

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const CreditsDataWrapper = styled('div')(() => ({}));

const CurrentPlan = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '4px',

  '& svg': {
    width: '20px',
    height: '17px',
  },
});

const PlanLabel = styled(Typography)({
  textTransform: 'capitalize',
});

const UpgradeWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

interface CreditsSliderProps {
  creditsUsed: number;
  allCredits: number;
  monthlyCredits: number;
}

const CreditsSlider = ({
  creditsUsed,
  allCredits,
  monthlyCredits,
}: CreditsSliderProps) => {
  const difference = allCredits - creditsUsed;
  const availableCredits = difference >= 0 ? difference : 0;
  const monthlyMillionCredits = Math.floor(monthlyCredits / 1000000);

  return (
    <CreditsWrapper>
      <Row>
        <CreditsRow>
          <Typography variant="paragraphMedium" color="grey.A200">
            {numberDecorator(availableCredits)} credits remaining
          </Typography>
          <StyledTooltip
            enterTouchDelay={0}
            title={
              <TooltipText>
                Plan is capped at {numberDecorator(allCredits)} credits/day (~
                {monthlyMillionCredits}M/mo) and renews daily. Unused credits
                are not rolled over.
              </TooltipText>
            }
          >
            <InfoIconWrapper>
              <InfoTooltipIcon />
            </InfoIconWrapper>
          </StyledTooltip>
        </CreditsRow>

        <Typography variant="paragraphMedium" color="grey.A200">
          {Math.floor((availableCredits / allCredits) * 100) || 0}%
        </Typography>
      </Row>
      <RemainingCreditsBar
        variant="determinate"
        value={Math.floor((availableCredits / allCredits) * 100) || 0}
      />
    </CreditsWrapper>
  );
};

const InfoIconWrapper = styled('div')({
  height: '16px',
});

const TooltipText = styled(Typography)({
  color: '#333333',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '8px 16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    alignItems: 'center',
    columnGap: '9px',
  },

  '& svg': {
    width: '13px',
    height: '16px',
  },
}));

const CreditsRow = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

const CreditsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const RemainingCreditsBar = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  background: theme.palette.grey[100],
  borderRadius: '26px',

  '& .MuiLinearProgress-bar': {
    borderRadius: '8px',
  },
}));

interface CreditsDiagramProps {
  data: {
    date: string;
    value: number;
  }[];
  passedDaysPercent: number;
  spentCredits: number;
  lastBillingDate?: Date;
  nextBillingDate?: Date;
}

const CreditsDiagram = ({
  data,
  passedDaysPercent,
  spentCredits,
  nextBillingDate,
}: CreditsDiagramProps) => {
  const daysLeft = getDaysUntilNextBilling(nextBillingDate);

  return (
    <CreditsDiagramWrapper>
      <Row>
        <Typography variant="paragraphMedium" color="grey.A200">
          {numberDecorator(spentCredits)} credits spent
        </Typography>
        <Typography variant="paragraphMedium" color="grey.A200">
          {daysLeft} days left
        </Typography>
      </Row>
      <ChartsWrapper>
        <LineChartWrapper passedDaysPercent={passedDaysPercent}>
          <LineChart height={22} units="credits" data={data} />
        </LineChartWrapper>
        <LineChartWrapper passedDaysPercent={100 - passedDaysPercent}>
          <RightChartWrapper>
            <LineChart
              height={22}
              units="credits"
              data={getEmptyCreditLeftDays(nextBillingDate)}
              strokeDasharray="3 3"
            />
          </RightChartWrapper>
        </LineChartWrapper>
      </ChartsWrapper>
    </CreditsDiagramWrapper>
  );
};

const CreditsDiagramWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
});

const ChartsWrapper = styled('div')({
  display: 'flex',
});

const LineChartWrapper = styled('div')<{ passedDaysPercent: number }>(
  ({ passedDaysPercent }) => ({
    width: `${passedDaysPercent}%`,
  })
);

const RightChartWrapper = styled('div')({
  position: 'relative',
  right: '10px',
});

export default SubscriptionWidget;
