import { Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';

import {
  getProjectMetrics,
  getProjectMetricsLoading,
} from '../../../redux/reducers/singleProjectReducer';

import Container from '../../../components/Container/Container';
import StatisticsCard from './StatisticsCard';

import { StatisticType } from '../../../types/statisticsCard';
import { MetricsItemBucket } from '../../../types/project';
import { format } from 'date-fns';

const getChartData = (data: MetricsItemBucket[] | undefined) => {
  if (!data) return;
  return data.map((item) => ({
    date: format(new Date(item.timestamp), 'MM/dd/yyyy h:mm:ss a'),
    value: item.count,
  }));
};

const Widgets = () => {
  const metrics = useSelector(getProjectMetrics);
  const isLoading = useSelector(getProjectMetricsLoading);

  const totalRequests = metrics?.requests_last_24h?.count;

  const invalidRequests = metrics?.requests_last_24h?.failed?.count;

  const succeededAmount = Math.round(
    (metrics?.requests_last_24h?.succeeded_pct.value || 0) * 100
  );

  const succeededData = metrics?.requests_last_24h?.succeeded_pct.buckets.map(
    ({ value, timestamp }) => ({
      count: Math.round(value * 100),
      timestamp,
    })
  );

  const medianResponseAmount = metrics?.requests_last_24h?.latency.length
    ? Math.round(
        metrics.requests_last_24h.latency[
          metrics?.requests_last_24h.latency.length - 1
        ]?.value
      )
    : 0;

  const medianResponseData = metrics?.requests_last_24h?.latency.map(
    ({ value, timestamp }) => ({
      count: value,
      timestamp,
    })
  );

  return (
    <Container>
      <StatisticsCardWrapper>
        <StatisticsCard
          amount={totalRequests}
          label="Total Request (24h)"
          data={getChartData(metrics?.requests_last_24h?.buckets)}
          type={StatisticType.totalRequest}
          isLoading={isLoading}
        />
        <StatisticsCard
          amount={succeededAmount}
          label="Success % (24h)"
          data={getChartData(succeededData)}
          type={StatisticType.success}
          isLoading={isLoading}
          measure="Succeeded"
          units="%"
        />
        <StatisticsCard
          amount={invalidRequests || 0}
          label="Invalid Requests (24h)"
          data={getChartData(metrics?.requests_last_24h?.failed.buckets)}
          type={StatisticType.invalidRequest}
          isLoading={isLoading}
          measure="Failed"
        />
        <StatisticsCard
          amount={medianResponseAmount}
          label="Median Response (10min)"
          data={getChartData(medianResponseData)}
          type={StatisticType.medianResponse}
          isLoading={isLoading}
          measure="Response time"
          units="ms"
        />
      </StatisticsCardWrapper>
    </Container>
  );
};

const StatisticsCardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  rowGap: '20px',

  '& > div': {
    width: 'calc(25% - 18px)',
  },

  [theme.breakpoints.down('lg')]: {
    '& > div': {
      width: 'calc(50% - 10px)',
    },
  },

  [theme.breakpoints.down('sm')]: {
    rowGap: '16px',

    '& > div': {
      width: '100%',
    },
  },
}));

const EmptyStateText = styled(Typography)({
  textAlign: 'center',
  width: '100%',
  marginTop: '12px',
});

export default Widgets;
