import { REHYDRATE } from '../actions/appActions';
import {
  CLEAR_AUTH_DATA,
  SET_UPDATED_USER_PICTURE,
  UPDATE_USER_DATA,
  UPDATE_AUTH_LOADING,
  UPDATE_IS_AUTHORIZED,
  SET_IS_UPDATING_USER_INFO,
  UPDATE_UPLOADING_IMAGE,
  UPDATE_AUTH0_DATA,
} from '../actions/authActions';
import { Auth0Data, UserData } from '../../types/auth';
import { StoreType } from '../types/store.types';

export interface AuthStateType {
  isLoading: boolean;
  isAuthorized: boolean;
  data: UserData;
  auth0: Auth0Data;
  isLoadingImage: boolean;
  updatedUserPicture: boolean;
  isUpdatingUserInfo: boolean;
}

const authInitialState: AuthStateType = {
  isLoading: false,
  isAuthorized: false,
  data: {} as UserData,
  auth0: {} as Auth0Data,
  isLoadingImage: false,
  updatedUserPicture: false,
  isUpdatingUserInfo: false,
};

const authReducer = (state = authInitialState, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const persistedData = action.payload;
      if (persistedData) {
        return {
          ...state,
          data: persistedData?.auth?.data,
          auth0: persistedData?.auth?.auth0,
          isAuthorized: persistedData?.auth?.isAuthorized,
        };
      }
      return { ...state };
    }
    case UPDATE_AUTH_LOADING: {
      return {
        ...state,
        isLoading: action.payload.status,
      };
    }
    case UPDATE_USER_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    }
    case UPDATE_AUTH0_DATA: {
      return { ...state, auth0: { ...state.auth0, ...action.payload.data } };
    }
    case UPDATE_IS_AUTHORIZED: {
      return { ...state, isAuthorized: action.payload.status };
    }
    case CLEAR_AUTH_DATA: {
      return { ...state, data: {} };
    }
    case UPDATE_UPLOADING_IMAGE: {
      return {
        ...state,
        isLoadingImage: action.payload.status,
      };
    }
    case SET_UPDATED_USER_PICTURE: {
      return { ...state, updatedUserPicture: action.payload.status };
    }
    case SET_IS_UPDATING_USER_INFO: {
      return { ...state, isUpdatingUserInfo: action.payload.status };
    }
    default: {
      return { ...state };
    }
  }
};

export const getAuthLoading = (state: StoreType) => state.auth.isLoading;
export const getAuthData = (state: StoreType) => state.auth.data;
export const getAuth0FuncData = (state: StoreType) => state.auth.auth0;
export const getIsAuthorized = (state: StoreType) => state.auth.isAuthorized;
export const getIsUploadingImage = (state: StoreType) =>
  state.auth.isLoadingImage;
export const getIsUserPictureUpdated = (state: StoreType) =>
  state.auth.updatedUserPicture;
export const getIsUpdatingUserInfo = (state: StoreType) =>
  state.auth.isUpdatingUserInfo;

export default authReducer;
