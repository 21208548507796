export const TrashIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68576 14.2222C1.68576 15.2 2.48576 16 3.46354 16H10.5747C11.5524 16 12.3524 15.2 12.3524 14.2222V3.55556H1.68576V14.2222ZM3.46354 5.33333H10.5747V14.2222H3.46354V5.33333ZM10.1302 0.888889L9.24132 0H4.79688L3.90799 0.888889H0.796875V2.66667H13.2413V0.888889H10.1302Z"
      fill="#808080"
    />
  </svg>
);
