export enum AuthModules {
  register = 'register',
  login = 'login',
  resetPass = 'resetPass',
}

export interface AuthField {
  type: 'text' | 'email' | 'password';
  label?: string;
  name: string;
  placeholder?: string;
  fullwidth?: boolean;
  minRows?: number;
  subtitle?: string;
  autoComplete?: string;
  fieldLabel?: string;
}

export enum LoginType {
  Github = 'Github',
  Google = 'Google',
  Regular = 'Regular',
}

export interface UserData {
  app_metadata: string;
  created_at: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  identities: {
    connection: string;
    isSocial: boolean;
    provider: string;
    user_id: string;
  }[];
  name: string;
  nickname: string;
  picture: string;
  updated_at: string;
  user_id: string;
  user_metadata: {
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    picture: string;
    use_mfa: boolean;
  };
  username: string;
}

export interface Auth0Data {
  accessToken: string;
  loginType: LoginType;
  expiresIn: number;
  email_verified: boolean;
}

export interface IAddress {
  city?: string;
  country: string;
  line1?: string;
  line2?: string;
  postal_code?: string;
  state?: string;
}

export interface StripeItem {
  billingInfo: IBillingInfo;
  card: ICard;
  id: string;
}

export interface IBillingInfo {
  address: IAddress;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export type CardBrand = 'mastercard' | 'visa' | 'amex';

export interface ICard {
  brand: CardBrand;
  country: string;
  expMonth: number;
  expYear: number;
  last4: string;
}
