import { styled } from '@mui/material';
import React, { useState } from 'react';

import Container from '../../../components/Container/Container';
import APICard from './APICard';
import PieChartCard from './PieChartCard';

const QueryDistribution = () => {
  const [selectSection, setSelectSection] = useState<string>('');

  const handleSelectSection = (label: string) => {
    //if (label !== selectSection)
    setSelectSection(label);
    // else setSelectSection("");
  };

  return (
    <Container>
      <Wrapper>
        <PieChartCard
          selectSection={selectSection}
          handleSelectSection={handleSelectSection}
        />
        {/* <APICard selectSection={selectSection} /> */}
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '36px',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    rowGap: '20px',
  },
}));

export default QueryDistribution;
