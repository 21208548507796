import { Chip } from '@maestro-org/ui-kit';
import { styled } from '@mui/material';
import { getUser } from '../../redux/reducers/usersReducer';
import { useSelector } from 'react-redux';
import { GreenCircleIcon } from '../Icons/GreenCircleIcon';
import { RedCircleIcon } from '../Icons/RedCircleIcon';
import { SubStatus } from '../../types/user';

const StatusChip = () => {
  const user = useSelector(getUser);
  const isActive = user?.status === SubStatus.active;
  return (
    <StyledChip
      textColor={'#0F0311'}
      color="custom"
      hexColor="#D166E0"
      label={
        <>
          {isActive ? <GreenCircleIcon /> : <RedCircleIcon />}
          {user?.status}
        </>
      }
    />
  );
};

const StyledChip = styled(Chip)<{ textColor: string }>(
  ({ theme, textColor }) => ({
    borderRadius: '6px',
    background: '#FFF',
    padding: '4px 8px',
    maxHeight: '20px',
    textTransform: 'uppercase',
    border: '1px solid #CCC',
    boxShadow: '0px 1px 1.5px 0px #E6E6E6',
    alignItems: 'center',

    '&:hover': {
      background: 'unset',
      cursor: 'default',
    },

    '& .MuiChip-label': {
      color: textColor,
      fontWeight: `500 !important`,
      lineHeight: '12px',
      fontSize: '11px !important',
      display: 'flex',
      alignItems: 'center',
      columnGap: '4px',
      letterSpacing: '0.11px',

      '& path': {
        fill: theme.palette.primary.main,
      },
    },

    '& svg': {
      display: 'inline-flex',
    },
  })
);

export default StatusChip;
