import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Chip, Dropdown } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  formattedRangeTypes,
  RangeType,
  TransactionMonitoringState,
} from '../../../types/transaction';

import { TurboTXIcon } from '../../../components/Icons';
import { getAllprojects } from '../../../redux/reducers/projectsReducer';
import { getTransactionsServer } from '../../../redux/actions/transactionsActions';

import { Option } from '../../../types/newEntry';

const allStates = {
  all: 'All',
  [TransactionMonitoringState.onchain]: 'OnChain',
  [TransactionMonitoringState.pending]: 'Pending',
  [TransactionMonitoringState.rejected]: 'Rejected',
  [TransactionMonitoringState.failed]: 'Failed',
  [TransactionMonitoringState.rolledback]: 'Rolledback',
};

interface Props {
  page: number;
}

const TransactionFilter = ({ page }: Props) => {
  const dispatch = useDispatch();

  const [projects, setProjects] = useState<string[]>([]);
  const [timeframe, setTimeframe] = useState<RangeType[]>([
    RangeType.thirtyMins,
  ]);
  const [turboTX, setTurboTX] = useState<boolean>(false);
  const [transactionState, setTransactionState] = useState<string>('all');
  const allProjects = useSelector(getAllprojects);

  const handleSelectedProjectChange = (value: any) => {
    setProjects(value);
  };

  const handleTimeframeChange = (value: any) => {
    setTimeframe(value);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setTurboTX(checked);

  const handleStateFilterChange = (state: string) => setTransactionState(state);

  useEffect(() => {
    if (!allProjects.length || !projects.length) return;
    dispatch(
      // getTransactionsServer({
      //   turbo_tx: false,
      //   filter_range_type: "24h",
      // }),
      getTransactionsServer({
        turbo_tx: turboTX,
        event_type: transactionState === 'all' ? undefined : transactionState,
        filter_range_type: formattedRangeTypes[timeframe[0]],
        project_ids: allProjects
          .filter((item) => projects.includes(item.name))
          .map((item) => item.id),
        page,
      })
    );
  }, [turboTX, transactionState, projects, timeframe, allProjects, page]);

  useEffect(() => {
    if (!allProjects.length) return;
    setProjects([allProjects[0].name]);
  }, [allProjects]);

  const projectsOptions: Option[] = allProjects.map((project) => ({
    value: project.name,
    disabled: false,
  }));

  const rangeTypeOptions: Option[] = Object.values(RangeType).map((type) => ({
    value: type,
    disabled: false,
  }));

  return (
    <Wrapper>
      <Left>
        <DropdownWrapper>
          <StyledDropdown
            // multiselect
            options={projectsOptions}
            value={projects}
            placeholder="Projects"
            onChange={handleSelectedProjectChange}
            helperText=""
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <StyledDropdown
            multiselect={false}
            options={rangeTypeOptions}
            value={timeframe}
            placeholder="Timeframe"
            onChange={handleTimeframeChange}
            helperText=""
          />
        </DropdownWrapper>
        <CheckboxWrapper>
          <Checkbox
            variant="outlined"
            label={
              <WithAdornment>
                <TurboTXIcon />
                <Typography color="grey.A200" variant="paragraphMedium">
                  Turbo Tx
                </Typography>
              </WithAdornment>
            }
            checked={turboTX}
            onChange={handleCheckboxChange}
          />
        </CheckboxWrapper>
      </Left>
      <Right>
        <ChipsWrapper>
          {Object.keys(allStates).map((key) => (
            <StyledChip
              key={key}
              label={allStates[key as keyof typeof allStates]}
              color={key === transactionState ? 'primary' : 'default'}
              onClick={() => handleStateFilterChange(key)}
            />
          ))}
        </ChipsWrapper>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '56px 0 40px',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    rowGap: '56px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '40px 0 56px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '20px 0 40px',
  },
}));

const Left = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: '36px',

  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    rowGap: '20px',
  },
}));

const DropdownWrapper = styled('div')(({ theme }) => ({
  width: '212px',
  maxWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Right = styled('div')(({ theme }) => ({
  paddingBottom: '12px',
  display: 'flex',
  alignItems: 'flex-end',
  overflow: 'auto hidden',

  '&::-webkit-scrollbar ': {
    height: '4px',
  },
  '&::-webkit-scrollbar-track ': {
    background: 'transparent',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb ': {
    background: theme.palette.grey[200],
    borderRadius: '4px',
  },
}));

const ChipsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  columnGap: '20px',
});

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  '& .MuiSelect-select': {
    border: `1px solid ${theme.palette.textfield.border.main}`,
    borderRadius: '4px !important',
    padding: '12px 16px !important',
  },
}));

const CheckboxWrapper = styled('div')(({ theme }) => ({
  paddingBottom: '12px',

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '0',
  },
}));

const WithAdornment = styled('div')({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',

  '& svg': {
    width: '16px',
    height: '16px',
  },
});

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.borderRadius.xs,
}));

export default TransactionFilter;
