import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getNewCreateWebhookDialog,
  getPrefilled,
} from '../../../redux/reducers/popupsReducer';
import { updatePopup } from '../../../redux/actions/popupsActions';
import { Popups } from '../../../types/popups';
import { NewEntryField } from '../../../types/newEntry';
import { getCreateWebhookFields } from '../../../forms/createWebhook/form';
import { TriggerCondition } from '../../../forms/createWebhook/types';

import {
  getPicklistAnchorsOptions,
  getPicklistConditionalsOptions,
  getPicklistOperatorsOptions,
} from '../../../redux/actions/eventsActions';
import {
  getAnchorsOptions,
  getConditionalsOptions,
  getOperatorsOptions,
  Filters,
} from '../../../redux/reducers/eventsReducer';

import Dialog from '../common/Dialog';
import DialogForm from '../common/DialogForm';
import DialogHeader from '../common/DialogHeader';
import FormFields from '../common/FormFields';
import DialogActions from '../common/DialogActions';
import AddTriggerButton from './components/AddTriggerButton';
import TriggerConditionsList from './components/TriggerConditionsList';
import { useEventWebhookForm } from './hooks/useEventWebhookForm';
import { useTriggerConditions } from './hooks/useTriggerConditions';

const EventCreateWebhook = () => {
  const dispatch = useDispatch();

  const prefillData = useSelector(getPrefilled) || {};
  const isEditMode = Boolean(prefillData?.id);
  const isOpen = useSelector(getNewCreateWebhookDialog);
  const anchorsOptions = useSelector(getAnchorsOptions);
  const conditionalsOptions = useSelector(getConditionalsOptions);
  const operatorsOptions = useSelector(getOperatorsOptions);

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.eventCreateWebhook, status: false }));
  };

  const anchorsPicklist = useMemo(
    () =>
      anchorsOptions.map((item) => ({
        label: item.display_name,
        value: item.value,
      })),
    [anchorsOptions]
  );

  const conditionalsPicklist = useMemo(
    () =>
      conditionalsOptions.map((item) => ({
        label: item.display_name,
        value: item.value,
      })),
    [conditionalsOptions]
  );

  const operatorsPicklist = useMemo(
    () =>
      operatorsOptions.map((item) => ({
        label: item.display_name,
        value: item.value,
      })),
    [operatorsOptions]
  );

  const {
    triggerConditions,
    setTriggerConditions,
    canAddTrigger,
    getAddTriggerTooltip,
    handleAddTrigger,
    areTriggerConditionsValid,
    getFieldDisabledOptions,
    handleFieldChange,
    handleRemoveCondition,
  } = useTriggerConditions(isEditMode, prefillData);

  const {
    getField,
    handleSubmit,
    isButtonDisabled,
    handleClose: formHandleClose,
  } = useEventWebhookForm(
    isEditMode,
    prefillData,
    triggerConditions,
    handleClose
  );

  useEffect(() => {
    if (isEditMode) {
      const triggerItem = prefillData.triggers?.[0];
      const updatedTriggerConditions = triggerItem?.filters?.map(
        (filter: Filters, i: number) => ({
          id: i.toString(),
          field: [filter.key] as never[],
          operator: [filter.operator] as never[],
          value: filter.value,
        })
      ) || [
        {
          id: '0',
          field: [] as never[],
          operator: [] as never[],
          value: '',
        },
      ];

      setTriggerConditions(updatedTriggerConditions);
    }
  }, [isEditMode, prefillData]);

  const getFormFields = (options: any) =>
    getCreateWebhookFields({
      triggerOptions: anchorsPicklist,
      operatorOptions: operatorsPicklist,
      fieldOptions: options,
    });

  const getTriggerFields = (fieldOptions: any) =>
    getFormFields(fieldOptions).slice(5, 8);

  return (
    <Dialog open={isOpen} onClose={formHandleClose}>
      <DialogForm onSubmit={handleSubmit}>
        <DialogHeader title="Create Event Webhook" />

        <FormFields>
          {getFormFields(conditionalsPicklist)
            .slice(0, 2)
            .map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>

        <FormFields>
          {getFormFields(conditionalsPicklist)
            .slice(2, 3)
            .map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>

        <FormFields>
          {getFormFields(conditionalsPicklist)
            .slice(3, 5)
            .map((field: NewEntryField) => getField(field)[field.type])}
        </FormFields>

        <AddTriggerButton
          onAdd={() => handleAddTrigger(conditionalsPicklist)}
          canAdd={canAddTrigger()}
          tooltipText={getAddTriggerTooltip()}
        />

        <TriggerConditionsList
          triggerConditions={triggerConditions}
          onFieldChange={handleFieldChange}
          onRemoveCondition={handleRemoveCondition}
          getFieldDisabledOptions={getFieldDisabledOptions}
          fields={getTriggerFields}
          conditionalsPicklist={conditionalsPicklist}
        />

        <DialogActions
          onCancel={formHandleClose}
          submitDisabled={isButtonDisabled(areTriggerConditionsValid())}
          submitLabel={isEditMode ? 'Edit' : 'Create'}
        />
      </DialogForm>
    </Dialog>
  );
};

export default EventCreateWebhook;
