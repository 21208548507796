import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { styled, Typography } from '@mui/material';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  getWeek,
} from 'date-fns';

import { getSingleRequestVolumeServer } from '../../../redux/actions/singleProjectActions';
import { getSingleRequestVolume } from '../../../redux/reducers/singleProjectReducer';
import {
  getAuthData,
  getIsAuthorized,
} from '../../../redux/reducers/authReducer';

import ChartHeader from './ChartHeader';
import Tabs from '../../../components/Tabs/Tabs';
import Container from '../../../components/Container/Container';
import { StackedBarChart } from '../../../components/Charts';

import { handleTRVtime } from '../../../lib/handleTRVtime';
import {
  getEmptyDay,
  getEmptyMonth,
  getEmptyWeekData,
  getEmptyYear,
} from '../../../lib/date';
import {
  barGetGap,
  barRadius,
  barSize,
  svgWidth,
} from '../../../lib/rechartsLib';

const getEmptyData = (type: DateTabs, date: Date) => {
  switch (type) {
    case DateTabs.day:
      return getEmptyDay(date);
    case DateTabs.week:
      return getEmptyWeekData(date);
    case DateTabs.month:
      return getEmptyMonth(date);
    case DateTabs.year:
      return getEmptyYear(date);
    default:
      return [];
  }
};

const compareDates = (
  date1: Date,
  date2: Date,
  tabValue: DateTabs
): boolean => {
  switch (tabValue) {
    case DateTabs.day:
      return format(date1, 'MM dd HH') === format(date2, 'MM dd HH');
    case DateTabs.week:
      return format(date1, 'yy MM dd') === format(date2, 'yy MM dd');
    case DateTabs.month:
      return format(date1, 'yy MM dd') === format(date2, 'yy MM dd');
    case DateTabs.year:
      return format(date1, 'yy MM') === format(date2, 'yy MM');
    default:
      return false;
  }
};

export enum DateTabs {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

enum FormatDate {
  day = 'MMMMdd yyyy',
  week = 'MMMMdd yyyy',
  month = 'MMMM yyyy',
  year = 'yyyy',
}

const tabsPanelOptions = [
  {
    value: DateTabs.day,
    label: 'Day',
  },
  {
    value: DateTabs.week,
    label: 'Week',
  },
  {
    value: DateTabs.month,
    label: 'Month',
  },
  {
    value: DateTabs.year,
    label: 'Year',
  },
];

interface Props {
  projectId?: string;
}

const TotalRequestVolume = ({ projectId }: Props) => {
  const [tabValue, setTabValue] = useState<DateTabs>(DateTabs.day);
  const [date, setDate] = useState<Date>(new Date());
  const [offset, setOffset] = useState(0);

  const requestVolumeData = useSelector(getSingleRequestVolume);
  const isAuthorized = useSelector(getIsAuthorized);

  const dispatch = useDispatch();

  const handleTabsChange = (event: React.SyntheticEvent, newValue: DateTabs) =>
    setTabValue(newValue as DateTabs);

  const nextPeriod = (type: DateTabs) => {
    setOffset(offset - 1);
    switch (type) {
      case DateTabs.day:
        setDate(addDays(date, 1));
        break;
      case DateTabs.week:
        setDate(addWeeks(date, 1));
        break;
      case DateTabs.month:
        setDate(addMonths(date, 1));
        break;
      case DateTabs.year:
        setDate(addYears(date, 1));
        break;
      default:
        break;
    }
  };

  const prevPeriod = (type: DateTabs) => {
    setOffset(offset + 1);
    switch (type) {
      case DateTabs.day:
        setDate(addDays(date, -1));
        break;
      case DateTabs.week:
        setDate(addWeeks(date, -1));
        break;
      case DateTabs.month:
        setDate(addMonths(date, -1));
        break;
      case DateTabs.year:
        setDate(addYears(date, -1));
        break;
      default:
        break;
    }
  };

  const isDateEqual = (
    date: Date,
    currentDate: Date,
    formatDate: FormatDate
  ) => {
    return format(date, formatDate) === format(currentDate, formatDate);
  };

  useEffect(() => {
    if (projectId && isAuthorized)
      dispatch(getSingleRequestVolumeServer({ projectId }));
  }, [projectId, isAuthorized]);

  const data = getEmptyData(tabValue, date).map((emptyItem) => {
    const serverItem = requestVolumeData
      ? requestVolumeData[tabValue]?.buckets?.find((serverItem) =>
          compareDates(
            new Date(emptyItem.time),
            new Date(serverItem.timestamp),
            tabValue
          )
        )
      : undefined;
    return serverItem
      ? { project: serverItem.count, time: serverItem.timestamp }
      : emptyItem;
  });

  const tabsContent = (data: any) => ({
    [DateTabs.day]: (
      <ChartHeader
        date={format(date, 'MMMM dd, yyyy')}
        label={
          isDateEqual(date, new Date(), FormatDate.day)
            ? 'Today'
            : format(date, 'dd.MM')
        }
        next={() => nextPeriod(DateTabs.day)}
        prev={() => prevPeriod(DateTabs.day)}
        chart={
          <StackedBarChart
            barSize={barSize(DateTabs.day)}
            data={handleTRVtime(data || [], DateTabs.day)}
            type={DateTabs.day}
            variant="project"
            radius={barRadius(DateTabs.day)}
            gap={barGetGap(DateTabs.day)}
            svgWidth={svgWidth(DateTabs.day, data.length)}
          />
        }
      />
    ),
    [DateTabs.week]: (
      <ChartHeader
        date={`Week ${getWeek(date)}, ${format(date, 'yyyy')}`}
        label={
          isDateEqual(date, new Date(), FormatDate.week)
            ? 'This Week'
            : 'Week' + getWeek(date)
        }
        next={() => nextPeriod(DateTabs.week)}
        prev={() => prevPeriod(DateTabs.week)}
        chart={
          <StackedBarChart
            barSize={barSize(DateTabs.week)}
            data={handleTRVtime(data || [], DateTabs.week)}
            type={DateTabs.week}
            variant="project"
            radius={barRadius(DateTabs.week)}
            gap={barGetGap(DateTabs.week)}
            svgWidth={svgWidth(DateTabs.week, data.length)}
          />
        }
      />
    ),
    [DateTabs.month]: (
      <ChartHeader
        date={format(date, 'MMMM yyyy')}
        label={
          isDateEqual(date, new Date(), FormatDate.month)
            ? 'This Month'
            : format(date, 'MMMM')
        }
        next={() => nextPeriod(DateTabs.month)}
        prev={() => prevPeriod(DateTabs.month)}
        chart={
          <StackedBarChart
            barSize={barSize(DateTabs.month)}
            data={handleTRVtime(data || [], DateTabs.month)}
            type={DateTabs.month}
            variant="project"
            radius={barRadius(DateTabs.month)}
            gap={barGetGap(DateTabs.month)}
            svgWidth={svgWidth(DateTabs.month, data.length)}
          />
        }
      />
    ),
    [DateTabs.year]: (
      <ChartHeader
        date={`Year ${format(date, 'yyyy')}`}
        label={
          isDateEqual(date, new Date(), FormatDate.year)
            ? 'This Year'
            : format(date, 'yyyy')
        }
        next={() => nextPeriod(DateTabs.year)}
        prev={() => prevPeriod(DateTabs.year)}
        chart={
          <StackedBarChart
            barSize={barSize(DateTabs.year)}
            data={handleTRVtime(data || [], DateTabs.year)}
            type={DateTabs.year}
            variant="project"
            radius={barRadius(DateTabs.year)}
            gap={barGetGap(DateTabs.year)}
            svgWidth={svgWidth(DateTabs.year, data.length)}
          />
        }
      />
    ),
  });

  return (
    <Container>
      <Wrapper>
        <Typography color="textColor.dark" variant="h5">
          Total Request Volume
        </Typography>
        <Actions>
          {tabValue && tabsContent(data)[tabValue]}
          <Tabs
            style={{ order: 2 }}
            options={tabsPanelOptions}
            value={tabValue}
            onChange={handleTabsChange}
            variant="fullWidth"
          />
        </Actions>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '48px',

  [theme.breakpoints.down('sm')]: {
    rowGap: '32px',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row wrap',
  gap: '48px',

  [theme.breakpoints.down('sm')]: {
    gap: '24px',
  },
}));

export default TotalRequestVolume;
