import { AccountDetailsFields } from './types';

export const initialValuesAccountDetails = {
  [AccountDetailsFields.firstName]: '',
  [AccountDetailsFields.lastName]: '',
  [AccountDetailsFields.email]: '',
  [AccountDetailsFields.organization]: '',
};

export const getAccountDetailsFields = [
  {
    type: 'text',
    name: AccountDetailsFields.firstName,
    placeholder: 'Enter your first name',
    fullWidth: false,
    fieldLabel: 'First Name',
  },
  {
    type: 'text',
    name: AccountDetailsFields.lastName,
    placeholder: 'Enter your second name',
    fullWidth: false,
    fieldLabel: 'Last Name',
  },
  {
    type: 'email',
    name: AccountDetailsFields.email,
    placeholder: 'Email address',
    fullWidth: true,
    fieldLabel: 'Email Address',
  },
  {
    type: 'text',
    name: AccountDetailsFields.organization,
    placeholder: 'Enter your organization name',
    fullWidth: true,
    fieldLabel: 'Organization Name',
  },
];
