import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  createProjectsServer,
  getProjectsNetworks,
} from '../../../../redux/actions/projectsActions';
import { getChainsNetworks } from '../../../../redux/reducers/projectsReducer';
import { getIsAuthorized } from '../../../../redux/reducers/authReducer';
import { Popups } from '../../../../types/popups';
import { Blockchain, Network } from '../../../../types/project';
import { CreateProjectFormValues } from '../../../../forms/createProject/types';
import useFields from '../../../../forms/createProject/useFields';
import { Option } from '../../../../types/newEntry';
import {
  blockhainsSortHelper,
  getBlockchainLabel,
  getNetworkLabel,
  networksSortHelper,
} from '../../../../lib/createProject.utils';

export const useAddNewProject = () => {
  const dispatch = useDispatch();

  const chainsNetworks = useSelector(getChainsNetworks);
  const isAuthorized = useSelector(getIsAuthorized);

  const onSubmit = (values: CreateProjectFormValues) => {
    const network = values.network[0];
    const blockchain = values.blockchain[0];

    if (!network || !blockchain) return;
    addProject(values.name, network, blockchain);
  };

  const { values, isValid, dirty, getField, handleSubmit, resetForm } =
    useFields({ onSubmit });

  const blockchainOptions: Option[] = chainsNetworks
    .map((item) => ({
      value: item.chain,
      label: getBlockchainLabel(item.chain as Blockchain),
    }))
    .sort(blockhainsSortHelper);

  const networkOptions: Option[] =
    chainsNetworks
      .find((item) => item.chain === values.blockchain[0])
      ?.network.map((value) => ({
        value,
        label: getNetworkLabel(values.blockchain[0], value as Network),
      }))
      .sort(networksSortHelper) || [];

  const addProject = (
    name: string,
    network: Network,
    blockchain: Blockchain
  ) => {
    dispatch(createProjectsServer({ name, network, chain: blockchain }));
    handleClose();
    resetForm();
  };

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addNewProject, status: false }));
  };

  useEffect(() => {
    if (!isAuthorized) return;
    dispatch(getProjectsNetworks());
  }, [isAuthorized]);

  return {
    values,
    isValid,
    dirty,
    getField,
    handleSubmit,
    blockchainOptions,
    networkOptions,
    handleClose,
  };
};
