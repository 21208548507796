import { DateTabs } from '../../modules/Project/components/TotalRequestVolume';
import {
  MetricsItem,
  QueryDistributionItem,
  RequestVolume,
} from '../../types/project';

export const GET_METRICS_OVERVIEW_SERVER = 'GET_METRICS_OVERVIEW_SERVER';
export const UPDATE_PROJECT_METRICS = 'UPDATE_PROJECT_METRICS';
export const UPDATE_PROJECT_METRICS_LOADING = 'UPDATE_PROJECT_METRICS_LOADING';
export const GET_QUERY_DISTRIBUTION_SERVER = 'GET_QUERY_DISTRIBUTION_SERVER';
export const UPDATE_QUERY_DISTRIBUTION = 'UPDATE_QUERY_DISTRIBUTION';
export const UPDATE_QUERY_DISTRIBUTION_LOADING =
  'UPDATE_QUERY_DISTRIBUTION_LOADING';
export const GET_SINGLE_PROJECT_REQUEST_VOLUME =
  'GET_SINGLE_PROJECT_REQUEST_VOLUME';
export const UPDATE_SINGLE_PROJECT_REQUEST_VOLUME =
  'UPDATE_SINGLE_PROJECT_REQUEST_VOLUME';
export const UPDATE_SINGLE_PROJECT_REQUEST_VOLUME_LOADING =
  'UPDATE_SINGLE_PROJECT_REQUEST_VOLUME_LOADING';

interface GetProjectInfoProps {
  projectId: string;
}

interface UpdateProjectMetricsProps {
  metrics: MetricsItem[];
}

interface UpdateStatus {
  status: boolean;
}

interface UpdateQueryDistributionProps {
  data: QueryDistributionItem[];
}

interface UpdateRequestVolumeProps {
  data: RequestVolume;
}

export const getProjectMetricsServer = ({
  projectId,
}: GetProjectInfoProps) => ({
  type: GET_METRICS_OVERVIEW_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: `/metric/project/${projectId}/overview`,
    },
    projectId,
  },
});

export const updateProjectMetrics = ({
  metrics,
}: UpdateProjectMetricsProps) => ({
  type: UPDATE_PROJECT_METRICS,
  payload: { metrics },
});

export const updateMetricsLoading = ({ status }: UpdateStatus) => ({
  type: UPDATE_PROJECT_METRICS_LOADING,
  payload: { status },
});

export const getQueryDistributionServer = ({
  projectId,
}: GetProjectInfoProps) => ({
  type: GET_QUERY_DISTRIBUTION_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: `/metric/project/${projectId}/usage`,
      // url: `/projects/${projectId}/endpoint-usage`,
      // data: { project_id: projectId },
    },
  },
});

export const updateQueryDistribution = ({
  data,
}: UpdateQueryDistributionProps) => ({
  type: UPDATE_QUERY_DISTRIBUTION,
  payload: { data },
});

export const updateQueryDistributionLoading = ({ status }: UpdateStatus) => ({
  type: UPDATE_QUERY_DISTRIBUTION_LOADING,
  payload: { status },
});

export const getSingleRequestVolumeServer = ({
  projectId,
}: GetProjectInfoProps) => ({
  type: GET_SINGLE_PROJECT_REQUEST_VOLUME,
  payload: {
    request: {
      method: 'GET',
      url: `/metric/project/${projectId}/volume`,
    },
  },
});

export const updateSingleRequestVolume = ({
  data,
}: UpdateRequestVolumeProps) => ({
  type: UPDATE_SINGLE_PROJECT_REQUEST_VOLUME,
  payload: { data },
});

export const updateSingleRequestVolumeLoading = ({ status }: UpdateStatus) => ({
  type: UPDATE_SINGLE_PROJECT_REQUEST_VOLUME_LOADING,
  payload: { status },
});
