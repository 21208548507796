export const IntroduceTipIcon = () => (
  <svg
    width="55"
    height="49"
    viewBox="0 0 55 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.9189 15.1579L27.4595 0L0 15.1579L27.4595 30.3158L54.9189 15.1579ZM18.6341 22.56L13.238 19.5789L27.4595 11.7221L41.6809 19.5789L36.2848 22.56L27.4595 17.6842L18.6341 22.56ZM27.4595 2.88L49.6993 15.1579L44.3033 18.1389L27.4595 8.8421L10.6156 18.1389L5.21956 15.1579L27.4595 2.88Z"
      fill="#C53DD8"
    />
    <path
      d="M27.4595 36.2726L2.62239 22.5547L0 23.9947L27.4595 39.1526L54.9189 23.9947L52.2965 22.5547L27.4595 36.2726Z"
      fill="#C53DD8"
    />
    <path
      d="M27.4595 45.1241L2.62239 31.4062L0 32.8462L27.4595 48.0041L54.9189 32.8462L52.2965 31.4062L27.4595 45.1241Z"
      fill="#C53DD8"
    />
  </svg>
);
