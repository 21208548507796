import * as Yup from 'yup';

import { UserCredentialsFields } from './types';

export const userCredentialsSchema = Yup.object().shape({
  [UserCredentialsFields.email]: Yup.string()
    .email('Not valid email')
    .label('Email')
    .required(({ label }) => `${label} is required`),
});
