export const TwitterIcon = () => (
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.500244 17.1334C3.08366 17.2723 5.34274 16.6956 7.54591 15.1684C5.32037 14.8053 3.77704 13.8334 2.93827 11.8257C3.58692 11.7402 4.15729 11.9004 4.8283 11.6441C2.63632 10.7257 1.216 9.34802 1.16008 6.96648C1.85346 7.01988 2.3791 7.45774 3.20668 7.39366C1.19364 5.51406 0.578529 3.46359 1.84228 0.975249C3.94479 3.28203 6.40519 4.85193 9.41358 5.58882C9.58133 5.63154 9.73789 5.67425 9.90565 5.70629C10.6773 5.87717 11.5944 6.24027 12.0641 6.18687C12.8693 6.09076 12.0641 5.20435 12.2654 4.05096C12.9029 0.462631 17.0408 -1.17134 20.1051 0.932531C20.9998 1.55195 21.7043 1.54127 22.5879 1.16748C23.0464 0.97525 23.5161 0.783018 24.0641 0.558747C23.9411 1.58398 23.1135 2.15 22.4537 2.90825C23.203 3.06844 23.8069 2.86553 24.5002 2.65194C24.2654 3.38883 23.6838 3.80533 23.1582 4.21116C22.6102 4.62766 22.3977 5.06552 22.3754 5.74901C22.0399 16.0975 9.72672 24.0965 1.30547 17.7315C0.489069 17.1121 1.2831 17.7315 0.500244 17.1334Z" />
  </svg>
);
