import { styled, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import MainNavigation, {
  NavItems,
} from '../../components/Navigation/MainNavigation';
import Footer from '../Home/components/Footer';
import { DocumentationIcon } from '../../components/Icons';

import withPrivateRoute from '../../hooks/withPrivateRoute';
import EventMetrics from './components/EventMetrics';
import EventsTable from './components/EventsTable';

import { getHydrated } from '../../redux/reducers/appReducer';
import { getIsAuthorized } from '../../redux/reducers/authReducer';
import { useEffect } from 'react';
import { getUserDataServer } from '../../redux/actions/authActions';
import {
  getPicklistAnchorsOptions,
  getPicklistConditionalsOptions,
  getPicklistOperatorsOptions,
} from '../../redux/actions/eventsActions';

const EventsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDataServer());
    dispatch(getPicklistAnchorsOptions());
    dispatch(getPicklistConditionalsOptions());
    dispatch(getPicklistOperatorsOptions());
  }, []);
  return (
    <Wrapper>
      <MainNavigation
        actions={<EventsPageNavActions />}
        activeItem={NavItems.events}
      />
      {/* <EventMetrics /> */}
      <EventsTable />
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#F5F5F5',
  minHeight: '100vh',
});

const EventsPageNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

export default withPrivateRoute(EventsPage);
