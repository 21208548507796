import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Loader } from '@maestro-org/ui-kit';

import { IBillingInfo } from '../../../types/auth';

import {
  getActiveMethodId,
  getAllPaymentMethods,
  getBillingDataLoading,
  getStripeCustomer,
  getStripeCustomerLoading,
} from '../../../redux/reducers/stripeReducer';

import Divider from '../../../components/Divider/Divider';
import SettingsCard from './SettingsCard';
import { EditIcon } from '../../../components/Icons';

import { pages } from '../../../lib/routeUtils';

const BillingInfo = () => {
  const isLoading = useSelector(getStripeCustomerLoading);
  const billingInfo = useSelector(getStripeCustomer);

  return (
    <form>
      <SettingsCard>
        <Row>
          <Typography color="grey.A200" variant="h6">
            Billing information
          </Typography>
          <Link to={pages.billingInformation()}>
            <StyledButton startIcon={<EditIcon />}>Update</StyledButton>
          </Link>
        </Row>
        {isLoading && <Loader />}
        {!isLoading && (
          <InfoWrapper>
            <InfoRow>
              <InfoText variant="paragraphSmall" color="grey.500">
                Full Name
              </InfoText>
              <InfoText
                variant="paragraphSmall"
                color="grey.A200"
              >{`${billingInfo?.name}`}</InfoText>
            </InfoRow>
            <InfoRow>
              <InfoText variant="paragraphSmall" color="grey.500">
                Billing address
              </InfoText>
              <InfoText variant="paragraphSmall" color="grey.A200">{`${
                billingInfo?.address?.line1
              } ${billingInfo?.address?.line2} ${
                billingInfo?.address?.postal_code || ''
              }, ${billingInfo?.address?.country}`}</InfoText>
            </InfoRow>
          </InfoWrapper>
        )}
      </SettingsCard>
    </form>
  );
};

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const InfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const InfoRow = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  columnGap: '80px',
});

const InfoText = styled(Typography)({
  width: '131px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '5px 10px',
  background: 'transparent',
  color: theme.palette.primary.main,

  '&:hover': {
    background: 'transparent',
    color: theme.palette.primary.main,
  },

  '& path': {
    stroke: theme.palette.primary.main,
  },
}));

const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const Ref = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export default BillingInfo;
