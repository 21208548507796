import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { EntryType } from '../../../types/table';

import { getUser } from '../../../redux/reducers/usersReducer';

import { WebhookActions } from '../../../types/transaction';

import Table from '../../../components/Table/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TransactionStateChip, {
  ChipColors,
} from '../../../components/Table/TableChip';
import { TransactionEventsListeningIcon } from '../../../components/Icons';
import Container from '../../../components/Container/Container';
import {
  getAllWebhooks,
  getAllWebhooksLoading,
  getIsWebhookCreating,
  getLoadingWebhooksIds,
} from '../../../redux/reducers/webhooksReducer';
import { Webhook } from '../../../types/webhooks';
import { updateWebhookServer } from '../../../redux/actions/webhooksActions';
import { getAllprojects } from '../../../redux/reducers/projectsReducer';
import { updatePopup } from '../../../redux/actions/popupsActions';
import { Popups } from '../../../types/popups';

import Tooltip from '../../../components/Tooltip/Tooltip';
import { UserRoles } from '../../../types/roles';
import { useCurrentSubscription } from '../../../hooks/useCurrentSubscription';
import { SubStatus } from '../../../types/user';

export interface TransactionProject {
  id: number;
  project: string;
  status: string;
  url: string;
  events: string;
  actions: React.ReactNode;
}

const getChipColor = (active: boolean) => {
  return active ? ChipColors.green : ChipColors.yellow;
};

const getChipLabel = (active: boolean) => {
  return active ? 'Active' : 'Paused';
};

const TransactionEventsListening = () => {
  const allWebhooks = useSelector(getAllWebhooks);
  const isLoading = useSelector(getAllWebhooksLoading);
  const isWebhookCreating = useSelector(getIsWebhookCreating);
  const loadingWebhooksIds = useSelector(getLoadingWebhooksIds);
  const user = useSelector(getUser);

  const currentSubscription = useCurrentSubscription();

  const allProjects = useSelector(getAllprojects);

  const dispatch = useDispatch();

  const createRow = (webhook: Webhook, projectId: string): EntryType => ({
    id: webhook.webhook_id,
    project: webhook.project,
    name: webhook.name,
    url: (
      <DefaultTooltip title={webhook.url}>
        <WebhookUrl>{webhook.url}</WebhookUrl>
      </DefaultTooltip>
    ),
    events: webhook.events,
    status: (
      <TransactionStateChip
        label={getChipLabel(webhook.active)}
        color={getChipColor(webhook.active)}
      />
    ),
    actions: (
      <div
        style={{
          display: 'flex',
          columnGap: '25px',
          justifyContent: 'flex-end',
        }}
      >
        {user.team_role_name === UserRoles.MEMBER ? (
          <>
            <StyledTooltip
              title={
                <Typography variant="paragraphSmall" color="grey.A200">
                  Not accessible for Team Member role
                </Typography>
              }
              followCursor
            >
              <div>
                <ActionWrapper>
                  <Typography
                    variant="paragraphSmall"
                    color="textColor.lightGray"
                  >
                    {webhook.active ? 'Pause' : 'Unpause'}
                  </Typography>
                </ActionWrapper>
              </div>
            </StyledTooltip>
            <StyledTooltip
              title={
                <Typography variant="paragraphSmall" color="grey.A200">
                  Not accessible for Team Member role
                </Typography>
              }
              followCursor
            >
              <ActionWrapper>
                <Typography
                  variant="paragraphSmall"
                  color="textColor.lightGray"
                >
                  Delete
                </Typography>
              </ActionWrapper>
            </StyledTooltip>
          </>
        ) : (
          <>
            <div>
              <ActionWrapper
                onClick={() =>
                  handleWebhookUpdate(
                    webhook.webhook_id,
                    projectId,
                    webhook.active
                      ? WebhookActions.pause
                      : WebhookActions.unpause
                  )
                }
              >
                <Typography variant="paragraphSmall" color="primary.main">
                  {webhook.active ? 'Pause' : 'Unpause'}
                </Typography>
              </ActionWrapper>
            </div>
            <ActionWrapper
              onClick={() => removeTransaction(webhook.webhook_id, projectId)}
            >
              <Typography variant="paragraphSmall" color="primary.main">
                Delete
              </Typography>
            </ActionWrapper>
          </>
        )}
      </div>
    ),
  });

  const [transactions, setTransactions] = useState<EntryType[]>([]);

  const removeTransaction = (webhook_id: string, project_id: string) => {
    dispatch(
      updatePopup({
        popup: Popups.deleteWebhook,
        status: true,
        prefilled: { webhookId: webhook_id, projectId: project_id },
      })
    );
  };

  const handleWebhookUpdate = (
    webhookId: string,
    projectId: string,
    action: WebhookActions
  ) => {
    dispatch(
      updateWebhookServer({
        webhook_id: webhookId,
        project_id: projectId,
        action,
      })
    );
    if (action === WebhookActions.pause && !isWebhookCreating) {
      toast.success('Webhook paused successfully');
    } else if (action === WebhookActions.unpause && !isWebhookCreating) {
      toast.success('Webhook unpaused successfully');
    }
  };

  const getProjectId = (webhook: Webhook) => {
    const projectId = allProjects.find(
      ({ name }) => webhook.project === name
    )?.id;
    if (!projectId) return false;
    return projectId;
  };

  const handleClick = () => {
    dispatch(updatePopup({ popup: Popups.createWebhook, status: true }));
  };

  useEffect(() => {
    if (!allWebhooks.length || !allProjects.length) {
      setTransactions([]);
      return;
    }
    setTransactions(
      allWebhooks
        .filter(getProjectId)
        .map((webhook) => createRow(webhook, getProjectId(webhook) as string))
    );
  }, [allWebhooks]);

  return (
    <Container>
      <Wrapper>
        <TransactionPanelWrapper>
          <TableHeader
            titleAndornment={
              <IconWrapper>
                <TransactionEventsListeningIcon />
              </IconWrapper>
            }
            triggerValue="Create webhook"
            title="Transaction Events Listening"
            subtitle="Get notified when a transaction changes state."
            newEntry={{
              disable: [
                {
                  condition: !allProjects.length,
                  message: 'Create a project to use webhooks',
                },
                {
                  condition:
                    allWebhooks.length >=
                    +(currentSubscription?.webhook_limit || 0),
                  message:
                    'You have reached the maximum number of webhooks for your subscription tier',
                },
                {
                  condition: user.team_role_name === UserRoles.MEMBER,
                  message: 'Not accessible for Team Member role.',
                },
                {
                  condition: user.status === SubStatus.paused,
                  message:
                    'Your account has an overdue invoice. Your subscription will soon be cancelled',
                },
              ],
              handleClick,
              isLoading: isWebhookCreating,
            }}
          />
        </TransactionPanelWrapper>
        <Table
          rowSx={getRowStyle()}
          data={transactions}
          heading={heading}
          page={0}
          rowsPerPage={5}
          isLoading={isLoading}
          loadingEntriesIds={loadingWebhooksIds}
        />
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '120px 0',

  '& .MuiPaper-root': {
    overflow: 'auto hidden !important',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '74px 0 96px',
  },

  [theme.breakpoints.down(709)]: {
    '& .MuiTable-root': {
      minWidth: '637px',
    },

    '& .MuiPaper-root': {
      overflow: 'auto hidden !important',
    },

    '& .first-header-cell, & .first-body-cell': {
      position: 'sticky',
      left: 0,
      background: theme.palette.common.white,
      boxShadow: '0px 0px 24px -6px rgba(0, 0, 0, 0.16)',
      marginRight: '38px',
    },
  },
}));

const TransactionPanelWrapper = styled('div')(({ theme }) => ({
  paddingBottom: '16px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '32px',
  },

  [theme.breakpoints.down('md')]: {
    '& .new-entry-panel': {
      flexWrap: 'wrap',
      rowGap: '20px',
    },
    '& .new-entry-panel > div': {
      maxWidth: '100%',
    },
    '& .new-entry-panel > div > div': {
      maxWidth: '100%',
    },
  },
}));

const ActionsHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

const getRowStyle = () => ({});

const DefaultTooltip = styled('abbr')({
  textDecoration: 'none',
});

const WebhookUrl = styled('div')({
  height: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical',
});

const ActionWrapper = styled('div')({
  cursor: 'pointer',
});

const IconWrapper = styled('div')({
  transform: 'rotate(-45deg)',
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '6px',
  },
}));

const heading: EntryType = {
  project: 'Project',
  name: 'Name',
  url: 'URL',
  events: 'Events',
  status: 'Status',
  actions: (
    <ActionsHeaderWrapper>
      <Typography color="table.headerText" variant="paragraphSmall">
        Actions
      </Typography>
    </ActionsHeaderWrapper>
  ),
};

export default TransactionEventsListening;
