import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getAllWebhooks } from '../../../../redux/reducers/webhooksReducer';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getDeleteWebhookDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { deleteWebhookServer } from '../../../../redux/actions/webhooksActions';
import { Popups } from '../../../../types/popups';

export const useDeleteWebhook = () => {
  const [webhookName, setWebhookName] = useState('');

  const allWebhooks = useSelector(getAllWebhooks);
  const isOpen = useSelector(getDeleteWebhookDialog);
  const { webhookId, projectId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteWebhook, status: false }));
    setWebhookName('');
  };

  const handleDelete = () => {
    if (!webhookId) {
      toast.error('Error occurred while deleting webhook');
      return;
    }
    dispatch(
      deleteWebhookServer({
        webhook_id: webhookId,
        project_id: projectId,
        prevWebhooks: allWebhooks,
      })
    );
    toast.success('Successfully deleted webhook');
    handleClose();
  };

  useEffect(() => {
    if (!allWebhooks.length) return;
    const webhookName = allWebhooks.find(
      (item) => item.webhook_id === webhookId
    )?.name;
    if (!webhookName) return;
    setWebhookName(webhookName);
  }, [allWebhooks, webhookId]);

  return {
    isOpen,
    webhookName,
    handleClose,
    handleDelete,
  };
};
