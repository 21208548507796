import {
  InvoiceHistoryItem,
  INewInvoiceHistoryItem,
} from '../../types/invoiceHistory';
import {
  CreditsUsageItem,
  SubscriptionService,
  UserMetrics,
  UserSubscriptions,
} from '../../types/user';

export const CREATE_USER_SERVER = 'CREATE_USER_SERVER';
export const DELETE_USER_SERVER = 'DELETE_USER_SERVER';
export const UPDATE_IS_USER_DELETING = 'UPDATE_IS_USER_DELETING';
export const GET_USER_SERVER = 'GET_USER_SERVER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const GET_METRICS_OVERVIEW_SERVER = 'GET_METRICS_OVERVIEW_SERVER';
export const UPDATE_METRICS_OVERVIEW = 'UPDATE_METRICS_OVERVIEW';
export const UPDATE_METRICS_OVERVIEW_LOADING =
  'UPDATE_METRICS_OVERVIEW_LOADING';
export const GET_USER_SUBSCRIPTIONS_SERVER = 'GET_USER_SUBSCRIPTIONS_SERVER';
export const UPDATE_USER_SUBSCRIPTIONS = 'UPDATE_USER_SUBSCRIPTIONS';
export const UPDATE_USER_SUBSCRIPTIONS_LOADING =
  'UPDATE_USER_SUBSCRIPTIONS_LOADING';
export const GET_SUBSCRIPTION_SERVICES_SERVER =
  'GET_SUBSCRIPTION_SERVICES_SERVER';
export const UPDATE_SUBSCRIPTION_SERVICES = 'UPDATE_SUBSCRIPTION_SERVICES';
export const UPDATE_SUBSCRIPTION_SERVICES_LOADING =
  'UPDATE_SUBSCRIPTION_SERVICES_LOADING';
export const GET_USER_CREDIT_USAGE_SERVER = 'GET_USER_CREDIT_USAGE_SERVER';
export const UPDATE_USER_CREDIT_USAGE = 'UPDATE_USER_CREDIT_USAGE';
export const UPDATE_USER_CREDIT_USAGE_LOADING =
  'UPDATE_USER_CREDIT_USAGE_LOADING';
export const GET_BILLING_INVOICES_SERVER = 'GET_BILLING_INVOICES_SERVER';
export const UPDATE_BILLING_SERVER = 'UPDATE_BILLING_SERVER';
export const UPDATE_BILLING_LOADING = 'UPDATE_BILLING_LOADING';
export const CHANGE_IS_BILLING_UPDATED = 'CHANGE_IS_BILLING_UPDATED';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const UPDATE_INVOICES_LOADING = 'UPDATE_INVOICES_LOADING';
export const GET_TEAM_OWNER_SERVER = 'GET_TEAM_OWNER_SERVER';
export const UPDATE_TEAM_OWNER_LOADING = 'UPDATE_TEAM_OWNER_LOADING';
export const UPDATE_TEAM_OWNER = 'UPDATE_TEAM_OWNER';
export const SET_USER_ORGANIZATION = 'SET_USER_ORGANIZATION';
export const GET_USER_ORGANIZATION_SERVER = 'GET_USER_ORGANIZATION';
export const SET_IS_USER_ORGANIZATION_UPDATING = 'USER_ORGANIZATION_LOADING';
export const UPDATE_USER_ORGANIZATION_SERVER = 'UPDATE_USER_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const CLEAR_ORGANIZATION_DATA = 'CLEAR_ORGANIZATION_DATA';

interface CreateUserProps {
  email: string;
  auth0_user_id: string;
  team_id?: string;
  organization?: string;
}

interface UpdateStatusProps {
  status: boolean;
}

interface UpdateUserProps {
  user: {
    email: string;
    auth0_user_id: string;
    subscription_id: string;
    available_credits?: string;
    credits_used?: string;
  };
}

interface UpdateUserSubscriptionsProps {
  data: UserSubscriptions[];
}

interface UpdateSubscriptionService {
  data: SubscriptionService[];
}

interface GetCreditUsageProps {
  days: number;
}

interface UpdateCreditsUsageProps {
  data: CreditsUsageItem[];
}

interface UpdateBillingDataProps {
  address?: any;
  name?: string;
  email?: string;
  phone?: string;
}

interface UpdateInvoicesProps {
  invoices: INewInvoiceHistoryItem[];
}

export const createUserServer = ({
  email,
  auth0_user_id,
  team_id,
  organization,
}: CreateUserProps) => ({
  type: CREATE_USER_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: '/user/create',
      data: { email, auth0_user_id, team_id, organization },
    },
  },
});

export const deleteUserServer = () => ({
  type: DELETE_USER_SERVER,
  payload: {
    request: {
      method: 'DELETE',
      url: '/user/delete',
    },
  },
});

export const updateIsUserDeleting = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_IS_USER_DELETING,
  payload: { status },
});

export const getUserServer = () => ({
  type: GET_USER_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/user',
    },
  },
});

export const getTeamOwnerServer = () => ({
  type: GET_TEAM_OWNER_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/user/owner',
    },
  },
});

export const isTeamOwnerLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_TEAM_OWNER_LOADING,
  payload: { status },
});

export const updateTeamOwner = ({ user }: UpdateUserProps) => ({
  type: UPDATE_TEAM_OWNER,
  payload: { user },
});

export const updateUser = ({ user }: UpdateUserProps) => ({
  type: UPDATE_USER,
  payload: { user },
});

export const updateUserLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_USER_LOADING,
  payload: { status },
});

export const getMetricsOverviewServer = () => ({
  type: GET_METRICS_OVERVIEW_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/metric/user/overview',
    },
  },
});

export const updateUserMetrics = (data: UserMetrics) => ({
  type: UPDATE_METRICS_OVERVIEW,
  payload: { data },
});

export const upateUserMetricsLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_METRICS_OVERVIEW_LOADING,
  payload: { status },
});

export const updateUserSubscriptions = ({
  data,
}: UpdateUserSubscriptionsProps) => ({
  type: UPDATE_USER_SUBSCRIPTIONS,
  payload: { data },
});

export const updateUserSubscriptionLoading = ({
  status,
}: UpdateStatusProps) => ({
  type: UPDATE_USER_SUBSCRIPTIONS_LOADING,
  payload: { status },
});

export const getSubscriptionServicesServer = () => ({
  type: GET_SUBSCRIPTION_SERVICES_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/subscription/services',
    },
  },
});

export const updateSubscriptionServices = ({
  data,
}: UpdateSubscriptionService) => ({
  type: UPDATE_SUBSCRIPTION_SERVICES,
  payload: { data },
});

export const updateSubscriptionServicesLoading = ({
  status,
}: UpdateStatusProps) => ({
  type: UPDATE_SUBSCRIPTION_SERVICES_LOADING,
  payload: { status },
});

export const getUserCreditUsageServer = ({ days }: GetCreditUsageProps) => ({
  type: GET_USER_CREDIT_USAGE_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: `/metric/user/credits?days=${days}`,
    },
  },
});

export const updateCreditUsage = ({ data }: UpdateCreditsUsageProps) => ({
  type: UPDATE_USER_CREDIT_USAGE,
  payload: { data },
});

export const updateCreditUsageLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_USER_CREDIT_USAGE_LOADING,
  payload: { status },
});

export const getBillingInvoicesServer = () => ({
  type: GET_BILLING_INVOICES_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/billing/invoices',
    },
  },
});

export const updateInvoices = ({ invoices }: UpdateInvoicesProps) => ({
  type: UPDATE_INVOICES,
  payload: { invoices },
});

export const updateInvoicesLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_INVOICES_LOADING,
  payload: { status },
});

export const updateBillingServer = (data: UpdateBillingDataProps) => ({
  type: UPDATE_BILLING_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: '/billing/update',
      data,
    },
  },
});

export const changeIsBillingUpdating = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_BILLING_LOADING,
  payload: { status },
});

export const changeBillingDataUpdated = ({ status }: UpdateStatusProps) => ({
  type: CHANGE_IS_BILLING_UPDATED,
  payload: { status },
});

export const setUserOrganization = (organization: string) => ({
  type: SET_USER_ORGANIZATION,
  payload: { organization },
});

export const getUserOrganizationServer = () => ({
  type: GET_USER_ORGANIZATION_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/user/organization',
    },
  },
});

export const updateOrganization = (organization: string) => ({
  type: UPDATE_ORGANIZATION,
  payload: organization,
});

export const updateUserOrganisationServer = (organization: string) => ({
  type: UPDATE_USER_ORGANIZATION_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: '/user/organization',
      data: { organization },
    },
  },
});

export const setIsUpdatingUserOrganization = ({
  status,
}: UpdateStatusProps) => ({
  type: SET_IS_USER_ORGANIZATION_UPDATING,
  payload: { status },
});

export const clearOrganizationData = () => ({
  type: CLEAR_ORGANIZATION_DATA,
});
