import { styled, Typography } from '@mui/material';
import { Button } from '@maestro-org/ui-kit';

import Dialog from '../common/Dialog';
import DialogHeader from '../common/DialogHeader';
import APIKeyDisplay from './components/APIKeyDisplay';
import { useAPIKeyCreated } from './hooks/useAPIKeyCreated';

const APIKeyCreated = () => {
  const {
    isPasswordType,
    isOpen,
    apiKey,
    togglePasswordType,
    handleClose,
    handleCopy,
  } = useAPIKeyCreated();

  return (
    <Dialog open={isOpen}>
      <DialogHeader title="API key successfuly created" />

      <APIKeyDisplay
        apiKey={apiKey}
        isPasswordType={isPasswordType}
        togglePasswordType={togglePasswordType}
        handleClose={handleClose}
      />

      <Actions>
        <CancelButton onClick={handleClose}>
          <Typography color="grey.A200" variant="paragraphSmall">
            Cancel
          </Typography>
        </CancelButton>
        <Button onClick={handleCopy}>
          <Typography variant="paragraphSmall">Copy</Typography>
        </Button>
      </Actions>
    </Dialog>
  );
};

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey['50'],

  '&:hover': {
    background: theme.palette.grey['50'],
  },
}));

export default APIKeyCreated;
