import React from 'react';
import {
  Menu as MuiMenu,
  styled,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { IconButton } from '@maestro-org/ui-kit';

import UserImage from '../UserImage/UserImage';

interface Props {
  label?: string;
  email?: string;
  endIcon?: React.ReactNode;
  shouldChangeIconColor?: boolean;
  children: React.ReactNode;
}

const Menu = ({
  children,
  email,
  label,
  endIcon,
  shouldChangeIconColor,
}: Props) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Wrapper
        onClick={handleClick}
        isOpen={isOpen}
        shouldChangeIconColor={shouldChangeIconColor}
      >
        {label !== undefined ? (
          <Typography
            color={isOpen ? 'primary.main' : 'grey.A200'}
            variant="paragraphMedium"
          >
            {label}
          </Typography>
        ) : (
          <>
            <StyledIconButton
              size="small"
              sx={{ ml: 2 }}
              aria-controls={isOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isOpen ? 'true' : undefined}
            >
              <UserImage size={48} />
            </StyledIconButton>
            <EmailWrapper>{email}</EmailWrapper>
          </>
        )}
        {endIcon}
      </Wrapper>
      <MuiMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: getMenuStyles(theme),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </MuiMenu>
    </>
  );
};

const getMenuStyles = (theme: Theme) => ({
  overflow: 'visible',
  zIndex: 20,
  border: `1px solid ${theme.palette.menu.border}`,
  boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
  borderRadius: '2px',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
});

const Wrapper = styled('div')<{
  shouldChangeIconColor?: boolean;
  isOpen: boolean;
}>(({ theme, shouldChangeIconColor, isOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',
  cursor: 'pointer',

  '& svg': {
    width: '11px',
    fill: !shouldChangeIconColor
      ? 'none'
      : isOpen
      ? theme.palette.primary.main
      : theme.palette.grey.A200,
  },
}));

const StyledIconButton = styled(IconButton)({
  background: 'transparent',
  width: '48px',
  height: '48px',
  marginLeft: '0 !important',

  '&:hover': {
    background: 'transparent',
  },
});

const EmailWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down(1101)]: {
    display: 'none',
  },
}));

const MenuImage = styled('img')({
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  objectFit: 'cover',
});

const ImagePlaceholder = styled('div')({
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  background: 'gray',
});

export default Menu;
