export const GreenCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
  >
    <circle
      cx="3.5"
      cy="3"
      r="2.5"
      fill="#53B06A"
      stroke="#46975A"
      strokeWidth="0.5"
    />
  </svg>
);
