import { styled, Typography } from '@mui/material';

const CustomTooltip = ({
  active,
  payload,
  data,
  activeValue,
  variant,
}: any) => {
  if (active && payload && payload.length && data) {
    const total = payload?.reduce((acc: any, curr: any) => acc + curr.value, 0);

    if (variant === 'project') {
      return (
        <Wrapper padding="16px" minWidth="181px" maxWidth="181px">
          {payload.map((data: any) => (
            <TextBlock key={data.dataKey}>
              <Row style={{ width: 'auto' }}>
                <div style={{ marginRight: '8px' }}>
                  <Circle color={data.fill} />
                </div>
                <Typography variant="tooltipText" color="textColor.tooltipText">
                  Total Requests
                </Typography>
              </Row>
              <Typography
                variant="tooltipText"
                color={
                  data.dataKey === activeValue
                    ? data.fill
                    : 'textColor.tooltipText'
                }
              >
                {data.value}
              </Typography>
            </TextBlock>
          ))}
        </Wrapper>
      );
    }

    return (
      <Wrapper padding="16px" minWidth="163px" maxWidth="163px">
        <TextBlock heading>
          <Typography variant="tooltipText" color="textColor.tooltipText">
            Total
          </Typography>
          <Typography variant="tooltipText" color="textColor.tooltipText">
            {total}
          </Typography>
        </TextBlock>
        <Column>
          {payload.map((data: any) => (
            <TextBlock key={data.dataKey}>
              <Typography variant="tooltipText" color="textColor.tooltipText">
                {data.dataKey}
              </Typography>
              <Row>
                <div style={{ width: '16px', marginRight: 'auto' }}>
                  <Circle color={data.fill} />
                </div>
                <Typography
                  variant="tooltipText"
                  color={
                    data.dataKey === activeValue
                      ? data.fill
                      : 'textColor.tooltipText'
                  }
                >
                  {data.value}
                </Typography>
              </Row>
            </TextBlock>
          ))}
        </Column>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper padding="3.5px 16px" minWidth="100%" maxWidth="100%">
        <Typography variant="tooltipText" color="textColor.tooltipText">
          No data available
        </Typography>
      </Wrapper>
    );
  }
};

export const Wrapper = styled('div')<{
  padding: string;
  minWidth: string;
  maxWidth: string;
}>(({ theme, padding, minWidth, maxWidth }) => ({
  backgroundColor: theme.palette.tooltip.background,
  boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
  borderRadius: '6px',
  border: '1px solid #e6e6e6',
  outline: 'none',
  padding,
  minWidth,
  maxWidth,
  width: '100%',
}));

const TextBlock = styled('div')<{ heading?: boolean }>(({ heading }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  ...(heading && {
    paddingBottom: '12px',
    borderBottom: '1px solid #e6e6e6',
    marginBottom: '12px',
  }),
}));

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '40px',
});

const Circle = styled('div')<{ color: string }>(({ color }) => ({
  width: '6px',
  height: '6px',
  backgroundColor: color,
  borderRadius: '50%',
  flexShrink: 0,
}));

export default CustomTooltip;
