export const ArrowDownTriangleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
  >
    <path d="M0 0L4 4L8 0H0Z" fill="#0F0311" />
  </svg>
);
