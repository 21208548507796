export const RedCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="6"
    viewBox="0 0 7 6"
    fill="none"
  >
    <circle
      cx="3.5"
      cy="3"
      r="2.5"
      fill="#DC6675"
      stroke="#BC5663"
      strokeWidth="0.5"
    />
  </svg>
);
