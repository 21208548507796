export const TelegramIcon = () => (
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.6613 0.262276C22.6613 0.262276 24.9277 -0.621475 24.7388 1.52478C24.6759 2.40854 24.1093 5.50166 23.6686 8.84731L22.1576 18.758C22.1576 18.758 22.0317 20.2098 20.8985 20.4624C19.7652 20.7148 18.0655 19.5786 17.7506 19.3261C17.4988 19.1367 13.029 16.2961 11.455 14.9073C11.0143 14.5286 10.5107 13.7711 11.518 12.8873L18.1284 6.57482C18.8839 5.81731 19.6394 4.04981 16.4915 6.19606L7.67764 12.193C7.67764 12.193 6.67034 12.8242 4.78168 12.2561L0.689496 10.9936C0.689496 10.9936 -0.82146 10.0467 1.75975 9.09978C8.05541 6.13289 15.799 3.10288 22.6613 0.262276Z" />
  </svg>
);
