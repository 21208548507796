import React from 'react';
import { LinearProgress, styled, Typography, useTheme } from '@mui/material';
import {
  COLORS_DARK_THEME,
  COLORS_LIGHT_THEME,
} from '../../../types/pieChartColors';
import { TokenomicsPoint } from '../../../types/tokenomicsTypes';
import { numberDecorator } from '../../../lib/numberDecorator';

interface Props {
  data: any;
  selectSection: string;
  handleSelectSection: (label: string) => void;
}

const getWidth = (data: TokenomicsPoint[]): number => {
  let maxCount = 0;

  data.forEach((point) => {
    if (point.count > maxCount) {
      maxCount = point.count;
    }
  });

  const length = maxCount.toString().length;

  const value = 140 + length * 8;

  return length < 5 ? 160 : value;
};

const CustomPieChartLegend = ({
  data,
  selectSection,
  handleSelectSection,
}: Props) => {
  const theme = useTheme();

  const total =
    data.reduce(
      (acc: number, item: TokenomicsPoint) => (acc += item.count),
      0
    ) || 1;

  const width = getWidth(data);

  return (
    <Wrapper>
      <List>
        {data.map((entry: TokenomicsPoint, index: number) => (
          <Legend
            onMouseEnter={() => handleSelectSection(entry.label)}
            onMouseLeave={() => handleSelectSection('')}
            key={`item-${index}`}
          >
            <Row>
              <Left>
                <Circle
                  style={{
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? COLORS_LIGHT_THEME[index % COLORS_LIGHT_THEME.length]
                        : COLORS_DARK_THEME[index % COLORS_DARK_THEME.length],
                  }}
                />
                <QueryNameWrapper>
                  <Value selectSection={selectSection}>{entry.label}</Value>
                </QueryNameWrapper>
              </Left>
              <QueryRouteWrapper>
                <Value selectSection={selectSection} className="route">
                  {entry.route}
                </Value>
              </QueryRouteWrapper>
            </Row>
            <Right width={width}>
              <BarWrapper>
                <ProgressBar
                  variant="determinate"
                  value={(entry.count / total) * 100}
                />
              </BarWrapper>
              <Percent selectSection={selectSection}>
                {numberDecorator(entry.count)}
              </Percent>
            </Right>
            <VoidDiv />
          </Legend>
        ))}
      </List>
    </Wrapper>
  );
};

const VoidDiv = styled('div')(({ theme }) => ({
  minWidth: '1px',
  height: '1px',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const BarWrapper = styled('div')({
  width: '84px',
});

export const Wrapper = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  maxHeight: '402px',
  padding: '0 15px 17px 0',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
    background: 'transparent',
    borderRadius: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey.A200,
    borderRadius: '7px',
  },

  '&::-webkit-scrollbar-corner': {
    background: 'transparent',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));

const Title = styled(Typography)({
  marginBottom: '16px',
});

export const Legend = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '55px',
  cursor: 'pointer',

  [theme.breakpoints.down(801)]: {
    gap: '30px',
  },
}));

const Left = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '16px',
});

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  // alignItems: "center",
  flexDirection: 'row',
  columnGap: '30px',
  flex: 1,

  '&:hover': {
    '& p': {
      color: theme.palette.primary.main,
    },
    '& .route': {
      textDecoration: 'underline',
    },
  },
}));

const QueryNameWrapper = styled('div')(({ theme }) => ({
  width: '270px',

  [theme.breakpoints.down('md')]: {
    width: '210px',
  },
}));

const QueryRouteWrapper = styled('div')({
  width: '170px',
});

export const Circle = styled('div')({
  width: '14px',
  height: '14px',
  borderRadius: '50%',
});

export const Value = styled(Typography)<{
  activeColor?: string;
  selectSection?: string;
}>(({ theme, activeColor, selectSection }) => ({
  fontFamily: theme.typography.fontFamily,
  minWidth: '150px',
  wordBreak: 'break-all',
  transition: '0.3s',

  ...(activeColor && selectSection
    ? {
        color: activeColor,
      }
    : {
        color: theme.palette.grey.A200,
      }),
}));

const Right = styled('div')<{ width: number }>(({ width }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: `${width}px`,
  gap: '12px',
  justifyContent: 'space-between',
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  maxWidth: '84px',
  height: '11px',
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.xxs,
  border: `1px solid ${theme.palette.grey[100]}`,
  width: '100%',

  '& .MuiLinearProgress-bar': {
    borderRadius: theme.borderRadius.xxs,
  },
}));

export const Percent = styled(Typography)<{
  activeColor?: string;
  selectSection?: string;
}>(({ theme, activeColor, selectSection }) => ({
  display: 'flex',
  justifyContent: 'end',
  fontFamily: theme.typography.fontFamily,

  ...(activeColor && selectSection
    ? {
        color: activeColor,
      }
    : {
        transition: '0.3s',
        color: theme.palette.grey.A200,
      }),
}));

export default CustomPieChartLegend;
