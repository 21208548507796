export const MessageIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 4V15C23 15.5304 22.7893 16.0391 22.4142 16.4142C22.0391 16.7893 21.5304 17 21 17H19V21L13 17H12"
      stroke="#808080"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13H5V18L12 13H17C17.5304 13 18.0391 12.7893 18.4142 12.4142C18.7893 12.0391 19 11.5304 19 11V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1Z"
      stroke="#808080"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);
