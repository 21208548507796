import { styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@maestro-org/ui-kit';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import SettingsCard from './SettingsCard';
import Divider from '../../../components/Divider/Divider';

import { pages } from '../../../lib/routeUtils';

import { IBillingInfo, ICard } from '../../../types/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getClientSecret } from '../../../redux/reducers/stripeReducer';
import {
  addPaymentServer,
  updateClientSecret,
} from '../../../redux/actions/stripeActions';
import { toast } from 'react-toastify';
import { getStripeError } from '../../../lib/errors';

interface Props {
  isLoading: boolean;
  billingData?: IBillingInfo;
}

const PaymentDetails = ({ isLoading, billingData }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const clientSecret = useSelector(getClientSecret);

  const dispatch = useDispatch();

  const onSubmit = async (event: any) => {
    if (!billingData) return;
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await (elements as any).submit();
    if (submitError) {
      return;
    }

    dispatch(addPaymentServer());
  };

  useEffect(() => {
    if (!clientSecret || !stripe || !elements || !billingData) {
      dispatch(updateClientSecret({ clientSecret: '' }));
      return;
    }

    const handleAddPayment = async () => {
      const result = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.origin + pages.subscriptionPlan(),
          payment_method_data: {
            billing_details: {
              address: {
                state: billingData.address.state,
              },
            },
          },
        },
      } as any);

      if (result.error)
        toast.error(
          getStripeError(
            result,
            'Could not add payment method. Try again later!'
          )
        );
      else toast.success('New payment method was successfully added!');

      dispatch(updateClientSecret({ clientSecret: '' }));

      navigate(pages.subscriptionPlan());
    };
    handleAddPayment();
  }, [clientSecret]);

  return (
    <form id="payment-details" onSubmit={onSubmit}>
      <SettingsCard>
        <Typography color="grey.A200" variant="h6">
          Add new payment
        </Typography>
        <FieldsWrapper>
          {isLoading && <p>loading</p>}
          {!isLoading && billingData && (
            <PaymentElement
              options={{
                wallets: {
                  applePay: 'never',
                  googlePay: 'never',
                },
                defaultValues: {
                  billingDetails: {
                    address: {
                      country: billingData.address.country,
                      postal_code: billingData.address.postal_code,
                    },
                  },
                },
              }}
            />
          )}
        </FieldsWrapper>
        <Actions>
          <Link to={pages.subscriptionPlan()}>
            <CancelButton>Cancel</CancelButton>
          </Link>
          <SubmitButton type="submit">Save</SubmitButton>
        </Actions>
      </SettingsCard>
    </form>
  );
};

const FieldsWrapper = styled('div')(({ theme }) => ({
  width: '590px',

  [theme.breakpoints.down(1101)]: {
    width: '100%',
  },
}));

const Actions = styled('div')({
  display: 'flex',
  columnGap: '16px',
  alignItems: 'center',
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey['A200'],

  '&:hover': {
    background: theme.palette.grey[50],
    color: theme.palette.grey['A200'],
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
}));

const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const Ref = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export default PaymentDetails;
