import { styled } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const PlansRightSidebar = ({ children }: Props) => (
  <Wrapper>{children}</Wrapper>
);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '56px 24px',
  minWidth: '322px',
  width: '322px',
  borderLeft: `1px solid ${theme.palette.grey[100]}`,
  overflow: 'hidden auto',
  paddingBottom: '200px',

  '&::-webkit-scrollbar': {
    backgroundColor: '#fff',
    width: '16px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#fff',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: ' 4px solid #fff',
  },

  '&::-webkit-scrollbar-button': {
    display: 'none',
  },

  [theme.breakpoints.down(1624)]: {
    minWidth: '292px',
    width: '292px',
  },
}));

export default PlansRightSidebar;
