import { NewEntryField, NewEntryFieldTypes } from '../../types/newEntry';
import { Project } from '../../types/project';
import { CreateTransactionFields, CreateTransactionFormValues } from './types';

import { Option } from '../../types/newEntry';

export const initialValuesCreateTransaction = {
  [CreateTransactionFields.project]: [],
  [CreateTransactionFields.url]: '',
  [CreateTransactionFields.webhookName]: '',
} as CreateTransactionFormValues;

export const getCreateTransactionFields = (
  options: Option[]
): NewEntryField[] => [
  {
    type: NewEntryFieldTypes.select,
    name: CreateTransactionFields.project,
    placeholder: 'Project Name',
    options: options,
    fieldLabel: 'Project Name',
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateTransactionFields.webhookName,
    placeholder: 'Webhook name',
    fieldLabel: 'Webhook Name',
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateTransactionFields.url,
    label: 'URL',
    placeholder: 'https://charlesthegreat.com/arpeggio',
    fieldLabel: 'URL',
  },
];
