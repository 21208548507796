import { useSelector } from 'react-redux';
import { getCurrentPlan } from '../lib/subscriptionPlanUtils';
import {
  getOldSubscriptionPlans,
  getSubscriptionPlans,
} from '../redux/reducers/stripeReducer';
import { getUser } from '../redux/reducers/usersReducer';

export const useCurrentSubscription = () => {
  const oldSubscriptionPlans = useSelector(getOldSubscriptionPlans);
  const newSubscriptionPlans = useSelector(getSubscriptionPlans);
  const user = useSelector(getUser);

  return getCurrentPlan(oldSubscriptionPlans, newSubscriptionPlans, user);
};
