import { FormEvent, ReactNode } from 'react';
import { styled } from '@mui/material';

interface DialogFormProps {
  children: ReactNode;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const DialogForm = ({ children, onSubmit }: DialogFormProps) => {
  return <Form onSubmit={onSubmit}>{children}</Form>;
};

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  '& > *:first-child': {
    marginBottom: '12px',
  },
});

export default DialogForm;
