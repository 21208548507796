import { toast } from 'react-toastify';
import { takeEvery, put } from 'redux-saga/effects';
import {
  CREATE_TEAM_SERVER,
  updateIsTeamCreating,
  GET_TEAM_SERVER,
  updateTeamLoading,
  updateTeamServer,
  ADD_TEAM_MEMBER_SERVER,
  getTeamServer,
  REMOVE_TEAM_MEMBER_SERVER,
  UPDATE_TEAM_MEMBER_ROLE_SERVER,
  addTeamMemberSuccess,
  removeTeamMemberSuccess,
  UPDATE_TEAM_NAME,
} from '../actions/teamsActions';

import { ITeamUser } from '../actions/teamsActions';
import { getRoleLevel } from '../../types/roles';

const teamSaga = [
  takeEvery(CREATE_TEAM_SERVER, handleCreateTeam),
  takeEvery(`${CREATE_TEAM_SERVER}_SUCCESS`, handleCreateTeamSuccess),
  takeEvery(`${CREATE_TEAM_SERVER}_FAIL`, handleCreateTeamFail),

  takeEvery(GET_TEAM_SERVER, handleGetTeam),
  takeEvery(`${GET_TEAM_SERVER}_SUCCESS`, handleGetTeamSuccess),
  takeEvery(`${GET_TEAM_SERVER}_FAIL`, handleGetTeamFail),

  takeEvery(ADD_TEAM_MEMBER_SERVER, handleAddTeamMember),
  takeEvery(`${ADD_TEAM_MEMBER_SERVER}_SUCCESS`, handleAddTeamMemberSuccess),
  takeEvery(`${ADD_TEAM_MEMBER_SERVER}_FAIL`, handleAddTeamMemberFail),

  takeEvery(REMOVE_TEAM_MEMBER_SERVER, handleRemoveTeamMember),
  takeEvery(
    `${REMOVE_TEAM_MEMBER_SERVER}_SUCCESS`,
    handleRemoveTeamMemberSuccess
  ),
  takeEvery(`${REMOVE_TEAM_MEMBER_SERVER}_FAIL`, handleRemoveTeamMemberFail),

  takeEvery(UPDATE_TEAM_MEMBER_ROLE_SERVER, handleUpdateTeamMemberRole),
  takeEvery(
    `${UPDATE_TEAM_MEMBER_ROLE_SERVER}_SUCCESS`,
    handleUpdateTeamMemberRoleSuccess
  ),
  takeEvery(
    `${UPDATE_TEAM_MEMBER_ROLE_SERVER}_FAIL`,
    handleUpdateTeamMemberRoleFail
  ),

  takeEvery(UPDATE_TEAM_NAME, handleUpdateTeamName),
  takeEvery(`${UPDATE_TEAM_NAME}_SUCCESS`, handleUpdateTeamNameSuccess),
  takeEvery(`${UPDATE_TEAM_NAME}_FAIL`, handleUpdateTeamNameFail),
];

function* handleCreateTeam() {
  yield put(updateIsTeamCreating({ status: true }));
}

function* handleCreateTeamFail(action: any) {
  yield put(updateIsTeamCreating({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleCreateTeamSuccess() {
  yield put(updateIsTeamCreating({ status: false }));
  yield put(getTeamServer());
  toast.success('New team was successfully created');
}

function* handleGetTeam() {
  yield put(updateTeamLoading({ status: true }));
}

function* handleGetTeamFail() {
  yield put(updateTeamLoading({ status: false }));
}

function* handleGetTeamSuccess(action: any) {
  yield put(updateTeamLoading({ status: false }));
  yield put(updateTeamServer(action.payload.data));
}

function* handleAddTeamMember() {
  yield put(updateTeamLoading({ status: false }));
}

function* handleAddTeamMemberFail(action: any) {
  yield put(updateTeamLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleAddTeamMemberSuccess(action: any) {
  yield put(updateTeamLoading({ status: false }));

  const { email, role } = action.payload.data;

  const roleLevel = getRoleLevel(role);

  const newMember: ITeamUser = {
    email,
    role_level: roleLevel,
    role_name: role,
    status: 'inactive',
    use_mfa: false,
  };

  yield put(addTeamMemberSuccess(newMember));

  toast.success('New user was successfully added!');
}

function* handleRemoveTeamMember() {
  yield put(updateTeamLoading({ status: false }));
}

function* handleRemoveTeamMemberFail(action: any) {
  yield put(updateTeamLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleRemoveTeamMemberSuccess(action: any) {
  yield put(updateTeamLoading({ status: false }));
  const removedUserEmail = action.payload.data.email;
  yield put(removeTeamMemberSuccess(removedUserEmail));
  toast.success('Team user was successfully removed');
}

function* handleUpdateTeamMemberRole() {
  yield put(updateTeamLoading({ status: true }));
}

function* handleUpdateTeamMemberRoleFail(action: any) {
  yield put(updateTeamLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleUpdateTeamMemberRoleSuccess() {
  yield put(updateTeamLoading({ status: false }));
  yield put(getTeamServer());
  toast.success("Team user's role was successfully updated");
}

function* handleUpdateTeamName() {
  yield put(updateTeamLoading({ status: true }));
}

function* handleUpdateTeamNameFail(action: any) {
  yield put(updateTeamLoading({ status: false }));
  const error = action.error.response.data.error;
  toast.error(error);
}

function* handleUpdateTeamNameSuccess() {
  yield put(updateTeamLoading({ status: false }));
  yield put(getTeamServer());
  toast.success("Team's name was successfully updated");
}

export default teamSaga;
