import { NewEntryField, NewEntryFieldTypes } from '../../types/newEntry';
import { CreateProjectFields } from './types';

import { Option } from '../../types/newEntry';

interface GetCreateProjectFieldsProps {
  blockchainOptions: Option[];
  networkOptions: Option[];
}

export const initialValuesCreateProject = {
  [CreateProjectFields.name]: '',
  [CreateProjectFields.network]: [],
  [CreateProjectFields.blockchain]: [],
};

export const getCreateProjectFields = ({
  blockchainOptions,
  networkOptions,
}: GetCreateProjectFieldsProps): NewEntryField[] => [
  {
    type: NewEntryFieldTypes.text,
    name: CreateProjectFields.name,
    placeholder: 'Project Name',
    fieldLabel: 'Project Name',
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateProjectFields.blockchain,
    placeholder: 'Blockchain',
    options: blockchainOptions,
    fieldLabel: 'Blockchain',
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateProjectFields.network,
    placeholder: 'Network',
    options: networkOptions,
    fieldLabel: 'Network',
  },
];
