import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import {
  ResponsiveContainer,
  BarChart as BarChartFromLib,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  CartesianGrid,
  Cell,
} from 'recharts';
import CustomTooltip from './CustomTooltip';
import { ChartData } from '../../../types/chart';
import {
  emptyDayData,
  emptyMonthData,
  emptyWeekData,
  emptyYearData,
} from '../../../mocks/charts';
import { DateTabs } from '../../../modules/Home/components/TotalRequestVolume';
import { getTheme } from '../../../lib/theme/theme';

interface Props {
  data: ChartData[];
  barSize?: number;
  radius?: number;
  type?: DateTabs;
  variant?: 'default' | 'project';
}

const emptyData = (type?: DateTabs) => {
  switch (type) {
    case DateTabs.day:
      return emptyDayData;
    case DateTabs.week:
      return emptyWeekData;
    case DateTabs.month:
      return emptyMonthData;
    case DateTabs.year:
      return emptyYearData;
    default:
      return;
  }
};

const BarChart = ({
  data,
  type,
  barSize = 44,
  radius = 8,
  variant = 'default',
}: Props) => {
  const projects = data.length
    ? Object.keys(data[0]).filter((key) => key !== 'time')
    : [];

  const theme = getTheme('light');

  const colors = [
    {
      active: theme.palette.chart.barFourth,
      disabled: theme.palette.chart.barFourthDisabled,
    },
    {
      active: theme.palette.chart.barThird,
      disabled: theme.palette.chart.barThirdDisabled,
    },
    {
      active: theme.palette.chart.barSecond,
      disabled: theme.palette.chart.barSecondDisabled,
    },
    {
      active: theme.palette.chart.barFirst,
      disabled: theme.palette.chart.barFirstDisabled,
    },
  ];

  const [focusBar, setFocusBar] = useState<number>(-1);

  const [hover, setHover] = useState<any>({});

  const [activeValue, setValueActive] = useState('');

  const handleChange = (key: string, value: any) => {
    const newValue = {
      [key]: value,
    };

    if (value) setValueActive(key);
    setHover((hover: any) => ({ ...hover, ...newValue }));
  };

  useEffect(() => {
    if (!data.length) return;
    const projects = data.length
      ? Object.keys(data[0]).filter((key) => key !== 'time')
      : [];
    setHover(projects.reduce((acc, item) => ({ ...acc, [item]: false }), {}));
  }, [data]);

  return (
    <ResponsiveContainerStyled width="100%" height={300}>
      <CustomBarChart
        data={data || emptyData(type)}
        barGap={-barSize}
        onMouseMove={(state: any) => {
          if (state.isTooltipActive) setFocusBar(state.activeTooltipIndex);
        }}
        onMouseLeave={() => setFocusBar(-1)}
      >
        <CartesianGrid strokeWidth={1} vertical={false} stroke="#CCCCCC" />
        <XAxis dataKey="time" tickLine={false} axisLine={false} />
        <YAxis
          domain={!data ? [0, 500] : ['auto', 'auto']}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip
          content={
            <CustomTooltip
              data={data}
              activeValue={activeValue}
              variant={variant}
            />
          }
          filterNull
          cursor={{
            fill:
              variant === 'project'
                ? 'none'
                : theme.palette.chart.tooltipCursor,
          }}
          wrapperStyle={{ border: 'none', outline: 'none' }}
        />
        <Bar
          barSize={barSize}
          dataKey={projects[0]}
          radius={[radius, radius, 0, 0]}
          fill={
            variant === 'project'
              ? theme.palette.primary.main
              : hover[projects[0]] ||
                Object.keys(hover).every((item) => !hover[item])
              ? colors[0].active
              : colors[0].disabled
          }
          onMouseOver={() => handleChange(projects[0], true)}
          onMouseOut={() => handleChange(projects[0], false)}
        >
          {variant === 'project' &&
            (data || emptyData(type))?.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  focusBar === index
                    ? theme.palette.primary.main
                    : focusBar !== index && focusBar >= 0
                    ? '#F7E6FA'
                    : theme.palette.primary.main
                }
              />
            ))}
        </Bar>
        {variant !== 'project' && projects.length > 1 && (
          <>
            {projects.splice(1).map((project, index) => (
              <Bar
                barSize={barSize}
                dataKey={project}
                key={project}
                radius={[radius, radius, 0, 0]}
                fill={
                  hover[project] ||
                  Object.keys(hover).every((item) => !hover[item])
                    ? colors[(index + 1) % colors.length].active
                    : colors[(index + 1) % colors.length].disabled
                }
                onMouseOver={() => handleChange(project, true)}
                onMouseOut={() => handleChange(project, false)}
              />
            ))}
          </>
        )}
      </CustomBarChart>
    </ResponsiveContainerStyled>
  );
};

const ResponsiveContainerStyled = styled(ResponsiveContainer)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  order: 3,
});

const CustomBarChart = styled(BarChartFromLib)({
  '.recharts-text': {
    fontFamily: 'Ranua',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    fill: '#0F0311',
  },
});

export default BarChart;
