import { AuthField } from '../../types/auth';
import { LoginFields } from './types';

export const initialValuesLogin = {
  [LoginFields.email]: '',
  [LoginFields.password]: '',
};

export const loginFields: AuthField[] = [
  {
    type: 'email',
    fieldLabel: 'Email address',
    name: LoginFields.email,
    fullwidth: true,
    autoComplete: 'email',
  },
  {
    type: 'password',
    fieldLabel: 'Password',
    name: LoginFields.password,
    fullwidth: true,
    autoComplete: 'current-password',
  },
];
