import { AuthField } from '../../types/auth';
import { RegisterFields } from './types';

export const initialValuesRegister = {
  [RegisterFields.firstName]: '',
  [RegisterFields.lastName]: '',
  [RegisterFields.email]: '',
  [RegisterFields.password]: '',
  [RegisterFields.organization]: '',
};

export const registerFields: AuthField[] = [
  {
    type: 'text',
    fieldLabel: 'First name',
    name: RegisterFields.firstName,
    fullwidth: true,
    autoComplete: 'given-name',
  },
  {
    type: 'text',
    fieldLabel: 'Last name',
    name: RegisterFields.lastName,
    fullwidth: true,
    autoComplete: 'family-name',
  },
  {
    type: 'email',
    fieldLabel: 'Email address',
    name: RegisterFields.email,
    fullwidth: true,
    autoComplete: 'email',
  },
  {
    type: 'password',
    fieldLabel: 'Password',
    name: RegisterFields.password,
    fullwidth: true,
    autoComplete: 'new-password',
  },
  {
    type: 'text',
    fieldLabel: 'Organization',
    name: RegisterFields.organization,
    fullwidth: true,
    autoComplete: 'organization-title',
  },
];
