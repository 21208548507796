import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getAllprojects } from '../../../../redux/reducers/projectsReducer';
import { createWebhookServer } from '../../../../redux/actions/webhooksActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import { Popups } from '../../../../types/popups';
import { initialValuesCreateTransaction } from '../../../../forms/createTransaction/form';
import { createTransactionSchema } from '../../../../forms/createTransaction/validation';
import { CreateTransactionFormValues } from '../../../../forms/createTransaction/types';
import { Blockchain } from '../../../../types/project';

export const useCreateWebhookForm = () => {
  const allProjects = useSelector(getAllprojects);
  const dispatch = useDispatch();

  const cardanoProjects = allProjects
    .filter((project) => project.chain === Blockchain.Cardano)
    .map((project) => ({ value: project.name, disabled: false }));

  const otherProjects = allProjects
    .filter((project) => project.chain !== Blockchain.Cardano)
    .map((project) => ({ value: project.name, disabled: true }));

  const sortedProjects = [...cardanoProjects, ...otherProjects];

  const handleClose = () =>
    dispatch(updatePopup({ popup: Popups.createWebhook, status: false }));

  const addTransactions = (
    projectName: string,
    url: string,
    webhookName: string
  ) => {
    const projectId = allProjects.find(({ name }) => name === projectName)?.id;
    if (!projectId) return;
    handleClose();
    formik.resetForm();
    dispatch(
      createWebhookServer({
        project_id: projectId,
        webhook_name: webhookName,
        webhook_url: url,
      })
    );
  };

  const onSubmit = (values: CreateTransactionFormValues) => {
    addTransactions(values.project[0], values.url, values.webhookName);
  };

  const formik = useFormik({
    initialValues: initialValuesCreateTransaction,
    validationSchema: createTransactionSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
  } = formik;

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string): boolean => {
    return !!(
      errors[name as keyof typeof errors] &&
      touched[name as keyof typeof touched]
    );
  };

  return {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleDropdownChange,
    handleDropdownBlur,
    checkError,
    isValid,
    dirty,
    handleClose,
    sortedProjects,
  };
};
