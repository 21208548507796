export const DiscordIcon = () => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.7032 2.01079C21.9186 1.18495 20.0048 0.576505 18.0039 0.228024C17.9674 0.221299 17.931 0.238106 17.9123 0.271722C17.6661 0.713204 17.3935 1.28915 17.2026 1.74185C15.0505 1.4169 12.9094 1.4169 10.8014 1.74185C10.6104 1.27909 10.3279 0.713204 10.0807 0.271722C10.0619 0.239228 10.0255 0.22242 9.98906 0.228024C7.98923 0.575391 6.07549 1.18383 4.28976 2.01079C4.2743 2.01751 4.26105 2.02873 4.25225 2.04328C0.62229 7.51259 -0.372109 12.8475 0.11571 18.1162C0.117917 18.142 0.132265 18.1666 0.152131 18.1823C2.54708 19.9561 4.86699 21.0329 7.14383 21.7467C7.18026 21.7579 7.21887 21.7445 7.24206 21.7142C7.78065 20.9724 8.26075 20.1903 8.67239 19.3678C8.69668 19.3196 8.67349 19.2625 8.62384 19.2434C7.86232 18.9521 7.1372 18.5969 6.43968 18.1935C6.38451 18.161 6.3801 18.0814 6.43085 18.0433C6.57763 17.9324 6.72446 17.817 6.86461 17.7005C6.88997 17.6792 6.92531 17.6747 6.95512 17.6881C11.5375 19.7981 16.4985 19.7981 21.0268 17.6881C21.0566 17.6736 21.0919 17.6781 21.1184 17.6993C21.2586 17.8159 21.4054 17.9324 21.5533 18.0433C21.604 18.0814 21.6007 18.161 21.5455 18.1935C20.848 18.6047 20.1229 18.9521 19.3603 19.2423C19.3106 19.2614 19.2885 19.3196 19.3128 19.3678C19.7333 20.1892 20.2134 20.9713 20.742 21.7131C20.7641 21.7445 20.8038 21.7579 20.8403 21.7467C23.1282 21.0329 25.4481 19.9561 27.843 18.1823C27.864 18.1666 27.8772 18.1431 27.8794 18.1173C28.4633 12.0261 26.9016 6.73495 23.7396 2.0444C23.7319 2.02873 23.7186 2.01751 23.7032 2.01079ZM9.35669 14.9081C7.97708 14.9081 6.84032 13.6307 6.84032 12.062C6.84032 10.4932 7.95504 9.21582 9.35669 9.21582C10.7694 9.21582 11.8951 10.5044 11.873 12.062C11.873 13.6307 10.7583 14.9081 9.35669 14.9081ZM18.6605 14.9081C17.281 14.9081 16.1442 13.6307 16.1442 12.062C16.1442 10.4932 17.2589 9.21582 18.6605 9.21582C20.0732 9.21582 21.199 10.5044 21.1769 12.062C21.1769 13.6307 20.0732 14.9081 18.6605 14.9081Z" />
  </svg>
);
