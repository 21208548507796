import { toast } from 'react-toastify';
import { takeEvery, put, delay } from 'redux-saga/effects';
import { Popups } from '../../types/popups';
import { Network, Project } from '../../types/project';
import { updatePopup } from '../actions/popupsActions';
import {
  addToLoadingProjects,
  addToResetKeys,
  ADD_TO_RESET_KEYS,
  CREATE_PROJECT_SERVER,
  DELETE_PROJECT_SERVER,
  getProjectsServer,
  GET_PROJECTS_SERVER,
  removeFromLoadingProjects,
  removeFromResetKeys,
  ROTATE_API_KEY_SERVER,
  updateIsProjectCreating,
  updateProjects,
  updateProjectsLoading,
  GET_PROJECTS_NETWORKS,
  updateProjectsNetworks,
  updateProjectsNetworksLoading,
} from '../actions/projectsActions';

const projectsSagas = [
  takeEvery(GET_PROJECTS_SERVER, handleGetProjects),
  takeEvery(`${GET_PROJECTS_SERVER}_SUCCESS`, handleGetProjectsSuccess),
  takeEvery(`${GET_PROJECTS_SERVER}_FAIL`, handleGetProjectsFail),

  takeEvery(CREATE_PROJECT_SERVER, handleCreateProject),
  takeEvery(`${CREATE_PROJECT_SERVER}_SUCCESS`, handleCreateProjectSuccess),
  takeEvery(`${CREATE_PROJECT_SERVER}_FAIL`, handleCreateProjectFail),

  takeEvery(DELETE_PROJECT_SERVER, handleDeleteProject),
  takeEvery(`${DELETE_PROJECT_SERVER}_SUCCESS`, handleDeleteProjectSuccess),
  takeEvery(`${DELETE_PROJECT_SERVER}_FAIL`, handleDeleteProjectFail),

  takeEvery(ROTATE_API_KEY_SERVER, handleRotateKey),
  takeEvery(`${ROTATE_API_KEY_SERVER}_SUCCESS`, handleRotateKeySuccess),
  takeEvery(`${ROTATE_API_KEY_SERVER}_FAIL`, handleRotateKeyFail),

  takeEvery(ADD_TO_RESET_KEYS, handleRemoveFromResetKeys),

  takeEvery(GET_PROJECTS_NETWORKS, handleGetNetworks),
  takeEvery(`${GET_PROJECTS_NETWORKS}_SUCCESS`, handleGetNetworksSuccess),
  takeEvery(`${GET_PROJECTS_NETWORKS}_FAIL`, handleGetNetworksFail),
];

function* handleGetProjects() {
  yield put(updateProjectsLoading({ status: true }));
}

function* handleGetProjectsFail() {
  yield put(updateProjectsLoading({ status: false }));
  toast.error('Unable to get projects. Try again later!');
}

function* handleGetProjectsSuccess(action: any) {
  yield put(updateProjectsLoading({ status: false }));
  yield put(updateProjects(action.payload.data));
}

function* handleGetNetworks() {
  yield put(updateProjectsNetworksLoading({ status: true }));
}

function* handleGetNetworksFail() {
  yield put(updateProjectsNetworksLoading({ status: false }));
}

function* handleGetNetworksSuccess(action: any) {
  yield put(updateProjectsNetworksLoading({ status: false }));
  yield put(updateProjectsNetworks(action.payload.data));
}

function* handleCreateProject() {
  yield put(updateIsProjectCreating({ status: true }));
}

function* handleCreateProjectFail(action: any) {
  if (
    action.error.response.data.error ===
    'Project with the same name already exists.'
  )
    toast.error('Project with the same name already exists.');
  else toast.error('Could not create a new project!');
  yield put(updateIsProjectCreating({ status: false }));
}

function* handleCreateProjectSuccess(action: any) {
  yield put(updateIsProjectCreating({ status: false }));
  toast.success('New project was successfully created');
  yield put(getProjectsServer({}));
  yield put(
    updatePopup({
      popup: Popups.apiKeyCreated,
      status: true,
      prefilled: { apiKey: action.payload.data.api_key },
    })
  );
}

function* handleDeleteProject(action: any) {
  yield put(addToLoadingProjects({ id: action.payload.id }));
}

function* handleDeleteProjectFail(action: any) {
  yield put(
    removeFromLoadingProjects({ id: action.meta.previousAction.payload.id })
  );
  toast.error('Could not delete this project!');
}

function* handleDeleteProjectSuccess(action: any) {
  const { prevProjects, id } = action.meta.previousAction.payload;
  yield put(removeFromLoadingProjects({ id }));
  yield put(
    updateProjects(prevProjects.filter((item: Project) => item.id !== id))
  );
}

function* handleRotateKey(action: any) {
  yield put(addToLoadingProjects({ id: action.payload.id }));
}

function* handleRotateKeyFail(action: any) {
  const { id } = action.meta.previousAction.payload;
  yield put(removeFromLoadingProjects({ id }));
  toast.error('Could not rotate the API key. Try again later!');
}

function* handleRotateKeySuccess(action: any) {
  const { id } = action.meta.previousAction.payload;
  yield put(removeFromLoadingProjects({ id }));
  toast.success('Successfully rotated API key!');
  yield put(
    updatePopup({
      popup: Popups.apiKeyCreated,
      status: true,
      prefilled: { apiKey: action.payload.data.api_key, projectId: id },
    })
  );
}

function* handleRemoveFromResetKeys(action: any) {
  yield delay(3000);
  yield put(removeFromResetKeys({ id: action.payload.id }));
}

export default projectsSagas;
