export const AccountIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99957 10.8351C11.2269 10.8351 12.2218 9.84015 12.2218 8.61285C12.2218 7.38555 11.2269 6.39062 9.99957 6.39062C8.77227 6.39062 7.77734 7.38555 7.77734 8.61285C7.77734 9.84015 8.77227 10.8351 9.99957 10.8351Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0022 18.0577C11.5656 18.0577 13.02 17.6055 14.2544 16.8344C13.72 14.9788 12.0289 13.6133 10.0022 13.6133C7.97556 13.6133 6.28333 14.9788 5.75 16.8344C6.98556 17.6055 8.43889 18.0577 10.0022 18.0577Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.09896 12.4049C2.5592 12.4049 2.93229 12.0319 2.93229 11.5716C2.93229 11.1114 2.5592 10.7383 2.09896 10.7383C1.63872 10.7383 1.26562 11.1114 1.26562 11.5716C1.26562 12.0319 1.63872 12.4049 2.09896 12.4049Z"
      fill="#212121"
    />
    <path
      d="M3.30208 6.35807C3.76232 6.35807 4.13542 5.98498 4.13542 5.52474C4.13542 5.0645 3.76232 4.69141 3.30208 4.69141C2.84185 4.69141 2.46875 5.0645 2.46875 5.52474C2.46875 5.98498 2.84185 6.35807 3.30208 6.35807Z"
      fill="#212121"
    />
    <path
      d="M3.30208 15.3073C3.76232 15.3073 4.13542 14.9342 4.13542 14.474C4.13542 14.0137 3.76232 13.6406 3.30208 13.6406C2.84185 13.6406 2.46875 14.0137 2.46875 14.474C2.46875 14.9342 2.84185 15.3073 3.30208 15.3073Z"
      fill="#212121"
    />
    <path
      d="M2.09896 9.26432C2.5592 9.26432 2.93229 8.89123 2.93229 8.43099C2.93229 7.97075 2.5592 7.59766 2.09896 7.59766C1.63872 7.59766 1.26562 7.97075 1.26562 8.43099C1.26562 8.89123 1.63872 9.26432 2.09896 9.26432Z"
      fill="#212121"
    />
    <path
      d="M5.52474 4.13542C5.98498 4.13542 6.35807 3.76232 6.35807 3.30208C6.35807 2.84185 5.98498 2.46875 5.52474 2.46875C5.0645 2.46875 4.69141 2.84185 4.69141 3.30208C4.69141 3.76232 5.0645 4.13542 5.52474 4.13542Z"
      fill="#212121"
    />
    <path
      d="M8.43099 2.93229C8.89123 2.93229 9.26432 2.5592 9.26432 2.09896C9.26432 1.63872 8.89123 1.26562 8.43099 1.26562C7.97075 1.26562 7.59766 1.63872 7.59766 2.09896C7.59766 2.5592 7.97075 2.93229 8.43099 2.93229Z"
      fill="#212121"
    />
    <path
      d="M16.7005 15.3073C17.1608 15.3073 17.5339 14.9342 17.5339 14.474C17.5339 14.0137 17.1608 13.6406 16.7005 13.6406C16.2403 13.6406 15.8672 14.0137 15.8672 14.474C15.8672 14.9342 16.2403 15.3073 16.7005 15.3073Z"
      fill="#212121"
    />
    <path
      d="M17.8997 9.26432C18.36 9.26432 18.7331 8.89123 18.7331 8.43099C18.7331 7.97075 18.36 7.59766 17.8997 7.59766C17.4395 7.59766 17.0664 7.97075 17.0664 8.43099C17.0664 8.89123 17.4395 9.26432 17.8997 9.26432Z"
      fill="#212121"
    />
    <path
      d="M14.474 4.13542C14.9342 4.13542 15.3073 3.76232 15.3073 3.30208C15.3073 2.84185 14.9342 2.46875 14.474 2.46875C14.0137 2.46875 13.6406 2.84185 13.6406 3.30208C13.6406 3.76232 14.0137 4.13542 14.474 4.13542Z"
      fill="#212121"
    />
    <path
      d="M17.8997 12.4049C18.36 12.4049 18.7331 12.0319 18.7331 11.5716C18.7331 11.1114 18.36 10.7383 17.8997 10.7383C17.4395 10.7383 17.0664 11.1114 17.0664 11.5716C17.0664 12.0319 17.4395 12.4049 17.8997 12.4049Z"
      fill="#212121"
    />
    <path
      d="M16.6966 6.35807C17.1569 6.35807 17.5299 5.98498 17.5299 5.52474C17.5299 5.0645 17.1569 4.69141 16.6966 4.69141C16.2364 4.69141 15.8633 5.0645 15.8633 5.52474C15.8633 5.98498 16.2364 6.35807 16.6966 6.35807Z"
      fill="#212121"
    />
    <path
      d="M11.5716 2.93229C12.0319 2.93229 12.4049 2.5592 12.4049 2.09896C12.4049 1.63872 12.0319 1.26562 11.5716 1.26562C11.1114 1.26562 10.7383 1.63872 10.7383 2.09896C10.7383 2.5592 11.1114 2.93229 11.5716 2.93229Z"
      fill="#212121"
    />
  </svg>
);
