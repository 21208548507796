import { SubscriptionPlan } from './subscription';

export enum HistoryItemStatus {
  paid = 'paid',
  draft = 'draft',
  open = 'open',
  uncollectible = 'uncollectible',
  failed = 'failed',
  cancelled = 'cancelled',
}

export interface InvoiceHistoryItem {
  id: string;
  period_end: number;
  subscription: SubscriptionPlan;
  amount_paid: number;
  total: number;
  status: HistoryItemStatus;
  hosted_invoice_url: string;
  attempt_count: number;
  lines: {
    data: {
      plan: {
        product: {
          id: string;
        };
      };
    }[];
  };
}

export interface INewInvoiceHistoryItem {
  created: number;
  subscription: string;
  price: number;
  status: HistoryItemStatus;
  attempts: number;
  pdf: string;
  hosted_invoice_url: string;
}
