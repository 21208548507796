import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AllDialogs from './components/Popups/AllDialogs';
import Mainpage from './modules/Home/MainPage';
import LoginPage from './modules/Auth/LoginPage';
import SignUpPage from './modules/Auth/SignUpPage';
import ResetPasswordPage from './modules/Auth/ResetPasswordPage';
import AuthenticatePage from './modules/Auth/AuthenicatePage';
import TransactionsPage from './modules/Transactions/TransactionsPage';
import AccountPage from './modules/Settings/AccountPage';
import ProjectPage from './modules/Project/[slug]';
import CredentialsPage from './modules/Settings/CredentialsPage';
import SubscriptionPlanPage from './modules/Settings/SubscriptionPlanPage';
import AutoRenewalPage from './modules/Settings/AutoRenewalPage';
import Plans from './modules/Settings/Plans';
import AddNewPaymentPage from './modules/Settings/AddNewPaymentPage';

import { pages } from './lib/routeUtils';
import BillingInformationPage from './modules/Settings/BillingInformationPage';
import BillingPage from './modules/Settings/BillingPage';
import TeamPage from './modules/Settings/TeamPage';
import EventsPage from './modules/Events/EventsPage';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={pages.main()} element={<Mainpage />} />
      <Route path={pages.login()} element={<LoginPage />} />
      <Route path={pages.signup()} element={<SignUpPage />} />
      <Route path={pages.resetPassword()} element={<ResetPasswordPage />} />
      <Route path={pages.authenticate()} element={<AuthenticatePage />} />
      <Route path={pages.settings()} element={<Plans />} />
      <Route path={pages.account()} element={<AccountPage />} />
      <Route path={pages.transactions()} element={<TransactionsPage />} />
      <Route path={pages.project()} element={<ProjectPage />} />
      {/* <Route path={pages.credentials()} element={<CredentialsPage />} /> */}
      <Route
        path={pages.subscriptionPlan()}
        element={<SubscriptionPlanPage />}
      />
      <Route path={pages.autoRenewal()} element={<AutoRenewalPage />} />
      <Route path={pages.addNewPayment()} element={<AddNewPaymentPage />} />
      <Route
        path={pages.billingInformation()}
        element={<BillingInformationPage />}
      />
      <Route path={pages.billing()} element={<BillingPage />} />
      <Route path={pages.team()} element={<TeamPage />} />
      <Route path={pages.eventManager()} element={<EventsPage />} />
    </Routes>
    <AllDialogs />
  </BrowserRouter>
);

export default Router;
