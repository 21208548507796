export const TransactionsTipIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2 31.2H31.2V24L48 33.6H7.2V31.2ZM24 48V40.8H48V38.4H7.2L24 48ZM26.4 26.4H14.4V14.4H26.4V26.4ZM24 16.8H16.8V24H24V16.8ZM12 14.4H0V26.4H12V14.4ZM26.4 0H14.4V12H26.4V0ZM12 12H0V0H12V12ZM9.6 2.4H2.4V9.6H9.6V2.4Z"
      fill="#C53DD8"
    />
  </svg>
);
