export const VisaIcon = () => (
  <svg
    width="35"
    height="15"
    viewBox="0 0 34 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2227 9.79865H14.8317L16.4647 0.222656H13.8547L12.2227 9.79865Z"
      fill="#1A1876"
    />
    <path
      d="M21.7287 4.06779C20.8167 3.62479 20.2577 3.32879 20.2637 2.88079C20.2637 2.48079 20.7367 2.05679 21.7637 2.05679C22.5204 2.03785 23.2705 2.20147 23.9507 2.53379L24.3047 0.458788C23.5544 0.188161 22.7622 0.0527308 21.9647 0.0587883C19.3857 0.0587883 17.5647 1.35879 17.5537 3.21879C17.5397 4.59479 18.8537 5.36279 19.8407 5.81879C20.8567 6.28779 21.1987 6.58679 21.1937 7.00579C21.1867 7.64779 20.3827 7.94179 19.6317 7.94179C18.6601 7.96448 17.6988 7.73904 16.8387 7.28679L16.4727 9.42979C17.403 9.77361 18.3878 9.94672 19.3797 9.94079C22.1237 9.94079 23.9047 8.65679 23.9247 6.66979C23.9297 5.57979 23.2347 4.75079 21.7287 4.06779Z"
      fill="#1A1876"
    />
    <path
      d="M30.9905 0.229281H28.9735C28.69 0.192985 28.4025 0.251092 28.1554 0.394618C27.9083 0.538143 27.7154 0.75909 27.6065 1.02328L23.7305 9.79628H26.4715C26.4715 9.79628 26.9195 8.61628 27.0205 8.35728H30.3635C30.4415 8.69328 30.6815 9.79228 30.6815 9.79228H33.1035L30.9905 0.229281ZM27.7725 6.40228C27.8805 6.12528 28.9455 3.39028 29.1585 2.81128C29.5105 4.46128 29.1675 2.86028 29.9385 6.40228H27.7725Z"
      fill="#1A1876"
    />
    <path
      d="M10.0323 0.228202L7.47628 6.7582L7.20428 5.4312L6.28928 1.0312C6.22259 0.776287 6.06605 0.554045 5.8485 0.405393C5.63094 0.25674 5.367 0.191684 5.10528 0.222202H0.896281L0.863281 0.4222C1.82027 0.647995 2.74254 1.0015 3.60528 1.4732L5.92528 9.7902H8.68728L12.7963 0.232202L10.0323 0.228202Z"
      fill="#1A1876"
    />
  </svg>
);
