import { IconButton } from '@maestro-org/ui-kit';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';

import HistoryStatusChip from './HistoryStatusChip';
import { OpenInNewTabIcon } from '../../../components/Icons';
import SettingsCard from './SettingsCard';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingInvoicesServer } from '../../../redux/actions/userActions';
import { getInvoicesHistory } from '../../../redux/reducers/usersReducer';
import { HistoryItemStatus } from '../../../types/invoiceHistory';
import { SubscriptionPlan } from '../../../types/subscription';

import { getOldSubscriptionTiersServer } from '../../../redux/actions/stripeActions';

import Tooltip from '../../../components/Tooltip/Tooltip';
import { InfoTooltipIcon } from '../../../components/Icons';

const getInvoiceDate = (timestapm: number) => {
  const date = new Date(timestapm * 1000);
  return format(date, 'MMMM d, yyyy');
};

const BillingInvoiceHistory = () => {
  const [showMore, setShowMore] = useState(false);

  const dispatch = useDispatch();

  const invoices = useSelector(getInvoicesHistory);

  const handleShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (invoices.length > 10) setShowMore(true);

    dispatch(getBillingInvoicesServer());
    dispatch(getOldSubscriptionTiersServer());
  }, []);

  return (
    <SettingsCard>
      <Top>
        <Typography variant="h6" color="grey.A200">
          Invoice history
        </Typography>
      </Top>
      <TableContainer isEmpty={!invoices.length} component={StyledPaper}>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <HeaderTableCell>Date</HeaderTableCell>
              <HeaderTableCell align="center">Subscription</HeaderTableCell>
              <HeaderTableCell align="center">Price</HeaderTableCell>
              <HeaderTableCell align="left">Status</HeaderTableCell>
              <HeaderTableCell></HeaderTableCell>
            </TableRow>
          </StyledTableHead>
          <StyledTableBody>
            {(showMore ? invoices : invoices.slice(0, 9)).map((row, i) => (
              <TableRow key={i}>
                <StyledTableCell style={{ width: 160 }}>
                  {getInvoiceDate(row.created)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    SubscriptionPlan[
                      row.subscription as keyof typeof SubscriptionPlan
                    ]
                  }
                </StyledTableCell>
                <StyledTableCell style={{ width: 160 }} align="center">
                  ${(row.price / 100).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell>
                  <StatusCellWrapper>
                    <HistoryStatusChip
                      status={
                        row.status === HistoryItemStatus.paid &&
                        row.attempts <= 3
                          ? HistoryItemStatus.paid
                          : row.status === HistoryItemStatus.open &&
                            row.attempts === 4
                          ? HistoryItemStatus.cancelled
                          : row.status === HistoryItemStatus.open &&
                            row.attempts < 4
                          ? HistoryItemStatus.failed
                          : row.status
                      }
                    />
                    {row.status === HistoryItemStatus.open && row.attempts < 4 && (
                      <StyledTooltip
                        enterTouchDelay={0}
                        title={
                          <TooltipText>
                            Failed Payment ({row.attempts}/4): Your card will be
                            charged up to 4 times within a period of two weeks.
                            If all retry payments fail, your account will be
                            suspended and all project keys will be deleted.
                          </TooltipText>
                        }
                      >
                        <InfoIconWrapper>
                          <InfoTooltipIcon />
                        </InfoIconWrapper>
                      </StyledTooltip>
                    )}
                  </StatusCellWrapper>
                </StyledTableCell>
                <StyledTableCell style={{ width: 20 }} align="right">
                  {row.status === HistoryItemStatus.uncollectible ? (
                    row.pdf ? (
                      <a
                        href={row.hosted_invoice_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ActiveIconButton>
                          <OpenInNewTabIcon />
                        </ActiveIconButton>
                      </a>
                    ) : (
                      <DisabledIconButton disabled>
                        <OpenInNewTabIcon />
                      </DisabledIconButton>
                    )
                  ) : row.pdf ? (
                    <a href={row.pdf} target="_blank" rel="noreferrer">
                      <ActiveIconButton>
                        <OpenInNewTabIcon />
                      </ActiveIconButton>
                    </a>
                  ) : (
                    <DisabledIconButton disabled>
                      <OpenInNewTabIcon />
                    </DisabledIconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
      {invoices.length >= 9 && (
        <Bottom>
          <LoadMoreWrapper onClick={handleShowMore}>
            <Typography color="primary.main" variant="paragraphMedium">
              {showMore ? 'Hide' : 'Load more'}
            </Typography>
          </LoadMoreWrapper>
        </Bottom>
      )}
    </SettingsCard>
  );
};

const Top = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'separate',
  borderSpacing: '0',

  [theme.breakpoints.down('md')]: {
    minWidth: '400px',
  },
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isEmpty',
})<{ isEmpty: boolean }>(({ isEmpty, theme }) => ({
  boxShadow: 'none',
  overflow: 'visible !important',

  [theme.breakpoints.down('md')]: {
    overflow: isEmpty ? 'hidden !important' : 'auto hidden !important',
    paddingBottom: '20px',

    '&::-webkit-scrollbar ': {
      height: '4px',
    },
    '&::-webkit-scrollbar-track ': {
      background: theme.palette.grey[50],
    },
    '&::-webkit-scrollbar-thumb ': {
      background: theme.palette.grey.A200,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '11.5px 0',
  ...theme.typography.paragraphSmall,

  [theme.breakpoints.down('sm')]: {
    borderBottom: 'none',
    padding: '4px 0',
  },
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  padding: '0 0 11.5px',
  ...theme.typography.article,
  textTransform: 'uppercase',
  color: theme.palette.grey[400],
}));

const ActiveIconButton = styled(IconButton)({
  background: 'transparent',

  '&:hover': {
    background: 'transparent',
  },
});

const DisabledIconButton = styled(IconButton)(({ theme }) => ({
  background: 'transparent',

  '&:hover': {
    background: 'transparent',
  },

  '& path': {
    fill: theme.palette.grey[200],
  },
}));

const Bottom = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const LoadMoreWrapper = styled('div')({
  cursor: 'pointer',
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '8px 16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    alignItems: 'center',
    columnGap: '9px',
    background: '#FFFFFF',
  },
}));

const StatusCellWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const TooltipText = styled(Typography)(({ theme }) => ({
  color: '#333333',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
}));

const InfoIconWrapper = styled('div')({
  height: '16px',
});

export default BillingInvoiceHistory;
