export const ProjectMetricsPlanIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4545 18.9091H30.5455C30.5455 22.5745 29.2509 25.9345 27.1127 28.5673L17.4545 18.9091ZM17.4545 1.52728V14.5455H30.4727C29.7891 7.68 24.32 2.21091 17.4545 1.52728ZM16 0C24.8436 0 32 7.15636 32 16H16V0ZM13.0909 4.36364C5.80364 4.74182 0 10.7927 0 18.1818C0 25.5709 6.18182 32 13.8182 32C17.2655 32 20.4073 30.7346 22.8218 28.64L13.0909 18.9091V4.36364Z"
      fill="#B500FF"
    />
  </svg>
);
