import { Button } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import Divider from '../../../components/Divider/Divider';
import SettingsCard from './SettingsCard';
import Tooltip from '../../../components/Tooltip/Tooltip';

import { pages } from '../../../lib/routeUtils';

const EmptyPaymentMethods = () => {
  return (
    <SettingsCard>
      <Row>
        <Typography color="grey.A200" variant="h6">
          Payment method
        </Typography>
        <Link to={pages.addNewPayment()}>
          <StyledButton>+ Add new</StyledButton>
        </Link>
      </Row>
      <Typography color="grey.500" variant="paragraphSmall">
        No Payment methods created yet. Please click the "Add New" button.
      </Typography>
      <BottomWrapper>
        <Divider />
        <Typography color="grey.400" variant="article">
          By updating your payment details, you agree to turn on automatic
          renewal for your current subscription. See offer details and
          cancellation terms{' '}
          <Ref
            href="https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf"
            target="_blank"
          >
            here
          </Ref>
          .
        </Typography>
      </BottomWrapper>
    </SettingsCard>
  );
};

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '5px 10px',
  background: 'transparent',
  color: theme.palette.primary.main,

  '&:hover': {
    background: 'transparent',
    color: theme.palette.primary.main,
  },

  '&:disabled': {
    background: 'transparent',
    color: theme.palette.grey[500],
  },
}));

const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const Ref = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '8px 16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

export default EmptyPaymentMethods;
