export const PriceFeedsIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.90909 24.4364H8.72727V31.7236H2.90909V24.4364ZM13.0909 31.7236H18.9091V11.36H13.0909V31.7236ZM23.2727 31.7236H29.0909V17.1782H23.2727V31.7236ZM32 1.17818L31.1273 0L23.04 6.82182L10.1818 2.63272L0 11.36L0.901833 12.5091L10.4436 4.26181L23.2727 8.43639L32 1.16366V1.17818Z"
      fill="#C53DD8"
    />
  </svg>
);
