export enum Network {
  Mainnet = 'mainnet',
  Preprod = 'preprod',
  Preview = 'preview',
  Testnet = 'testnet',
}

export enum Blockchain {
  Cardano = 'cardano',
  Bitcoin = 'bitcoin',
  Dogecoin = 'dogecoin',
  Arch = 'arch',
}

export const blockchainLabels = {
  [Blockchain.Bitcoin]: 'Bitcoin',
  [Blockchain.Cardano]: 'Cardano',
  [Blockchain.Dogecoin]: 'Dogecoin',
  [Blockchain.Arch]: 'Arch Network',
};

export interface Project {
  id: string;
  name: string;
  chain: Blockchain;
  network: Network;
  created_at: string;
  api_key: string;
}

export interface GetProjectsBlockchain {
  chain: string;
  network: string[];
}

export interface MetricsItemBucket {
  count: number;
  timestamp: string;
}

interface Latency {
  value: number;
  timestamp: string;
}

interface MetricsData {
  buckets: MetricsItemBucket[];
  count: number;
  failed: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  latency: Latency[];
  succeeded_pct: {
    buckets: { value: number; timestamp: string }[];
    value: number;
  };
}

export interface MetricsItem {
  requests_last_1h: MetricsData;
  requests_last_24h: MetricsData;
}
export enum ProjectStateModules {
  metrics = 'metrics',
  queryDistribution = 'queryDistribution',
  requestVolume = 'requestVolume',
}

export interface ResponseSample {
  status_code: number;
  content_type: string;
  schema: string;
}

export enum RequestMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
}

export interface QueryDistributionItem {
  host: string;
  route: string;
  usage: {
    requests_last_1h: number;
    requests_last_6h: number;
    requests_last_12h: number;
    requests_last_24h: number;
    requests_last_7d: number;
  };
  verb: string;
}

export interface RequestVolume {
  day: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  month: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  week: {
    buckets: MetricsItemBucket[];
    count: number;
  };
  year: {
    buckets: MetricsItemBucket[];
    count: number;
  };
}
