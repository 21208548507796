export const dayData = [
  {
    title: new Date(),
    data: [
      {
        project1: 70,
        project2: 50,
        time: '18:00',
      },
      {
        project1: 400,
        project2: 350,
        time: '19:00',
      },
      {
        project1: 350,
        project2: 240,
        time: '20:00',
      },
      {
        project1: 440,
        project2: 340,
        time: '21:00',
      },
      {
        project1: 450,
        project2: 420,
        time: '22:00',
      },
      {
        project1: 450,
        project2: 420,
        time: '23:00',
      },
      {
        project1: 200,
        project2: 150,
        time: '00:00',
      },
      {
        project1: 210,
        project2: 180,
        time: '01:00',
      },
      {
        project1: 172,
        project2: 122,
        time: '02:00',
      },
      {
        project1: 172,
        project2: 122,
        time: '03:00',
      },
      {
        project1: 100,
        project2: 90,
        time: '04:00',
      },
      {
        project1: 194,
        project2: 109,
        time: '05:00',
      },
      {
        project1: 114,
        project2: 103,
        time: '06:00',
      },
      {
        project1: 143,
        project2: 109,
        time: '07:00',
      },
      {
        project1: 400,
        project2: 280,
        time: '08:00',
      },
      {
        project1: 210,
        project2: 180,
        time: '09:00',
      },
      {
        project1: 196,
        project2: 111,
        time: '10:00',
      },
      {
        project1: 200,
        project2: 150,
        time: '11:00',
      },
      {
        project1: 450,
        project2: 340,
        time: '12:00',
      },
      {
        project1: 450,
        project2: 340,
        time: '13:00',
      },
      {
        project1: 182,
        project2: 97,
        time: '14:00',
      },
      {
        project1: 335,
        project2: 135,
        time: '15:00',
      },
      {
        project1: 194,
        project2: 109,
        time: '16:00',
      },
      {
        project1: 335,
        project2: 135,
        time: '17:00',
      },
    ],
  },
  {
    title: new Date(),
    data: [
      {
        project1: 400,
        project2: 360,
        time: '18:00',
      },
      {
        project1: 400,
        project2: 350,
        time: '19:00',
      },
      {
        project1: 350,
        project2: 240,
        time: '20:00',
      },
      {
        project1: 440,
        project2: 340,
        time: '21:00',
      },
      {
        project1: 450,
        project2: 420,
        time: '22:00',
      },
      {
        project1: 450,
        project2: 420,
        time: '23:00',
      },
      {
        project1: 200,
        project2: 150,
        time: '00:00',
      },
      {
        project1: 210,
        project2: 180,
        time: '01:00',
      },
      {
        project1: 172,
        project2: 122,
        time: '02:00',
      },
      {
        project1: 172,
        project2: 122,
        time: '03:00',
      },
      {
        project1: 100,
        project2: 90,
        time: '04:00',
      },
      {
        project1: 194,
        project2: 109,
        time: '05:00',
      },
      {
        project1: 114,
        project2: 103,
        time: '06:00',
      },
      {
        project1: 143,
        project2: 109,
        time: '07:00',
      },
      {
        project1: 400,
        project2: 280,
        time: '08:00',
      },
      {
        project1: 210,
        project2: 180,
        time: '09:00',
      },
      {
        project1: 196,
        project2: 111,
        time: '10:00',
      },
      {
        project1: 200,
        project2: 150,
        time: '11:00',
      },
      {
        project1: 450,
        project2: 340,
        time: '12:00',
      },
      {
        project1: 450,
        project2: 340,
        time: '13:00',
      },
      {
        project1: 182,
        project2: 97,
        time: '14:00',
      },
      {
        project1: 335,
        project2: 135,
        time: '15:00',
      },
      {
        project1: 194,
        project2: 109,
        time: '16:00',
      },
      {
        project1: 335,
        project2: 135,
        time: '17:00',
      },
    ],
  },
];

export const emptyDayData = [
  {
    project1: 10,
    project2: 10,
    time: '18:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '19:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '20:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '21:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '22:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '23:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '00:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '01:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '02:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '03:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '04:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '05:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '06:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '07:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '08:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '09:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '10:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '11:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '12:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '13:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '14:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '15:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '16:00',
  },
  {
    project1: 10,
    project2: 10,
    time: '17:00',
  },
];

export const weekData = [
  {
    title: new Date(),
    data: [
      {
        project1: 335,
        project2: 135,
        time: 'Thu 5.1',
      },
      {
        project1: 200,
        project2: 150,
        time: 'Fri 06.1',
      },
      {
        project1: 196,
        project2: 111,
        time: 'Sat 07.1',
      },
      {
        project1: 194,
        project2: 109,
        time: 'Sun 08.1',
      },
      {
        project1: 196,
        project2: 111,
        time: 'Mon 09.1',
      },
      {
        project1: 335,
        project2: 135,
        time: 'Tue 10.1',
      },
      {
        project1: 182,
        project2: 97,
        time: 'Wed 11.1',
      },
    ],
  },
  {
    title: new Date(),
    data: [
      {
        project1: 110,
        project2: 135,
        time: 'Thu 12.1',
      },
      {
        project1: 200,
        project2: 0,
        time: 'Fri 13.1',
      },
      {
        project1: 196,
        project2: 111,
        time: 'Sat 14.1',
      },
      {
        project1: 194,
        project2: 44,
        time: 'Sun 15.1',
      },
      {
        project1: 196,
        project2: 111,
        time: 'Mon 16.1',
      },
      {
        project1: 140,
        project2: 135,
        time: 'Tue 17.1',
      },
      {
        project1: 82,
        project2: 13,
        time: 'Wed 18.1',
      },
    ],
  },
];

export const emptyWeekData = [
  {
    project1: 10,
    project2: 10,
    time: 'Thu 5.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Fri 06.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Sat 07.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Sun 08.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Mon 09.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Tue 10.1',
  },
  {
    project1: 10,
    project2: 10,
    time: 'Wed 11.1',
  },
];

export const monthData = [
  {
    title: new Date(),
    data: [
      {
        project1: 400,
        project2: 360,
        time: '01.12',
      },
      {
        project1: 400,
        project2: 350,
        time: '02.12',
      },
      {
        project1: 350,
        project2: 240,
        time: '03.12',
      },
      {
        project1: 440,
        project2: 340,
        time: '04.12',
      },
      {
        project1: 450,
        project2: 420,
        time: '05.12',
      },
      {
        project1: 450,
        project2: 420,
        time: '06.12',
      },
      {
        project1: 200,
        project2: 150,
        time: '07.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '08.12',
      },
      {
        project1: 172,
        project2: 122,
        time: '09.12',
      },
      {
        project1: 172,
        project2: 122,
        time: '10.12',
      },
      {
        project1: 100,
        project2: 90,
        time: '11.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '12.12',
      },
      {
        project1: 114,
        project2: 103,
        time: '13.12',
      },
      {
        project1: 143,
        project2: 109,
        time: '14.12',
      },
      {
        project1: 400,
        project2: 280,
        time: '15.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '16.12',
      },
      {
        project1: 196,
        project2: 111,
        time: '17.12',
      },
      {
        project1: 200,
        project2: 150,
        time: '18.12',
      },
      {
        project1: 450,
        project2: 340,
        time: '19.12',
      },
      {
        project1: 450,
        project2: 340,
        time: '20.12',
      },
      {
        project1: 182,
        project2: 97,
        time: '21.12',
      },
      {
        project1: 335,
        project2: 135,
        time: '22.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '23.12',
      },
      {
        project1: 335,
        project2: 135,
        time: '24.12',
      },
      {
        project1: 100,
        project2: 90,
        time: '25.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '26.12',
      },
      {
        project1: 114,
        project2: 103,
        time: '27.12',
      },
      {
        project1: 143,
        project2: 109,
        time: '28.12',
      },
      {
        project1: 400,
        project2: 280,
        time: '29.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '30.12',
      },
    ],
  },
  {
    title: new Date(),
    data: [
      {
        project1: 400,
        project2: 360,
        time: '01.12',
      },
      {
        project1: 400,
        project2: 350,
        time: '02.12',
      },
      {
        project1: 350,
        project2: 240,
        time: '03.12',
      },
      {
        project1: 440,
        project2: 340,
        time: '04.12',
      },
      {
        project1: 450,
        project2: 420,
        time: '05.12',
      },
      {
        project1: 450,
        project2: 420,
        time: '06.12',
      },
      {
        project1: 200,
        project2: 150,
        time: '07.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '08.12',
      },
      {
        project1: 172,
        project2: 122,
        time: '09.12',
      },
      {
        project1: 172,
        project2: 122,
        time: '10.12',
      },
      {
        project1: 100,
        project2: 90,
        time: '11.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '12.12',
      },
      {
        project1: 114,
        project2: 103,
        time: '13.12',
      },
      {
        project1: 143,
        project2: 109,
        time: '14.12',
      },
      {
        project1: 400,
        project2: 280,
        time: '15.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '16.12',
      },
      {
        project1: 196,
        project2: 111,
        time: '17.12',
      },
      {
        project1: 200,
        project2: 150,
        time: '18.12',
      },
      {
        project1: 450,
        project2: 340,
        time: '19.12',
      },
      {
        project1: 450,
        project2: 340,
        time: '20.12',
      },
      {
        project1: 182,
        project2: 97,
        time: '21.12',
      },
      {
        project1: 335,
        project2: 135,
        time: '22.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '23.12',
      },
      {
        project1: 335,
        project2: 135,
        time: '24.12',
      },
      {
        project1: 100,
        project2: 90,
        time: '25.12',
      },
      {
        project1: 194,
        project2: 109,
        time: '26.12',
      },
      {
        project1: 114,
        project2: 103,
        time: '27.12',
      },
      {
        project1: 143,
        project2: 109,
        time: '28.12',
      },
      {
        project1: 400,
        project2: 280,
        time: '29.12',
      },
      {
        project1: 210,
        project2: 180,
        time: '30.12',
      },
    ],
  },
];

export const emptyMonthData = [
  {
    project1: 10,
    project2: 10,
    time: '01.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '02.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '03.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '04.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '05.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '06.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '07.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '08.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '09.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '10.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '11.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '12.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '13.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '14.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '15.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '16.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '17.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '18.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '19.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '20.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '21.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '22.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '23.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '24.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '25.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '26.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '27.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '28.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '29.12',
  },
  {
    project1: 10,
    project2: 10,
    time: '30.12',
  },
];

export const yearData = [
  {
    title: new Date(),
    data: [
      {
        project1: 440,
        project2: 340,
        time: 'January',
      },
      {
        project1: 450,
        project2: 420,
        time: 'February',
      },
      {
        project1: 450,
        project2: 420,
        time: 'March',
      },
      {
        project1: 200,
        project2: 150,
        time: 'April',
      },
      {
        project1: 210,
        project2: 180,
        time: 'May',
      },
      {
        project1: 172,
        project2: 122,
        time: 'June',
      },
      {
        project1: 172,
        project2: 122,
        time: 'July',
      },
      {
        project1: 100,
        project2: 90,
        time: 'August',
      },
      {
        project1: 194,
        project2: 109,
        time: 'September',
      },
      {
        project1: 114,
        project2: 103,
        time: 'October',
      },
      {
        project1: 143,
        project2: 109,
        time: 'November',
      },
      {
        project1: 400,
        project2: 280,
        time: 'December',
      },
    ],
  },
  {
    title: new Date(),
    data: [
      {
        project1: 440,
        project2: 340,
        time: 'January',
      },
      {
        project1: 450,
        project2: 420,
        time: 'February',
      },
      {
        project1: 450,
        project2: 420,
        time: 'March',
      },
      {
        project1: 200,
        project2: 150,
        time: 'April',
      },
      {
        project1: 210,
        project2: 180,
        time: 'May',
      },
      {
        project1: 172,
        project2: 122,
        time: 'June',
      },
      {
        project1: 172,
        project2: 122,
        time: 'July',
      },
      {
        project1: 100,
        project2: 90,
        time: 'August',
      },
      {
        project1: 194,
        project2: 109,
        time: 'September',
      },
      {
        project1: 114,
        project2: 103,
        time: 'October',
      },
      {
        project1: 143,
        project2: 109,
        time: 'November',
      },
      {
        project1: 400,
        project2: 280,
        time: 'December',
      },
    ],
  },
];

export const emptyYearData = [
  {
    project1: 10,
    project2: 10,
    time: 'January',
  },
  {
    project1: 10,
    project2: 10,
    time: 'February',
  },
  {
    project1: 10,
    project2: 10,
    time: 'March',
  },
  {
    project1: 10,
    project2: 10,
    time: 'April',
  },
  {
    project1: 10,
    project2: 10,
    time: 'May',
  },
  {
    project1: 10,
    project2: 10,
    time: 'June',
  },
  {
    project1: 10,
    project2: 10,
    time: 'July',
  },
  {
    project1: 10,
    project2: 10,
    time: 'August',
  },
  {
    project1: 10,
    project2: 10,
    time: 'September',
  },
  {
    project1: 10,
    project2: 10,
    time: 'October',
  },
  {
    project1: 10,
    project2: 10,
    time: 'November',
  },
  {
    project1: 10,
    project2: 10,
    time: 'December',
  },
];
