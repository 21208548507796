export const ManagedSmartContracts = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8182 11.1304H30.5455V6.95652H32V2.78261H27.6364V6.95652H29.0909V9.73913H21.8182V6.95652L14.5455 0H0V29.2174H21.8182V22.2609H29.0909V25.0435H27.6364V29.2174H32V25.0435H30.5455V20.8696H21.8182V16.6957H27.6364V18.087H32V13.913H27.6364V15.3043H21.8182V11.1304ZM19.7673 6.95652H14.5455V1.96174L19.7673 6.95652ZM20.3636 20.8696V27.8261H1.45455V1.3913H13.0909V8.34783H20.3636V20.8696ZM4.36364 15.3043H17.4545V16.6957H4.36364V15.3043ZM11.6364 11.1304H4.36364V9.73913H11.6364V11.1304ZM4.36364 20.8696H17.4545V22.2609H4.36364V20.8696Z"
      fill="#C53DD8"
    />
  </svg>
);
