import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { deleteProjectsServer } from '../../../../redux/actions/projectsActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getDeleteProjectDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { getAllprojects } from '../../../../redux/reducers/projectsReducer';
import { Popups } from '../../../../types/popups';

export const useDeleteProject = () => {
  const [projectName, setProjectName] = useState('');

  const allProjects = useSelector(getAllprojects);
  const isOpen = useSelector(getDeleteProjectDialog);
  const { projectId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteProject, status: false }));
    setProjectName('');
  };

  const handleDelete = () => {
    if (!projectId) {
      toast.error('Error occurred while deleting project');
      return;
    }
    dispatch(
      deleteProjectsServer({ id: projectId, prevProjects: allProjects })
    );
    toast.success('Successfully deleted project');
    handleClose();
  };

  useEffect(() => {
    if (!allProjects.length) return;
    const projectName = allProjects.find((item) => item.id === projectId)?.name;
    if (!projectName) return;
    setProjectName(projectName);
  }, [allProjects, projectId]);

  return {
    isOpen,
    projectName,
    handleClose,
    handleDelete,
  };
};
