import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteWebhook } from './hooks/useDeleteWebhook';

const DeleteWebhook = () => {
  const { isOpen, webhookName, handleClose, handleDelete } = useDeleteWebhook();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Delete ${webhookName}`}
      description="Are you sure you want to delete this webhook? The action cannot be undone."
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmLabel="Delete"
    />
  );
};

export default DeleteWebhook;
