export const CodeIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.61732 15.2167L4.40065 11L8.61732 6.78333L7.33398 5.5L1.83398 11L7.33398 16.5L8.61732 15.2167ZM13.384 15.2167L17.6007 11L13.384 6.78333L14.6673 5.5L20.1673 11L14.6673 16.5L13.384 15.2167Z"
      fill="#C53DD8"
    />
  </svg>
);
