import { LoginFormValues } from '../../forms/login/types';
import { RegisterFormValues } from '../../forms/register/types';
import { ResetPasswordFormValues } from '../../forms/reset-password/types';
import { SubscriptionPlan } from '../../types/subscription';

export const UPDATE_AUTH_LOADING = 'UPDATE_AUTH_LOADING';
export const REGISTER_ACCOUNT_SERVER = 'REGISTER_ACCOUNT_SERVER';
export const LOGIN_SERVER = 'LOGIN_SERVER';
export const RESET_PASSWORD_SERVER = 'RESET_PASSWORD_SERVER';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_AUTH0_DATA = 'UPDATE_AUTH0_DATA';
export const UPDATE_IS_AUTHORIZED = 'UPDATE_IS_AUTHORIZED';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const UPLOAD_IMAGE_SERVER = 'UPLOAD_IMAGE_SERVER';
export const SEND_EMAIL_VERIFICATION_SERVER = 'SEND_EMAIL_VERIFICATION_SERVER';
export const UPDATE_UPLOADING_IMAGE = 'UPDATE_UPLOADING_IMAGE';
export const SET_UPDATED_USER_PICTURE = 'SET_UPDATED_USER_PICTURE';
export const SET_IS_UPDATING_USER_INFO = 'SET_IS_UPDATING_USER_INFO';
export const UPDATE_USER_METADATA = 'UPDATE_USER_METADATA';
export const GET_USER_AUTHENTICATORS_SERVER = 'GET_USER_AUTHENTICATORS_SERVER';
export const UPDATE_USER_METADATA_SERVER = 'UPDATE_USER_METADATA_SERVER';
export const DELETE_USER_AUTHENTICATORS_SERVER =
  'DELETE_USER_AUTHENTICATORS_SERVER';
export const GET_USER_DATA = 'GET_USER_DATA';

interface UpdateStatusProps {
  status: boolean;
}

interface UpdateStatusProps {
  use_mfa?: boolean;
}

interface DeleteAuthenticatorsProps {
  authentication_method_id: string;
  isLastElem?: boolean;
}

interface IUpdateUserMetadata {
  email?: string;
  firstName?: string;
  lastName?: string;
  use_mfa?: boolean;
}

interface UpdatePlanProps {
  plan: SubscriptionPlan;
}

export const updateIsLoadingAuth = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_AUTH_LOADING,
  payload: { status },
});

export const updateUserData = (data: any) => ({
  type: UPDATE_USER_DATA,
  payload: { data },
});

export const updateAuth0Data = (data: any) => ({
  type: UPDATE_AUTH0_DATA,
  payload: { data },
});

export const clearAuthData = () => ({
  type: CLEAR_AUTH_DATA,
});

export const updateIsAuthorized = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_IS_AUTHORIZED,
  payload: { status },
});

export const updateUserMetadata = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_USER_METADATA,
  payload: { status },
});

export const registerAccountServer = (data: RegisterFormValues) => ({
  type: REGISTER_ACCOUNT_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: `/register`,
      data,
    },
  },
});

export const loginServer = (data: LoginFormValues) => ({
  type: LOGIN_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: `/login`,
      data,
    },
  },
});

export const resetPasswordServer = (data: ResetPasswordFormValues) => ({
  type: RESET_PASSWORD_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: `/reset`,
      data,
    },
  },
});

export const uploadImageServer = (data: FormData) => ({
  type: UPLOAD_IMAGE_SERVER,
  payload: {
    client: 'imageHost',
    request: {
      method: 'POST',
      url: '/upload',
      data,
    },
  },
});

export const sendEmailVerificationServer = () => ({
  type: SEND_EMAIL_VERIFICATION_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: '/user/send-verification-email',
    },
  },
});

export const getUserAuthenticatorsServer = () => ({
  type: GET_USER_AUTHENTICATORS_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/user/authenticators',
    },
  },
});

export const getUserDataServer = () => ({
  type: GET_USER_DATA,
  payload: {
    request: {
      method: 'GET',
      url: '/user/auth0',
    },
  },
});

export const deleteUserAuthenticatorsServer = (
  data: DeleteAuthenticatorsProps
) => ({
  type: DELETE_USER_AUTHENTICATORS_SERVER,
  payload: {
    request: {
      method: 'DELETE',
      url: '/user/authenticator',
      data,
    },
    data,
  },
});

export const updateUserMetadataServer = (data: IUpdateUserMetadata) => ({
  type: UPDATE_USER_METADATA_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: '/user/metadata',
      data,
    },
  },
});

export const updateIsUploadingImage = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_UPLOADING_IMAGE,
  payload: { status },
});

export const setUpdatedUserPicture = ({ status }: UpdateStatusProps) => ({
  type: SET_UPDATED_USER_PICTURE,
  payload: { status },
});

export const setIsUpdatingUserInfo = ({ status }: UpdateStatusProps) => ({
  type: SET_IS_UPDATING_USER_INFO,
  payload: { status },
});
