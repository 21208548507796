import { LoginType } from '../types/auth';

export const getLoginType = (sub: string): LoginType => {
  if (sub.includes('google-oauth2')) return LoginType.Google;
  if (sub.includes('github')) return LoginType.Github;
  return LoginType.Regular;
};

export const parseGoogleLoginData = (userData: any) => {
  const [firstName, lastName] = userData.name.split(' ');
  const { email, picture, nickname, sub } = userData;

  return {
    email,
    email_verified: true,
    firstName,
    lastName,
    picture,
    nickname,
    sub,
  };
};

export const parseGithubLoginData = (userData: any) => {
  const [firstName, lastName] = userData.name.split(' ');
  const { email, picture, nickname, sub } = userData;

  return {
    email,
    email_verified: true,
    firstName,
    lastName,
    picture,
    nickname,
    sub,
  };
};
