import { styled } from '@mui/material';

import { useEffect, useState } from 'react';
import EmptyTeamCase from './Teams/EmptyTeamCase';
import TeamCardInvite from './Teams/TeamCardInvite';
import TeamCardTable from './Teams/TeamCardTable';
import TeamCardTop from './Teams/TeamCardTop';

import { getTeamServer } from '../../../redux/actions/teamsActions';
import { useDispatch, useSelector } from 'react-redux';
import { UserRoles } from '../../../types/roles';

import {
  getIsTeamCreating,
  getIsTeamLoading,
  getTeam,
} from '../../../redux/reducers/teamsReducer';
import { getAuthData } from '../../../redux/reducers/authReducer';
import { getUser } from '../../../redux/reducers/usersReducer';

import { getIsAuthorized } from '../../../redux/reducers/authReducer';

import { Loader } from '@maestro-org/ui-kit';

const Teams = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const team = useSelector(getTeam);
  const userData = useSelector(getAuthData);
  const isAuthorized = useSelector(getIsAuthorized);
  const isTeamLoading = useSelector(getIsTeamLoading);
  const isTeamCreating = useSelector(getIsTeamCreating);

  const teamLoading = isTeamLoading || isTeamCreating;

  const updatedUsers = team?.users
    ? team.users.map((u) => {
        const picture = userData?.picture;
        const userEmail = user?.email;
        if (u.email === userEmail) {
          return {
            ...u,
            avatar: picture,
          };
        }
        return u;
      })
    : [];

  const rearrangedUsers = () => {
    if (!updatedUsers || !user) return updatedUsers;

    const currentUserIndex = updatedUsers.findIndex(
      (u) => u.email === user.email
    );

    if (currentUserIndex > -1) {
      const currentUser = updatedUsers[currentUserIndex];

      if (currentUser.role_name !== UserRoles.OWNER) {
        updatedUsers.splice(currentUserIndex, 1);
        updatedUsers.splice(1, 0, currentUser);
      }
    }

    return updatedUsers;
  };

  const updatedTeam = {
    ...team,
    users: rearrangedUsers(),
  };

  useEffect(() => {
    if (!isAuthorized) return;

    dispatch(getTeamServer());
  }, [isAuthorized]);

  return (
    <Card teamLoading={teamLoading}>
      {teamLoading ? (
        <Loader />
      ) : (
        <>
          {!updatedTeam.team_name ? (
            <EmptyTeamCase />
          ) : (
            <Wrapper>
              <TeamCardTop teamData={updatedTeam} />
              <TeamCardTable teamData={updatedTeam} />
              {(user.team_role_name === UserRoles.OWNER ||
                user.team_role_name === UserRoles.ADMIN ||
                team.users.find(
                  (u) =>
                    u.email === user.email && u.role_name === UserRoles.OWNER
                )) && (
                <>
                  <Divider />
                  <TeamCardInvite teamUsersNumber={updatedTeam.users.length} />
                </>
              )}
            </Wrapper>
          )}
        </>
      )}
    </Card>
  );
};

const Card = styled('div')<{ teamLoading: boolean }>(
  ({ theme, teamLoading }) => ({
    background: theme.palette.common.white,
    padding: '40px 32px',
    borderRadius: theme.borderRadius.sm,
    boxShadow: '0px 2px 12px rgba(153, 153, 153, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
    maxWidth: '708px',
    border: `1px solid ${theme.palette.grey[100]}`,

    [theme.breakpoints.down(1101)]: {
      maxWidth: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '24px',
    },

    ...(teamLoading && {
      justifyContent: 'center',
      alignItems: 'center',
    }),
  })
);

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
});

const Divider = styled('div')({
  height: '1px',
  width: '100%',
  background: '#D9D9D9',
});

export default Teams;
