export const CloseDrawerIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.41406"
      y="1"
      width="11.0007"
      height="2"
      transform="rotate(45 1.41406 1)"
      fill="#0F0311"
    />
    <rect
      y="23.6064"
      width="32"
      height="2"
      transform="rotate(-45 0 23.6064)"
      fill="#0F0311"
    />
    <rect
      x="16"
      y="15.707"
      width="11.0033"
      height="2"
      transform="rotate(45 16 15.707)"
      fill="#0F0311"
    />
  </svg>
);
