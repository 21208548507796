export const GET_TEAM_SERVER = 'GET_TEAM_DATA';
export const UPDATE_TEAM_SERVER = 'UPDATE_TEAM_SERVER';
export const CREATE_TEAM_SERVER = 'CREATE_TEAM_SERVER';
export const UPDATE_IS_TEAM_CREATING = 'UPDATE_IS_TEAM_CREATING';
export const UPDATE_TEAM_LOADING = 'UPDATE_TEAM_LOADING';
export const ADD_TEAM_MEMBER_SERVER = 'ADD_TEAM_MEMBER_SERVER';
export const UPDATE_TEAM_USERS = 'UPDATE_TEAM_USERS';
export const REMOVE_TEAM_MEMBER_SERVER = 'REMOVE_TEAM_MEMBER_SERVER';
export const UPDATE_TEAM_MEMBER_ROLE_SERVER = 'UPDATE_TEAM_MEMBER_ROLE_SERVER';
export const SET_TEAM_ID = 'SET_TEAM_ID';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_NAME = 'UPDATE_TEAM_NAME';

interface UpdateStatusProps {
  status: boolean;
}

export interface ITeamUser {
  email: string;
  role_level: number;
  role_name: string;
  status: string;
  use_mfa: boolean;
  avatar?: string;
}

export interface TeamProps {
  team_name: string;
  users: ITeamUser[];
}

export const getTeamServer = () => ({
  type: GET_TEAM_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: '/team',
    },
  },
});

export const updateTeamServer = (teamServer: TeamProps) => ({
  type: UPDATE_TEAM_SERVER,
  payload: { teamServer },
});

export const updateTeamLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_TEAM_LOADING,
  payload: { status },
});

export const createTeamServer = (team_name: string) => ({
  type: CREATE_TEAM_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: '/team',
      data: { team_name },
    },
  },
});

export const updateIsTeamCreating = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_IS_TEAM_CREATING,
  payload: { status },
});

export const addTeamMemberServer = (email: string, role: string) => ({
  type: ADD_TEAM_MEMBER_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: '/team/user/add',
      data: { email, role },
    },
  },
});

export const removeTeamMemberServer = (email: string) => ({
  type: REMOVE_TEAM_MEMBER_SERVER,
  payload: {
    request: {
      method: 'DELETE',
      url: '/team/user/remove',
      data: { email },
    },
  },
});

export const updateTeamMemberRoleServer = (email: string, role: string) => ({
  type: UPDATE_TEAM_MEMBER_ROLE_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: '/team/user/update',
      data: { email, role },
    },
  },
});

export const updateTeamName = (team_name: string) => ({
  type: UPDATE_TEAM_NAME,
  payload: {
    request: {
      method: 'PUT',
      url: '/team/update',
      data: { team_name },
    },
  },
});

export const setQueryTeamId = (queryTeamId: string) => ({
  type: SET_TEAM_ID,
  payload: queryTeamId,
});

export const addTeamMemberSuccess = (user: ITeamUser) => ({
  type: ADD_TEAM_MEMBER_SUCCESS,
  payload: user,
});

export const removeTeamMemberSuccess = (email: string) => ({
  type: REMOVE_TEAM_MEMBER_SUCCESS,
  payload: email,
});
