import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { updateTeamMemberRoleServer } from '../../../../redux/actions/teamsActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getUpdateTeamMemberRoleDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { Popups } from '../../../../types/popups';

export const useUpdateTeamMemberRole = () => {
  const isOpen = useSelector(getUpdateTeamMemberRoleDialog);
  const { email, role } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updatePopup({ popup: Popups.updateTeamMemberRole, status: false })
    );
  };

  const handleUpdate = () => {
    if (!email || !role) {
      toast.error('Error occurred while updating team user role');
      return;
    }
    dispatch(updateTeamMemberRoleServer(email, role));
    handleClose();
  };

  return {
    isOpen,
    email,
    handleClose,
    handleUpdate,
  };
};
