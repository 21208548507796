export const UserPlusIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 10.25V15.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 12.75H12.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7C8.24264 7 9.25 5.99264 9.25 4.75C9.25 3.50736 8.24264 2.5 7 2.5C5.75736 2.5 4.75 3.50736 4.75 4.75C4.75 5.99264 5.75736 7 7 7Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2305 6.86C11.4705 6.947 11.7275 7 11.9975 7C13.2405 7 14.2475 5.993 14.2475 4.75C14.2475 3.507 13.2405 2.5 11.9975 2.5C11.7275 2.5 11.4705 2.552 11.2305 2.64"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6168 10.87C9.64984 10.02 8.38784 9.5 6.99884 9.5C4.85384 9.5 2.99884 10.729 2.09284 12.52C1.69284 13.311 2.12084 14.277 2.95884 14.568C3.98984 14.926 5.36684 15.251 6.99884 15.251C8.45484 15.251 9.69084 14.99 10.6768 14.68"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
