export const CompareIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7575 10.5H1.5V12H6.7575V14.25L9.75 11.25L6.7575 8.25V10.5ZM11.2425 9.75V7.5H16.5V6H11.2425V3.75L8.25 6.75L11.2425 9.75Z"
      fill="#C53DD8"
    />
  </svg>
);
