import { Link } from 'react-router-dom';
import { InputWarningIcon } from '../Icons';
import { styled, Typography } from '@mui/material';

const AnnouncementLine = () => {
  return (
    <AnnouncementLineWrapper>
      <AnnouncementWrapper>
        <InputWarningIcon />
        <AnnouncementText variant="textfieldLabel" color="#C45050">
          Your account has an overdue invoice. Your subscription will soon be
          cancelled.{' '}
          <Link to="settings/subscription/billing">
            <AnnouncementLinkText variant="textfieldLabel" color="#C45050">
              Pay invoice
            </AnnouncementLinkText>
          </Link>
        </AnnouncementText>
      </AnnouncementWrapper>
    </AnnouncementLineWrapper>
  );
};

const AnnouncementLineWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '40px',
  background: '#FAE3DF',
  borderBottom: '1px solid #F8D0C9',
  justifyContent: 'center',
  alignItems: 'center',
});

const AnnouncementWrapper = styled('div')({
  display: 'flex',
  gap: '16px',
});

const AnnouncementText = styled(Typography)({});

const AnnouncementLinkText = styled(Typography)({
  fontWeight: '500',
  textDecoration: 'underline',
});

export default AnnouncementLine;
