import { NewEntryField, NewEntryFieldTypes } from '../../types/newEntry';
import { CreateWebhookFields } from './types';
import { Option } from '../../types/newEntry';

interface GetCreateWebhookFieldsProps {
  triggerOptions?: Option[];
  fieldOptions?: Option[];
  operatorOptions?: Option[];
}

export const initialValuesCreateWebhook = {
  [CreateWebhookFields.network]: [],
  [CreateWebhookFields.blockchain]: [],
  [CreateWebhookFields.trigger]: [],
  [CreateWebhookFields.url]: '',
  [CreateWebhookFields.triggervalue]: '',
  [CreateWebhookFields.field]: [],
  [CreateWebhookFields.operator]: [],
  [CreateWebhookFields.value]: '',
};

export const getCreateWebhookFields = ({
  triggerOptions,
  fieldOptions,
  operatorOptions,
}: GetCreateWebhookFieldsProps): NewEntryField[] => [
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.blockchain,
    placeholder: 'Blockchain',
    options: [{ value: 'bitcoin', label: 'Bitcoin' }],
    fieldLabel: 'Blockchain',
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.network,
    placeholder: 'Network',
    options: [
      { value: 'mainnet', label: 'Mainnet' },
      { value: 'testnet', label: 'Testnet4' },
    ],
    fieldLabel: 'Network',
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateWebhookFields.url,
    placeholder: 'Webhook URL',
    fieldLabel: 'Webhook URL',
  },
  // {
  //   type: NewEntryFieldTypes.text,
  //   name: CreateWebhookFields.confirmations,
  //   placeholder: "Confirmations",
  //   fieldLabel: "Confirmations",
  // },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.trigger,
    placeholder: 'Trigger',
    options: triggerOptions,
    fieldLabel: 'Trigger',
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateWebhookFields.triggervalue,
    placeholder: 'Trigger Value',
    fieldLabel: 'Trigger Value',
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.field,
    placeholder: 'Field',
    options: fieldOptions,
    fieldLabel: 'Field',
  },
  {
    type: NewEntryFieldTypes.select,
    name: CreateWebhookFields.operator,
    placeholder: 'Operator',
    options: operatorOptions,
    fieldLabel: 'Operator',
  },
  {
    type: NewEntryFieldTypes.text,
    name: CreateWebhookFields.value,
    placeholder: 'Value',
    fieldLabel: 'Value',
  },
];
