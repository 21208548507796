import { toast } from 'react-toastify';
import { takeEvery, put, select } from 'redux-saga/effects';
import {
  ADD_PAYMENT_SERVER,
  DELETE_PAYMENT_SERVER,
  GET_BILLING_DATA_SERVER,
  GET_CUSTOMER_SERVER,
  SET_PAYMENT_SERVER,
  SET_SUBSCRIPTION_SERVER,
  UPDATE_USER_SUBSCRIPTION_SERVER,
  getBillingDataServer,
  setPaymentServer,
  updateActivePaymentMethod,
  updateBillingData,
  updateBillingDataLoading,
  updateClientSecret,
  updateCustomer,
  updateCustomerLoading,
  updateLoadingMethods,
  GET_NEW_USER_SUBSCRIPTIONS_SERVER,
  updateSubscriptions,
  getSubscriptionsServerLoading,
  GET_SUBSCRIPTION_FEATURES_SERVER,
  updateSubscriptionFeatures,
  getSubscriptionFeaturesLoading,
  GET_SUBSCRIPTION_TIERS_SERVER,
  updateSubscriptionTiers,
  getSubscriptionTiersLoading,
  GET_USER_CURRENT_SUBSCRIPTION,
  updateUserCurrentSubscription,
  GET_USER_UPDATED_SUBSCRIPTIONS_SERVER,
  updateUserUpdatedSubscriptionsServer,
  getUserUpdatedSubscriptionsServerLoading,
  GET_USER_UPDATED_FEATURES_SERVER,
  updateUserUpdatedFeaturesServer,
  getUserUpdatedFeaturesServerLoading,
  GET_USER_UPDATED_SUBSCRIPTION_TIERS_SERVER,
  updateUserUpdatedSubscriptionTiers,
  getUserUpdatedSubscriptionTiersLoading,
  setActiveCheckoutStep,
} from '../actions/stripeActions';
import {
  getUserServer,
  updateUserSubscriptionLoading,
} from '../actions/userActions';
import {
  getActiveMethodId,
  getAllPaymentMethods,
} from '../reducers/stripeReducer';
import { getDapiStripeError } from '../../lib/errors';
import { CheckoutSteps } from '../../types/settings';

const stripeSagas = [
  takeEvery(`${GET_BILLING_DATA_SERVER}`, handleGetBillingData),
  takeEvery(`${GET_BILLING_DATA_SERVER}_SUCCESS`, handleGetBillingDataSuccess),
  takeEvery(`${GET_BILLING_DATA_SERVER}_FAIL`, handleGetBillingDataFail),

  takeEvery(
    `${GET_NEW_USER_SUBSCRIPTIONS_SERVER}`,
    handleGetSubscriptionsServer
  ),
  takeEvery(
    `${GET_NEW_USER_SUBSCRIPTIONS_SERVER}_SUCCESS`,
    handleGetSubscriptionsServerSuccess
  ),
  takeEvery(
    `${GET_NEW_USER_SUBSCRIPTIONS_SERVER}_FAIL`,
    handleGetSubscriptionsServerFail
  ),

  takeEvery(
    `${GET_USER_CURRENT_SUBSCRIPTION}`,
    handleGetUserCurrentSubscriptionServer
  ),
  takeEvery(
    `${GET_USER_CURRENT_SUBSCRIPTION}_SUCCESS`,
    handleGetUserCurrentSubscriptionSuccess
  ),
  takeEvery(
    `${GET_USER_CURRENT_SUBSCRIPTION}_FAIL`,
    handleGetUserCurrentSubscriptionFail
  ),

  takeEvery(
    `${GET_SUBSCRIPTION_FEATURES_SERVER}`,
    handleGetSubscriptionFeaturesServer
  ),
  takeEvery(
    `${GET_SUBSCRIPTION_FEATURES_SERVER}_SUCCESS`,
    handleGetSubscriptionFeaturesSuccess
  ),
  takeEvery(
    `${GET_SUBSCRIPTION_FEATURES_SERVER}_FAIL`,
    handleGetSubscriptionFeaturesServerFail
  ),

  takeEvery(
    `${GET_SUBSCRIPTION_TIERS_SERVER}`,
    handleGetSubscriptionTiersServer
  ),
  takeEvery(
    `${GET_SUBSCRIPTION_TIERS_SERVER}_SUCCESS`,
    handleGetSubscriptionTiersServerSuccess
  ),
  takeEvery(
    `${GET_SUBSCRIPTION_TIERS_SERVER}_FAIL`,
    handleGetSubscriptionTiersServerFail
  ),

  takeEvery(`${ADD_PAYMENT_SERVER}_SUCCESS`, handleAddPaymentSuccess),
  takeEvery(`${ADD_PAYMENT_SERVER}_FAIL`, handleAddPaymentFail),

  takeEvery(`${DELETE_PAYMENT_SERVER}`, handleDeleteMethod),
  takeEvery(`${DELETE_PAYMENT_SERVER}_SUCCESS`, handleDeleteMethodSuccess),
  takeEvery(`${DELETE_PAYMENT_SERVER}_FAIL`, handleDeleteMethodFail),

  takeEvery(`${SET_PAYMENT_SERVER}_SUCCESS`, handleSetPaymentSuccess),
  takeEvery(`${SET_PAYMENT_SERVER}_FAIL`, handleSetPaymentFail),

  takeEvery(
    `${SET_SUBSCRIPTION_SERVER}_SUCCESS`,
    handleUpdateSubscriptionSuccess
  ),
  takeEvery(`${SET_SUBSCRIPTION_SERVER}_FAIL`, handleUpdateSubscriptionFail),

  takeEvery(
    `${UPDATE_USER_SUBSCRIPTION_SERVER}_SUCCESS`,
    handleUpdateSubscriptionSuccess
  ),
  takeEvery(
    `${UPDATE_USER_SUBSCRIPTION_SERVER}_FAIL`,
    handleUpdateSubscriptionFail
  ),

  takeEvery(`${GET_CUSTOMER_SERVER}`, handleGetCustomer),
  takeEvery(`${GET_CUSTOMER_SERVER}_SUCCESS`, handleGetCustomerSuccess),
  takeEvery(`${GET_CUSTOMER_SERVER}_FAIL`, handleGetCustomerFail),

  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTIONS_SERVER}`,
    handleGetUpdatedSubscriptions
  ),
  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTIONS_SERVER}_SUCCESS`,
    handleGetUpdatedSubscriptionsSuccess
  ),
  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTIONS_SERVER}_FAIL`,
    handleGetUpdatedSubscriptionsFail
  ),

  takeEvery(`${GET_USER_UPDATED_FEATURES_SERVER}`, handleGetUpdatedFeatures),
  takeEvery(
    `${GET_USER_UPDATED_FEATURES_SERVER}_SUCCESS`,
    handleGetUpdatedFeaturesSuccess
  ),
  takeEvery(
    `${GET_USER_UPDATED_FEATURES_SERVER}_FAIL`,
    handleGetUpdatedFeaturesFail
  ),

  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTION_TIERS_SERVER}`,
    handleGetUpdatedTiers
  ),
  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTION_TIERS_SERVER}_SUCCESS`,
    handleGetUpdatedTiersSuccess
  ),
  takeEvery(
    `${GET_USER_UPDATED_SUBSCRIPTION_TIERS_SERVER}_FAIL`,
    handleGetUpdatedTiersFail
  ),
];

function* handleGetBillingData() {
  yield put(updateBillingDataLoading({ status: true }));
}

function* handleGetBillingDataSuccess(action: any) {
  yield put(updateBillingDataLoading({ status: false }));
  const allMethods = action.payload.data.payment_methods;

  if (!allMethods) {
    yield put(updateBillingData({ paymentMethods: [] }));
    return;
  }

  const data = allMethods.map((item: any) => {
    const { billing_details, card, id } = item;
    let firstName,
      lastName = '';
    if (billing_details.name)
      [firstName, lastName] = billing_details.name.split(' ');

    const newBillingData = {
      address: billing_details.address,
      email: billing_details.email,
      firstName,
      lastName,
      phone: billing_details.phone,
    };

    const newCardData = {
      brand: card.brand,
      country: card.country,
      expMonth: card.exp_month,
      expYear: card.exp_year,
      last4: card.last4,
    };

    return { billingInfo: newBillingData, card: newCardData, id };
  });

  yield put(updateBillingData({ paymentMethods: data }));
  const active = action.meta.previousAction.payload.activeId;
}

function* handleGetBillingDataFail(action: any) {
  yield put(updateBillingDataLoading({ status: false }));
}

function* handleGetUserCurrentSubscriptionServer(action: any) {
  yield put(updateUserSubscriptionLoading({ status: true }));
}

function* handleGetUserCurrentSubscriptionSuccess(action: any) {
  yield put(updateUserSubscriptionLoading({ status: false }));
  yield put(updateUserCurrentSubscription(action.payload.data));
}

function* handleGetUserCurrentSubscriptionFail(action: any) {
  yield put(updateUserSubscriptionLoading({ status: false }));
  yield toast.error('Error while getting subscription');
}

function* handleGetSubscriptionsServer(action: any) {
  yield put(getSubscriptionsServerLoading({ status: true }));
}

function* handleGetSubscriptionsServerSuccess(action: any) {
  yield put(updateSubscriptions(action.payload.data));
}

function* handleGetSubscriptionsServerFail(action: any) {
  yield toast.error('Cannot get subscriptions now.');
  yield put(getSubscriptionsServerLoading({ status: false }));
}

function* handleGetUpdatedSubscriptions() {
  yield put(getUserUpdatedSubscriptionsServerLoading({ status: true }));
}

function* handleGetUpdatedSubscriptionsSuccess(action: any) {
  yield put(getUserUpdatedSubscriptionsServerLoading({ status: false }));
  yield put(updateUserUpdatedSubscriptionsServer(action.payload.data));
}

function* handleGetUpdatedSubscriptionsFail() {
  yield toast.error('Cannot get updated subscriptions now.');
  yield put(getUserUpdatedSubscriptionsServerLoading({ status: false }));
}

function* handleGetSubscriptionFeaturesServer() {
  yield put(getSubscriptionFeaturesLoading({ status: true }));
}

function* handleGetSubscriptionFeaturesSuccess(action: any) {
  yield put(updateSubscriptionFeatures(action.payload.data));
}

function* handleGetSubscriptionFeaturesServerFail() {
  yield toast.error('Cannot get features now.');
  yield put(getSubscriptionFeaturesLoading({ status: false }));
}

function* handleGetUpdatedFeatures() {
  yield put(getUserUpdatedFeaturesServerLoading({ status: true }));
}

function* handleGetUpdatedFeaturesSuccess(action: any) {
  yield put(getUserUpdatedFeaturesServerLoading({ status: false }));
  yield put(updateUserUpdatedFeaturesServer(action.payload.data));
}

function* handleGetUpdatedFeaturesFail() {
  yield toast.error('Cannot get updated features now.');
  yield put(getUserUpdatedFeaturesServerLoading({ status: false }));
}

function* handleGetSubscriptionTiersServer(action: any) {
  yield put(getSubscriptionTiersLoading({ status: true }));
}

function* handleGetSubscriptionTiersServerSuccess(action: any) {
  yield put(updateSubscriptionTiers(action.payload.data));
}

function* handleGetSubscriptionTiersServerFail(action: any) {
  yield toast.error('Cannot get tiers now.');
  yield put(getSubscriptionTiersLoading({ status: false }));
}

function* handleGetUpdatedTiers() {
  yield put(getUserUpdatedSubscriptionTiersLoading({ status: true }));
}

function* handleGetUpdatedTiersSuccess(action: any) {
  yield put(getUserUpdatedSubscriptionTiersLoading({ status: false }));
  yield put(updateUserUpdatedSubscriptionTiers(action.payload.data));
}

function* handleGetUpdatedTiersFail() {
  yield toast.error('Cannot get updated tiers now.');
  yield put(getUserUpdatedSubscriptionTiersLoading({ status: false }));
}

function* handleAddPaymentFail(action: any) {
  yield toast.error(
    getDapiStripeError(
      action,
      'Could not get client secret for adding payment method!'
    )
  );
}

function* handleAddPaymentSuccess(action: any) {
  yield put(
    updateClientSecret({ clientSecret: action.payload.data.client_secret })
  );
}

function* handleDeleteMethod(action: any) {
  yield put(
    updateLoadingMethods({
      methodsIds: [...action.payload.loadingMethods, action.payload.methodId],
    })
  );
}

function* handleDeleteMethodFail(action: any) {
  const { loadingMethods, methodId } = action.meta.previousAction.payload;
  yield put(
    updateLoadingMethods({
      methodsIds: loadingMethods.filter((item: string) => item !== methodId),
    })
  );
  toast.error(getDapiStripeError(action, 'Could not delete payment method!'));
}

function* handleDeleteMethodSuccess(action: any): any {
  const { loadingMethods, methodId } = action.meta.previousAction.payload;
  const paymentMethods = yield select(getAllPaymentMethods);
  const activeMethodId = yield select(getActiveMethodId);

  if (activeMethodId === methodId && paymentMethods.length > 1) {
    const newDefaultElem = paymentMethods.find(
      (payment: any) => payment.id !== methodId
    );

    yield put(
      setPaymentServer({ methodId: newDefaultElem.id, withoutToast: true })
    );
  }

  yield put(
    updateLoadingMethods({
      methodsIds: loadingMethods.filter((item: string) => item !== methodId),
    })
  );
  yield put(getBillingDataServer());
  toast.success('The payment method was successfully deleted!');
}

function* handleSetPaymentFail(action: any) {
  yield toast.error(
    getDapiStripeError(action, 'Could not change payment method!')
  );
}

function* handleSetPaymentSuccess(action: any) {
  yield put(
    updateActivePaymentMethod({
      id: action.payload.data.default_payment_method_id,
    })
  );
  const isWithoutToast = action.meta.previousAction.payload.withoutToast;

  if (!isWithoutToast)
    toast.success('Payment method was successfully changed!');
}

function* handleUpdateSubscriptionSuccess() {
  yield put(getUserServer());
  yield put(setActiveCheckoutStep({ step: CheckoutSteps.success }));

  toast.success('The subscription was successfully changed!');
}

function* handleUpdateSubscriptionFail(action: any) {
  const errorMessage = getDapiStripeError(
    action,
    'Could not change subscription! Try again later!'
  );
  yield toast.error(errorMessage, { toastId: errorMessage });

  yield put(setActiveCheckoutStep({ step: CheckoutSteps.failure }));
}

function* handleGetCustomer() {
  yield put(updateCustomerLoading({ status: true }));
}

function* handleGetCustomerFail(action: any) {
  yield put(updateCustomerLoading({ status: false }));
  yield toast.error(getDapiStripeError(action, 'Could not get customer info!'));
}

function* handleGetCustomerSuccess(action: any) {
  yield put(updateCustomerLoading({ status: false }));
  yield put(updateCustomer({ customer: action.payload.data.customer }));
}

export default stripeSagas;
