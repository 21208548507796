import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Button } from '@maestro-org/ui-kit';

import { getCreateWebhookDialog } from '../../../redux/reducers/popupsReducer';
import { getCreateTransactionFields } from '../../../forms/createTransaction/form';

import Dialog from '../common/Dialog';
import DialogForm from '../common/DialogForm';
import DialogHeader from '../common/DialogHeader';
import DialogActions from '../common/DialogActions';
import WebhookFormFields from './components/WebhookFormFields';
import { useCreateWebhookForm } from './hooks/useCreateWebhookForm';

const CreateWebhook = () => {
  const isOpen = useSelector(getCreateWebhookDialog);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    handleDropdownChange,
    handleDropdownBlur,
    checkError,
    isValid,
    dirty,
    handleClose,
    sortedProjects,
  } = useCreateWebhookForm();

  return (
    <Dialog open={isOpen}>
      <DialogForm onSubmit={handleSubmit}>
        <DialogHeader title="Create webhook" />

        <WebhookFormFields
          fields={getCreateTransactionFields(sortedProjects)}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleDropdownChange={handleDropdownChange}
          handleDropdownBlur={handleDropdownBlur}
          checkError={checkError}
        />

        <DialogActions
          onCancel={handleClose}
          submitDisabled={!isValid || !dirty}
          submitLabel="Save webhook"
        />
      </DialogForm>
    </Dialog>
  );
};

export default CreateWebhook;
