export const KeyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12.5C5.82843 12.5 6.5 11.8284 6.5 11C6.5 10.1716 5.82843 9.5 5 9.5C4.17157 9.5 3.5 10.1716 3.5 11C3.5 11.8284 4.17157 12.5 5 12.5Z"
      stroke="#A926BB"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 0.5H12.5L6.29 6.71C5.87259 6.57729 5.43796 6.50653 5 6.5C4.10999 6.5 3.23996 6.76392 2.49994 7.25839C1.75991 7.75285 1.18314 8.45566 0.842544 9.27792C0.50195 10.1002 0.412835 11.005 0.586468 11.8779C0.760102 12.7508 1.18869 13.5526 1.81802 14.182C2.44736 14.8113 3.24918 15.2399 4.1221 15.4135C4.99501 15.5872 5.89981 15.4981 6.72208 15.1575C7.54434 14.8169 8.24715 14.2401 8.74162 13.5001C9.23608 12.76 9.5 11.89 9.5 11C9.5 10.549 9.413 10.121 9.29 9.71L11.5 7.5V4.5H14.5L15.5 3.5V0.5Z"
      stroke="#A926BB"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
