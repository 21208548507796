import { SubscriptionPlan } from '../types/subscription';

interface Limits {
  eventManagers: number | 'unlimited';
  triggers: number | 'unlimited';
}

type LimitType = keyof Limits;

const subscriptionLimits = {
  [SubscriptionPlan.artist]: {
    eventManagers: 1,
    triggers: 50,
  },
  [SubscriptionPlan.conductor]: {
    eventManagers: 10,
    triggers: 50,
  },
  [SubscriptionPlan.virtuoso]: {
    eventManagers: 20,
    triggers: 50,
  },
  [SubscriptionPlan.enterprise]: {
    eventManagers: 'unlimited',
    triggers: 'unlimited',
  },
} as const;

interface CheckLimitsParams {
  currentCount: number;
  subscriptionPlan: SubscriptionPlan;
  limitType: LimitType;
}

interface LimitResult {
  isLimitReached: boolean;
  limit: number | '∞';
  remaining: number | '∞';
}

export const checkLimit = ({
  currentCount,
  subscriptionPlan,
  limitType,
}: CheckLimitsParams): LimitResult => {
  const limits = subscriptionLimits[subscriptionPlan];
  const limit = limits[limitType];

  if (limit === 'unlimited') {
    return {
      isLimitReached: false,
      limit: '∞',
      remaining: '∞',
    };
  }

  return {
    isLimitReached: currentCount >= limit,
    limit,
    remaining: Math.max(0, limit - currentCount),
  };
};

const entityNames: Record<LimitType, string> = {
  eventManagers: 'event managers',
  triggers: 'triggers',
};

export const getLimitTooltip = (
  subscriptionPlan: SubscriptionPlan,
  limitType: LimitType
): string => {
  const limits = subscriptionLimits[subscriptionPlan];
  const limit = limits[limitType];

  if (limit === 'unlimited') return '';

  return `Your ${subscriptionPlan.toLowerCase()} plan allows up to ${limit} ${
    entityNames[limitType]
  }`;
};
