import Dialog from '../common/Dialog';
import DialogHeader from '../common/DialogHeader';
import DialogActions from '../common/DialogActions';
import TeamNameField from './components/TeamNameField';
import { useRenameTeam } from './hooks/useRenameTeam';

const RenameTeam = () => {
  const {
    isOpen,
    teamName,
    newTeamName,
    touched,
    handleChange,
    handleClose,
    handleRename,
  } = useRenameTeam();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogHeader title={`Rename "${teamName}"`} />

      <TeamNameField
        onChange={handleChange}
        value={newTeamName}
        isMaxReached={touched && newTeamName.length >= 32}
      />

      <DialogActions
        onCancel={handleClose}
        onSubmit={handleRename}
        submitLabel="Rename"
      />
    </Dialog>
  );
};

export default RenameTeam;
