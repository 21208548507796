import { toast } from 'react-toastify';
import { takeEvery, put } from 'redux-saga/effects';
import {
  GET_TRANSACTIONS_SERVER,
  updateTransactions,
  updateTransactionsLoading,
} from '../actions/transactionsActions';

const transactionsSagas = [
  takeEvery(GET_TRANSACTIONS_SERVER, handleGetTransactions),
  takeEvery(`${GET_TRANSACTIONS_SERVER}_SUCCESS`, handleGetTransactionsSuccess),
  takeEvery(`${GET_TRANSACTIONS_SERVER}_FAIL`, handleGetTransactionsFail),
];

function* handleGetTransactions() {
  yield put(updateTransactions([]));
  yield put(updateTransactionsLoading({ status: true }));
}

function* handleGetTransactionsFail() {
  yield put(updateTransactionsLoading({ status: false }));

  const toastId = 'transaction-error';

  if (!toast.isActive(toastId)) {
    toast.error('Unable to get transactions. Try again later!', { toastId });
  }
}

function* handleGetTransactionsSuccess(action: any) {
  yield put(updateTransactionsLoading({ status: false }));
  yield put(updateTransactions(action.payload.data.transactions));
}

export default transactionsSagas;
