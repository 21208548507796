export const WalletManagerIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 12.4706H30.5455V8.23529H5.81818C5.01818 8.23529 4.36364 7.6 4.36364 6.82353C4.36364 6.04706 5.01818 5.41176 5.81818 5.41176H23.2727V4H5.81818C4.21818 4 2.90909 5.27059 2.90909 6.82353V13.8824H4.36364V9.64706H29.0909V12.4706H18.9091V20.9412H29.0909V23.7647H14.5455V25.1765H30.5455V20.9412H32V12.4706ZM30.5455 19.5294H20.3636V13.8824H30.5455V19.5294ZM5.81818 22.3529V28H0V22.3529H5.81818ZM4.36364 16.7059V19.5294H1.45455V16.7059H4.36364ZM5.81818 15.2941H0V20.9412H5.81818V15.2941ZM11.6364 23.7647V26.5882H8.72727V23.7647H11.6364ZM13.0909 22.3529H7.27273V28H13.0909V22.3529ZM13.0909 15.2941V20.9412H7.27273V15.2941H13.0909ZM21.8182 16.7059C21.8182 15.9294 22.4727 15.2941 23.2727 15.2941C24.0727 15.2941 24.7273 15.9294 24.7273 16.7059C24.7273 17.4824 24.0727 18.1176 23.2727 18.1176C22.4727 18.1176 21.8182 17.4824 21.8182 16.7059Z"
      fill="#C53DD8"
    />
  </svg>
);
