import { AddTeamMemberFields, AddTeamMemberFormValues } from './types';
import { NewEntryFieldTypes } from '../../types/newEntry';

// prettier-ignore
export const initialValuesAddTeamMember = {
    [AddTeamMemberFields.emailAddress]: "",
    [AddTeamMemberFields.role]: [],
} as AddTeamMemberFormValues;

// prettier-ignore
export const getCreateAddTeamMemberFields = (): any[] => [
    {
        type: NewEntryFieldTypes.text,
        name: AddTeamMemberFields.emailAddress,
        fieldLabel: "Email Address",
    },
    {
        type: NewEntryFieldTypes.select,
        name: AddTeamMemberFields.role,
        fieldLabel: "Role",
        options: [
            {
                value: "Member",
                disabeld: false,
            },
            {
                value: "Developer",
                disabled: false,
            },
            {
                value: "Admin",
                disabled: false,
            },
        ],
    },
];
