import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import { Popups } from '../../../../types/popups';
import useFields from '../../../../forms/createWebhook/useField';
import { CreateWebhookFormValues } from '../../../../forms/createWebhook/types';
import {
  createEvent,
  updateEventServer,
} from '../../../../redux/actions/eventsActions';

export const useEventWebhookForm = (
  isEditMode: boolean,
  prefillData: any,
  triggerConditions: any[],
  onClose: () => void
) => {
  const dispatch = useDispatch();

  const onSubmit = (values: CreateWebhookFormValues) => {
    const formData = {
      chain: values.blockchain[0],
      network: values.network[0],
      name: values.trigger[0],
      webhook_url: values.url,
      triggers: [
        {
          event_manager_id: '',
          trigger_type: values.trigger[0],
          tracked_id: values.triggervalue,
          filters: triggerConditions.map((condition) => ({
            key: condition.field[0],
            operator: condition.operator[0],
            value: condition.value,
          })),
        },
      ],
      ...(isEditMode && { id: prefillData.id, status: prefillData.status }),
    };
    dispatch(
      isEditMode
        ? updateEventServer(prefillData.id && prefillData.status && formData)
        : createEvent(formData)
    );
    onClose();
  };

  const { values, isValid, dirty, getField, handleSubmit, resetForm } =
    useFields({ onSubmit });

  useEffect(() => {
    if (isEditMode) {
      const triggerItem = prefillData.triggers?.[0];

      const prefilledData = {
        blockchain: [prefillData.chain] as never[],
        network: [prefillData.network] as never[],
        trigger: [prefillData.name] as never[],
        url: prefillData.webhookUrl,
        field: [] as never[],
        operator: [] as never[],
        value: '',
        triggervalue: prefillData.triggers[0].trackedId,
      };

      resetForm({ values: prefilledData });
    }
  }, [isEditMode, prefillData]);

  const isButtonDisabled = (areTriggerConditionsValid: boolean) => {
    if (isEditMode) {
      return !isValid || !areTriggerConditionsValid;
    }
    return !isValid || !dirty || !areTriggerConditionsValid;
  };

  const handleClose = () => {
    resetForm({
      values: {
        blockchain: [],
        network: [],
        trigger: [],
        url: '',
        field: [],
        operator: [],
        value: '',
        triggervalue: '',
      },
    });
    onClose();
  };

  return {
    values,
    isValid,
    dirty,
    getField,
    handleSubmit,
    resetForm,
    isButtonDisabled,
    handleClose,
  };
};
