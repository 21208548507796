export const EmptyTransactionsIcon = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="60" cy="60" r="60" fill="#F5F5F5" />
    <path
      d="M73.5265 60.6031V57.9304H65.8018L60.2992 44.5668L54.7967 57.9304H47.0719V60.6031H53.6856L51.4899 65.9486H47.0719V68.6213H50.3788L47.0719 76.6395H49.7174L53.0771 68.6213H67.5478L70.881 76.6395H73.5265L70.2197 68.6213H73.5265V65.9486H69.1086L66.9129 60.6031H73.5265ZM60.3257 51.2486L63.1034 57.9304H57.5215L60.3257 51.2486ZM66.4367 65.9486H54.1882L56.4104 60.6031H64.2145L66.4367 65.9486ZM89.3992 79.3122V81.9849H52.3629V90.0031L31.1992 79.3122H89.3992ZM31.1992 41.894V39.2213H68.2356V31.2031L89.3992 41.894H31.1992Z"
      fill="#CCCCCC"
    />
  </svg>
);
