import { Button, Loader } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getActiveMethodId,
  getAllPaymentMethods,
  getBillingDataLoading,
  getLoadingPaymentMethods,
} from '../../../redux/reducers/stripeReducer';
import {
  deletePaymentServer,
  setPaymentServer,
} from '../../../redux/actions/stripeActions';

import Divider from '../../../components/Divider/Divider';
import PaymentMethod from './PaymentMethod';
import SettingsCard from './SettingsCard';

import { pages } from '../../../lib/routeUtils';
import { getUser, getTeamOwner } from '../../../redux/reducers/usersReducer';
import { SubscriptionPlan } from '../../../types/subscription';

import { UserRoles } from '../../../types/roles';
import { useCurrentSubscription } from '../../../hooks/useCurrentSubscription';

const AvailableCards = () => {
  const paymentMethods = useSelector(getAllPaymentMethods);
  const isLoading = useSelector(getBillingDataLoading);
  const loadingPayments = useSelector(getLoadingPaymentMethods);
  const user = useSelector(getUser);
  const owner = useSelector(getTeamOwner);

  const currentSubscription = useCurrentSubscription();

  const showOwnerData =
    user.team_id && !(user.team_role_name === UserRoles.OWNER);

  const activeMethod = showOwnerData
    ? owner.default_payment_method_id
    : useSelector(getActiveMethodId);

  const [prevPaymentMethodsLength, setPrevPaymentMethodsLength] = useState(
    paymentMethods.length
  );

  const dispatch = useDispatch();

  const handleChangeActive = (id: string) => () => {
    dispatch(setPaymentServer({ methodId: id }));
  };

  const handleDeleteMethod = (id: string) => {
    if (!user) return;

    if (
      paymentMethods.length <= 1 &&
      currentSubscription.name !== SubscriptionPlan.artist
    ) {
      toast.error(
        "Can't delete last payment method in paid tier. Add another payment method or downgrade to Artist plan."
      );
      return;
    }

    const newPayments = paymentMethods.filter((item) => item.id !== id);

    dispatch(
      deletePaymentServer({
        loadingMethods: loadingPayments,
        methodId: id,
        newActiveId:
          activeMethod === id
            ? newPayments.length
              ? paymentMethods[0].id
              : undefined
            : activeMethod,
        customerId: user.customer_id,
      })
    );
  };

  useEffect(() => {
    if (paymentMethods.length > 0) {
      if (paymentMethods.length > prevPaymentMethodsLength) {
        const newDefaultMethod = paymentMethods[0];
        if (newDefaultMethod.id !== activeMethod) {
          dispatch(setPaymentServer({ methodId: newDefaultMethod.id }));
        }
      }
    }
    setPrevPaymentMethodsLength(paymentMethods.length);
  }, [paymentMethods, activeMethod, dispatch, prevPaymentMethodsLength]);

  return (
    <SettingsCard>
      <Row>
        <Typography color="grey.A200" variant="h6">
          Payment method
        </Typography>
        {paymentMethods.length < 4 && (
          <Link to={pages.addNewPayment()}>
            <StyledButton>+ Add new</StyledButton>
          </Link>
        )}
      </Row>
      {isLoading && <Loader />}
      {!isLoading && (
        <PaymentMethodsWrapper>
          {!!paymentMethods.length &&
            paymentMethods.map((item) => (
              <PaymentMethod
                key={item.id}
                last4={item.card.last4}
                expMonth={item.card.expMonth}
                expYear={item.card.expYear}
                brand={item.card.brand}
                handleDelete={() => handleDeleteMethod(item.id)}
                handleMakeDefault={handleChangeActive(item.id)}
                isActive={item.id === activeMethod}
                isLoading={loadingPayments.includes(item.id)}
              />
            ))}
        </PaymentMethodsWrapper>
      )}
      <BottomWrapper>
        <Divider />
        <Typography color="grey.400" variant="article">
          By updating your payment details, you agree to turn on automatic
          renewal for your current subscription. See offer details and
          cancellation terms{' '}
          <Ref
            href="https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf"
            target="_blank"
          >
            here
          </Ref>
          .
        </Typography>
      </BottomWrapper>
    </SettingsCard>
  );
};

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PaymentMethodsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const Ref = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '5px 10px',
  background: 'transparent',
  color: theme.palette.primary.main,

  '&:hover': {
    background: 'transparent',
    color: theme.palette.primary.main,
  },
}));

export default AvailableCards;
