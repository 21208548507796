import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteProject } from './hooks/useDeleteProject';

const DeleteProject = () => {
  const { isOpen, projectName, handleClose, handleDelete } = useDeleteProject();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Delete ${projectName}`}
      description="Are you sure you want to delete this project? The API key associated with this project will stop working once the project is deleted."
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmLabel="Delete"
    />
  );
};

export default DeleteProject;
