import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getApiKeyCreatedDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { Popups } from '../../../../types/popups';
import { copyToClipboard } from '../../../../lib/copyToClipboard';
import { addToResetKeys } from '../../../../redux/actions/projectsActions';

export const useAPIKeyCreated = () => {
  const [isPasswordType, setIsPasswordType] = useState(true);
  const isOpen = useSelector(getApiKeyCreatedDialog);
  const { apiKey, projectId } = useSelector(getPrefilled) || {};
  const dispatch = useDispatch();

  const togglePasswordType = () => setIsPasswordType(!isPasswordType);

  const handleClose = () => {
    setIsPasswordType(true);
    dispatch(updatePopup({ popup: Popups.apiKeyCreated, status: false }));
  };

  const handleCopy = () => {
    copyToClipboard(apiKey);
    toast.success('Copied to clipboard');
    dispatch(addToResetKeys({ id: projectId }));
    handleClose();
  };

  return {
    isPasswordType,
    isOpen,
    apiKey,
    togglePasswordType,
    handleClose,
    handleCopy,
  };
};
