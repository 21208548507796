import { useSelector, useDispatch } from 'react-redux';

import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getPrefilled,
  getResetApiKeyDialog,
} from '../../../../redux/reducers/popupsReducer';
import { rotateApiKeyServer } from '../../../../redux/actions/projectsActions';
import { Popups } from '../../../../types/popups';

export const useResetKey = () => {
  const isOpen = useSelector(getResetApiKeyDialog);
  const { projectName, projectId } = useSelector(getPrefilled) || {};
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(updatePopup({ popup: Popups.resetKey, status: false }));

  const handleResetClick = () => {
    handleClose();
    dispatch(rotateApiKeyServer({ id: projectId }));
  };

  return {
    isOpen,
    projectName,
    handleClose,
    handleResetClick,
  };
};
