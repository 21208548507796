import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { addTeamMemberServer } from '../../../../redux/actions/teamsActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getAddTeamMemberDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { Popups } from '../../../../types/popups';

export const useAddTeamMember = () => {
  const isOpen = useSelector(getAddTeamMemberDialog);
  const { email, role } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.addTeamMember, status: false }));
  };

  const handleAdd = () => {
    if (!email || !role) {
      toast.error('Error occurred while adding new team user');
      return;
    }
    dispatch(addTeamMemberServer(email, role));
    handleClose();
  };

  return {
    isOpen,
    email,
    handleClose,
    handleAdd,
  };
};
