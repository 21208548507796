import { DateTabs } from '../modules/Home/components/TotalRequestVolume';

export const svgWidth = (type: DateTabs, dataLength: number) => {
  switch (type) {
    case DateTabs.day:
      return 66 + 57 * dataLength;
    case DateTabs.week:
      return 66 + 102 * dataLength;
    case DateTabs.month:
      return 66 + 55 * dataLength;
    case DateTabs.year:
      return 66 + 79 * dataLength;

    default:
      break;
  }
};

export const barGetGap = (type: DateTabs) => {
  switch (type) {
    case DateTabs.day:
      return 14;
    case DateTabs.week:
      return 57;
    case DateTabs.month:
      return 10;
    case DateTabs.year:
      return 34;
    default:
      break;
  }
};

export const barRadius = (type: DateTabs) => {
  switch (type) {
    case DateTabs.day:
      return 8;
    case DateTabs.week:
      return 8;
    case DateTabs.month:
      return 6;
    case DateTabs.year:
      return 8;
    default:
      break;
  }
};

export const barSize = (type: DateTabs) => {
  switch (type) {
    case DateTabs.day:
      return 44;
    case DateTabs.week:
      return 148;
    case DateTabs.month:
      return 36;
    case DateTabs.year:
      return 84;
    default:
      break;
  }
};

export const getChartsTooltipGap = (arr: any) => {
  if (!arr) return 40;

  const data = arr[0]?.payload;

  if (!data) return 40;

  let maxNumberValue = Number.NEGATIVE_INFINITY;

  for (const key in data) {
    if (key !== 'time' && typeof data[key] === 'number') {
      maxNumberValue = Math.max(maxNumberValue, data[key]);
    }
  }

  return 25 + maxNumberValue.toString().length * 6;
};
