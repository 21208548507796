export const TransactionEventsListeningIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.96 40.96L39.3371 39.3371C35.4057 43.2686 29.9657 45.7143 24 45.7143C12.0229 45.7143 2.28571 35.9771 2.28571 24C2.28571 12.0229 12.0229 2.28571 24 2.28571C35.9771 2.28571 45.0514 11.4057 45.6457 22.8571L38.7886 22.8571C38.1943 15.1771 31.8171 9.14286 24 9.14286C15.7943 9.14286 9.14286 15.7943 9.14286 24C9.14286 32.2057 15.7943 38.8571 24 38.8571C28.0914 38.8571 31.8171 37.1886 34.5143 34.5143L32.8914 32.8914C30.6057 35.1771 27.4743 36.5714 24 36.5714C17.0743 36.5714 11.4286 30.9257 11.4286 24C11.4286 17.0743 17.0743 11.4286 24 11.4286C30.5371 11.4286 35.9314 16.4571 36.5029 22.8571L29.5771 22.8571C29.0514 20.2514 26.7429 18.2857 23.9771 18.2857C20.8229 18.2857 18.2629 20.8457 18.2629 24C18.2629 27.1543 20.8229 29.7143 23.9771 29.7143C26.7429 29.7143 29.0514 27.7486 29.5771 25.1429L48 25.1429L47.9543 22.8571C47.3371 10.1257 36.8686 -4.8657e-07 24 -1.04907e-06C11.1314 -1.61158e-06 -4.28428e-06 10.7429 -4.86377e-06 24C-5.44326e-06 37.2571 10.7429 48 24 48C30.6286 48 36.6171 45.3029 40.96 40.96ZM24 27.4286C22.1029 27.4286 20.5714 25.8971 20.5714 24C20.5714 22.1029 22.1029 20.5714 24 20.5714C25.8971 20.5714 27.4286 22.1029 27.4286 24C27.4286 25.8971 25.8971 27.4286 24 27.4286Z"
      fill="#C53DD8"
    />
  </svg>
);
