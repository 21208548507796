import { Radio } from '@maestro-org/ui-kit';
import { styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import { SubscriptionPlan } from '../../../types/subscription';

import Tooltip from '../../../components/Tooltip/Tooltip';

import PlanPackageData from './PlanPackageData';
import Switch from '../../../components/Switch/Switch';

import { InfoTooltipIcon } from '../../../components/Icons';
import { LockIcon } from '../../../components/Icons';

import { Package, TiersProps } from '../../../redux/reducers/stripeReducer';

import {
  getPlanIcon,
  getTooltipText,
  getSubscriptionPrice,
} from '../../../lib/subscriptionPlanUtils';

const MILLION = 1000000 as const;

interface AdditionalProps {
  isDisabled: boolean;
  price_per_month?: number;
  isPlanSelected?: boolean;
  isVirtuosoAnnual: boolean;
  subscriptionTiers?: TiersProps;
  handleToogleSwitch: () => void;
  handleChangeActive: (id: string) => void;
}

const PlanPackage = ({
  id,
  name,
  credits_per_month,
  features,
  isPlanSelected,
  webhook_limit,
  project_limit,
  throughput_limit,
  isVirtuosoAnnual,
  subscriptionTiers,
  credits_per_day,
  isDisabled,
  handleToogleSwitch,
  handleChangeActive,
}: Package & AdditionalProps) => {
  const isVirtuoso = name === SubscriptionPlan.virtuoso;
  const subscriptionPrice = getSubscriptionPrice(
    name,
    isVirtuosoAnnual,
    subscriptionTiers
  );
  const { price, discount } = subscriptionPrice;

  const handleButtonClick = () => {
    handleChangeActive(id);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1512));

  return (
    <OutterWrapper
      onClick={handleButtonClick}
      isActive={isPlanSelected}
      isDisabled={isDisabled}
    >
      <UnavailableTooltip
        disabled={isDisabled}
        title={
          <>
            <Typography variant="article" color="grey.800">
              You are on an Enterprise plan and can't switch to a
              non-Enterprise. For more info contact us at:{' '}
              <StyledLink href="mailto:support@gomaestro.org">
                support@gomaestro.org
              </StyledLink>
            </Typography>
          </>
        }
        followCursor
        placement="top"
      >
        <PackageWrapper isVirtuoso={isVirtuoso}>
          <Top>
            <TitleIconWrapper>
              <TitleWrapper>
                <ActionWrapper>
                  <StyledRadio
                    checked={isPlanSelected}
                    value=""
                    label=""
                    handleChange={console.log}
                  />
                </ActionWrapper>
                <WithIcon>
                  <IconWrapper disabled={isDisabled}>
                    {getPlanIcon[name]}
                  </IconWrapper>
                  <Typography
                    color={isDisabled ? 'grey.300' : 'textColor.dark'}
                    variant="subtitle1"
                  >
                    {name}
                  </Typography>
                </WithIcon>
                {isVirtuoso && !isMobile && (
                  <ToogleWrapper>
                    <ToogleLeft>
                      <Switch
                        isDisabled={isDisabled}
                        checked={isVirtuosoAnnual}
                        handleChange={handleToogleSwitch}
                      />
                      <SwitchText
                        isChecked={isVirtuosoAnnual}
                        isDisabled={isDisabled}
                      >
                        {isVirtuosoAnnual ? 'Billed yearly' : 'Billed monthly'}
                      </SwitchText>
                    </ToogleLeft>
                    {/* {isVirtuosoAnnual && (
                  <SwitchText isChecked>Save {gitDiscountPercentage({ price, discount })}%</SwitchText>
                )} */}
                  </ToogleWrapper>
                )}
              </TitleWrapper>
              <PriceWrapper>
                {discount && !isMobile && (
                  <Discount variant="chartTitle">{discount}</Discount>
                )}
                <Typography
                  color={isDisabled ? 'grey.300' : 'textColor.dark'}
                  variant="chartTitle"
                >
                  {price}
                </Typography>
              </PriceWrapper>
            </TitleIconWrapper>
            <CreditsWrapper>
              <CreditsPerMonth color="textColor.lightGray">
                {credits_per_month / MILLION}M credits a month
              </CreditsPerMonth>
              <StyledTooltip
                disabled={isDisabled}
                enterTouchDelay={0}
                title={
                  <TooltipText>
                    {getTooltipText(name, credits_per_day, credits_per_month)}
                  </TooltipText>
                }
              >
                <InfoIconWrapper>
                  <InfoTooltipIcon />
                </InfoIconWrapper>
              </StyledTooltip>
            </CreditsWrapper>
          </Top>
          <PlanInfoWrapper>
            {/* <PackageDividerWrapper>
            <Divider color="light" />
          </PackageDividerWrapper> */}
            <PlanPackageData
              isDisabled={isDisabled}
              throughputLimit={throughput_limit}
              webhookLimit={webhook_limit}
              projectLimit={project_limit}
              isMobile={isMobile}
              isVirtuoso={isVirtuoso}
              isVirtuosoAnnual={isVirtuosoAnnual}
              handleToogleSwitch={handleToogleSwitch}
            />
            {/* <PlanPackagePros pros={features} planName={name} /> */}
          </PlanInfoWrapper>
        </PackageWrapper>
      </UnavailableTooltip>
    </OutterWrapper>
  );
};

const CreditsPerMonth = styled(Typography)({
  fontSize: '16px',
  fontWeight: '300',
  lineHeight: '24px',
});

const PriceWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  minHeight: '34px',
});

const TooltipText = styled(Typography)(() => ({
  color: '#333333',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
}));

const PackageWrapper = styled('div')<{ isVirtuoso: boolean }>(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '32px',
  gap: '16px',
  flex: 1,
  borderRadius: '12px 12px 12px 12px',
  background: theme.palette.common.white,

  [theme.breakpoints.down('xl')]: {
    gap: '20px',
    padding: '22px',
  },

  [theme.breakpoints.down(1512)]: {
    padding: '32px',
  },
}));

const StyledRadio = styled(Radio)({
  padding: 0,
  cursor: 'default',
});

const TitleIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down(1512)]: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    maxHeight: '32px',
  },
}));

const Top = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const PlanInfoWrapper = styled('div')({
  display: 'flex',
  gap: '32px',
});

const CreditsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const InfoIconWrapper = styled('div')({
  height: '16px',
});

const IconWrapper = styled('div')<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    display: 'flex',

    '& svg': {
      flexShrink: '0',

      '& path': {
        fill: disabled ? theme.palette.grey[300] : theme.palette.primary.main,
      },
    },

    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  })
);

const StyledTooltip = styled(Tooltip)<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    ...(disabled && { display: 'none' }),
    '& .MuiTooltip-tooltip': {
      maxWidth: '236px',
      padding: '8px 16px',
      boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
      border: `1px solid ${theme.palette.grey[100]}`,
      display: 'flex',
      alignItems: 'center',
      columnGap: '9px',
      background: '#FFFFFF',
    },
  })
);

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  paddingBottom: '16px',

  '& .MuiFormControlLabel-root': {
    margin: 0,
  },

  [theme.breakpoints.down(1512)]: {
    flexDirection: 'row-reverse',
    paddingBottom: '0px',
    gap: '12px',
  },
}));

const WithIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',

  '& svg': {
    width: '32px',
    height: '32px',
  },
}));

const ActionWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
});

const Discount = styled(Typography)({
  color: '#CCC',
  textDecoration: 'line-through',
});

const OutterWrapper = styled('div')<{
  isActive?: boolean;
  isDisabled: boolean;
}>(({ isActive, isDisabled, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(50% - 18px)',
  marginTop: 'auto',
  borderRadius: theme.borderRadius.sm,
  boxShadow: '0px 2px 12px rgba(153, 153, 153, 0.12)',
  border: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : `1px solid ${theme.palette.grey[100]}`,
  transition: 'border-color 0.3s',

  [theme.breakpoints.down(1700)]: {
    width: 'calc(50% - 10px)',
  },

  ...(!isDisabled && {
    '&:hover': {
      borderColor: theme.palette.contrastText.main,
    },
  }),

  [theme.breakpoints.down(1330)]: {
    width: '100%',
  },
}));

const ToogleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '12px 12px 0px 0px',
  transition: 'background-color 0.3s ease-in-out',

  [theme.breakpoints.down(1920)]: {
    justifyContent: 'end',
    alignItems: 'end',
    marginLeft: 'auto',
  },
}));

const ToogleLeft = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const SwitchText = styled(Typography)<{
  isChecked: boolean;
  isDisabled: boolean;
}>(({ theme, isChecked, isDisabled }) => ({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  color: isDisabled
    ? theme.palette.grey[300]
    : isChecked
    ? theme.palette.primary.main
    : theme.palette.social.dark,
  transition: 'color 0.3s ease-in-out',
}));

const UnavailableTooltip = styled(Tooltip)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    ...(!disabled && { display: 'none' }),
    '& .MuiTooltip-tooltip': {
      maxWidth: '300px',
      padding: '8px 16px',
      boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
      border: `1px solid ${theme.palette.grey[100]}`,
      display: 'flex',
      alignItems: 'center',
      columnGap: '9px',
    },
  })
);

const StyledLink = styled('a')<any>(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export default PlanPackage;
