import React from 'react';
import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useDeleteAccount } from './hooks/useDeleteAccount';

const DeleteAccount = () => {
  const { isOpen, handleClose, handleDelete } = useDeleteAccount();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Delete Account"
      description={
        <>
          Deleting your account will permanently delete all of your projects and
          webhooks. Once you confirm deletion, this process cannot be undone.
          <br />
          <br />
          Are you sure you want to delete your account?
        </>
      }
      onConfirm={handleDelete}
      onCancel={handleClose}
      confirmLabel="Delete"
    />
  );
};

export default DeleteAccount;
