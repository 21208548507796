import { RowType, Transaction } from '../types/transaction';
import { handleSingleDigit } from './handleSingleDigit';

const heading = {
  submitTime: 'Submit Timestamp',
  state: 'State',
  txHash: 'Tx Hash',
  project: 'Project',
  block: 'Block',
  confirmations: 'Confirmations',
  webhook: 'Webhook',
};

const handleHash = (hash: string): string =>
  hash ? hash.slice(0, 8) + '..' + hash.slice(-8) : '';

const handleTimestamp = (timestamp: string): string => {
  if (!timestamp) return '';

  const date = new Date(timestamp);

  const year = date.getFullYear().toString().slice(2);
  const month = handleSingleDigit(date.getMonth() + 1);
  const day = handleSingleDigit(date.getDate());
  const hours = handleSingleDigit(date.getHours());
  const minutes = handleSingleDigit(date.getMinutes());
  const seconds = handleSingleDigit(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const createRow = (id: number, transaction: Transaction): RowType => ({
  id,
  heading,
  data: {
    submitTime: handleTimestamp(transaction.timestamp),
    state: transaction.state,
    txHash: handleHash(transaction.transaction_hash),
    txHashFull: transaction.transaction_hash,
    project: transaction.project_name,
    block: transaction.block,
    confirmations: transaction.confirmations,
    webhook: transaction.webhook,
  },
  details: transaction.info,
  turboTX: transaction.turbo_tx,
});
