export const TransactionMonitoringIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 23.6504L22.6667 18.0839V22.2588H9.33333V23.6504H32ZM9.33333 26.4336L18.6667 32V27.8252H32V26.4336H9.33333ZM12.56 12.4479L10.9333 6.95108H8V5.55947H12L12.6933 8.16177L15.28 0.0626223C15.3067 -0.0208741 15.4133 -0.0208741 15.44 0.0626223L17.0667 5.55947H20V6.95108H16L15.3067 4.34877L12.72 12.4479C12.6933 12.5314 12.5867 12.5314 12.56 12.4479ZM10.6667 15.3007V18.0839H20V19.4755H0V18.0839H9.33333V15.3007H0V1.38465H9.33333V2.77626H1.33333V13.9091H18.6667V9.73429H20V15.3007H10.6667Z"
      fill="#C53DD8"
    />
  </svg>
);
