import { MetricsItemBucket } from './project';
import { SubscriptionPlan } from './subscription';

export interface UserMetrics {
  all_requests_last_24h: {
    count: number;
    buckets: any[];
  };
  transactions_last_24h: {
    count: number;
    buckets: any[];
  };
  webhook_events_last_24h: number;
}

export interface UserSubscriptions {
  credits_description: string;
  daily_credit_limit: string;
  general_description: string;
  id: string;
  monthly_fee: string;
  price_per_credit: string;
  project_limit: string;
  services: string[];
  subscription_name: SubscriptionPlan;
  webhook_limit: string;
  stripe_price_id: string;
  stripe_product_id: string;
  throughput_limit: string;
}

export enum SubStatus {
  active = 'active',
  paused = 'paused',
}

export interface SubscriptionService {
  service_name: string;
  details: string[];
  subscriptions: string[];
}

export interface SubscriptionFeature {
  chains: string[];
  details: string[];
  name: string;
  packages: string[];
}

export interface UserReuestVolume {
  project_id: string;
  day: {
    count: number;
    buckets: MetricsItemBucket[];
  };
  week: {
    count: number;
    buckets: MetricsItemBucket[];
  };
  month: {
    count: number;
    buckets: MetricsItemBucket[];
  };
  year: {
    count: number;
    buckets: MetricsItemBucket[];
  };
}

export interface CreditsUsageItem {
  credits_used: string;
  date: string;
}
