import React from 'react';
import {
  TabsProps as MuiTabsProps,
  Tabs as MuiTabs,
  Tab,
  styled,
  Theme,
} from '@mui/material';

interface CustomProps {
  color?: 'default' | 'colored';
  options: {
    value: string;
    label: string;
    icon?: JSX.Element;
  }[];
}

export type TabsProps = Omit<MuiTabsProps, 'color'> & CustomProps;

const Tabs = ({ options, color = 'default', ...props }: TabsProps) => (
  <StyledTabs color={color} {...props}>
    {options.map(({ value, label, icon }) => (
      <Tab
        key={value}
        value={value}
        label={label}
        icon={icon}
        iconPosition="start"
      />
    ))}
  </StyledTabs>
);

const StyledTabs = styled(MuiTabs, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: 'default' | 'colored' }>(({ theme, color }) => ({
  '& .MuiTabs-flexContainer .MuiButtonBase-root': {
    textTransform: 'none',
    color: '#0F0311',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiTabs-flexContainer .Mui-selected': {
    color: '#f5f5f5',
    backgroundColor: '#0f0311',
    borderRadius: '8px',
    maxHeight: '32px',
  },

  ...getCustomColor(theme)[color],
}));

const getCustomColor = (theme: Theme) => ({
  default: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '&.MuiTabs-root': {
      minHeight: '32px',
    },
    '& .MuiButtonBase-root': {
      minHeight: '32px',
      padding: '8px 16px',
      minWidth: 'auto',
    },
  },
  colored: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `2px solid ${theme.palette.grey[200]}`,
    },

    '& .MuiTab-root': {
      paddingLeft: '0',
      paddingRight: '33px !important',
      fontWeight: 500,
      fontSize: theme.typography.paragraphSmall.fontSize,
      lineHeight: theme.typography.paragraphSmall.lineHeight,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: '3px',
    },
    '& .MuiTabs-flexContainer .MuiButtonBase-root': {
      textTransform: 'none',
      color: theme.palette.grey['A200'],
      alignItems: 'flex-start',
      padding: '13px 0',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      minHeight: 0,
      columnGap: '15px',
    },
    '& .MuiTabs-flexContainer .Mui-selected': {
      fontWeight: 500,
    },
  },
});

export default Tabs;
