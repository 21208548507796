import { styled } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <StyledLoader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  );
};

const StyledLoader = styled('div')({
  color: 'official',
  display: 'inline-block',
  position: 'relative',
  width: `80px`,
  height: `80px`,

  '& div': {
    transformOrigin: '40px 40px',
    animation: 'lds-spinner 1.5s linear infinite',
  },
  '& div:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '15px',
    left: '40px',
    width: '4px',
    height: '12px',
    borderRadius: '900px',
    background: '#2D2D2D',
  },
  '& div:nth-child(1)': {
    transform: 'rotate(0deg)',
    animationDelay: '0.1s',
  },
  '& div:nth-child(2)': {
    transform: 'rotate(45deg)',
    animationDelay: '0.3s',
  },
  '& div:nth-child(3)': {
    transform: 'rotate(90deg)',
    animationDelay: '0.5s',
  },
  '& div:nth-child(4)': {
    transform: 'rotate(135deg)',
    animationDelay: '0.7s',
  },
  '& div:nth-child(5)': {
    transform: 'rotate(180deg)',
    animationDelay: '0.9s',
  },
  '& div:nth-child(6)': {
    transform: 'rotate(225deg)',
    animationDelay: '1.1s',
  },
  '& div:nth-child(7)': {
    transform: 'rotate(270deg)',
    animationDelay: '1.3s',
  },
  '& div:nth-child(8)': {
    transform: 'rotate(315deg)',
    animationDelay: '1.5s',
  },
  '@keyframes lds-spinner': {
    '0% ': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});

export default Loader;
