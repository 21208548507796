import { REHYDRATE } from '../actions/appActions';
import {
  TeamProps,
  SET_TEAM_ID,
  UPDATE_IS_TEAM_CREATING,
  UPDATE_TEAM_LOADING,
  UPDATE_TEAM_SERVER,
  ADD_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_SUCCESS,
} from '../actions/teamsActions';

import { StoreType } from '../types/store.types';

export interface teamStateType {
  teamServer: TeamProps;
  isTeamCreating: boolean;
  isTeamLoading: boolean;
  queryTeamId?: string;
}

export const teamInitialState: teamStateType = {
  teamServer: {} as TeamProps,
  isTeamCreating: false,
  isTeamLoading: false,
  queryTeamId: undefined,
};

const teamsReducer = (state = teamInitialState, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const persistedData = action.payload?.team;
      if (persistedData) {
        return {
          ...state,
          queryTeamId: persistedData.queryTeamId,
        };
      }
      return { ...state };
    }

    case UPDATE_IS_TEAM_CREATING:
      return {
        ...state,
        isTeamCreating: action.payload.status,
      };

    case UPDATE_TEAM_LOADING:
      return {
        ...state,
        isTeamLoading: action.payload.status,
      };

    case UPDATE_TEAM_SERVER: {
      return {
        ...state,
        teamServer: action.payload.teamServer,
      };
    }

    case SET_TEAM_ID:
      return {
        ...state,
        queryTeamId: action.payload,
      };

    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamServer: {
          ...state.teamServer,
          users: [...state.teamServer.users, action.payload],
        },
      };

    case REMOVE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamServer: {
          ...state.teamServer,
          users: state.teamServer.users.filter(
            (user) => user.email !== action.payload
          ),
        },
      };

    default:
      return state;
  }
};

export const getTeam = (state: StoreType) => state.team.teamServer;
export const getIsTeamCreating = (state: StoreType) =>
  state.team.isTeamCreating;
export const getIsTeamLoading = (state: StoreType) => state.team.isTeamLoading;
export const getQueryTeamId = (state: StoreType) => state.team.queryTeamId;

export default teamsReducer;
