export const NavBarMenuIcon = () => (
  <svg
    width="32"
    height="22"
    viewBox="0 0 32 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="5" width="16" height="2" fill="#0F0311" />
    <rect x="16" y="15" width="16" height="2" fill="#0F0311" />
    <rect y="10" width="32" height="2" fill="#0F0311" />
  </svg>
);
