import { useDispatch, useSelector } from 'react-redux';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import { getDeleteAccountDialog } from '../../../../redux/reducers/popupsReducer';
import { deleteUserServer } from '../../../../redux/actions/userActions';
import { Popups } from '../../../../types/popups';

export const useDeleteAccount = () => {
  const isOpen = useSelector(getDeleteAccountDialog);
  const dispatch = useDispatch();

  const handleClose = () =>
    dispatch(updatePopup({ popup: Popups.deleteAccount, status: false }));

  const handleDelete = () => {
    dispatch(deleteUserServer());
    handleClose();
  };

  return {
    isOpen,
    handleClose,
    handleDelete,
  };
};
