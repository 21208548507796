import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { removeTeamMemberServer } from '../../../../redux/actions/teamsActions';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getDeleteTeamMemberDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { Popups } from '../../../../types/popups';

export const useDeleteTeamMember = () => {
  const isOpen = useSelector(getDeleteTeamMemberDialog);
  const { email } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteTeamMember, status: false }));
  };

  const handleDelete = () => {
    if (!email) {
      toast.error('Error occurred while deleting team user');
      return;
    }
    dispatch(removeTeamMemberServer(email));
    handleClose();
  };

  return {
    isOpen,
    email,
    handleClose,
    handleDelete,
  };
};
