import { Transaction } from '../../types/transaction';
import {
  UPDATE_LOADING_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
} from '../actions/transactionsActions';
import { StoreType } from '../types/store.types';

export interface transactionsStateType {
  transactionsLoading: boolean;
  transactions: Transaction[];
}

export const transactionsInitialState: transactionsStateType = {
  transactionsLoading: false,
  transactions: [],
};

const transactionsReducer = (state = transactionsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOADING_TRANSACTIONS: {
      return {
        ...state,
        transactionsLoading: action.payload.status,
      };
    }
    case UPDATE_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload.transactions,
      };
    }
    default:
      return { ...state };
  }
};

export const getAllTransactions = (state: StoreType) =>
  state.transactions.transactions;
export const getTransactionsLoading = (state: StoreType) =>
  state.transactions.transactionsLoading;

export default transactionsReducer;
