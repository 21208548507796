import React, { useEffect, useState } from 'react';
import {
  ListItem,
  MenuItem as MuiMenuItem,
  styled,
  Typography,
  ListItemButton as MuiListItemButton,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@maestro-org/ui-kit';

import { getAuthData, getIsAuthorized } from '../../redux/reducers/authReducer';
import {
  clearAuthData,
  updateIsAuthorized,
} from '../../redux/actions/authActions';
import { clearOrganizationData } from '../../redux/actions/userActions';
import { getAllprojects } from '../../redux/reducers/projectsReducer';

import Container from '../Container/Container';
import Logo, { LogoStyles } from '../Logo/Logo';
import Divider from '../Divider/Divider';
import {
  DropdownIcon,
  FilledArrowDown,
  NavBarMenuIcon,
  InputWarningIcon,
} from '../Icons';

import { pages } from '../../lib/routeUtils';
import Menu from './Menu';
import Drawer from './Drawer';

import { useCurrentSubscription } from '../../hooks/useCurrentSubscription';
import { getUser } from '../../redux/reducers/usersReducer';
import { SubStatus } from '../../types/user';
import AnnouncementLine from '../AnnouncementLine/AnnouncementLine';

interface Props {
  actions?: React.ReactNode;
  activeItem?: NavItems;
}

const MainNavigation = ({ actions, activeItem }: Props) => {
  const isAuthorized = useSelector(getIsAuthorized);
  const userData = useSelector(getAuthData);
  const user = useSelector(getUser);
  const projects = useSelector(getAllprojects);

  const currentSubscription = useCurrentSubscription();

  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [mobileSpacing, changeMobileSpacing] = useState(24);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  const location = useLocation();

  const handleLogOut = () => {
    if (!isAuthorized) return;

    dispatch(updateIsAuthorized({ status: false }));
    dispatch(clearAuthData());
    dispatch(clearOrganizationData());
    toast.success('Successfully logged out!');
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    const wrapper: any = document.querySelector('#navigation');

    window.addEventListener('scroll', () => {
      const elemRect = wrapper.getBoundingClientRect();
      const elemViewportOffset = elemRect.top;
      changeMobileSpacing(elemViewportOffset);
    });
  }, []);

  return (
    <OuterWrapper id="navigation">
      {user.status === SubStatus.paused && <AnnouncementLine />}
      <Container>
        <Wrapper>
          <Logo style={LogoStyles.Dark} />
          <ItemsWrapper>
            {navItems.map((item) =>
              item.name === NavItems.projects ? (
                <Item key={item.name} active={item.name === activeItem}>
                  <Menu
                    label={item.name}
                    endIcon={<FilledArrowDown />}
                    shouldChangeIconColor
                  >
                    {projects.length ? (
                      projects.map((project) => (
                        <Link key={project.name} to={`/project/${project.id}`}>
                          <MenuItem>
                            <Typography
                              color="grey.A200"
                              variant="paragraphMedium"
                            >
                              {project.name}
                            </Typography>
                          </MenuItem>
                        </Link>
                      ))
                    ) : (
                      <NoProjectsMessage
                        color="grey.A200"
                        variant="paragraphMedium"
                      >
                        No projects yet
                      </NoProjectsMessage>
                    )}
                  </Menu>
                </Item>
              ) : (
                <Link key={item.name} to={item.link}>
                  <Item active={item.name === activeItem}>
                    <Typography
                      variant="paragraphMedium"
                      color="textColor.dark"
                    >
                      {item.name}
                    </Typography>
                  </Item>
                </Link>
              )
            )}
          </ItemsWrapper>
          <Actions>
            <DocumentationWrapper>{actions}</DocumentationWrapper>
            {userData && (
              <Menu
                endIcon={
                  <MenuIconWrapper>
                    <DropdownIcon />
                  </MenuIconWrapper>
                }
              >
                <MenuTopSection>
                  <UserInfoWrapper>
                    <Typography
                      color="textColor.dark"
                      variant="paragraphMedium"
                    >
                      {`${userData?.user_metadata?.firstName || ''} ${
                        userData?.user_metadata?.lastName || ''
                      }`}
                    </Typography>
                    <Typography color="menu.grayText" variant="article">
                      {userData.email || userData.nickname}
                    </Typography>
                  </UserInfoWrapper>
                  <Ref to={pages.subscriptionPlan()}>
                    <Typography color="menu.refColor" variant="article">
                      {currentSubscription.name}
                    </Typography>
                  </Ref>
                </MenuTopSection>
                <DividerWrapper>
                  <Divider />
                </DividerWrapper>
                <Link to={pages.account()}>
                  <MenuItem>
                    <Typography
                      color="textColor.dark"
                      variant="paragraphMedium"
                    >
                      Settings
                    </Typography>
                  </MenuItem>
                </Link>
                <MenuItem onClick={handleLogOut}>
                  <Typography color="textColor.dark" variant="paragraphMedium">
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            )}
            <DrawerMenu>
              <Drawer
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                openTrigger={
                  <StyledIconButton
                    variant="light"
                    onClick={toggleDrawer(true)}
                  >
                    <NavBarMenuIcon />
                  </StyledIconButton>
                }
                mobileSpacing={mobileSpacing}
              >
                <DrawerItems />
              </Drawer>
            </DrawerMenu>
          </Actions>
        </Wrapper>
      </Container>
    </OuterWrapper>
  );
};

const OuterWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.grey['50'],
  borderBottom: `1px solid ${theme.palette.grey['100']}`,
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '20px 0',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('lg')]: {
    padding: '17px 0',
  },
}));

const ItemsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '40px',

  [theme.breakpoints.down('lg')]: {
    columnGap: '24px',
    marginLeft: '20px',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MenuIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Item = styled('div')<{ active?: boolean }>(({ active, theme }) => ({
  cursor: 'pointer',
  position: 'relative',

  '&:after': {
    content: active ? '""' : '""',
    position: 'absolute',
    width: '100%',
    height: '3px',
    background: theme.palette.primary.main,
    visibility: active ? 'initial' : 'hidden',
    bottom: '-32px',
    left: '50%',
    transform: 'translateX(-50%)',

    [theme.breakpoints.down('lg')]: {
      bottom: '-27px',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '24px',
  width: '310px',
  justifyContent: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    columnGap: '20px',
  },
}));

const DocumentationWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const DrawerMenu = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: '38px',
  height: '38px',
  background: 'transparent',

  '&:hover': {
    background: 'transparent',
  },
}));

const NavbarRef = styled(Typography)<{ active: boolean }>(
  ({ active, theme }) => ({
    cursor: 'pointer',

    color: active ? theme.palette.primary.main : 'none',
  })
);

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: '8px 24px 8px 12px',

  '&:after': {
    content: '""',
    height: '100%',
    width: '3px',
    position: 'absolute',
    right: '0px',
    background: theme.palette.primary.main,
    opacity: 0,
    transition: 'opacity .3s ease-out',
  },

  '&:hover': {
    background: theme.palette.neutral[100],

    '&:after': {
      opacity: 1,
    },
  },
}));

const MenuTopSection = styled('div')({
  padding: '8px 24px 8px 12px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const UserInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Ref = styled(Link)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
}));

const DividerWrapper = styled('div')({
  padding: '16px 12px',
});

const NoProjectsMessage = styled(Typography)({
  margin: '20px',
});

export enum NavItems {
  dashboard = 'Dashboard',
  projects = 'Projects',
  transactions = 'Transactions',
  events = 'Events',
  nodes = 'Nodes',
}

interface INavItem {
  name: NavItems;
  link: string;
}

const navItems: INavItem[] = [
  {
    name: NavItems.dashboard,
    link: pages.main(),
  },
  {
    name: NavItems.projects,
    link: pages.project(),
  },
  {
    name: NavItems.transactions,
    link: pages.transactions(),
  },
  {
    name: NavItems.events,
    link: pages.eventManager(),
  },
  // {
  //   name: NavItems.nodes,
  //   link: "/",
  // },
];

interface DrawerItem {
  name: string;
  link?: string;
  href?: string;
  icon?: React.ReactNode;
  target?: string;
}

const drawerNavItems: DrawerItem[] = [
  {
    name: NavItems.dashboard,
    link: pages.main(),
  },
  {
    name: NavItems.transactions,
    link: pages.transactions(),
  },
  {
    name: 'Documentation',
    href: 'https://docs.gomaestro.org/',
    target: '_blank',
  },
];

const DrawerItems = () => {
  const location = useLocation();

  return (
    <DrawerItemsWrapper>
      {drawerNavItems.map((ref) =>
        ref.link ? (
          <Link to={ref.link} key={ref.name}>
            <NavbarRef
              color="textColor.dark"
              variant="h4"
              active={location.pathname === ref.link}
            >
              <ListItem>
                <ListItemButton>
                  {ref.icon}
                  {ref.name}
                </ListItemButton>
              </ListItem>
            </NavbarRef>
          </Link>
        ) : (
          <a key={ref.name} href={ref.href} target={ref.target}>
            <NavbarRef
              color="textColor.dark"
              variant="h4"
              active={location.pathname === ref.href}
            >
              <ListItem>
                <ListItemButton>
                  {ref.icon}
                  {ref.name}
                </ListItemButton>
              </ListItem>
            </NavbarRef>
          </a>
        )
      )}
    </DrawerItemsWrapper>
  );
};

const DrawerItemsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '232px',
});

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  justifyContent: 'center',
  padding: '8px 10px',
  borderRadius: theme.borderRadius.ms,
  columnGap: '16px',

  '& svg': {
    width: '32px',
    height: '32px',
  },
}));

export default MainNavigation;
