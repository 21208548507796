import { ArtistIcon, ConductorIcon, VirtuosoIcon } from '../components/Icons';
import { Package, TiersProps } from '../redux/reducers/stripeReducer';
import { IUser } from '../redux/reducers/usersReducer';
import { Project } from '../types/project';
import { SubscriptionPlan } from '../types/subscription';
import { Webhook } from '../types/webhooks';
import { roundToNDecimalPlaces } from './roundToNDecimalPlaces';

import { enterpriseMocks } from '../mocks/enterprise';

const defaultPackage = {
  id: '',
  name: SubscriptionPlan.artist,
  description: '',
  credits_per_day: 0,
  credits_per_month: 0,
  project_limit: 0,
  webhook_limit: 0,
  throughput_limit: '',
  features: [],
};

export const getCurrentPlan = (
  oldSubscriptions: Package[],
  newSubscriptions: Package[],
  user: IUser
): Package => {
  const currentOldSubscription = oldSubscriptions.find(
    (item) => item.id === user.package_id
  );
  const currentNewSubscription = newSubscriptions.find(
    (item) => item.id === user.package_id
  );

  if (currentOldSubscription) return currentOldSubscription;

  if (currentNewSubscription) return currentNewSubscription;

  const oldArtist = oldSubscriptions.find(
    (item) => item.name === SubscriptionPlan.artist
  );

  if (
    user.package_id === 'a35441c9-6c78-4114-9c2f-2e9a417b3d54' ||
    user.tier_id === '22007e67-8ee1-448e-9f14-ddd769845a2f'
  )
    return enterpriseMocks;

  return oldArtist || oldSubscriptions[0] || defaultPackage;
};

export const getIsVirtuosoAnnual = (
  newTiers: TiersProps,
  currentSubscription: Package,
  user: IUser
) => {
  if (currentSubscription.name === SubscriptionPlan.enterprise) return false;
  if (currentSubscription.name !== SubscriptionPlan.virtuoso) return true;

  const annualTier = newTiers.annual_tiers.find(
    ({ id }) => id === user.tier_id
  );

  return !!annualTier;
};

export const formatNumberWithCommas = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getWordPlural = (num: number, str: string): string => {
  if (Math.abs(num) !== 1) {
    return `${str}s`;
  }
  return str;
};

export const getTooltipText = (
  subscriptionPlan: SubscriptionPlan,
  creditsPerDay: number,
  creditsPerMonth: number
) => {
  switch (subscriptionPlan) {
    case SubscriptionPlan.artist:
      return `Plan is capped at ${formatNumberWithCommas(
        creditsPerDay
      )} credits/day (~${
        creditsPerMonth / MILLION
      }/mo) and renews daily. Unused credits are not rolled over.`;
    case SubscriptionPlan.conductor:
      return 'Commit to a minimum amount of credits per month and unlock incrementally higher volume discounts. Any credits consumed above that is pay-as-you-go.';
    case SubscriptionPlan.virtuoso:
      return 'Benefit from specialized discounts tailored to your needs and scale your usage cost-effectively.';
  }
};

export const getPlanDescription = (
  subscriptionPlan: SubscriptionPlan
): string => {
  switch (subscriptionPlan) {
    case SubscriptionPlan.artist:
      return 'Products:';
    default:
      return 'Everything in ARTIST plus:';
  }
};

export const getPlanIcon = {
  [SubscriptionPlan.artist]: <ArtistIcon />,
  [SubscriptionPlan.conductor]: <ConductorIcon />,
  [SubscriptionPlan.virtuoso]: <VirtuosoIcon />,
  [SubscriptionPlan.enterprise]: <VirtuosoIcon />,
};

interface ReturnType {
  price: string;
  discount: string;
}

const defaultReturnType = {
  price: '',
  discount: '',
};

export const getSubscriptionPrice = (
  name: SubscriptionPlan,
  isChecked: boolean,
  subscriptionTiers?: TiersProps
): ReturnType => {
  if (
    !subscriptionTiers?.monthly_tiers.length ||
    !subscriptionTiers?.annual_tiers.length
  )
    return defaultReturnType;

  switch (name) {
    case SubscriptionPlan.artist:
      return {
        price: 'Free',
        discount: '',
      };

    case SubscriptionPlan.conductor: {
      const price = subscriptionTiers.monthly_tiers[0].price_per_month;
      return {
        price: `$${price}/mo`,
        discount: '',
      };
    }

    case SubscriptionPlan.virtuoso: {
      const annualPrice = subscriptionTiers.annual_tiers[0].price_per_month;
      const monthlyPrice = subscriptionTiers.monthly_tiers[1].price_per_month;

      if (isChecked) {
        return {
          price: `$${annualPrice}/mo`,
          discount: `$${monthlyPrice}`,
        };
      }

      return {
        price: `$${monthlyPrice}/mo`,
        discount: '',
      };
    }

    default:
      return defaultReturnType;
  }
};

export const gitDiscountPercentage = ({ price, discount }: ReturnType) => {
  const priceNumber = parseInt(price.slice(1));
  const discountNumber = parseInt(discount.slice(1));
  const difference = discountNumber - priceNumber;

  return Math.round((difference / discountNumber) * 100);
};

export const formActiveSubscriptionName = (
  id: string,
  name: SubscriptionPlan,
  subscriptionPlans: Package[]
): string => {
  const activeSubscription = subscriptionPlans.find((plan) => plan.id === id);
  if (name === SubscriptionPlan.enterprise) return name;
  if (!activeSubscription) return ` ${name} (legacy)`;

  return `${activeSubscription.name}`;
};

interface GetSubscriptionOverviewDataProps {
  isAuthorized: boolean;
  subscriptionPlans: Package[];
  oldSubscriptionPlans: Package[];
  oldSubscriptionTiers: TiersProps;
  user: IUser;
  subscriptionTiers: TiersProps;
  currentPlanName: SubscriptionPlan;
}

const defaultReturnValue = {
  price: '',
  credits_per_month: '',
  rate: '',
  plan: SubscriptionPlan.artist,
};

const HUNDRED_THOUSAND = 100000;
const MILLION = 1000000;
const THOUSAND = 1000;

export const getSubscriptionOverviewData = ({
  isAuthorized,
  subscriptionPlans,
  oldSubscriptionPlans,
  oldSubscriptionTiers,
  user,
  subscriptionTiers,
  currentPlanName,
}: GetSubscriptionOverviewDataProps) => {
  if (!isAuthorized) return defaultReturnValue;

  if (currentPlanName === SubscriptionPlan.enterprise) {
    return {
      price: 'Custom',
      credits_per_month: 'Custom',
      rate: 'Custom',
      plan: SubscriptionPlan.enterprise,
    };
  }

  if (currentPlanName === SubscriptionPlan.artist) {
    const planDetails = subscriptionPlans.find(
      ({ name }) => name === SubscriptionPlan.artist
    );

    if (!planDetails) return defaultReturnValue;

    return {
      price: `$0/mo`,
      credits_per_month: `${planDetails.credits_per_month / MILLION}M/mo`,
      rate: `N/A`,
      plan: SubscriptionPlan.artist,
    };
  }

  const currentOldSubscriptionPlan = oldSubscriptionPlans.find(
    ({ id }) => id === user.package_id
  );
  const currentNewSubscriptionPlan = subscriptionPlans.find(
    ({ id }) => id === user.package_id
  );

  if (currentOldSubscriptionPlan) {
    if (currentOldSubscriptionPlan.name === SubscriptionPlan.conductor) {
      const currentOldMonthlyTier = oldSubscriptionTiers.monthly_tiers.find(
        ({ id }) => id === user.tier_id
      );
      const currentOldAnnualTier = oldSubscriptionTiers.annual_tiers.find(
        ({ id }) => id === user.tier_id
      );

      const currentOldTier = currentOldMonthlyTier || currentOldAnnualTier;

      if (!currentOldTier) return defaultReturnValue;

      return {
        price: `$${currentOldTier.price_per_month}/mo`,
        credits_per_month: `${currentOldTier.credits_per_month / MILLION}M/mo`,
        rate: `$${roundToNDecimalPlaces(
          currentOldTier.price_per_credit * THOUSAND,
          4
        )}/1k`,
        plan: SubscriptionPlan.conductor,
      };
    }

    if (currentOldSubscriptionPlan.name === SubscriptionPlan.virtuoso) {
      return {
        price: `Custom`,
        credits_per_month: `Custom`,
        rate: `N/A`,
        plan: SubscriptionPlan.virtuoso,
      };
    }
  }

  if (currentNewSubscriptionPlan) {
    if (currentNewSubscriptionPlan.name === SubscriptionPlan.conductor) {
      const currentNewMonthlyTier = subscriptionTiers.monthly_tiers.find(
        ({ id }) => id === user.tier_id
      );

      if (!currentNewMonthlyTier) return defaultReturnValue;

      return {
        price: `$${currentNewMonthlyTier.price_per_month}/mo`,
        credits_per_month: `${
          currentNewMonthlyTier.credits_per_month / MILLION
        }M/mo`,
        rate: `$${roundToNDecimalPlaces(
          currentNewMonthlyTier.price_per_credit * THOUSAND,
          4
        )}/1k`,
        plan: SubscriptionPlan.conductor,
      };
    }

    if (currentNewSubscriptionPlan.name === SubscriptionPlan.virtuoso) {
      const currentNewMonthlyTier = subscriptionTiers.monthly_tiers.find(
        ({ id }) => id === user.tier_id
      );
      const currentNewAnnualTier = subscriptionTiers.annual_tiers.find(
        ({ id }) => id === user.tier_id
      );

      const currentNewTier = currentNewMonthlyTier || currentNewAnnualTier;

      if (!currentNewTier) return defaultReturnValue;

      return {
        price: `$${currentNewTier.price_per_month}/mo`,
        credits_per_month: `${currentNewTier.credits_per_month / MILLION}M/mo`,
        rate: `$${roundToNDecimalPlaces(
          currentNewTier.price_per_credit * THOUSAND,
          4
        )}/1k`,
        plan: SubscriptionPlan.virtuoso,
      };
    }
  }

  return defaultReturnValue;
};

export const formatCreditsPerDay = (credits?: number) =>
  `${Math.round((credits || 0) / 1000)}k/day`;

export const getSelectedTier = (
  tiers: TiersProps,
  isVirtuosoAnnual: boolean,
  selectedPlan?: Package
) => {
  if (!selectedPlan) return undefined;

  const monthlyTier = tiers.monthly_tiers.find(
    ({ package_id }) => package_id === selectedPlan.id
  );
  const annualTier = tiers.annual_tiers.find(
    ({ package_id }) => package_id === selectedPlan.id
  );

  if (monthlyTier && annualTier)
    return isVirtuosoAnnual ? annualTier : monthlyTier;

  return monthlyTier || annualTier || undefined;
};

export const formatCreditsPerMonth = (credits?: number) =>
  `${Math.round((credits || 0) / MILLION)}M/mo`;

export const getPaymentError = (
  allProjects: Project[],
  allWebhooks: Webhook[],
  projectsLimit?: number,
  webhooksLimit?: number,
  selectedPlan?: SubscriptionPlan
) => {
  if (
    projectsLimit &&
    selectedPlan === SubscriptionPlan.artist &&
    projectsLimit < allProjects.length
  ) {
    return {
      title: 'Only 1 project allowed in Artist plan',
      subtitle:
        'You have more than 1 project in your dashboard. To downgrade to Artist delete the other projects and try again.',
    };
  }

  if (
    webhooksLimit &&
    selectedPlan === SubscriptionPlan.artist &&
    webhooksLimit < allWebhooks.length
  ) {
    return {
      title: 'Only 1 webhook allowed in Artist plan',
      subtitle:
        'You have more than 1 webhook in your dashboard. To downgrade to Artist delete the other webhooks and try again.',
    };
  }

  if (projectsLimit && projectsLimit < allProjects.length) {
    return {
      title: 'User has too many projects',
      subtitle: 'Please check your payment and billing and try again.',
    };
  }

  if (webhooksLimit && webhooksLimit < allWebhooks.length) {
    return {
      title: 'User has too many webhooks',
      subtitle: 'Please check your payment and billing and try again.',
    };
  }

  return {
    title: 'Something went wrong :(',
    subtitle: 'Please try again later or contact support.',
  };
};
