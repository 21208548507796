import * as Yup from 'yup';
import { CreateTeamFields } from './types';
// prettier-ignore
export const createTeamSchema = Yup.object().shape({
    [CreateTeamFields.teamName]: Yup.string()
        .max(32, "Team name must be 32 characters or less")
        .required("Team name is required"),
    // [CreateTeamFields.emailAddress]: Yup.array()
    //     .of(
    //         Yup.string()
    //             .test('is-not-empty', 'Email address is required', value => value !== undefined && value.trim() !== "")
    //     )
    //     .min(1, 'At least one valid Email address is required')
    //     .required('Email address is required'),
    // [CreateTeamFields.role]: Yup.array()
    //     .of(
    //         Yup.array()
    //             .test('is-not-empty', 'Role is required', value => value !== undefined && value.toString() !== "")
    //             .min(1, "")
    //             .min(1, "Role is required")
    //     )
    //     .min(1, "Role is required")
    //     .required('Role is required'),
});
