export const pages = {
  main: () => '/',
  login: () => '/login',
  signup: () => '/signup',
  resetPassword: () => '/reset-password',
  authenticate: () => '/authenticate',
  settings: () => '/settings/subscription/plans',
  transactions: () => '/transactions',
  account: () => '/settings/account',
  credentials: () => '/settings/account/credentials',
  project: () => '/project/:id',
  subscriptionPlan: () => '/settings/subscription/overview',
  autoRenewal: () => '/settings/subscription/reneval',
  addNewPayment: () => '/settings/subscription/overview/add-new-payment',
  billingInformation: () =>
    '/settings/subscription/overview/billing-information',
  billing: () => '/settings/subscription/billing',
  team: () => '/settings/team',
  eventManager: () => '/event-manager',
};
