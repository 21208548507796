import { styled, Typography } from '@mui/material';
import { PlusIcon } from '../../../Icons';
import Tooltip from '../../../Tooltip/Tooltip';

interface AddTriggerButtonProps {
  onAdd: () => void;
  canAdd: boolean;
  tooltipText: string;
}

const AddTriggerButton = ({
  onAdd,
  canAdd,
  tooltipText,
}: AddTriggerButtonProps) => {
  return (
    <ButtonWrapper>
      <Line />
      <StyledTooltip
        title={
          <Typography variant="paragraphSmall" color="grey.A200">
            {tooltipText}
          </Typography>
        }
        followCursor
        disabled={canAdd}
      >
        <AddButton onClick={onAdd} disabled={!canAdd}>
          <Circle>
            <PlusIcon />
          </Circle>
          <ButtonText>Add Trigger conditions</ButtonText>
        </AddButton>
      </StyledTooltip>
      <Line />
    </ButtonWrapper>
  );
};

const AddButton = styled('div')<{ disabled: boolean }>(({ disabled }) => ({
  background: '#FFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '15px',
  cursor: 'pointer',
  opacity: disabled ? 0.5 : 1,
}));

const ButtonText = styled(Typography)({
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  color: '#C53DD8',
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '10px 0px',
});

const Line = styled('span')({
  display: 'flex',
  height: '1px',
  background: '#D9D9D9',
  width: '25%',
});

const Circle = styled('div')({
  display: 'flex',
  padding: '7px',
  borderRadius: '50%',
  background: '#C53DD8',
});

const StyledTooltip = styled(Tooltip)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    '& .MuiTooltip-tooltip': {
      ...(disabled && { display: 'none' }),
      maxWidth: '236px',
      padding: '16px',
      boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '6px',
    },
  })
);

export default AddTriggerButton;
