import { Typography, styled } from '@mui/material';

interface DialogHeaderProps {
  title: string;
}

const DialogHeader = ({ title }: DialogHeaderProps) => {
  return (
    <Header>
      <Typography color="grey.A200" variant="h5">
        {title}
      </Typography>
    </Header>
  );
};

const Header = styled('div')({
  marginBottom: '12px',
});

export default DialogHeader;
