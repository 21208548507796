import { styled } from '@mui/material';
import { NewEntryField } from '../../../../types/newEntry';
import { Option } from '../../../../types/newEntry';
import { getCreateProjectFields } from '../../../../forms/createProject/form';

interface ProjectFormFieldsProps {
  blockchainOptions: Option[];
  networkOptions: Option[];
  getField: (field: NewEntryField) => any;
}

const ProjectFormFields = ({
  blockchainOptions,
  networkOptions,
  getField,
}: ProjectFormFieldsProps) => {
  return (
    <FormFields>
      {getCreateProjectFields({ blockchainOptions, networkOptions }).map(
        (field: NewEntryField) => getField(field)[field.type]
      )}
    </FormFields>
  );
};

const FormFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
});

export default ProjectFormFields;
