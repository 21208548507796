import { Theme, styled, useTheme } from '@mui/material';
import { Dropdown, TextField } from '@maestro-org/ui-kit';
import { NewEntryField, NewEntryFieldTypes } from '../../../../types/newEntry';
import { CreateTransactionFields } from '../../../../forms/createTransaction/types';

interface WebhookFormFieldsProps {
  fields: NewEntryField[];
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleDropdownChange: (value: unknown, name: string) => void;
  handleDropdownBlur: (name: string) => void;
  checkError: (name: string) => boolean;
}

const WebhookFormFields = ({
  fields,
  values,
  handleChange,
  handleBlur,
  handleDropdownChange,
  handleDropdownBlur,
  checkError,
}: WebhookFormFieldsProps) => {
  const theme = useTheme();

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || '';
    const isWebhookNameTooLong =
      field.name === CreateTransactionFields.webhookName &&
      fieldValue.length >= 32;
    const isUrlTooLong =
      field.name === CreateTransactionFields.url && fieldValue.length >= 100;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextField
          variant="outlined"
          name={field.name}
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          error={checkError(field.name)}
          fieldLabel={field.fieldLabel}
          inputProps={
            field.name === CreateTransactionFields.webhookName
              ? { maxLength: 32 }
              : { maxLength: 100 }
          }
          isMaxReached={isUrlTooLong || isWebhookNameTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={''}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          fieldLabel={field.fieldLabel}
          error={checkError(field.name) as boolean}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name) as boolean),
              },
            },
          }}
        />
      ),
    };
  };

  return (
    <FormFields>
      {fields.map((field: NewEntryField) => getField(field)[field.type])}
    </FormFields>
  );
};

const FormFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
});

const StyledTextField = styled(TextField)<{ isMaxReached: boolean }>(
  ({ isMaxReached }) => ({
    '& .MuiFormLabel-root.Mui-error': {
      color: '#DC6675 !important',
    },
    ...(isMaxReached && {
      '& fieldset': {
        borderColor: '#DC6675 !important',
      },

      '& .Mui-focused fieldset': {
        borderColor: '#DC6675 !important',
      },
    }),
  })
);

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px !important',
    borderWidth: '1px !important',
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : 'auto',
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: 'none',
  border: `2px solid ${
    error
      ? theme.palette.dropdown.border.error
      : theme.palette.dropdown.border.main
  }`,
  transform: 'translateY(5px)',
});

export default WebhookFormFields;
