import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Button, TextField } from '@maestro-org/ui-kit';
import { CircularProgress, styled, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Auth0Error } from 'auth0-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import {
  getUserCredentialsFields,
  initialValuesUserCredentials,
} from '../../../forms/userCredentials/form';
import {
  UserCredentialsFields,
  UserCredentialsFormValues,
} from '../../../forms/userCredentials/types';
import { userCredentialsSchema } from '../../../forms/userCredentials/validation';

import {
  getAuth0FuncData,
  getAuthData,
  getAuthLoading,
} from '../../../redux/reducers/authReducer';
import { updateIsLoadingAuth } from '../../../redux/actions/authActions';

import { LoginType } from '../../../types/auth';

import SettingsCard from './SettingsCard';

import { auth } from '../../../services/auth0.service';
import Tooltip from '../../../components/Tooltip/Tooltip';

const UserCredentials = () => {
  const navigate = useNavigate();

  const isLoading = useSelector(getAuthLoading);
  const userData = useSelector(getAuthData);
  const auth0Data = useSelector(getAuth0FuncData);

  const dispatch = useDispatch();

  const onSubmit = (values: UserCredentialsFormValues) => {
    auth.changePassword(
      {
        connection: `${process.env.REACT_APP_AUTH0_REALM}`,
        email: values.email,
      },
      function (error: Auth0Error | null, result: any | null) {
        if (error) {
          return;
        }
        toast.success('Password reset email sent!');
        navigate('/login');
      }
    );
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValuesUserCredentials,
    validationSchema: userCredentialsSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const checkError = (name: string) =>
    !!errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];

  useEffect(() => {
    dispatch(updateIsLoadingAuth({ status: false }));
  }, []);

  useEffect(() => {
    if (userData.email)
      setFieldValue(UserCredentialsFields.email, userData.email);
  }, [userData]);

  return (
    <form onSubmit={handleSubmit}>
      <SettingsCard>
        <Typography color="grey.A200" variant="h6">
          Reset password
        </Typography>
        <Typography color="grey.A200" variant="paragraphMedium">
          Please enter the email address associated with your account to change
          your password.
        </Typography>
        {getUserCredentialsFields.map((field) => (
          <StyledTextField
            key={field.name}
            name={field.name}
            type={field.type}
            variant="outlined"
            value={values[field.name]}
            onChange={handleChange}
            onBlur={handleBlur}
            errorPosition={'bottom'}
            error={checkError(field.name)}
            disabled={field.name === UserCredentialsFields.email}
            fieldLabel={field.fieldLabel}
          />
        ))}
        <div>
          {auth0Data.loginType === LoginType.Regular ? (
            <Button
              type="submit"
              onMouseDown={(e) => e.preventDefault()}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Typography>Submit</Typography>
              )}
            </Button>
          ) : (
            <TooltipWrapper>
              <StyledTooltip
                title={
                  <Typography variant="article" color="grey.800">
                    You cannot reset your password while authorized with
                    Google/GitHub, go to your native account settings
                  </Typography>
                }
                followCursor
              >
                <div>
                  <Button
                    type="submit"
                    onMouseDown={(e) => e.preventDefault()}
                    disabled
                  >
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Typography>Submit</Typography>
                    )}
                  </Button>
                </div>
              </StyledTooltip>
            </TooltipWrapper>
          )}
        </div>
      </SettingsCard>
    </form>
  );
};

const TooltipWrapper = styled('div')({
  display: 'flex',
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '336px',
    padding: '8px 16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette.textfield.border.main} !important`,
    borderRadius: '4px',

    '& .MuiFormLabel-root.Mui-error': {
      color: '#DC6675 !important',
    },
  },

  '& .MuiOutlinedInput-root': {
    padding: '12px 16px !important',
  },
}));

export default UserCredentials;
