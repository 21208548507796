import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { styled, Typography } from '@mui/material';

import { getWebhooksServer } from '../../redux/actions/webhooksActions';

import MainNavigation, {
  NavItems,
} from '../../components/Navigation/MainNavigation';
import Footer from '../Home/components/Footer';
import TransactionEventsListening from './components/TransactionEventsListening';
import TransactionMonitoring from './components/TransactionMonitoring';
import { DocumentationIcon } from '../../components/Icons';

import { getAuthData } from '../../redux/reducers/authReducer';
import withPrivateRoute from '../../hooks/withPrivateRoute';

const TransactionsPage = () => {
  const userData = useSelector(getAuthData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(userData).length) return;
    dispatch(getWebhooksServer({ project_ids: [] }));
  }, []);

  return (
    <Wrapper>
      <MainNavigation
        activeItem={NavItems.transactions}
        actions={<TransactionPageNavActions />}
      />
      <TransactionMonitoring />
      <TransactionEventsListening />
      <Footer />
      <Bg />
    </Wrapper>
  );
};

const Bg = styled('div')({
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  background: '#F5F5F5',
  zIndex: '-1',
});

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#F5F5F5',
});

const TransactionPageNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

export default withPrivateRoute(TransactionsPage);
