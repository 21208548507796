import React, { useEffect, useState } from 'react';
import { styled, Theme, Typography, useTheme } from '@mui/material';
import { Button, Dropdown, Loader, TextField } from '@maestro-org/ui-kit';
import { FormikProps } from 'formik';

import { CreateProjectFormValues } from '../../forms/createProject/types';
import { CreateTransactionFormValues } from '../../forms/createTransaction/types';
import { NewEntryField, NewEntryFieldTypes } from '../../types/newEntry';

interface Props {
  fields: NewEntryField[];
  openPanel: boolean;
  submitText: string;
  submitLoading?: boolean;
  togglePanel: () => void;
}

type FormikType =
  | FormikProps<CreateProjectFormValues>
  | FormikProps<CreateTransactionFormValues>;

const NewEntryPanel = ({
  openPanel,
  submitText,
  togglePanel,
  fields,
  submitLoading,
  ...props
}: Props & FormikType) => {
  const theme = useTheme();
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  const handleDropdownChange = (value: unknown, name: string) => {
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const handleClick = (event: KeyboardEvent) => {
    if (event.code === 'Escape' && openPanel) {
      togglePanel();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleClick);

    return () => {
      window.removeEventListener('keydown', handleClick);
    };
  });

  const checkError = (name: string) =>
    !!errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];

  const getHelperText = (name: string) =>
    errors[name as keyof typeof errors] && touched[name as keyof typeof touched]
      ? errors[name as keyof typeof errors]
      : undefined;

  const getField = (field: NewEntryField) => ({
    [NewEntryFieldTypes.text]: (
      <TextfieldWrapper>
        <TextField
          label={field.label}
          variant="outlined"
          name={field.name}
          value={values[field.name as keyof typeof values]}
          onChange={handleChange}
          placeholder={field.placeholder}
          onBlur={handleBlur}
          error={checkError(field.name)}
          helperText={getHelperText(field.name)}
        />
      </TextfieldWrapper>
    ),
    [NewEntryFieldTypes.select]: (
      <DropdownWrapper>
        <StyledSelect
          label={field.label}
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={field.placeholder}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          error={checkError(field.name)}
          helperText={getHelperText(field.name) || ('' as string)}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name)),
              },
            },
          }}
        />
      </DropdownWrapper>
    ),
  });

  return (
    <Wrapper onSubmit={handleSubmit}>
      <PanelFieldsWrapper>
        {fields.map((field) => getField(field)[field.type])}
      </PanelFieldsWrapper>
      <ActionsWrapper>
        <Actions>
          <CancelButton
            variant="secondary"
            onClick={togglePanel}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Typography color="textColor.dark">Cancel</Typography>
          </CancelButton>
          <Button type="submit" onMouseDown={(e) => e.preventDefault()}>
            {submitLoading ? (
              <Loader color="secondary" size={24} />
            ) : (
              submitText
            )}
          </Button>
        </Actions>
      </ActionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('form')(({ theme }) => ({
  marginTop: '40px',
  display: 'flex',
  padding: '24px',
  alignItems: 'flex-end',
  background: theme.palette.common.white,
}));

const PanelFieldsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '36px',
  alignItems: 'center',

  [theme.breakpoints.down('xl')]: {
    columnGap: '12px',
  },

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const TextfieldWrapper = styled('div')(({ theme }) => ({
  width: '340px',
  height: '101px',
  maxWidth: '100%',

  [theme.breakpoints.down('xl')]: {
    width: '280px',
  },
  [theme.breakpoints.down('lg')]: {
    // width: "200px",
  },
}));

const DropdownWrapper = styled('div')(({ theme }) => ({
  width: '340px',
  maxWidth: '100%',
  height: '101px',

  [theme.breakpoints.down('xl')]: {
    width: '280px',
  },
  [theme.breakpoints.down('lg')]: {
    // width: "200px",
  },
}));

const ActionsWrapper = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
});

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '16px',
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey['50'],

  '&:hover': {
    background: theme.palette.grey['50'],
  },
}));

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.common.white,
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : 'auto',
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: 'none',
  border: `2px solid ${
    error
      ? theme.palette.dropdown.border.error
      : theme.palette.dropdown.border.main
  }`,
  transform: 'translateY(5px)',
});

export default NewEntryPanel;
