import { ReactNode } from 'react';
import { styled } from '@mui/material';

interface FormFieldsProps {
  children: ReactNode;
  equalWidth?: boolean;
}

const FormFields = ({ children, equalWidth }: FormFieldsProps) => {
  return (
    <StyledFormFields equalWidth={equalWidth}>{children}</StyledFormFields>
  );
};

const StyledFormFields = styled('div')<{ equalWidth?: boolean }>(
  ({ equalWidth }) => ({
    display: 'flex',
    width: '100%',
    gap: '20px',
    position: 'relative',

    '& > div': {
      width: equalWidth ? 'calc(33% - 40px / 3)' : '100%',
      maxWidth: equalWidth ? 'calc(33% - 40px / 3)' : '100%',
    },
  })
);

export default FormFields;
