import ConfirmationDialog from '../common/ConfirmationDialog/ConfirmationDialog';
import { useAddTeamMember } from './hooks/useAddTeamMember';

const AddTeamMember = () => {
  const { isOpen, email, handleClose, handleAdd } = useAddTeamMember();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={`Add ${email}`}
      description="Are you sure you want to add this user?"
      onConfirm={handleAdd}
      onCancel={handleClose}
      confirmLabel="Add"
    />
  );
};

export default AddTeamMember;
