import Dialog from '../common/Dialog';
import DialogHeader from '../common/DialogHeader';
import DialogActions from '../common/DialogActions';
import ResetKeyDescription from './components/ResetKeyDescription';
import { useResetKey } from './hooks/useResetKey';

const ResetKey = () => {
  const { isOpen, projectName, handleClose, handleResetClick } = useResetKey();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogHeader
        title={`Are you sure you want to reset you API key for project ${projectName}?`}
      />

      <ResetKeyDescription onClose={handleClose} />

      <DialogActions
        onCancel={handleClose}
        onSubmit={handleResetClick}
        submitLabel="Reset"
      />
    </Dialog>
  );
};

export default ResetKey;
