import { Button } from '@maestro-org/ui-kit';
import { Typography, styled } from '@mui/material';

interface Props {
  isMobileCheckoutDisabled: boolean;
  handleNextStep: () => void;
}

const PlansMobileCheckout = ({
  handleNextStep,
  isMobileCheckoutDisabled,
}: Props) => {
  return (
    <OuterWrapper>
      <Fade />
      <Wrapper>
        <Typography variant="h6" color="grey.A200">
          Order summary
        </Typography>
        {isMobileCheckoutDisabled && (
          <Typography
            color="grey.A200"
            variant="paragraphSmall"
            margin="-10px 0"
          >
            Select a plan...
          </Typography>
        )}
        <Button onClick={handleNextStep} disabled={isMobileCheckoutDisabled}>
          Continue to order review
        </Button>
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled('div')({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
});

const Fade = styled('div')({
  width: '100%',
  height: '80px',
  background:
    'linear-gradient(0deg, #FFFFFF 0%, rgba(245, 245, 245, 0) 73.51%)',
});

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
  padding: '24px',
  background: theme.palette.common.white,

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export default PlansMobileCheckout;
