export const SortTableIcon = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 5.5L5 0.5L10 5.5H0Z" fill="#808080" />
    <path d="M0 8.5L5 13.5L10 8.5H0Z" fill="#808080" />
  </svg>
);
