import { Button } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { SettingsPages } from '../../types/settings';
import { StripeItem } from '../../types/auth';

import {
  getActiveMethodId,
  getAllPaymentMethods,
  getBillingDataLoading,
} from '../../redux/reducers/stripeReducer';

import SettingsLayout from '../../layouts/SettingsLayout';

import PaymentDetails from './components/PaymentDetails';
import { BackIcon } from '../../components/Icons';

import { pages } from '../../lib/routeUtils';
import AddFirstPaymentMethod from './components/AddFirstPaymentMethod';
import withPrivateRoute from '../../hooks/withPrivateRoute';

const AddNewPaymentPage = () => {
  const [paymentMethod, setPaymentMethod] = useState<StripeItem>();

  const isLoading = useSelector(getBillingDataLoading);
  const paymentMethods = useSelector(getAllPaymentMethods);
  const activePaymentMethodId = useSelector(getActiveMethodId);

  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentMethods.length) return;
    const data = paymentMethods.find(({ id }) => id === activePaymentMethodId);
    if (!data) return;
    setPaymentMethod(data);
  }, [activePaymentMethodId]);

  useEffect(() => {
    if (paymentMethods.length > 3) navigate(pages.subscriptionPlan());
  }, [paymentMethods]);

  return (
    <SettingsLayout
      path={[
        { title: 'Settings', href: '/' },
        { title: 'Subscription', href: '/' },
        { title: 'Overview', href: '/' },
        { title: 'Add new payment', href: '/' },
      ]}
      active={SettingsPages.overview}
    >
      <Wrapper>
        <Link to={pages.subscriptionPlan()}>
          <StyledButton startIcon={<BackIcon />}>
            <Typography color="grey.A200" variant="paragraphMedium">
              Back
            </Typography>
          </StyledButton>
        </Link>
        {paymentMethods.length ? (
          <PaymentDetails
            isLoading={isLoading}
            billingData={paymentMethod?.billingInfo}
          />
        ) : (
          <AddFirstPaymentMethod isLoading={isLoading} />
        )}
      </Wrapper>
    </SettingsLayout>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',

  '@media (max-height: 1000px)': {
    rowGap: '28px',
  },

  [theme.breakpoints.down('xl')]: {
    rowGap: '25px',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '5px 10px',
  background: 'transparent',
  color: theme.palette.grey.A200,

  '&:hover': {
    background: 'transparent',
    color: theme.palette.grey.A200,
  },
}));

export default withPrivateRoute(AddNewPaymentPage);
