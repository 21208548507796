import { Button } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { InfoIcon } from '../../../components/Icons';
import { Package } from '../../../redux/reducers/stripeReducer';
import { getAllprojects } from '../../../redux/reducers/projectsReducer';
import { getAllWebhooks } from '../../../redux/reducers/webhooksReducer';

import { getPaymentError } from '../../../lib/subscriptionPlanUtils';

interface Props {
  handleBackClick: () => void;
  selectedPlan?: Package;
}

const PaymentFail = ({ handleBackClick, selectedPlan }: Props) => {
  const allProjects = useSelector(getAllprojects);
  const allWebhooks = useSelector(getAllWebhooks);

  const projectsLimit = selectedPlan?.project_limit;
  const webhooksLimit = selectedPlan?.webhook_limit;

  const error = getPaymentError(
    allProjects,
    allWebhooks,
    projectsLimit,
    webhooksLimit,
    selectedPlan?.name
  );

  return (
    <Wrapper>
      <Top>
        <TitleWrapper>
          <InfoIcon />
          <Typography variant="h5" color="grey.A200">
            {error.title}
          </Typography>
        </TitleWrapper>
        <Typography color="grey.A200" variant="paragraphSmall">
          {error.subtitle}
        </Typography>
      </Top>
      <StyledButton onClick={handleBackClick}>Go back</StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',
});

const Top = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
});

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',

  '& svg': {
    width: '21px',
    height: '21px',
    flexShrink: 0,
  },

  '& path': {
    stroke: theme.palette.error.main,
  },
}));

const StyledButton = styled(Button)({
  width: '100%',
});

export default PaymentFail;
