import React from 'react';
import { styled, Switch as MuiSwitch, SwitchProps } from '@mui/material';

interface Props {
  checked: boolean;
  isDisabled?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch = ({
  checked,
  handleChange,
  isDisabled,
  ...props
}: Props & SwitchProps) => {
  return (
    <StyledSwitch
      checked={checked}
      onChange={handleChange}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

const StyledSwitch = styled(MuiSwitch)<{ isDisabled?: boolean }>(
  ({ isDisabled, theme }) => ({
    ...(isDisabled && {
      '& span': {
        cursor: 'default !important',
      },
    }),
    width: 38,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      width: 22,
      height: 22,
      padding: 0,
      top: 1,
      left: 1,
      transitionDuration: '300ms',
      border: `2px solid ${theme.palette.grey[200]}`,
      '&.Mui-checked': {
        transform: 'translateX(14px)',
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary.main,
          border: `2px solid ${theme.palette.primary.main}`,
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: `6px solid ${theme.palette.common.white}`,
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
      },
      '&.Mui-disabled + .MuiSwitch-track': {},
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
      minWidth: 20,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[200],
      border: `2px solid ${theme.palette.grey[200]}`,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'], {
        duration: 500,
      }),
    },
  })
);

export default Switch;
