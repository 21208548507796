export const WhiteLogoIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4996 9.29949C16.4996 13.945 14.6545 18.401 11.3692 21.6856L6.88232 26.1726L4.43456 23.7248L11.8326 16.3265C11.953 16.2061 11.8679 15.9993 11.6971 15.9993H2.5V12.5373H12.7559C12.9116 12.5373 13.0378 12.4112 13.0378 12.2555V2H16.4996V9.29949ZM30.4993 16H21.2294C21.0859 16 21.0139 15.8263 21.1155 15.7254L28.5669 8.2738L26.1191 5.82598L21.6308 10.3144C18.3455 13.599 16.5004 18.055 16.5004 22.7005V30H19.9622V19.7438C19.9622 19.5881 20.0884 19.462 20.2441 19.462H30.5V16H30.4993Z"
      fill="white"
    />
  </svg>
);
