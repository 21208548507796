import { useSelector } from 'react-redux';
import { Button } from '@maestro-org/ui-kit';

import { getAddNewProjectDialog } from '../../../redux/reducers/popupsReducer';

import Dialog from '../common/Dialog';
import DialogForm from '../common/DialogForm';
import DialogHeader from '../common/DialogHeader';
import DialogActions from '../common/DialogActions';
import ProjectFormFields from './components/ProjectFormFields';
import { useAddNewProject } from './hooks/useAddNewProject';

const AddNewProject = () => {
  const isOpen = useSelector(getAddNewProjectDialog);

  const {
    values,
    isValid,
    dirty,
    getField,
    handleSubmit,
    blockchainOptions,
    networkOptions,
    handleClose,
  } = useAddNewProject();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogForm onSubmit={handleSubmit}>
        <DialogHeader title="Add new project" />

        <ProjectFormFields
          blockchainOptions={blockchainOptions}
          networkOptions={networkOptions}
          getField={getField}
        />

        <DialogActions
          onCancel={handleClose}
          submitDisabled={!isValid || !dirty || !values.network[0]}
          submitLabel="Save project"
        />
      </DialogForm>
    </Dialog>
  );
};

export default AddNewProject;
