import React from 'react';
import { IconButton } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';

import Container from '../../../components/Container/Container';
import Logo, { LogoStyles } from '../../../components/Logo/Logo';
import {
  DiscordIcon,
  TelegramIcon,
  TwitterIcon,
} from '../../../components/Icons';

const socials = [
  {
    href: 'https://twitter.com/GoMaestroOrg',
    icon: <TwitterIcon />,
  },
  {
    href: 'https://t.me/+WsD8BXKBggw5ZmU5',
    icon: <TelegramIcon />,
  },
  {
    href: 'https://discord.com/invite/ES2rDhBJt3',
    icon: <DiscordIcon />,
  },
];

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <LogoWrapper>
          <Logo style={LogoStyles.Grey} />
        </LogoWrapper>
        <Typography color="grey.200" variant="article">
          © {new Date().getFullYear()} Maestro Blockchain Inc.
        </Typography>
        <SocialsWrapper>
          {socials.map((item) => (
            <StyledIconButton key={item.href} href={item.href}>
              {item.icon}
            </StyledIconButton>
          ))}
        </SocialsWrapper>
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  borderTop: `1px solid #E8EAEB`,
  padding: '4px 0 52px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    padding: '12px 0 68px',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    columnGap: '40px',
    rowGap: '12px',
    padding: '20px 0 32px',
  },
}));

const LogoWrapper = styled('div')({});

const SocialsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: 'transparent',
  width: '28px',
  height: '28px',

  '&:hover': {
    background: 'transparent',
  },

  '& svg': {
    fill: theme.palette.grey[200],
  },
}));

export default Footer;
