import { Link } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { pages } from '../../../../lib/routeUtils';

interface ResetKeyDescriptionProps {
  onClose: () => void;
}

const ResetKeyDescription = ({ onClose }: ResetKeyDescriptionProps) => {
  return (
    <Typography color="grey.A200" variant="paragraphMedium">
      Reset your key if it is lost or has been compromised. Beware that the old
      key will become invalid once you generate a new key.{' '}
      <Ref onClick={onClose} to={pages.main()}>
        Learn more
      </Ref>
    </Typography>
  );
};

const Ref = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export default ResetKeyDescription;
