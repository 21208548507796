import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export const parseDate = (newDate: Date): string => {
  const splitted = newDate.toISOString().split('T');
  let [date, time] = splitted;
  time = time.split('.')[0];
  return `${date} ${time}`;
};

const addYears = (date: Date, years: number) => {
  const dateCopy = new Date(date);
  dateCopy.setFullYear(date.getFullYear() + years);
  return dateCopy;
};

const addMonths = (date: Date, months: number) => {
  const dateCopy = new Date(date);
  dateCopy.setMonth(dateCopy.getMonth() + months);
  return dateCopy;
};

const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() + days);
  return dateCopy;
};

const addHours = (date: Date, hours: number) => {
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() + hours);
  return dateCopy;
};

const addMinutes = (date: Date, minutes: number) => {
  const dateCopy = new Date(date);
  dateCopy.setMinutes(date.getMinutes() + minutes);
  return dateCopy;
};

const addSeconds = (date: Date, seconds: number) => {
  const dateCopy = new Date(date);
  dateCopy.setSeconds(date.getSeconds() + seconds);
  return dateCopy;
};

export const timeSince = (from: Date) => {
  const now = new Date();
  let date = new Date(from);

  const years = differenceInYears(now, date);
  date = addYears(date, years);

  const months = differenceInMonths(now, date);
  date = addMonths(date, months);

  const days = differenceInDays(now, date);
  date = addDays(date, days);

  const hours = differenceInHours(now, date);
  date = addHours(date, hours);

  const minutes = differenceInMinutes(now, date);
  date = addMinutes(date, minutes);

  const seconds = differenceInSeconds(now, date);
  date = addSeconds(date, seconds);

  const res = {
    y: years,
    mo: months,
    d: days,
    h: hours,
    m: minutes,
    s: seconds,
  };
  // return Object.keys(res).reduce(
  //   (acc, curr) => acc + (res[curr as keyof typeof res] > 0 ? `${res[curr as keyof typeof res]}${curr} ` : ""),
  //   "",
  // );
  for (const key of Object.keys(res)) {
    if (res[key as keyof typeof res] > 0)
      return `${res[key as keyof typeof res]}${key} `;
  }
  return '0s';
};

export const getEmptyWeekData = (
  date: Date
): { [key: string]: number | string }[] => {
  const startOfCurrentWeek = startOfWeek(date);
  const currentWeekData = [];

  for (let i = 0; i < 7; i++) {
    const currentDay = addDays(startOfCurrentWeek, i);
    const dayData: { [key: string]: number | string } = {
      project: 0,
      time: currentDay.toString(),
    };
    currentWeekData.push(dayData);
  }

  return currentWeekData;
};

export const getEmptyDay = (
  date: Date
): { [key: string]: number | string }[] => {
  const emptyDay = [];
  const startOfToday = startOfDay(date);

  for (let i = 0; i < 24; i++) {
    const currentHour = addHours(startOfToday, i);
    const hourData: { [key: string]: number | string } = {
      project: 0,
      time: currentHour.toString(),
    };
    emptyDay.push(hourData);
  }

  return emptyDay;
};

export const getEmptyMonth = (
  date: Date
): { [key: string]: number | string }[] => {
  const startOfMonthDate = startOfMonth(date);
  const endOfMonthDate = endOfMonth(date);
  const monthDates = eachDayOfInterval({
    start: startOfMonthDate,
    end: endOfMonthDate,
  });
  const emptyMonth = monthDates.map((date) => ({
    project: 0,
    time: date.toString(),
  }));

  return emptyMonth;
};

export const getEmptyYear = (
  date: Date
): { [key: string]: number | string }[] => {
  const startOfYearDate = startOfYear(date);
  const endOfYearDate = endOfYear(date);
  const yearMonths = eachMonthOfInterval({
    start: startOfYearDate,
    end: endOfYearDate,
  });
  const emptyYear = yearMonths.map((date) => ({
    project: 0,
    time: date.toString(),
  }));

  return emptyYear;
};
