import { styled } from '@mui/material';
import { TextField } from '@maestro-org/ui-kit';

interface TeamNameFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMaxReached: boolean;
  value?: string;
}

const TeamNameField = ({
  onChange,
  isMaxReached,
  value,
}: TeamNameFieldProps) => {
  return (
    <StyledTextfield
      variant="outlined"
      fieldLabel="Team name"
      onChange={onChange}
      value={value}
      inputProps={{ maxLength: 32 }}
      isMaxReached={isMaxReached}
    />
  );
};

const StyledTextfield = styled(TextField)<{ isMaxReached: boolean }>(
  ({ isMaxReached }) => ({
    ...(isMaxReached && {
      '& fieldset': {
        borderColor: '#DC6675 !important',
      },

      '& .Mui-focused fieldset': {
        borderColor: '#DC6675 !important',
      },
    }),
  })
);

export default TeamNameField;
