import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Router from './routes';
import {
  getSubscriptionsServer,
  getOldSubscriptionsServer,
} from './redux/actions/stripeActions';
import { getIsAuthorized } from './redux/reducers/authReducer';
import {
  getTeamOwnerServer,
  getUserOrganizationServer,
  getUserServer,
} from './redux/actions/userActions';
import { getProjectsServer } from './redux/actions/projectsActions';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(getUserServer());
      dispatch(getTeamOwnerServer());

      dispatch(getProjectsServer({}));
      dispatch(getUserOrganizationServer());

      dispatch(getSubscriptionsServer());
      dispatch(getOldSubscriptionsServer());
    }
  }, [isAuthorized]);

  return (
    <>
      <Router />
    </>
  );
};

export default App;
