export const SubscriptionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.777 13.1666V6.82988C17.777 5.9164 17.3041 5.07209 16.5364 4.61343L11.2479 1.4553C10.4752 0.994068 9.52323 0.994068 8.75049 1.4553L3.46327 4.61343C2.69551 5.07209 2.22266 5.9164 2.22266 6.82988V13.1666C2.22266 14.0801 2.69551 14.9244 3.46327 15.3831L8.75173 18.5412C9.52447 19.0025 10.4764 19.0025 11.2491 18.5412L16.5376 15.3831C17.3054 14.9244 17.7782 14.0801 17.7782 13.1666H17.777Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0007 7.36042C10.0007 9.22558 9.26851 11.0146 7.96482 12.3334L6.18433 14.1349L5.213 13.1521L8.14873 10.1818C8.1965 10.1334 8.16275 10.0504 8.09496 10.0504H4.44531V8.66039H8.51512C8.57691 8.66039 8.62696 8.60975 8.62696 8.54724V4.42969H10.0007V7.36042ZM15.5561 10.0507H11.8776C11.8207 10.0507 11.7921 9.98091 11.8324 9.9404L14.7893 6.9486L13.818 5.96581L12.0369 7.76789C10.7332 9.08667 10.001 10.8757 10.001 12.7409V15.6716H11.3748V11.5538C11.3748 11.4913 11.4248 11.4406 11.4866 11.4406H15.5564V10.0507H15.5561Z"
      fill="#0F0311"
    />
  </svg>
);
