export const AmexIcon = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6835 0.53125H2.11455C1.76508 0.53125 1.42992 0.670076 1.18281 0.917187C0.935701 1.1643 0.796875 1.49945 0.796875 1.84892V14.1472C0.796875 14.4966 0.935701 14.8318 1.18281 15.0789C1.42992 15.326 1.76508 15.4648 2.11455 15.4648H19.6835C20.0329 15.4648 20.3681 15.326 20.6152 15.0789C20.8623 14.8318 21.0012 14.4966 21.0012 14.1472V1.84892C21.0012 1.49945 20.8623 1.1643 20.6152 0.917187C20.3681 0.670076 20.0329 0.53125 19.6835 0.53125Z"
      fill="#007AC6"
    />
    <path
      d="M4.98491 8.18533H6.0083L5.49661 6.93046L4.98491 8.18533ZM19.2452 5.80078H16.6235L15.9839 6.4908L15.4081 5.80078H9.84492L9.33322 6.93002L8.82153 5.80078H6.58324V6.30281L6.32762 5.80078H4.40909L2.55469 10.193H4.79297L5.0486 9.503H5.68811L5.94373 10.193H8.43764V9.69098L8.62959 10.193H9.9086L10.1005 9.62817V10.193H15.2166L15.8561 9.503L16.4315 10.193H19.0532L17.3908 7.9969L19.2452 5.80078ZM11.5074 9.56536H10.8037V7.11845L9.71666 9.56536H9.07715L7.99008 7.11845V9.56536H6.51912L6.26349 8.87534H4.72884L4.47322 9.56536H3.64177L4.98447 6.42799H6.07155L7.28644 9.37694V6.42799H8.50133L9.4606 8.5613L10.3557 6.42799H11.5706V9.56536H11.5074ZM17.8383 9.56536H16.8791L16.0476 8.49849L15.0884 9.56536H12.2106V6.42799H15.1521L16.0472 7.43206L17.0065 6.42799H17.9016L16.4952 7.9969L17.8383 9.56536ZM12.9142 7.05564V7.62048H14.513V8.24813H12.9142V8.87534H14.7049L15.5364 7.93409L14.769 7.05564H12.9142Z"
      fill="white"
    />
  </svg>
);
