export const ArrowDownTriangleGreyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
  >
    <g clipPath="url(#clip0_4716_5634)">
      <path d="M0 0L4 4L8 0H0Z" fill="#999999" />
    </g>
    <defs>
      <clipPath id="clip0_4716_5634">
        <rect width="8" height="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
