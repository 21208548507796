import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './styles/App.css';
import App from './App';

import { getTheme } from './lib/theme/theme';
import { mutateFontSizeResponsiveness } from './lib/theme/responsiveTypography';
import store from './redux/store';
import { AUTH0_LOGIN_REDIRECT_URI } from './config';

const rootElement = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

const Project = () => {
  const [activeMode, setActiveMode] = useState('light');

  let theme = createTheme(getTheme(activeMode as 'light' | 'dark'));

  return (
    // <React.StrictMode>
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
      redirectUri={AUTH0_LOGIN_REDIRECT_URI}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Elements
            options={{
              mode: 'setup',
              currency: 'usd',
              appearance: {
                theme: 'none',
                rules: {
                  '.Input': {
                    border: '2px solid #CCCCCC',
                    borderRadius: '8px',
                    color: '#0F0311',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    outline: 'none',
                    padding: '10px 24px',
                    fontFamily: "'Ranua', sans-serif",
                  },
                  '.Input:autofill': {
                    color: '#0F0311',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0 0 0px 1000px white inset',
                  },
                  '.Input--invalid': {
                    color: '#DC6675',
                    borderColor: '#DC6675',
                  },
                  '.Label': {
                    fontFamily: "'Ranua', sans-serif",
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#0F0311',
                  },
                },
                // variables: {
                //   colorPrimary: "#C53DD8",
                //   colorBackground: "#FFFFFF",
                //   colorText: "#0F0311",
                //   borderRadius: "8px",
                //   spacingGridColumn: "20px",
                // },
              },
              fonts: [
                {
                  family: 'Ranua',
                  // src: "url(https://storage.googleapis.com/maestro-org-fonts/RanuaTrials/300.otf)",
                  src: `url(${process.env.REACT_APP_RANUA_FONT_LINK}/300.otf)`,
                  weight: '300',
                },
                {
                  family: 'Ranua',
                  // src: "url(https://storage.googleapis.com/maestro-org-fonts/RanuaTrials/400.otf)",
                  src: `url(${process.env.REACT_APP_RANUA_FONT_LINK}/400.otf)`,
                  weight: '400',
                },
                {
                  family: 'Ranua',
                  // src: "url(https://storage.googleapis.com/maestro-org-fonts/RanuaTrials/500.otf)",
                  src: `url(${process.env.REACT_APP_RANUA_FONT_LINK}/500.otf)`,
                  weight: '500',
                },
              ],
            }}
            stripe={stripePromise}
          >
            <App />
            <ToastContainer position="bottom-right" autoClose={2500} />
          </Elements>
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
    // </React.StrictMode>
  );
};

rootElement.render(<Project />);
