import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { getIsAuthorized } from '../../redux/reducers/authReducer';
import {
  getProjectMetricsServer,
  getQueryDistributionServer,
} from '../../redux/actions/singleProjectActions';

import Footer from '../Home/components/Footer';
import Tips from '../Home/components/Tips';
import MainNavigation, {
  NavItems,
} from '../../components/Navigation/MainNavigation';
import TotalRequestVolume from './components/TotalRequestVolume';
import ProjectHeader from './components/ProjectHeader';
import QueryDistribution from './components/QueryDistribution';
import Widgets from './components/Widgets';
import {
  DocumentationIcon,
  BuildTogetherTipIcon,
  IntroduceTipIcon,
  TransactionsTipIcon,
} from '../../components/Icons';

import withPrivateRoute from '../../hooks/withPrivateRoute';

const ProjectPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (!isAuthorized || !id) return;
    dispatch(getProjectMetricsServer({ projectId: id }));
    dispatch(getQueryDistributionServer({ projectId: id }));
  }, [id, isAuthorized]);

  return (
    <Wrapper>
      <MainNavigation
        activeItem={NavItems.projects}
        actions={<PageNavActions />}
      />
      <HeaderWrapper>
        <ProjectHeader />
        <Widgets />
      </HeaderWrapper>
      <TotalRequestVolumeWrapper>
        <TotalRequestVolume projectId={id} />
      </TotalRequestVolumeWrapper>
      <PieChartCardWrapper>
        <QueryDistribution />
      </PieChartCardWrapper>
      <TipsWrapper>
        <Tips tips={tips} />
      </TipsWrapper>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const HeaderWrapper = styled('div')(({ theme }) => ({
  padding: '100px 0',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '50px',
  background: theme.palette.grey[50],
  borderBottom: `1px solid ${theme.palette.grey[100]}`,

  [theme.breakpoints.down('md')]: {
    rowGap: '40px',
    padding: '56px 0 80px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '48px 0',
    rowGap: '32px',
  },
}));

const TotalRequestVolumeWrapper = styled('div')(({ theme }) => ({
  padding: '50px 0',

  [theme.breakpoints.down('md')]: {
    padding: '120px 0 90px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '72px 0',
  },
}));

const PieChartCardWrapper = styled('div')(({ theme }) => ({
  padding: '50px 0',

  [theme.breakpoints.down('md')]: {
    padding: '0 0 20px',
  },
}));

const TipsWrapper = styled('div')(({ theme }) => ({
  paddingBottom: '120px',

  [theme.breakpoints.down('sm')]: {
    paddingBottom: '96px',
  },
}));

const PageNavActions = () => (
  <>
    <a href="https://docs.gomaestro.org/" target="_blank" rel="noreferrer">
      <DocumentationWrapper>
        <DocumentationIcon />
        <Typography variant="paragraphMedium" color="textColor.dark">
          Documentation
        </Typography>
      </DocumentationWrapper>
    </a>
  </>
);

const DocumentationWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const tips = [
  {
    title: 'Let’s build together',
    subtitle:
      "Checkout Maestro's quickstart guide and tutorials. Learn how to harness Maestro's UTxO-optimized APIs to power your application.",
    linkText: 'Start building with Maestro',
    href: 'https://docs.gomaestro.org/',
    icon: <BuildTogetherTipIcon />,
  },
  {
    title: 'Project dashboard',
    subtitle:
      "Track your project's traffic and monitor performance metrics across your applications. Maestro gives you the tools to better understand your users.",
    linkText: 'Access your dashboard',
    link: '/#navigation',
    icon: <IntroduceTipIcon />,
  },
  {
    title: 'Transactions',
    subtitle:
      "Monitor your dapp transaction states in real-time with Maestro's transaction management system. Never lose sight of a transaction again.",
    linkText: 'Manage your transactions',
    link: '/transactions#navigation',
    icon: <TransactionsTipIcon />,
  },
];

export default withPrivateRoute(ProjectPage);
