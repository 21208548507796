import { styled, Typography } from '@mui/material';
import { LineChart } from '../../../components/Charts';
import { numberDecorator } from '../../../lib/numberDecorator';
import { StatisticType } from '../../../types/statisticsCard';
import { Loader } from '@maestro-org/ui-kit';

interface Props {
  amount?: number;
  label: string;
  data?: {
    date: string;
    value: number;
  }[];
  type?: StatisticType;
  isLoading?: boolean;
  units?: string;
  measure?: string;
}

const StatisticsCard = ({
  amount,
  type = StatisticType.invalidRequest,
  label,
  data,
  isLoading,
  units,
  measure,
}: Props) => {
  return (
    <Wrapper isEmpty={!amount}>
      {isLoading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : (
        <>
          <Heading isEmpty={!amount}>
            {!!amount && (
              <Typography variant="h4" color="grey.A200">
                {amountUnit(amount, type)}
              </Typography>
            )}
            <Label variant="paragraphMedium" color="grey.400" isEmpty={!amount}>
              {label}
            </Label>
          </Heading>
          {data && data.length > 1 && !!amount && (
            <LineChart data={data} measure={measure} units={units} />
          )}
          {(!amount || !data || !data.length) && (
            <NoDataTypography variant="paragraphSmall" color="grey.A200">
              No data available
            </NoDataTypography>
          )}
        </>
      )}
    </Wrapper>
  );
};

const amountUnit = (amount: number, type: StatisticType) => {
  switch (type) {
    case StatisticType.totalRequest: {
      return numberDecorator(amount);
    }
    case StatisticType.success: {
      return `${amount}%`;
    }
    case StatisticType.medianResponse: {
      return `${amount} ms`;
    }

    default:
      return amount;
  }
};

const Wrapper = styled('div')<{ isEmpty: boolean }>(({ theme, isEmpty }) => ({
  background: '#FFFFFF',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '12px',
  padding: '32px 25.5px',
  display: 'flex',
  flexDirection: 'column',
  gap: isEmpty ? '40px' : '55px',
  transition: '0.3s',
  boxShadow: 'none',

  '&:hover': {
    boxShadow: `0px 8px 30px -2px rgba(0, 0, 0, 0.08), 0px 1px ${theme.palette.primary.main}`,
    border: `1px solid transparent`,
  },
}));

const LoadingWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '46px 0',
});

const Heading = styled('div')<{ isEmpty: boolean }>(({ isEmpty }) => ({
  display: 'flex',
  // alignItems: "center",
  justifyContent: isEmpty ? 'center' : 'space-between',

  '& + *': {
    marginTop: 'auto',
  },
}));

const Label = styled(Typography)<{ isEmpty: boolean }>(({ isEmpty }) => ({
  maxWidth: isEmpty ? 'none' : '172px',
  textAlign: isEmpty ? 'center' : 'end',
  marginTop: '4px',
}));

const NoDataTypography = styled(Typography)({
  textAlign: 'center',
});

export default StatisticsCard;
