import React from 'react';
import { styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface TipCardProps {
  title: string;
  subtitle: string;
  linkText: string;
  link?: string;
  href?: string;
  icon: React.ReactNode;
}

const TipCard = ({
  title,
  subtitle,
  linkText,
  link,
  href,
  icon,
}: TipCardProps) => (
  <Wrapper>
    <IconWrapper>{icon}</IconWrapper>
    <Typography color="textColor.dark" variant="h6">
      {title}
    </Typography>
    <SubtitleWrapper>
      <Typography color="textColor.dark" variant="paragraphSmall">
        {subtitle}
      </Typography>
    </SubtitleWrapper>
    {link ? (
      <StyledLink to={link}>
        <Typography color="textColor.contrastText" variant="paragraphMedium">
          {linkText}
        </Typography>
      </StyledLink>
    ) : (
      !!href && (
        <StyledRef href={href} target="_blank">
          <Typography color="textColor.contrastText" variant="paragraphMedium">
            {linkText}
          </Typography>
        </StyledRef>
      )
    )}
  </Wrapper>
);

const Wrapper = styled('div')(({ theme }) => ({
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.borderRadius.sm,
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.grey[100]}`,
}));

const IconWrapper = styled('div')({
  paddingBottom: '24px',
});

const SubtitleWrapper = styled('div')({
  margin: '16px 0 32px',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginTop: 'auto',
});

const StyledRef = styled('a')({
  textDecoration: 'none',
  marginTop: 'auto',
});

export default TipCard;
