import { styled, Typography } from '@mui/material';
import { IconButton, TextField } from '@maestro-org/ui-kit';
import { Link } from 'react-router-dom';
import { EyeIcon } from '../../../Icons';
import { pages } from '../../../../lib/routeUtils';

interface APIKeyDisplayProps {
  apiKey: string;
  isPasswordType: boolean;
  togglePasswordType: () => void;
  handleClose: () => void;
}

const APIKeyDisplay = ({
  apiKey,
  isPasswordType,
  togglePasswordType,
  handleClose,
}: APIKeyDisplayProps) => {
  return (
    <APIKeyWrapper>
      <TextField
        variant="outlined"
        label="API key"
        type={isPasswordType ? 'password' : 'text'}
        value={apiKey}
        endIcon={
          <StyledIconButton onClick={togglePasswordType}>
            <EyeIcon />
          </StyledIconButton>
        }
      />
      <Typography variant="paragraphSmall" color="grey.500">
        This is your unique key and it is non-recoverable. If you loose this API
        key, you will have to reset it.{' '}
        <Ref onClick={handleClose} to={pages.main()}>
          Learn more
        </Ref>
      </Typography>
    </APIKeyWrapper>
  );
};

const APIKeyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const StyledIconButton = styled(IconButton)({
  background: 'transparent',
  width: '25px',
  height: '25px',

  '&:hover': {
    background: 'transparent',
  },
});

const Ref = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

export default APIKeyDisplay;
