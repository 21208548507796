import { IconButton, Loader, Radio } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';

import {
  AmexIcon,
  MasterCardIcon,
  TrashIcon,
  VisaIcon,
} from '../../../components/Icons';
import { CardBrand } from '../../../types/auth';

interface Props {
  last4: string;
  expMonth: number;
  expYear: number;
  brand: CardBrand;
  handleMakeDefault: () => void;
  handleDelete?: () => void;
  isActive?: boolean;
  isCheckout?: boolean;
  isLoading?: boolean;
}

const getIcon = {
  mastercard: <MasterCardIcon />,
  visa: <VisaIcon />,
  amex: <AmexIcon />,
};

const handleSingleDigit = (number: number) =>
  number < 10 ? `0${number}` : number;

const PaymentMethod = ({
  last4,
  expMonth,
  expYear,
  brand,
  isActive,
  isCheckout,
  isLoading,
  handleMakeDefault,
  handleDelete,
}: Props) => (
  <Wrapper>
    <Left onClick={handleMakeDefault} isCheckout={isCheckout}>
      <StyledRadio
        label=""
        value=""
        handleChange={console.log}
        checked={isActive}
      />
      <LogoAndNumberWrapper isCheckout={isCheckout}>
        <IconWrapper>{getIcon[brand]}</IconWrapper>
        <Typography variant="paragraphMedium" color="grey.A200">
          ****{last4}
          {isCheckout
            ? ''
            : ` Exp ${handleSingleDigit(expMonth)}/${expYear % 100}`}
        </Typography>
        {isActive && !isCheckout && (
          <Typography variant="paragraphMedium" color="grey.400">
            (Default)
          </Typography>
        )}
      </LogoAndNumberWrapper>
      {isCheckout && (
        <Typography variant="paragraphMedium" color="grey.A200">
          Exp {handleSingleDigit(expMonth)}/{expYear % 100}
        </Typography>
      )}
    </Left>
    {handleDelete && (
      <StyledIconButton onClick={handleDelete} className="delete-button">
        {isLoading ? <Loader size={25} /> : <TrashIcon />}
      </StyledIconButton>
    )}
  </Wrapper>
);

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '4px 0',

  '&:hover .delete-button': {
    visibility: 'visible',
    opacity: '1',
  },
});

const Left = styled('div')<{ isCheckout?: boolean }>(({ isCheckout }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',
  flex: 1,
  justifyContent: isCheckout ? 'space-between' : 'flex-start',

  '& .MuiFormControlLabel-root': {
    margin: 0,
  },
}));

const LogoAndNumberWrapper = styled('div')<{ isCheckout?: boolean }>(
  ({ isCheckout, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',

    [theme.breakpoints.down('md')]: {
      marginRight: isCheckout ? 'auto' : 'none',
    },
  })
);

const IconWrapper = styled('div')({
  width: '35px',
});

const StyledIconButton = styled(IconButton)({
  padding: 0,
  width: '25px',
  height: '25px',
  background: 'transparent',
  visibility: 'hidden',
  opacity: '0',
  transition: '0.3s',

  '&:hover': {
    background: 'transparent',
  },
});

const StyledRadio = styled(Radio)({
  padding: 0,
});

export default PaymentMethod;
