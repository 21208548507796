import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody,
  Typography,
  styled,
  SxProps,
  Paper,
} from '@mui/material';
import React from 'react';

import { EntryValue, TableProps, ValueWithAdornment } from '../../types/table';
// import Loader from "../Loader/Loader";

import { parseDate } from '../../lib/date';
import { Loader } from '@maestro-org/ui-kit';

interface Props extends TableProps {
  rowSx?: any;
  page: number;
  rowsPerPage: number;
  empyStateMessage?: string;
  loadingEntriesIds?: string[];
  sortTable?: {
    columnName: string;
    handleClick: () => void;
  }[];
  handleRowClick?: (id?: string) => void;
}

const instanceOfValueAdornment = (
  object: any
): object is ValueWithAdornment => {
  return !!object?.adornemnt;
};

enum CellTypes {
  date = 'date',
  adornment = 'Adornment',
  primitive = 'primitive',
  node = 'node',
}

const getCellType = (content: EntryValue): CellTypes => {
  if (content instanceof Date) return CellTypes.date;
  if (instanceOfValueAdornment(content)) return CellTypes.adornment;
  if (typeof content === 'number' || typeof content === 'string')
    return CellTypes.primitive;
  return CellTypes.node;
};

const Table = ({
  heading,
  data,
  isLoading = false,
  page,
  rowsPerPage,
  rowSx,
  empyStateMessage = 'No results',
  sortTable,
  loadingEntriesIds,
  handleRowClick,
}: Props) => {
  const rows = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const formatCell = (
    key: EntryValue,
    content: EntryValue
  ): React.ReactNode => {
    switch (getCellType(content)) {
      case CellTypes.date: {
        return (
          <Typography color="grey.A200" variant="paragraphSmall">
            {parseDate(content as Date)}
          </Typography>
        );
      }
      case CellTypes.adornment: {
        return (
          <WithAdornment>
            {(content as ValueWithAdornment).adornmentPosition === 'start' &&
              (content as ValueWithAdornment).adornemnt}
            <Typography color="grey.A200" variant="paragraphSmall">
              {(content as ValueWithAdornment)?.title}
            </Typography>
            {(content as ValueWithAdornment).adornmentPosition === 'end' &&
              (content as ValueWithAdornment).adornemnt}
          </WithAdornment>
        );
      }
      case CellTypes.primitive: {
        return (
          <Typography color="grey.A200" variant="paragraphSmall">
            {content as string}
          </Typography>
        );
      }
      case CellTypes.node: {
        return content as React.ReactNode;
      }
      default: {
        return '';
      }
    }
  };

  const handleSort = (key: string) => {
    const currentSortObj = sortTable?.find(
      ({ columnName }) => columnName === key
    );
    if (!currentSortObj) return;
    return currentSortObj.handleClick();
  };

  return (
    <Wrapper>
      <TableContainer component={StyledPaper}>
        <StyledTable>
          <TableHead>
            <HeaderRow>
              <FirstHeaderCellBackground />
              {Object.keys(heading).map((key, index) => (
                <TableCell
                  className={index === 0 ? 'first-header-cell' : ''}
                  onClick={() => handleSort(key)}
                  key={key}
                >
                  {formatCell(key, heading[key])}
                </TableCell>
              ))}
            </HeaderRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableCell colSpan={Object.keys(heading).length} align="center">
                {/* <Loader /> */}
                <p>loading</p>
              </TableCell>
            ) : (
              <>
                {rows.map((entry) =>
                  entry.id && loadingEntriesIds?.includes(entry.id) ? (
                    <TableCell
                      key={entry.id}
                      colSpan={Object.keys(heading).length}
                      align="center"
                    >
                      <Loader size={16} />
                    </TableCell>
                  ) : (
                    <>
                      <TableRow
                        key={entry.id}
                        sx={
                          handleRowClick
                            ? { ...rowSx, cursor: 'pointer' }
                            : rowSx
                        }
                        onClick={
                          handleRowClick
                            ? () => handleRowClick(entry.id)
                            : undefined
                        }
                      >
                        <FirstCellBackground />
                        {Object.keys(heading).map((key, index) => (
                          <TableCell
                            className={index === 0 ? 'first-body-cell' : ''}
                            key={`${entry.id}-${key}`}
                          >
                            {formatCell(key, entry[key])}
                          </TableCell>
                        ))}
                      </TableRow>
                    </>
                  )
                )}
              </>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {!data.length && !isLoading && (
        <EmptyMessageWrapper>
          <EmptyMessage color="grey.800" variant="paragraphMedium">
            {empyStateMessage}
          </EmptyMessage>
        </EmptyMessageWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  position: 'relative',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  paddingBottom: '24px',
  background: 'transparent',
  overflow: 'visible !important',

  '&::-webkit-scrollbar ': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track ': {
    background: theme.palette.grey[50],
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb ': {
    background: theme.palette.grey.A200,
    borderRadius: '13px',
  },
}));

const StyledTable = styled(MuiTable)(({ theme }) => ({
  borderCollapse: 'separate',
  borderSpacing: '0',
  background: theme.palette.grey['50'],
}));

const HeaderRow = styled(MuiTableRow)(({ theme }) => ({
  background: theme.palette.common.white,

  '& > .MuiTableCell-root': {
    cursor: 'pointer',
  },
  '& > .MuiTableCell-root .MuiTypography-root': {
    color: theme.palette.table.headerText,
  },
}));

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  background: theme.palette.grey['50'],
  borderRadius: '0 0 16px 16px',

  '& .show-on-hover': {
    opacity: 0,
    transition: '0.2s',
  },

  '&:hover': {
    background: theme.palette.common.white,
    boxShadow: '0px 8px 30px -2px rgba(0, 0, 0, 0.04)',

    '& .show-on-hover': {
      opacity: 1,
    },

    '& > .MuiTableCell-root': {
      borderBottom: '1px solid #C53DD8',

      '&:last-child': {
        borderRadius: '0 0 8px 0',
      },
    },
    '& .first-body-cell': {
      borderRadius: '0 0 0 8px',
    },
  },
}));

const FirstHeaderCellBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: 'calc(100% - 30px)',
  width: '20px',
  background: '#F5F5F5',

  [theme.breakpoints.up(709)]: {
    display: 'none',
  },
}));

const FirstCellBackground = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(709)]: {
    display: 'none',
  },
}));

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  zIndex: 1,
  '& .MuiTypography-root': {
    ...theme.typography.paragraphSmall,
  },
}));

const WithAdornment = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const EmptyMessageWrapper = styled('div')({
  display: 'flex',
  padding: '40px 0 0',
  justifyContent: 'center',
});

const EmptyMessage = styled(Typography)({
  textAlign: 'center',
});

export default Table;
