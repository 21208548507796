import { Button, Loader } from '@maestro-org/ui-kit';
import { Collapse, styled, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';

import Tooltip from '../Tooltip/Tooltip';
import { PlusIcon } from '../Icons';

import { NewEntryField } from '../../types/newEntry';
import NewEntryPanel from './NewEntryPanel';

interface Props {
  title: string;
  titleAndornment?: React.ReactNode;
  subtitle?: string;
  action?: React.ReactNode;
  triggerValue?: string;
  newEntry?: {
    disable?: {
      condition: boolean;
      message: string;
    }[];
    handleClick: () => void;
    isLoading: boolean;
    panel?: {
      openPanel: boolean;
      submitText: string;
      formik: FormikProps<any>;
      fields: NewEntryField[];
      togglePanel: () => void;
    };
  };
}

const TableHeader = ({
  title,
  subtitle,
  action,
  triggerValue,
  newEntry,
  titleAndornment,
}: Props) => {
  return (
    <Wrapper>
      <Top className="new-entry-panel">
        <Left>
          <TitleWrapper>
            <TitleWithAndornment>
              {titleAndornment}
              <Typography variant="h5" color="textColor.dark">
                {title}
              </Typography>
            </TitleWithAndornment>
            {!!subtitle && (
              <Subtitle color="grey.500" variant="paragraphSmall">
                {subtitle}
              </Subtitle>
            )}
          </TitleWrapper>
          {action}
        </Left>
        {newEntry &&
          (newEntry.disable?.some((item) => item.condition) ? (
            <StyledTooltip
              title={
                <Typography variant="paragraphSmall" color="grey.A200">
                  {newEntry.disable.find((item) => item.condition)?.message}
                </Typography>
              }
              followCursor
            >
              <div>
                <CustomButton
                  size="xsmall"
                  startIcon={!newEntry.isLoading && <PlusIcon />}
                  disabled
                >
                  {triggerValue}
                </CustomButton>
              </div>
            </StyledTooltip>
          ) : (
            <CustomButton
              size="xsmall"
              startIcon={!newEntry.isLoading && <PlusIcon />}
              disabled={newEntry.isLoading}
              onClick={newEntry.handleClick}
            >
              {newEntry.isLoading ? <StyledLoader size={20} /> : triggerValue}
            </CustomButton>
          ))}
      </Top>
      {newEntry && newEntry?.panel && (
        <Collapse in={newEntry.panel.openPanel} timeout="auto">
          <NewEntryPanel {...newEntry.panel} {...newEntry.panel.formik} />
        </Collapse>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Top = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

const Left = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '32px',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    rowGap: '32px',
    paddingTop: '2px',
  },
}));

const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
});

const TitleWithAndornment = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '12px',

  '& svg': {
    display: 'block',
    width: '40px',
    height: '40px',
  },
});

const Subtitle = styled(Typography)({
  width: '388px',
  maxWidth: '100%',
});

const CustomButton = styled(Button)<{ disabled?: boolean }>(({ disabled }) => ({
  padding: '8px 26px',
  minWidth: '155px',

  '& svg': {
    '& path': {
      stroke: disabled ? '#DE91E9' : '#FEFEFE',
    },
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '6px',
  },
}));

const StyledLoader = styled(Loader)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const SwitchRoleWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  gap: '20px',
  alignItems: 'center',
});

const RoleText = styled(Typography)({
  fontSize: '16px',
});

export default TableHeader;
