import {
  AddNewProject,
  APIKeyCreated,
  CreateTeam,
  CreateWebhook,
  EventCreateWebhook,
  DeleteAccount,
  DeleteProject,
  DeleteWebhook,
  RenameTeam,
  ResetKey,
  AddTeamMember,
  UpdateTeamMemberRole,
  DeleteTeamMember,
  DeleteEvent,
} from './';

const AllDialogs = () => (
  <>
    <APIKeyCreated />
    <ResetKey />
    <DeleteProject />
    <DeleteWebhook />
    <AddNewProject />
    <CreateWebhook />
    <EventCreateWebhook />
    <DeleteAccount />
    <RenameTeam />
    <CreateTeam />
    <AddTeamMember />
    <UpdateTeamMemberRole />
    <DeleteTeamMember />
    <DeleteEvent />
  </>
);

export default AllDialogs;
