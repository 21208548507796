import React, { useState } from 'react';
import { styled, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { updatePopup } from '../../../redux/actions/popupsActions';

import { getResetKeysIds } from '../../../redux/reducers/projectsReducer';

import { KeyIcon, SuccessCopiedIcon, UrlIcon } from '../../../components/Icons';

import { Popups } from '../../../types/popups';
import { Blockchain, Network } from '../../../types/project';
import { copyToClipboard } from '../../../lib/copyToClipboard';

import { getUser } from '../../../redux/reducers/usersReducer';

import Tooltip from '../../../components/Tooltip/Tooltip';
import { UserRoles } from '../../../types/roles';

interface Props {
  projectId: string;
  copyKey: string;
  projectName: string;
  network: Network;
  blockChain: Blockchain;
}

export function getAPIUrl(blockchain: Blockchain, network: Network): string {
  switch (blockchain) {
    case Blockchain.Cardano:
      switch (network) {
        case Network.Preview:
          return process.env.REACT_APP_CARDANO_PREVIEW_URL || '';
        case Network.Preprod:
          return process.env.REACT_APP_CARDANO_PREPROD_URL || '';
        case Network.Mainnet:
          return process.env.REACT_APP_CARDANO_MAINNET_URL || '1';
        default:
          throw new Error('Invalid network for Cardano');
      }
    case Blockchain.Bitcoin:
      switch (network) {
        case Network.Testnet:
          return process.env.REACT_APP_BITCOIN_TESTNET_URL || '';
        case Network.Mainnet:
          return process.env.REACT_APP_BITCOIN_MAINNET_URL || '';
        default:
          throw new Error('Invalid network for Bitcoin');
      }
    case Blockchain.Dogecoin:
      switch (network) {
        case Network.Testnet:
          return process.env.REACT_APP_DOGECOIN_TESTNET_URL || '';
        case Network.Mainnet:
          return process.env.REACT_APP_DOGECOIN_MAINNET_URL || '';
        default:
          throw new Error('Invalid network for Dogecoin');
      }
    case Blockchain.Arch:
      switch (network) {
        case Network.Testnet:
          return process.env.REACT_APP_ARCH_TESTNET_URL || '';
        default:
          throw new Error('Invalid network for Arch Network');
      }
    default:
      throw new Error('Invalid blockchain');
  }
}

const ApiKeyTableCell = ({
  projectId,
  projectName,
  network,
  blockChain,
}: Props) => {
  const [copiedUrls, setCopiedUrls] = useState<string[]>([]);

  const resetKeys = useSelector(getResetKeysIds);

  const user = useSelector(getUser);

  const isMember = user.team_role_name === UserRoles.MEMBER;

  const dispatch = useDispatch();

  const handleCopyUrl = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string
  ) => {
    event.stopPropagation();
    const url = `${getAPIUrl(blockChain, network)}`;
    copyToClipboard(url);

    setCopiedUrls((ids) => [...ids, id]);
    setTimeout(() => {
      setCopiedUrls((ids) => ids.filter((projectId) => projectId !== id));
    }, 1500);
  };

  const handleCopyKey = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    dispatch(
      updatePopup({
        popup: Popups.resetKey,
        status: true,
        prefilled: { projectName, projectId },
      })
    );
  };

  return (
    <Wrapper>
      <CopyItemWrapper onClick={(event) => handleCopyUrl(event, projectId)}>
        {copiedUrls.includes(projectId) ? (
          <WithIcon>
            <SuccessCopiedIcon />
            <Typography color="textColor.dark" variant="paragraphSmall">
              URL copied!
            </Typography>
          </WithIcon>
        ) : (
          <WithIcon>
            <CopyText
              color="textColor.contrastText"
              variant="paragraphSmall"
              className="hover-appear"
            >
              Copy
            </CopyText>
            <CopyText
              color="textColor.contrastText"
              variant="paragraphSmall"
              className="hover-hide"
            >
              URL
            </CopyText>
            <UrlIcon />
          </WithIcon>
        )}
      </CopyItemWrapper>
      <CopyItemWrapper onClick={isMember ? undefined : handleCopyKey}>
        {resetKeys.includes(projectId) ? (
          <WithIcon>
            <SuccessCopiedIcon />
            <Typography color="textColor.dark" variant="paragraphSmall">
              Key Reset!
            </Typography>
          </WithIcon>
        ) : (
          <WithIcon isMember={isMember}>
            {isMember ? (
              <>
                <StyledTooltip
                  title={
                    <Typography variant="paragraphSmall" color="grey.A200">
                      Not accessible for Team Member role
                    </Typography>
                  }
                  followCursor
                >
                  <CopyText
                    color="textColor.lightGray"
                    variant="paragraphSmall"
                    className="hover-appear"
                  >
                    Reset
                  </CopyText>
                </StyledTooltip>
                <CopyText
                  color="textColor.lightGray"
                  variant="paragraphSmall"
                  className="hover-hide"
                >
                  Key
                </CopyText>
                <KeyIcon />
              </>
            ) : (
              <>
                <CopyText
                  color="textColor.contrastText"
                  variant="paragraphSmall"
                  className="hover-appear"
                >
                  Reset
                </CopyText>
                <CopyText
                  color="textColor.contrastText"
                  variant="paragraphSmall"
                  className="hover-hide"
                >
                  Key
                </CopyText>
                <KeyIcon />
              </>
            )}
          </WithIcon>
        )}
      </CopyItemWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  columnGap: '24px',
  width: '190px',
  maxWidth: '100%',

  [theme.breakpoints.down('lg')]: {
    width: 'auto',
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '236px',
    padding: '16px',
    boxShadow: '0px 2px 16px -2px rgba(15, 3, 17, 0.16)',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '6px',
  },
}));

const CopyText = styled(Typography)({
  minWidth: '35px',
});

const CopyItemWrapper = styled('div')({
  cursor: 'pointer',

  '& .hover-appear': {
    display: 'none',
  },
  '&:hover .hover-appear': {
    display: 'flex',
  },
  '&:hover .hover-hide': {
    display: 'none',
  },
});

const WithIcon = styled('div')<{ isMember?: boolean }>(
  ({ isMember, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',

    ...(isMember && {
      '& svg': {
        '& path': {
          stroke: theme.palette.requestMethod.DELETE.primary,
        },
      },
    }),
  })
);

export default ApiKeyTableCell;
