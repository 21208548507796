import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getDeleteEventDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { deleteEventServer } from '../../../../redux/actions/eventsActions';
import { Popups } from '../../../../types/popups';

export const useDeleteEvent = () => {
  const isOpen = useSelector(getDeleteEventDialog);
  const { eventId } = useSelector(getPrefilled) || {};

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(updatePopup({ popup: Popups.deleteEvent, status: false }));
  };

  const handleDelete = () => {
    if (!eventId) {
      toast.error('Error occurred while deleting event');
      return;
    }
    dispatch(deleteEventServer(eventId));
    handleClose();
  };

  return {
    isOpen,
    handleClose,
    handleDelete,
  };
};
