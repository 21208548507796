import { WebhookActions, WebhookStatus } from '../../types/transaction';
import { Webhook } from '../../types/webhooks';

export const GET_WEBHOOKS_SERVER = 'GET_WEBHOOKS_SERVER';
export const UPDATE_WEBHOOKS = 'UPDATE_WEBHOOKS';
export const UPDATE_WEBHOOKS_LOADING = 'UPDATE_WEBHOOKS_LOADING';
export const CREATE_WEBHOOKS_SERVER = 'CREATE_WEBHOOKS_SERVER';
export const UPDATE_WEBHOOK_IS_CREATING = 'UPDATE_WEBHOOK_IS_CREATING';
export const DELETE_WEBHOOK_SERVER = 'DELETE_WEBHOOK_SERVER';
export const ADD_TO_LOADING_WEBHOOKS = 'ADD_TO_LOADING_WEBHOOKS';
export const REMOVE_FROM_LOADING_WEBHOOKS = 'REMOVE_FROM_LOADING_WEBHOOKS';
export const UPDATE_SINGLE_WEBHOOK_SERVER = 'UPDATE_SINGLE_WEBHOOK_SERVER';
export const UPDATE_ACTIVE_PROJECT_ID = 'UPDATE_ACTIVE_PROJECT_ID';

interface GetWebhooksProps {
  project_ids?: string[];
}

interface UpdateWebhooksProps {
  webhooks: Webhook[];
}

interface UpdateStatusProps {
  status: boolean;
}

interface CreateWebhookProps {
  project_id: string;
  webhook_name: string;
  webhook_url: string;
}

interface DeleteWebhookProps {
  project_id: string;
  webhook_id: string;
  prevWebhooks: Webhook[];
}

interface SingleWebhookLoadingProps {
  id: string;
}

interface UpdateWebhookServerProps {
  project_id: string;
  webhook_id: string;
  action: WebhookActions;
}

interface UpdateCurrentProjectProps {
  id: string;
}

export const getWebhooksServer = ({ project_ids }: GetWebhooksProps) => ({
  type: GET_WEBHOOKS_SERVER,
  payload: {
    request: {
      method: 'GET',
      url: `/webhook`,
      data: { project_ids },
    },
  },
});

export const updateWebhooks = ({ webhooks }: UpdateWebhooksProps) => ({
  type: UPDATE_WEBHOOKS,
  payload: { webhooks },
});

export const updateWebhooksLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_WEBHOOKS_LOADING,
  payload: { status },
});

export const createWebhookServer = (data: CreateWebhookProps) => ({
  type: CREATE_WEBHOOKS_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: '/webhook/create',
      data,
    },
  },
});

export const updateIsWebhookCreating = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_WEBHOOK_IS_CREATING,
  payload: { status },
});

export const deleteWebhookServer = ({
  webhook_id,
  project_id,
  prevWebhooks,
}: DeleteWebhookProps) => ({
  type: DELETE_WEBHOOK_SERVER,
  payload: {
    request: {
      method: 'DELETE',
      url: '/webhook/delete',
      data: { webhook_id, project_id },
    },
    id: webhook_id,
    prevWebhooks,
  },
});

export const addToLoadingWebhooks = ({ id }: SingleWebhookLoadingProps) => ({
  type: ADD_TO_LOADING_WEBHOOKS,
  payload: { id },
});

export const removeFromLoadingWebhooks = ({
  id,
}: SingleWebhookLoadingProps) => ({
  type: REMOVE_FROM_LOADING_WEBHOOKS,
  payload: { id },
});

export const updateWebhookServer = (data: UpdateWebhookServerProps) => ({
  type: UPDATE_SINGLE_WEBHOOK_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: '/webhook/update',
      data,
    },
    id: data.webhook_id,
  },
});

export const updateActiveProjectId = ({ id }: UpdateCurrentProjectProps) => ({
  type: UPDATE_ACTIVE_PROJECT_ID,
  payload: { id },
});
