import { CreateTeamFields, CreateTeamFormValues } from './types';
import { NewEntryFieldTypes } from '../../types/newEntry';

// prettier-ignore
export const initialValuesCreateTeam = {
    [CreateTeamFields.teamName]: "",
    [CreateTeamFields.emailAddress]: [""],
    [CreateTeamFields.role]: [""],
} as CreateTeamFormValues;

// prettier-ignore
export const getCreateTeamFields = (): any[] => [
    {
        type: NewEntryFieldTypes.text,
        name: CreateTeamFields.teamName,
        fieldLabel: "Team Name",
    },
    {
        type: NewEntryFieldTypes.text,
        name: CreateTeamFields.emailAddress,
        fieldLabel: "Email Address",
    },
    {
        type: NewEntryFieldTypes.select,
        name: CreateTeamFields.role,
        fieldLabel: "Role",
        options: [
            {
                value: "Admin",
                disabled: false,
            },
            {
                value: "Developer",
                disabled: false,
            },
            {
                value: "Member",
                disabeld: false,
            },
        ],
    },
];
