import React from 'react';
import { Chip as KitChip } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';

export enum ChipColors {
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  purple = 'purple',
}

interface Props {
  color: ChipColors;
  label: string;
}

const TableChip = ({ color, label }: Props) => {
  const chipColors = {
    [ChipColors.green]: '#C2E2B0',
    [ChipColors.yellow]: '#E3BF45',
    [ChipColors.red]: '#DC6675',
    [ChipColors.purple]: '#C53DD8',
  };

  const typographyColors = {
    [ChipColors.green]: 'grey.A200',
    [ChipColors.yellow]: 'grey.A200',
    [ChipColors.red]: '#E8F4E1',
    [ChipColors.purple]: '#E8F4E1',
  };

  return (
    <Chip
      label={<Typography color={typographyColors[color]}>{label}</Typography>}
      color="custom"
      hexColor={chipColors[color]}
    />
  );
};

const Chip = styled(KitChip)(({ theme }) => ({
  borderRadius: theme.borderRadius.xxs,
  padding: '4px 8px',
  height: '20px',
  textTransform: 'capitalize',

  '& .MuiTypography-root': {
    fontSize: '10px !important',
    lineHeight: '12px !important',
  },
}));

export default TableChip;
