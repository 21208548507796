export const ArtistIcon = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.48 12.87C10.9 13.1 10.35 13.32 10.04 13.63C9.15 14.52 8.8 15.82 9.11 17.03L9.18 17.31L9.46 17.38C9.75 17.46 10.05 17.5 10.35 17.5C11.28 17.5 12.19 17.13 12.86 16.46C13.16 16.16 13.39 15.61 13.62 15.03L13.65 14.97L23.7 4.49L22.01 2.8L11.53 12.85L11.48 12.87ZM10.02 16.48C9.91 15.7 10.18 14.91 10.75 14.34C10.9 14.18 11.37 13.99 11.69 13.86L12.17 14.34L12.65 14.82C12.53 15.14 12.33 15.6 12.17 15.76C11.59 16.32 10.8 16.58 10.02 16.48ZM22.3 4.51L13.21 13.98L12.65 13.42L12.52 13.29L21.99 4.2L22.3 4.51ZM5 13.5C3.9 13.5 3 14.4 3 15.5C3 16.6 3.9 17.5 5 17.5C6.1 17.5 7 16.6 7 15.5C7 14.4 6.1 13.5 5 13.5ZM5 16.5C4.45 16.5 4 16.05 4 15.5C4 14.95 4.45 14.5 5 14.5C5.55 14.5 6 14.95 6 15.5C6 16.05 5.55 16.5 5 16.5ZM13 4.5C13 3.4 12.1 2.5 11 2.5C9.9 2.5 9 3.4 9 4.5C9 5.6 9.9 6.5 11 6.5C12.1 6.5 13 5.6 13 4.5ZM10 4.5C10 3.95 10.45 3.5 11 3.5C11.55 3.5 12 3.95 12 4.5C12 5.05 11.55 5.5 11 5.5C10.45 5.5 10 5.05 10 4.5ZM5 3.5C3.9 3.5 3 4.4 3 5.5C3 6.6 3.9 7.5 5 7.5C6.1 7.5 7 6.6 7 5.5C7 4.4 6.1 3.5 5 3.5ZM5 6.5C4.45 6.5 4 6.05 4 5.5C4 4.95 4.45 4.5 5 4.5C5.55 4.5 6 4.95 6 5.5C6 6.05 5.55 6.5 5 6.5ZM17.87 12.01H18.88C18.15 17.09 13.78 21 8.5 21C5.65 21 2.9 19.88 0.97 17.92C0.34 17.26 0 16.4 0 15.5C0 14.04 0.92 12.72 2.29 12.22C3.02 11.96 3.5 11.27 3.5 10.5C3.5 9.73 3.01 9.04 2.29 8.78C0.92 8.28 0 6.96 0 5.5C0 4.6 0.34 3.74 0.96 3.09C2.9 1.12 5.65 0 8.5 0C12.39 0 15.93 2.16 17.74 5.51L16.98 6.24C15.38 3.07 12.11 1.01 8.5 1.01C5.91 1.01 3.43 2.03 1.68 3.8C1.24 4.25 1 4.86 1 5.5C1 6.54 1.66 7.48 2.63 7.84C3.74 8.24 4.5 9.31 4.5 10.5C4.5 11.69 3.75 12.75 2.63 13.16C1.66 13.52 1 14.46 1 15.5C1 16.14 1.24 16.75 1.69 17.22C3.43 18.98 5.92 20 8.5 20C13.23 20 17.14 16.53 17.87 12.01Z"
      fill="#C53DD8"
    />
  </svg>
);
