export enum Popups {
  apiKeyCreated = 'apiKeyCreated',
  resetKey = 'resetKey',
  deleteProject = 'deleteProject',
  deleteWebhook = 'deleteWebhook',
  addNewProject = 'addNewProject',
  createWebhook = 'createWebhook',
  eventCreateWebhook = 'eventCreateWebhook',
  deleteAccount = 'deleteAccount',
  renameTeam = 'renameTeam',
  createTeam = 'createTeam',
  addTeamMember = 'addTeamMember',
  updateTeamMemberRole = 'updateTeamMemberRole',
  deleteTeamMember = 'deleteTeamMember',
  deleteEvent = 'deleteEvent',
}
