import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePopup } from '../../../../redux/actions/popupsActions';
import {
  getRenameTeamtDialog,
  getPrefilled,
} from '../../../../redux/reducers/popupsReducer';
import { updateTeamName } from '../../../../redux/actions/teamsActions';
import { Popups } from '../../../../types/popups';

export const useRenameTeam = () => {
  const isOpen = useSelector(getRenameTeamtDialog);
  const dispatch = useDispatch();
  const teamName = useSelector(getPrefilled);

  const [newTeamName, setNewTeamName] = useState(teamName || '');
  const [touched, setTouched] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setNewTeamName(event.target.value);
  };

  const handleClose = () => {
    setTouched(false);
    dispatch(updatePopup({ popup: Popups.renameTeam, status: false }));
  };

  const handleRename = () => {
    handleClose();
    setTouched(false);
    dispatch(updateTeamName(newTeamName));
  };

  return {
    isOpen,
    teamName,
    newTeamName,
    touched,
    handleChange,
    handleClose,
    handleRename,
  };
};
