import {
  Blockchain,
  Network,
  Project,
  GetProjectsBlockchain,
} from '../../types/project';

export const GET_PROJECTS_SERVER = 'GET_PROJECTS_SERVER';
export const UPDATE_LOADING_PROJECTS = 'UPDATE_LOADING_PROJECTS';
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS';
export const CREATE_PROJECT_SERVER = 'CREATE_PROJECT_SERVER';
export const UPDATE_IS_PROJECT_CREATING = 'UPDATE_IS_PROJECT_CREATING';
export const DELETE_PROJECT_SERVER = 'DELETE_PROJECT_SERVER';
export const ADD_TO_LOADING_PROJECTS = 'ADD_TO_LOADING_PROJECTS';
export const REMOVE_FROM_LOADING_PROJECTS = 'REMOVE_FROM_LOADING_PROJECTS';
export const ROTATE_API_KEY_SERVER = 'ROTATE_API_KEY_SERVER';
export const ADD_TO_RESET_KEYS = 'ADD_TO_RESET_KEYS';
export const REMOVE_FROM_RESET_KEYS = 'REMOVE_FROM_RESET_KEYS';

export const GET_PROJECTS_NETWORKS = 'GET_PROJECTS_NETWORKS';
export const UPDATE_PROJECTS_NETWORKS = 'UPDATE_PROJECTS_NETWORKS';
export const UPDATE_PROJECTS_NETWORKS_LOADING =
  'UPDATE_PROJECTS_NETWORKS_LOADING';

interface GetProjectsProps {
  project_ids?: number[];
}

interface CreateProjectProps {
  name: string;
  network: Network;
  chain: Blockchain;
}

interface DeleteProjectProps {
  id: string;
  prevProjects: Project[];
}

interface UpdateStatusProps {
  status: boolean;
}

interface OneLoadingProjectProps {
  id: string;
}

export const getProjectsServer = (data: GetProjectsProps) => ({
  type: GET_PROJECTS_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: `/project`,
      data,
    },
  },
});

export const updateProjectsLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_LOADING_PROJECTS,
  payload: { status },
});

export const getProjectsNetworks = () => ({
  type: GET_PROJECTS_NETWORKS,
  payload: {
    request: {
      method: 'GET',
      url: `/project/chain-networks`,
    },
  },
});

export const updateProjectsNetworksLoading = ({
  status,
}: UpdateStatusProps) => ({
  type: UPDATE_PROJECTS_NETWORKS_LOADING,
  payload: { status },
});

export const updateProjectsNetworks = (networks: GetProjectsBlockchain[]) => ({
  type: UPDATE_PROJECTS_NETWORKS,
  payload: { networks },
});

export const updateProjects = (projects: Project[]) => ({
  type: UPDATE_PROJECTS,
  payload: { projects },
});

export const createProjectsServer = ({
  name,
  network,
  chain,
}: CreateProjectProps) => ({
  type: CREATE_PROJECT_SERVER,
  payload: {
    request: {
      method: 'POST',
      url: `/project/create`,
      data: { name, network, chain },
    },
  },
});

export const updateIsProjectCreating = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_IS_PROJECT_CREATING,
  payload: { status },
});

export const deleteProjectsServer = ({
  id,
  prevProjects,
}: DeleteProjectProps) => ({
  type: DELETE_PROJECT_SERVER,
  payload: {
    request: {
      method: 'DELETE',
      url: `/project/${id}/delete`,
    },
    id,
    prevProjects,
  },
});

export const addToLoadingProjects = ({ id }: OneLoadingProjectProps) => ({
  type: ADD_TO_LOADING_PROJECTS,
  payload: { id },
});

export const removeFromLoadingProjects = ({ id }: OneLoadingProjectProps) => ({
  type: REMOVE_FROM_LOADING_PROJECTS,
  payload: { id },
});

export const rotateApiKeyServer = ({ id }: OneLoadingProjectProps) => ({
  type: ROTATE_API_KEY_SERVER,
  payload: {
    request: {
      method: 'PUT',
      url: `/project/${id}/rotate`,
    },
    id,
  },
});

export const addToResetKeys = ({ id }: OneLoadingProjectProps) => ({
  type: ADD_TO_RESET_KEYS,
  payload: { id },
});

export const removeFromResetKeys = ({ id }: OneLoadingProjectProps) => ({
  type: REMOVE_FROM_RESET_KEYS,
  payload: { id },
});
