import { all } from 'redux-saga/effects';

import authSagas from './authSaga';
import projectsSagas from './projectsSaga';
import requestVolumeSagas from './requestVolumeSaga';
import singleProjectSagas from './singleProjectSaga';
import stripeSagas from './stripeSaga';
import transactionsSagas from './transactionsSaga';
import usersSagas from './usersSaga';
import webhooksSagas from './webhooksSaga';
import teamSaga from './teamSaga';
import eventsSaga from './eventsSaga';

function* rootSaga() {
  yield all([
    ...authSagas,
    ...transactionsSagas,
    ...projectsSagas,
    ...singleProjectSagas,
    ...requestVolumeSagas,
    ...stripeSagas,
    ...webhooksSagas,
    ...usersSagas,
    ...teamSaga,
    ...eventsSaga,
  ]);
}

export default rootSaga;
