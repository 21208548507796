export const TurboTXIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.37558 3.75096H5.25058L6.25058 0.813457C6.43808 0.188457 5.68808 -0.311543 5.18808 0.188457L0.188076 5.18846C-0.186924 5.56346 0.0630757 6.25096 0.625576 6.25096H4.75058L3.75058 9.18846C3.56308 9.81346 4.31308 10.3135 4.81308 9.81346L9.81308 4.81346C10.1881 4.43846 9.93808 3.75096 9.37558 3.75096Z"
      fill="#0F0311"
    />
  </svg>
);
