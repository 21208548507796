export const PenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M2.75 15.75C2.75 15.75 6.349 15.182 7.296 14.235C8.243 13.288 14.623 6.90799 14.623 6.90799C15.46 6.07099 15.46 4.71399 14.623 3.87799C13.786 3.04099 12.429 3.04099 11.593 3.87799C11.593 3.87799 5.213 10.258 4.266 11.205C3.319 12.152 2.751 15.751 2.751 15.751L2.75 15.75Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15.75H15.25"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
