export const ConductorIcon = () => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.99 10.3L25.74 9.33L16.17 11.75L13.21 8.33L11.49 8.5L13.38 6.9L12.08 4.93L10.5 5.98L11.39 3.86L9.34 2.7L5.62 9.25C4.28 10.93 3.28 12.92 2.66 15.15C2.17 16.9 1.98 18.54 2.08 20.29L0.24 20.22L0 26.22L1 26.26L1.11 23.37L9.1 23.68L9 26.57L10 26.61L10.24 20.61L8.16 20.53C8.09 19.64 8.1 18.75 8.23 17.85L14.08 17.29L15.76 14.17L14.14 13.3L25.99 10.3ZM11.8 6.32L12.06 6.71L9.75 8.67L7.99 8.83L11.8 6.32ZM9.71 4.05L10.12 4.28L9 6.97L7.5 7.96L9.71 4.05ZM9.2 21.57L9.16 22.68L1.17 22.37L1.21 21.27L9.2 21.57ZM14.41 14.58L13.46 16.35L7.38 16.93L7.31 17.31C7.12 18.37 7.07 19.44 7.15 20.5L3.09 20.34C3.01 18.69 3.18 17.04 3.63 15.43C4.2 13.38 5.1 11.56 6.31 10.01L12.81 9.39L15.1 12.04L14.12 12.29L12.05 10.47L10.17 10.65C9.51 10.71 8.91 11.03 8.48 11.55C8.06 12.07 7.86 12.72 7.92 13.38C7.98 14.04 8.3 14.64 8.82 15.07C9.34 15.5 9.99 15.69 10.65 15.63L12.79 15.42L14.05 14.4L14.41 14.58ZM10.36 14.26L13.62 13.43L12.38 14.44L10.54 14.62C10.14 14.66 9.75 14.54 9.44 14.28C9.13 14.03 8.94 13.67 8.9 13.27C8.86 12.87 8.98 12.48 9.24 12.17C9.49 11.86 9.86 11.67 10.25 11.63L11.7 11.49L12.93 12.57L10.11 13.28L10.36 14.26ZM19.35 8.65C19.42 8.66 19.49 8.66 19.56 8.66C20.02 8.66 20.46 8.51 20.81 8.21C21.23 7.87 21.48 7.4 21.54 6.87L22.09 1.71L24.23 3.08L24.77 2.24L21.26 0L20.72 5.06C20.44 4.85 20.11 4.72 19.75 4.68C19.22 4.62 18.7 4.78 18.28 5.11C17.86 5.45 17.6 5.92 17.55 6.45C17.51 6.98 17.67 7.5 18 7.92C18.34 8.33 18.81 8.59 19.35 8.65ZM18.93 5.88C19.11 5.73 19.33 5.66 19.56 5.66C19.6 5.66 19.63 5.66 19.67 5.67C19.94 5.7 20.17 5.83 20.34 6.04C20.51 6.25 20.58 6.51 20.56 6.77C20.53 7.04 20.4 7.27 20.19 7.44C19.98 7.61 19.72 7.68 19.46 7.66C19.19 7.63 18.96 7.5 18.79 7.29C18.62 7.08 18.55 6.82 18.57 6.56C18.59 6.29 18.72 6.05 18.93 5.88Z"
      fill="#C53DD8"
    />
  </svg>
);
