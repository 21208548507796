import { useFormik } from 'formik';
import { Dropdown, TextField } from '@maestro-org/ui-kit';
import { styled, Theme, useTheme } from '@mui/material';

import { NewEntryField, NewEntryFieldTypes } from '../../types/newEntry';
import { CreateWebhookFields, CreateWebhookFormValues } from './types';
import { initialValuesCreateWebhook } from './form';
import { getBlockchainIcon } from '../../lib/createProject.utils';
import { createEventSchema } from './validation';

import { Option } from '../../types/newEntry';

interface UseFieldsProps {
  onSubmit: (values: CreateWebhookFormValues) => void;
}

const useFields = ({ onSubmit }: UseFieldsProps) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues: initialValuesCreateWebhook,
    validationSchema: createEventSchema,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit,
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  const handleDropdownChange = (value: string[], name: string) => {
    if (name === CreateWebhookFields.blockchain)
      setFieldValue(CreateWebhookFields.network, [], true);
    setFieldValue(name, value, true);
  };

  const handleDropdownBlur = (name: string) => {
    setFieldTouched(name, true, true);
  };

  const checkError = (name: string) =>
    !!errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];

  const getSelectedLabels = (selectedValues: string[], options: Option[]) => {
    return selectedValues
      .map((val) => {
        const option = options.find((opt) => opt.value === val);
        return option ? option.label : val;
      })
      .join(', ');
  };

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || '';

    const menuProps = {
      PaperProps: {
        style: {
          ...getMenuStyle(theme, checkError(field.name) as boolean),
        },
      },
    };

    const isDisabled =
      field.name === CreateWebhookFields.network &&
      values[CreateWebhookFields.blockchain].length === 0;
    const startAdornment =
      field.name === CreateWebhookFields.blockchain
        ? getBlockchainIcon(values[field.name][0])
        : null;

    const fieldName = field.name as CreateWebhookFields;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextfield
          label={field.label}
          variant="outlined"
          name={fieldName}
          value={fieldValue}
          onChange={handleChange}
          onBlur={handleBlur}
          error={checkError(fieldName) as boolean}
          fieldLabel={field.fieldLabel}
          inputProps={
            field.name === CreateWebhookFields.url
              ? { maxLength: 100 }
              : { maxLength: 64 }
          }
          disabled={isDisabled}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          label={field.label}
          name={fieldName}
          fullWidth
          onBlur={() => handleDropdownBlur(fieldName)}
          value={values[fieldName] as any}
          renderValue={(selected) =>
            getSelectedLabels(selected as string[], field.options || [])
          }
          fieldLabel={field.fieldLabel}
          onChange={(value) => handleDropdownChange(value, fieldName)}
          options={field.options || []}
          startAdornment={startAdornment}
          error={checkError(fieldName) as boolean}
          disabled={isDisabled}
          MenuProps={menuProps}
        />
      ),
    };
  };

  return {
    values,
    getField,
    isValid: formik.isValid,
    dirty: formik.dirty,
    handleSubmit,
    resetForm: formik.resetForm,
  };
};

const StyledTextfield = styled(TextField)<{ isMaxReached?: boolean }>(
  ({ isMaxReached }) => ({
    width: '100%',
    '& fieldset': {
      borderRadius: '4px !important',
      borderWidth: '1px !important',
    },

    '& .MuiInputBase-root': {
      padding: '12.5px 16px',
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: '#DC6675 !important',
    },

    ...(isMaxReached && {
      '& fieldset': {
        borderColor: '#DC6675 !important',
      },

      '& .Mui-focused fieldset': {
        borderColor: '#DC6675 !important',
      },
    }),
  })
);

const IconWrapper = styled('div')({
  display: 'flex',
  gap: '8px',
});

const ButtonWrapper = styled('div')<{ isDisabled: boolean }>(
  ({ isDisabled }) => ({
    display: 'flex',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: isDisabled ? 'none' : 'auto',
    opacity: isDisabled ? 0.5 : 1,

    '&:hover': {
      '& > svg': {
        '& > path:first-of-type': {
          fill: '#C53DD8',
        },
      },
    },
  })
);

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  paddingLeft: '0',

  '& .MuiSelect-select': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px !important',
    borderWidth: '1px !important',
  },

  '& .MuiInputBase-inputAdornedStart': {
    paddingLeft: '28px !important',
  },

  '& img': {
    position: 'absolute',
    left: '7px',
  },
}));

const getMenuStyle = (theme: Theme, error?: boolean, width?: number) => ({
  width: width ? `${width}px` : 'auto',
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius.sm,
  boxShadow: 'none',
  border: `2px solid ${
    error
      ? theme.palette.dropdown.border.error
      : theme.palette.dropdown.border.main
  }`,
  transform: 'translateY(5px)',
});

export default useFields;
