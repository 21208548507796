import { styled } from '@mui/material';
import { NewEntryField } from '../../../../types/newEntry';
import DynamicFieldsGroup from '../components/DynamicFieldGroup';
import FormFields from '../../common/FormFields';
import { AddressMinusIcon } from '../../../Icons/AddressMinusIcon';
import { TriggerCondition } from '../../../../forms/createWebhook/types';
import { Option } from '../../../../types/newEntry';

interface TriggerConditionsListProps {
  triggerConditions: TriggerCondition[];
  onFieldChange: (conditionId: string, fieldName: string, value: any) => void;
  onRemoveCondition: (conditionId: string) => void;
  getFieldDisabledOptions: (
    id: string,
    conditionalsPicklist: Option[]
  ) => Option[];
  fields: (fieldOptions: Option[]) => NewEntryField[];
  conditionalsPicklist: Option[];
}

const TriggerConditionsList = ({
  triggerConditions,
  onFieldChange,
  onRemoveCondition,
  getFieldDisabledOptions,
  fields,
  conditionalsPicklist,
}: TriggerConditionsListProps) => {
  return (
    <>
      {triggerConditions.map((condition, index) => (
        <FormFields key={condition.id} equalWidth>
          <DynamicFieldsGroup
            condition={condition}
            onFieldChange={(fieldName: string, value: any) => {
              onFieldChange(condition.id, fieldName, value);
            }}
            fields={fields(
              getFieldDisabledOptions(condition.id, conditionalsPicklist)
            )}
          />
          {index > 0 && (
            <DeleteRow onClick={() => onRemoveCondition(condition.id)}>
              <AddressMinusIcon />
            </DeleteRow>
          )}
        </FormFields>
      ))}
    </>
  );
};

const DeleteRow = styled('div')({
  display: 'flex',
  width: 'unset !important',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '3px',
  position: 'absolute',
  right: '-33px',
  top: '20.5px',
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:hover': {
    '& path:first-of-type': {
      fill: '#C53DD8',
      transition: 'all 0.3s',
    },
    '& path:last-of-type': {
      fill: '#FFF',
    },
  },
});

export default TriggerConditionsList;
