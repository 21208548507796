import { Typography, styled } from '@mui/material';
import React from 'react';

import { numberDecorator } from '../../../lib/numberDecorator';
import { Loader } from '@maestro-org/ui-kit';

interface Props {
  title: string;
  value: number;
  isLoading: boolean;
}

const WidgetCard = ({ title, value, isLoading }: Props) => {
  return (
    <Wrapper>
      <Typography variant="paragraphMedium" color="grey.500">
        {title}
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <Value variant="h4" color="grey.A200">
          {numberDecorator(value)}
        </Value>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
  background: theme.palette.common.white,
  borderRadius: '2px',
  //   border: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: '0px 8px 30px -2px rgba(0, 0, 0, 0.04)',
  width: 'calc((100% - 496px) / 3 - 18px)',

  [theme.breakpoints.down('lg')]: {
    width: 'calc((100% - 386px) / 3 - 8px)',
  },
}));

const Value = styled(Typography)({
  marginTop: 'auto',
});

export default WidgetCard;
