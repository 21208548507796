import {
  UPDATE_IS_EVENT_CREATING,
  UPDATE_EVENTS_LOADING,
  UPDATE_EVENTS_SERVER,
  UPDATE_PICKLIST_ANCHORS_OPTIONS_SERVER,
  UPDATE_PICKLIST_CONDITIONALS_OPTIONS_SERVER,
  UPDATE_PICKLIST_OPERATORS_OPTIONS_SERVER,
} from '../actions/eventsActions';
import { StoreType } from '../types/store.types';

export interface ApiTrigger extends Trigger {
  id: string;
}

export interface Trigger {
  event_manager_id: string;
  trigger_type: string;
  tracked_id: string;
  filters: Filters[];
}

export interface Filters {
  key: string;
  operator: string;
  value: string;
}

export interface EventManagerProps {
  chain: string;
  name: string;
  network: string;
  webhook_url: string;
  triggers: Trigger[];
}

export interface EventManagerItem {
  chain: string;
  id: string;
  name: string;
  network: string;
  status: string;
  webhookUrl: string;
  triggers: Trigger[];
}

export interface SingleOption {
  display_name: string;
  list_name: string;
  sort_order: number;
  value: string;
}

export interface PicklistOptions {
  display_name: string;
  list_name: string;
  nested_list_name: string;
  options: SingleOption[];
  sort_order: number;
  value: string;
}

export interface eventsStateType {
  eventsServer: EventManagerItem[];
  isEventCreating: boolean;
  isEventsLoading: boolean;
  picklistAnchorsOptions: PicklistOptions[];
  picklistConditionalsOptions: PicklistOptions[];
  picklistOperatorsOptions: PicklistOptions[];
}

export const eventsInitialState: eventsStateType = {
  eventsServer: [] as EventManagerItem[],
  isEventCreating: false,
  isEventsLoading: false,
  picklistAnchorsOptions: [],
  picklistConditionalsOptions: [],
  picklistOperatorsOptions: [],
};

const eventsReducer = (state = eventsInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_IS_EVENT_CREATING:
      return {
        ...state,
        isEventCreating: action.payload.status,
      };

    case UPDATE_EVENTS_LOADING:
      return {
        ...state,
        isEventsLoading: action.payload.status,
      };

    case UPDATE_EVENTS_SERVER:
      return {
        ...state,
        eventsServer: action.payload.eventsServer,
      };

    case UPDATE_PICKLIST_ANCHORS_OPTIONS_SERVER:
      return {
        ...state,
        picklistAnchorsOptions: action.payload.picklistAnchorsOptions,
      };

    case UPDATE_PICKLIST_CONDITIONALS_OPTIONS_SERVER:
      return {
        ...state,
        picklistConditionalsOptions: action.payload.picklistConditionalsOptions,
      };

    case UPDATE_PICKLIST_OPERATORS_OPTIONS_SERVER:
      return {
        ...state,
        picklistOperatorsOptions: action.payload.picklistOperatorsOptions,
      };
    default:
      return state;
  }
};

export const getEvents = (state: StoreType) => state.events.eventsServer || [];
export const getIsEventCreating = (state: StoreType) =>
  state.events.isEventCreating;
export const getIsEventsLoading = (state: StoreType) =>
  state.events.isEventsLoading;
export const getAnchorsOptions = (state: StoreType) =>
  state.events.picklistAnchorsOptions || [];
export const getConditionalsOptions = (state: StoreType) =>
  state.events.picklistConditionalsOptions || [];
export const getOperatorsOptions = (state: StoreType) =>
  state.events.picklistOperatorsOptions || [];

export default eventsReducer;
