import { Button, Loader } from '@maestro-org/ui-kit';
import { styled, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsUserDeleting } from '../../../redux/reducers/usersReducer';
import { updatePopup } from '../../../redux/actions/popupsActions';

import SettingsCard from './SettingsCard';

import { Popups } from '../../../types/popups';

const DeleteAccount = () => {
  const isDeleting = useSelector(getIsUserDeleting);
  const dispatch = useDispatch();

  const handleDeleteClick = () => {
    dispatch(updatePopup({ popup: Popups.deleteAccount, status: true }));
  };

  return (
    <SettingsCard>
      <Typography color="grey.A200" variant="h6">
        Delete account
      </Typography>
      <Typography color="grey.A200" variant="paragraphSmall">
        Deleting your account will permanently delete all of your projects and
        webhooks. Once you confirm deletion, this process cannot be undone.
      </Typography>
      <div>
        <Button onClick={handleDeleteClick}>
          {isDeleting ? <StyledLoader size={20} /> : 'Delete'}
        </Button>
      </div>
    </SettingsCard>
  );
};

const StyledLoader = styled(Loader)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export default DeleteAccount;
