import { ReactNode } from 'react';
import { Typography } from '@mui/material';

import Dialog from '../Dialog';
import DialogHeader from '../DialogHeader';
import DialogActions from '../DialogActions';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  description?: string | ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel?: string;
  children?: ReactNode;
}

const ConfirmationDialog = ({
  isOpen,
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel = 'Confirm',
  children,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={isOpen}>
      <DialogHeader title={title} />

      {description && (
        <Typography color="grey.A200" variant="paragraphMedium">
          {description}
        </Typography>
      )}

      {children}

      <DialogActions
        onCancel={onCancel}
        onSubmit={onConfirm}
        submitLabel={confirmLabel}
      />
    </Dialog>
  );
};

export default ConfirmationDialog;
