export const BillingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.94531 8.05469H18.0564"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1658 15.8359L15.8325 15.8359C17.0598 15.8359 18.0547 14.841 18.0547 13.6137V6.39149C18.0547 5.16419 17.0598 4.16927 15.8325 4.16927L4.1658 4.16927C2.9385 4.16927 1.94358 5.16419 1.94358 6.39149V13.6137C1.94358 14.841 2.9385 15.8359 4.1658 15.8359Z"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.72266 12.5H8.05599"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.168 12.5H15.2791"
      stroke="#212121"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
