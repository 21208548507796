import { styled, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Loader } from '@maestro-org/ui-kit';

import PlanPackage from './PlanPackage';
import { Package, TiersProps } from '../../../redux/reducers/stripeReducer';
import { CompareIcon, WhiteLogoIcon } from '../../../components/Icons';

import { formActiveSubscriptionName } from '../../../lib/subscriptionPlanUtils';
import { SubscriptionPlan } from '../../../types/subscription';

interface Props {
  selectedPlan?: Package;
  subscriptionTiers?: TiersProps;
  subscriptionPlans: Package[];
  activeSubscriptionId: string;
  activeSubscriptionName: SubscriptionPlan;
  isLoading: boolean;
  isVirtuosoAnnual: boolean;
  isUserEnterprise: boolean;
  handleToogleSwitch: () => void;
  handlePlanSelection: (planId: string) => void;
}

const APIPackages = ({
  selectedPlan,
  subscriptionPlans,
  isLoading,
  isVirtuosoAnnual,
  subscriptionTiers,
  activeSubscriptionId,
  activeSubscriptionName,
  isUserEnterprise,
  handleToogleSwitch,
  handlePlanSelection,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1512));
  return (
    <Wrapper>
      <TitleOutterWrapper>
        <TitleWrapper>
          <Typography color="grey.A200" variant="h5">
            API Packages
          </Typography>
          <TextWrapper>
            <Typography color="grey.A200" variant="paragraphSmall">
              Currently subscribed to{' '}
              <PlanName>
                {formActiveSubscriptionName(
                  activeSubscriptionId,
                  activeSubscriptionName,
                  subscriptionPlans
                )}
              </PlanName>
            </Typography>
          </TextWrapper>
        </TitleWrapper>
        <CompareWrapper
          href="https://www.gomaestro.org/pricing#plan-comparison"
          target="_blank"
        >
          <CompareText color="textColor.contrastText">
            Compare Plans
          </CompareText>
          <CompareIcon />
        </CompareWrapper>
      </TitleOutterWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <Content>
          <PackagesWrapper>
            <InnerWrapper>
              {(subscriptionPlans || []).slice(0, 2).map((item) => (
                <PlanPackage
                  {...item}
                  key={item.id}
                  isDisabled={isUserEnterprise}
                  isVirtuosoAnnual={isVirtuosoAnnual}
                  subscriptionTiers={subscriptionTiers}
                  handleToogleSwitch={handleToogleSwitch}
                  handleChangeActive={handlePlanSelection}
                  isPlanSelected={item.id === selectedPlan?.id}
                />
              ))}
            </InnerWrapper>
            <InnerWrapper>
              {(subscriptionPlans || []).slice(2, 3).map((item) => (
                <PlanPackage
                  {...item}
                  key={item.id}
                  isDisabled={isUserEnterprise}
                  isVirtuosoAnnual={isVirtuosoAnnual}
                  subscriptionTiers={subscriptionTiers}
                  handleToogleSwitch={handleToogleSwitch}
                  handleChangeActive={handlePlanSelection}
                  isPlanSelected={item.id === selectedPlan?.id}
                />
              ))}
              <ContactWrapper>
                <ContactTitleWrapper>
                  <WhiteLogoIcon></WhiteLogoIcon>
                  <Typography variant="subtitle1">Enterprise</Typography>
                </ContactTitleWrapper>
                <ContactTextWrapper>
                  <Typography variant="subtitle4" color="#F0D351">
                    Tailored solution&nbsp;
                    {isMobile && <br></br>}
                    <Typography
                      component="span"
                      variant="subtitle4"
                      color="#FFF"
                    >
                      for professionals.
                    </Typography>
                  </Typography>
                </ContactTextWrapper>
                <StyledLink href="mailto:support@gomaestro.org">
                  <Button>
                    <Typography
                      variant="footer5"
                      style={{ lineHeight: '16px' }}
                    >
                      {isUserEnterprise ? 'Subscribed' : 'Contact Us'}
                    </Typography>
                  </Button>
                </StyledLink>
              </ContactWrapper>
            </InnerWrapper>
          </PackagesWrapper>
          {/* <Fade /> */}
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '24px',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '12px',
  position: 'relative',
});

const PlanName = styled('strong')({
  textTransform: 'capitalize',
});

const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const TitleOutterWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  position: 'relative',
});

const InnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '36px',

  [theme.breakpoints.down(1700)]: {
    gap: '20px',
  },

  [theme.breakpoints.down(1330)]: {
    flexDirection: 'column',
    width: '100%',
  },
}));

const ContactWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '32px',
  width: 'calc(50% - 18px)',
  flex: 1,
  borderRadius: '12px 12px 12px 12px',
  background: theme.palette.chart.barThird,

  [theme.breakpoints.down(1700)]: {
    width: 'calc(50% - 10px)',
  },

  [theme.breakpoints.down(1330)]: {
    width: '100%',
  },
}));

const ContactTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const ContactTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down(1330)]: {
    marginTop: '14px',
  },
}));

const Button = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '14px 24px',
  borderRadius: '12px',
  width: '100%',
  backgroundColor: theme.palette.social.gray,

  [theme.breakpoints.down(1330)]: {
    marginTop: '20px',
  },
}));

const CompareText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const CompareWrapper = styled('a')(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '6px',

  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  justifySelf: 'end',

  [theme.breakpoints.down('md')]: {
    position: 'relative',
    display: 'flex',
  },
}));

const Content = styled('div')({
  position: 'relative',
});

const PackagesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  // overflow: "auto hidden",
  paddingTop: '16px',
  position: 'relative',

  '&::-webkit-scrollbar': {
    height: '2px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#E6E6E6',
    height: '2px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#0F0311',
    borderRadius: '2px',
    height: '2px',
  },

  '&::-webkit-scrollbar-button': {
    display: 'none',
  },

  [theme.breakpoints.down('xl')]: {
    columnGap: '26px',
  },
}));

const StyledLink = styled('a')({
  textDecoration: 'none',
  color: '#0F0311',
});

export default APIPackages;
