import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@maestro-org/ui-kit';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { getClientSecret } from '../../../redux/reducers/stripeReducer';

import SettingsCard from './SettingsCard';
import Divider from '../../../components/Divider/Divider';

import { pages } from '../../../lib/routeUtils';

import { IBillingInfo, ICard } from '../../../types/auth';
import {
  addPaymentServer,
  updateClientSecret,
} from '../../../redux/actions/stripeActions';
import { updateBillingServer } from '../../../redux/actions/userActions';
import { toast } from 'react-toastify';
import { getStripeError } from '../../../lib/errors';

interface Props {
  isLoading: boolean;
  cardData?: ICard;
}

const AddFirstPaymentMethod = ({ isLoading, cardData }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [billingInfo, setBillingInfo] = useState<{
    name: string;
    address: {
      state: string;
      city: string;
      country: string;
      line1: string;
      line2: string | null;
      postal_code: string;
    };
  }>();

  const dispatch = useDispatch();

  const clientSecret = useSelector(getClientSecret);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!billingInfo) return;

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await (elements as any).submit();
    if (submitError) {
      return;
    }

    dispatch(addPaymentServer());
  };

  useEffect(() => {
    if (!clientSecret || !stripe || !elements || !billingInfo) {
      dispatch(updateClientSecret({ clientSecret: '' }));
      return;
    }

    const handleAddPayment = async () => {
      dispatch(
        updateBillingServer({
          address: billingInfo.address,
          name: billingInfo.name,
        })
      );
      const result = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.origin + pages.subscriptionPlan(),
          payment_method_data: {
            billing_details: {
              address: billingInfo.address,
              name: billingInfo.name,
            },
          },
        },
      } as any);

      if (result.error)
        toast.error(
          getStripeError(
            result,
            'Could not add payment method. Try again later!'
          )
        );
      else toast.success('New payment method was successfully added!');

      dispatch(updateClientSecret({ clientSecret: '' }));

      navigate(pages.subscriptionPlan());
    };
    handleAddPayment();
  }, [clientSecret]);

  return (
    <form id="payment-details" onSubmit={onSubmit}>
      <SettingsCard>
        <Typography color="grey.A200" variant="h6">
          Add new payment
        </Typography>
        <FieldsWrapper>
          {isLoading && <p>loading</p>}
          {!isLoading && (
            <PaymentElement
              options={{
                wallets: {
                  applePay: 'never',
                  googlePay: 'never',
                },
              }}
            />
          )}
        </FieldsWrapper>
        <Typography color="grey.A200" variant="h6">
          Billing information
        </Typography>
        <FieldsWrapper>
          {isLoading && <p>loading</p>}
          {!isLoading && (
            <AddressElement
              onChange={(event) => setBillingInfo(event.value)}
              options={{
                mode: 'billing',
                display: {
                  name: 'split',
                },
              }}
            />
          )}
        </FieldsWrapper>
        <Actions>
          <Link to={pages.subscriptionPlan()}>
            <CancelButton>Cancel</CancelButton>
          </Link>
          <SubmitButton type="submit" disabled={!stripe}>
            Save
          </SubmitButton>
        </Actions>
        <BottomWrapper>
          <Divider />
          <Typography color="grey.400" variant="article">
            By updating your payment details, you agree to turn on automatic
            renewal for your current subscription. See offer details and
            cancellation terms{' '}
            <Ref
              href="https://storage.googleapis.com/ispo-marketplace/legal/Privacy_Policy.pdf"
              target="_blank"
            >
              here
            </Ref>
            .
          </Typography>
        </BottomWrapper>
      </SettingsCard>
    </form>
  );
};

const FieldsWrapper = styled('div')(({ theme }) => ({
  width: '590px',

  [theme.breakpoints.down(1101)]: {
    width: '100%',
  },
}));

const Actions = styled('div')({
  display: 'flex',
  columnGap: '16px',
  alignItems: 'center',
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey['A200'],

  '&:hover': {
    background: theme.palette.grey[50],
    color: theme.palette.grey['A200'],
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
}));

const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

const Ref = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export default AddFirstPaymentMethod;
