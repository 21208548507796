import { styled, Typography } from '@mui/material';
import { EventStatus } from '../../../types/event';

interface Props {
  status: EventStatus;
}

const getCircleColor = {
  [EventStatus.active]: '#53B06A',
  [EventStatus.paused]: '#EEBE44',
};

const getCircleBorderColor = {
  [EventStatus.active]: '#46975A',
  [EventStatus.paused]: '#EAB226',
};

const EventStatusChip = ({ status }: Props) => {
  return (
    <Wrapper>
      <Circle
        background={getCircleColor[status]}
        borderColor={getCircleBorderColor[status]}
      />
      <Label>{status}</Label>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  padding: '4px 8px',
  borderRadius: '6px',
  alignItems: 'center',
  gap: '4px',
  boxShadow: '0px 1px 1.5px 0px #E6E6E6',
  background: theme.palette.common.white,
  border: '1px solid #CCC',
}));

const Circle = styled('div')<{ borderColor: string; background: string }>(
  ({ borderColor, background, theme }) => ({
    background: background,
    border: `1px solid ${borderColor}`,
    borderRadius: '50%',
    width: '5px',
    height: '5px',
  })
);

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '11px !important',
  color: theme.palette.social.dark,
  fontWeight: '500 !important',
  lineHeight: '12px !important',
  letterSpacing: '0.11px',
  textTransform: 'uppercase',
}));

export default EventStatusChip;
