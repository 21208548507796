import { useSelector } from 'react-redux';
import { Typography, styled } from '@mui/material';
import { Button } from '@maestro-org/ui-kit';

import { getCreateTeamDialog } from '../../../redux/reducers/popupsReducer';
import { getCreateTeamFields } from '../../../forms/createTeam/form';

import Dialog from '../common/Dialog';
import DialogForm from '../common/DialogForm';
import DialogHeader from '../common/DialogHeader';
import DialogActions from '../common/DialogActions';
import TeamFormFields from './components/TeamFormFields';
import { useCreateTeamForm } from './hooks/useCreateTeamForm';

const CreateTeam = () => {
  const isOpen = useSelector(getCreateTeamDialog);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    handleDropdownChange,
    handleDropdownBlur,
    checkError,
    isValid,
    dirty,
    handleClose,
    getMenuStyle,
  } = useCreateTeamForm();

  return (
    <Dialog open={isOpen}>
      <DialogForm onSubmit={handleSubmit}>
        <DialogHeader title="Name your team" />

        <TeamFormFields
          fields={getCreateTeamFields().slice(0, 1)}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleDropdownChange={handleDropdownChange}
          handleDropdownBlur={handleDropdownBlur}
          checkError={checkError}
          getMenuStyle={getMenuStyle}
        />

        <DialogActions
          onCancel={handleClose}
          submitDisabled={!isValid || !dirty}
          submitLabel="Create"
        />
      </DialogForm>
    </Dialog>
  );
};

export default CreateTeam;
