import React from 'react';
import { styled } from '@mui/material';

import Container from '../../../components/Container/Container';
import TipCard, { TipCardProps } from './TipCard';

interface Props {
  tips: TipCardProps[];
}

const Tips = ({ tips }: Props) => (
  <Container>
    <Wrapper>
      {tips.map((props) => (
        <TipCard key={props.title} {...props} />
      ))}
    </Wrapper>
  </Container>
);

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '& > div': {
    width: 'calc(33% - 18px)',
  },

  [theme.breakpoints.down('md')]: {
    rowGap: '20px',

    '& > div': {
      width: '100%',
    },
  },
}));

export default Tips;
