import { Theme, styled, useTheme } from '@mui/material';
import { Dropdown, TextField } from '@maestro-org/ui-kit';
import { NewEntryField, NewEntryFieldTypes } from '../../../../types/newEntry';
import { CreateTeamFields } from '../../../../forms/createTeam/types';

interface TeamFormFieldsProps {
  fields: NewEntryField[];
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleDropdownChange: (value: unknown, name: string) => void;
  handleDropdownBlur: (name: string) => void;
  checkError: (name: string) => boolean;
  getMenuStyle: (theme: Theme, error?: boolean, width?: number) => any;
}

const TeamFormFields = ({
  fields,
  values,
  handleChange,
  handleBlur,
  handleDropdownChange,
  handleDropdownBlur,
  checkError,
  getMenuStyle,
}: TeamFormFieldsProps) => {
  const theme = useTheme();

  const getField = (field: NewEntryField) => {
    const fieldValue = values[field.name as keyof typeof values] || '';
    const isTeamNameTooLong =
      field.name === CreateTeamFields.teamName && fieldValue.length >= 32;

    return {
      [NewEntryFieldTypes.text]: (
        <StyledTextField
          label={field.label}
          variant="outlined"
          name={field.name}
          value={fieldValue}
          onChange={handleChange}
          placeholder={field.placeholder}
          error={checkError(field.name)}
          onBlur={handleBlur}
          fieldLabel={field.fieldLabel}
          inputProps={{ maxLength: 32 }}
          isMaxReached={isTeamNameTooLong}
        />
      ),
      [NewEntryFieldTypes.select]: (
        <StyledSelect
          label={field.label}
          name={field.name}
          fullWidth
          onBlur={() => handleDropdownBlur(field.name)}
          value={values[field.name as keyof typeof values] as any}
          placeholder={field.placeholder}
          onChange={(value) => handleDropdownChange(value, field.name)}
          options={field.options || []}
          error={checkError(field.name)}
          fieldLabel={field.fieldLabel}
          MenuProps={{
            PaperProps: {
              style: {
                ...getMenuStyle(theme, checkError(field.name)),
              },
            },
          }}
        />
      ),
    };
  };

  return (
    <FormFields>
      <Row>
        {fields.map((field: NewEntryField) => getField(field)[field.type])}
      </Row>
    </FormFields>
  );
};

const FormFields = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
});

const Row = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',

  '& > div': {
    width: '100%',
  },

  '& > div:nth-child(2)': {
    maxWidth: '188px',
  },
});

const StyledTextField = styled(TextField)<{ isMaxReached: boolean }>(
  ({ isMaxReached }) => ({
    '& .MuiFormLabel-root.Mui-error': {
      color: '#DC6675 !important',
    },

    ...(isMaxReached && {
      '& fieldset': {
        borderColor: '#DC6675 !important',
      },

      '& .Mui-focused fieldset': {
        borderColor: '#DC6675 !important',
      },
    }),
  })
);

const StyledSelect = styled(Dropdown)(({ theme }) => ({
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px !important',
    borderWidth: '1px !important',
  },
}));

export default TeamFormFields;
