import { ReactNode } from 'react';
import { styled } from '@mui/material';
import { Button } from '@maestro-org/ui-kit';

interface DialogActionsProps {
  children?: ReactNode;
  onCancel: () => void;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  submitLabel?: string;
  showCancel?: boolean;
}

const DialogActions = ({
  children,
  onCancel,
  onSubmit,
  submitDisabled = false,
  submitLabel = 'Submit',
  showCancel = true,
}: DialogActionsProps) => {
  return (
    <Actions>
      {children}
      <ActionInnerWrapper>
        {showCancel && <CancelButton onClick={onCancel}>Cancel</CancelButton>}
        <Button
          type="submit"
          onMouseDown={(e) => e.preventDefault()}
          disabled={submitDisabled}
          onClick={onSubmit}
        >
          {submitLabel}
        </Button>
      </ActionInnerWrapper>
    </Actions>
  );
};

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '12px',
  marginTop: '20px',
});

const ActionInnerWrapper = styled('div')({
  display: 'flex',
  gap: '12px',
});

const CancelButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  background: theme.palette.grey[50],
  color: theme.palette.grey['A200'],

  '&:hover': {
    background: theme.palette.grey[50],
    color: theme.palette.grey['A200'],
  },
}));

export default DialogActions;
