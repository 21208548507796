import { SetupIntentResult } from '@stripe/stripe-js';

export const getDapiStripeError = (
  action: any,
  defaultErrorMessage: string
) => {
  return action?.error?.response?.data?.error || defaultErrorMessage;
};

export const getStripeError = (
  result: SetupIntentResult,
  defaultErrorMessage: string
) => {
  if (result.error?.type === 'card_error') return result.error.message;
  else {
    return defaultErrorMessage;
  }
};
