import * as Yup from 'yup';

import { AddTeamMemberFields } from './types';

export const addTeamMemberSchema = Yup.object().shape({
  [AddTeamMemberFields.emailAddress]: Yup.string()
    .email('Invalid email')
    .max(32, 'User email must be 32 characters or less')
    .required('Email is required'),
  [AddTeamMemberFields.role]: Yup.array().min(1, 'Role is required'),
});
