import { styled } from '@mui/material';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import {
  BarChart as BarChartFromLib,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { getTheme } from '../../../lib/theme/theme';

import { DateTabs } from '../../../modules/Home/components/TotalRequestVolume';
import StackedBarTooltip from './StackedBarTooltip';

import { ChartData } from '../../../types/chart';

interface Props {
  data: ChartData[];
  svgWidth?: number;
  gap?: number;
  barSize?: number;
  radius?: number;
  type?: DateTabs;
  variant?: 'default' | 'project';
}

const StackedBarChart = ({
  data,
  type,
  barSize = 44,
  radius = 8,
  variant = 'default',
  svgWidth = 1450,
  gap = 10,
}: Props) => {
  const [focusBar, setFocusBar] = useState<number>(-1);
  const [hover, setHover] = useState<any>({});
  const [activeValue, setValueActive] = useState('');
  const projects = data.length
    ? Object.keys(data[0]).filter((key) => key !== 'time')
    : [];
  const projectToRender: string[] =
    variant === 'default' ? projects : [projects[0]];
  const [viewportWidth, setViewportWidth] = useState(1920);
  const wrapperRef = useRef<any>(null);

  const isEmpty = data.every((item) =>
    Object.keys(item)
      .filter((key) => key !== 'time')
      .every((key) => item[key as keyof typeof item] === 0)
  );

  const theme = getTheme('light');

  const colors = [
    {
      active: theme.palette.chart.barFourth,
      disabled: theme.palette.chart.barFourthDisabled,
    },
    {
      active: theme.palette.chart.barThird,
      disabled: theme.palette.chart.barThirdDisabled,
    },
    {
      active: theme.palette.chart.barSecond,
      disabled: theme.palette.chart.barSecondDisabled,
    },
    {
      active: theme.palette.chart.barFirst,
      disabled: theme.palette.chart.barFirstDisabled,
    },
  ];

  const handleChange = (key: string, value: any) => {
    const newValue = {
      [key]: value,
    };

    if (value) setValueActive(key);
    setHover((hover: any) => ({ ...hover, ...newValue }));
  };

  useEffect(() => {
    if (!data.length) return;
    const projects = data.length
      ? Object.keys(data[0]).filter((key) => key !== 'time')
      : [];
    setHover(projects.reduce((acc, item) => ({ ...acc, [item]: false }), {}));
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window === 'undefined') return;
      setViewportWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getChartsWidth = () => {
    if (!wrapperRef?.current) return;

    const newWidth = wrapperRef.current.clientWidth;

    return Math.max(svgWidth, newWidth);
  };

  return (
    <ResponsiveContainerStyled width="100%" height={300}>
      <ScrollableContainer ref={wrapperRef}>
        <BackgroundWrapper>
          <BackgroundChart
            data={data}
            onMouseMove={(state: any) => {
              if (state.isTooltipActive) setFocusBar(state.activeTooltipIndex);
            }}
            onMouseLeave={() => setFocusBar(-1)}
            width={1452}
            height={300}
            barCategoryGap={gap / 2}
          >
            <CartesianGrid strokeWidth={1} vertical={false} stroke="#CCCCCC" />
            <XAxis dataKey="time" tickLine={false} axisLine={false} />
            <YAxis
              domain={isEmpty ? [0, 500] : ['auto', 'auto']}
              tickLine={false}
              axisLine={false}
              allowDecimals={false}
              width={100}
            />

            {projectToRender.map((project, index) => (
              <Bar
                barSize={0}
                dataKey={project}
                key={project}
                radius={
                  index === projectToRender.length - 1
                    ? [radius, radius, 0, 0]
                    : [0, 0, 0, 0]
                }
                stackId="a"
                fill={
                  hover[project] ||
                  Object.keys(hover).every((item) => !hover[item])
                    ? colors[(index + 1) % colors.length].active
                    : colors[(index + 1) % colors.length].disabled
                }
                onMouseOver={() => handleChange(project, true)}
                onMouseOut={() => handleChange(project, false)}
              >
                {variant === 'project' &&
                  data?.map((entry, index) => (
                    <Cell
                      key={index}
                      fill={
                        focusBar === index
                          ? theme.palette.primary.main
                          : focusBar !== index && focusBar >= 0
                          ? '#F7E6FA'
                          : theme.palette.primary.main
                      }
                    />
                  ))}
              </Bar>
            ))}
          </BackgroundChart>
        </BackgroundWrapper>

        <CustomBarChart
          className="CustomBarChart"
          data={data}
          onMouseMove={(state: any) => {
            if (state.isTooltipActive) setFocusBar(state.activeTooltipIndex);
          }}
          onMouseLeave={() => setFocusBar(-1)}
          width={getChartsWidth()}
          height={300}
          barCategoryGap={gap / 2}
        >
          <CartesianGrid strokeWidth={1} vertical={false} stroke="#CCCCCC" />
          <XAxis dataKey="time" tickLine={false} axisLine={false} />
          <YAxis
            domain={isEmpty ? [0, 500] : ['auto', 'auto']}
            tickLine={false}
            axisLine={false}
            allowDecimals={false}
            width={100}
          />
          <Tooltip
            content={
              <StackedBarTooltip
                data={data}
                activeValue={activeValue}
                variant={variant}
                isEmpty={isEmpty}
              />
            }
            filterNull
            cursor={{
              fill:
                variant === 'project'
                  ? 'none'
                  : theme.palette.chart.tooltipCursor,
            }}
            wrapperStyle={{
              border: 'none',
              outline: 'none',
              visibility: focusBar === -1 ? 'hidden' : 'visible',
            }}
          />
          {projectToRender.map((project, index) => (
            <Bar
              // onAnimationStart={index === 0 ? () => shiftBars() : () => null}
              // barSize={barSize}
              dataKey={project}
              key={project}
              radius={
                index === projectToRender.length - 1
                  ? [radius, radius, 0, 0]
                  : [0, 0, 0, 0]
              }
              stackId="a"
              fill={
                hover[project] ||
                Object.keys(hover).every((item) => !hover[item])
                  ? colors[(index + 1) % colors.length].active
                  : colors[(index + 1) % colors.length].disabled
              }
              onMouseOver={() => handleChange(project, true)}
              onMouseOut={() => handleChange(project, false)}
            >
              {variant === 'project' &&
                data?.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={
                      focusBar === index
                        ? theme.palette.primary.main
                        : focusBar !== index && focusBar >= 0
                        ? '#F7E6FA'
                        : theme.palette.primary.main
                    }
                  />
                ))}
            </Bar>
          ))}
        </CustomBarChart>
      </ScrollableContainer>
    </ResponsiveContainerStyled>
  );
};

const BackgroundWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  overflow: 'hidden',
});

const ScrollableContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'auto hidden',
  marginLeft: '-24px',

  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
    background: 'transparent',
    borderRadius: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.grey.A200,
    borderRadius: '7px',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));

const ResponsiveContainerStyled = styled(ResponsiveContainer)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '860px',
  width: '100%',
  order: 3,
}));

const CustomBarChart = styled(BarChartFromLib)({
  width: '100%',
  zIndex: '1000',
  marginLeft: '-60px',

  '.recharts-text': {
    fontFamily: 'Ranua',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '12px',
    fill: '#0F0311',
  },

  '.recharts-cartesian-grid-horizontal line': {
    stroke: '#D2D2D2',
  },
});

const BackgroundChart = styled(BarChartFromLib)({
  width: '100%',
  zIndex: -1,

  '.recharts-cartesian-grid-horizontal line': {
    stroke: '#D2D2D2',
  },

  '.recharts-text': {
    display: 'none',
  },
});

export default StackedBarChart;
